import { alpha, Box, styled, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from '../../../../store';

type CustomAddressProps = {
  isdefault: string;
};
export const AddressBoxStyle = styled(Box)<CustomAddressProps>(({ theme, isdefault }) => ({
  width: '300px',
  minHeight: '250px',
  borderRadius: '6px',
  border: `1px solid ${isdefault === 'false' ? alpha(theme.palette.primary.main, 0.23) : theme.palette.primary.main}`,
  padding: '12px 16px',
  color: theme.palette.primary.main,
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
  transition: 'all .3s'
}));

export const AddAddressBox = styled(AddressBoxStyle)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

type NewAddressBoxProps = {
  onClick: () => void;
  title: string;
  type: 'address' | 'billing';
};
const NewAddressBox = (props: NewAddressBoxProps) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Tooltip
      title={
        user?.isDemo
          ? props.type === 'address'
            ? "L'account demo non può aggiungere indirizzi di spedizione"
            : "L'account demo non può aggiungere dati di fatturazione"
          : !user?.building.registrationConfirmed
          ? props.type === 'address'
            ? 'Completa la registrazione per poter aggiungere indirizzi di spedizione'
            : 'Completa la registrazione per poter aggiungere indirizzi di fatturazione'
          : undefined
      }
      arrow
    >
      <AddAddressBox
        isdefault={'false'}
        onClick={() => {
          if (!user?.isDemo && user?.building.registrationConfirmed) props.onClick();
        }}
      >
        <Typography variant="subtitle2">{props.title}</Typography>
        <AddIcon fontSize="small" />
      </AddAddressBox>
    </Tooltip>
  );
};

export default NewAddressBox;
