import { useRef, useState } from 'react';

export default function useDelayLoading(initialValue: boolean, delay = 500): [boolean, (value: boolean) => void] {
  const [loading, setLoading] = useState<boolean>(initialValue);

  const delayLoading = useRef<boolean>(initialValue);
  const timeout = useRef<NodeJS.Timeout>();
  const setDelayLoading = (value: boolean) => {
    delayLoading.current = value;

    if (value) {
      timeout.current = setTimeout(() => {
        setLoading(true);
      }, delay);
    } else {
      clearInterval(timeout.current);
      setLoading(false);
    }
  };

  return [loading, setDelayLoading];
}
