import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const UploadIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="590" height="786" viewBox="0 0 590 786" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M589.86 785.86H0.580078V0.139648H511.04C554.58 0.139648 589.87 35.4296 589.87 78.9696V785.85L589.86 785.86Z"
        fill="#094754"
      />
      <path d="M589.859 137.64V70.6696C589.859 31.7196 558.279 0.139648 519.329 0.139648H452.359L589.859 137.64Z" fill="#91DCF9" />
      <path d="M452.359 137.64H589.859L452.359 0.139648V137.64Z" fill="#1ED367" />
      <path d="M173.17 302.11L295.72 302.61L417.27 303.11L294.72 180.56L292.08 183.19L173.17 302.11Z" fill="#F0FF32" />
      <path d="M472.009 581.09H118.439V659.66H472.009V581.09Z" fill="#91DCF9" />
      <path
        d="M295.219 484.4C315.295 484.4 331.569 468.126 331.569 448.05C331.569 427.975 315.295 411.7 295.219 411.7C275.144 411.7 258.869 427.975 258.869 448.05C258.869 468.126 275.144 484.4 295.219 484.4Z"
        fill="#F0FF32"
      />
      <path
        d="M295.219 571.37C315.289 571.37 331.569 555.1 331.569 535.02C331.569 514.95 315.299 498.67 295.219 498.67C275.149 498.67 258.869 514.94 258.869 535.02C258.869 555.09 275.139 571.37 295.219 571.37Z"
        fill="#F0FF32"
      />
      <path
        d="M295.219 658.349C315.289 658.349 331.569 642.079 331.569 621.999C331.569 601.929 315.299 585.649 295.219 585.649C275.149 585.649 258.869 601.919 258.869 621.999C258.869 642.069 275.139 658.349 295.219 658.349Z"
        fill="#1ED367"
      />
      <path
        d="M295.219 393C315.295 393 331.569 376.725 331.569 356.65C331.569 336.574 315.295 320.3 295.219 320.3C275.144 320.3 258.869 336.574 258.869 356.65C258.869 376.725 275.144 393 295.219 393Z"
        fill="#F0FF32"
      />
    </svg>
  </SvgIcon>
);

export default UploadIcon;
