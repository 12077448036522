import { Button, ButtonProps, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  isSuccess?: boolean;
}

const LoadingButton = ({ loading, isSuccess, children, ...rest }: LoadingButtonProps) => {
  // const theme = useTheme();
  return (
    <Button
      {...rest}
      {...(loading && { disabled: true })}
      {...(isSuccess && {
        color: 'success',
        disabled: true,
        sx: {
          ...rest.sx,
          '&.Mui-disabled': {
            color: 'text.primary',
            backgroundColor: '#49EBAA'
          }
        }
      })}
    >
      {isSuccess && (
        <CheckIcon
          color={'primary'}
          sx={{
            fontSize: rest.size === 'small' ? 15 : rest.size === 'medium' ? 20 : rest.size === 'large' ? 25 : 40,
            mr: 1.25
          }}
        />
      )}
      {children}
      {loading && (
        <CircularProgress
          size={rest.size === 'small' ? 15 : rest.size === 'medium' ? 20 : rest.size === 'large' ? 25 : 40}
          color="inherit"
          sx={{
            ml: 1.25
          }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
