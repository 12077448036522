import { Box, styled } from '@mui/material';
import { JSX } from 'react';

export type SecondaryCardProps = {
  title: JSX.Element;
  chip?: JSX.Element;
  children: JSX.Element;
  borderColor?: string;
};
const SecondaryCardBox = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '11px',
  padding: '15px',
  borderWidth: 2.5,
  borderStyle: 'solid'
  // border: `1px solid ${theme.palette.divider}`
}));

const HeaderSecondaryCard = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '30px'
}));

const SecondaryCard = ({ title, chip, children, borderColor }: SecondaryCardProps) => {
  return (
    <SecondaryCardBox
      sx={(theme) => ({
        borderColor: borderColor ?? theme.palette.divider
      })}
    >
      <HeaderSecondaryCard>
        <Box>{title}</Box>

        {chip != null && <Box>{chip}</Box>}
      </HeaderSecondaryCard>
      <Box>{children}</Box>
    </SecondaryCardBox>
  );
};

export default SecondaryCard;
