import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import { Box, Button, Typography } from '@mui/material';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import { ContactFormPresetEnum } from '../../../utils/contactFormPreset';

const SlowCreditSolutionModal = () => {
  const { trackAction } = useNavigationTracker();
  const navigate = useNavigate();
  const { closeModal } = useModal();
  return (
    <Box width={'100%'} textAlign={'center'} maxWidth={500} marginX={'auto'}>
      <Typography variant={'h4'} mb={4} mt={-3}>
        Vuoi utilizzare questa soluzione di credito?
      </Typography>

      <Typography variant={'body1'} mb={4}>
        Per ottenere questa soluzione di credito, contattaci al numero +39 02 84132058 o alla mail info@buyplus.it
      </Typography>

      <Button
        size={'medium'}
        color={'primary'}
        variant={'contained'}
        onClick={() => {
          trackAction({
            type: ClientTypeEnum.Link,
            action: ClientActionEnum.Clicked,
            name: 'Ha cliccato il bottone contattaci dal modal per utilizzare la soluzione di credito lenta'
          });
          navigate('/contacts', {
            state: {
              preset: ContactFormPresetEnum.SlowSolutions
            }
          });
          closeModal();
        }}
      >
        Contattaci
      </Button>
    </Box>
  );
};

export default SlowCreditSolutionModal;
