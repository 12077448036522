import { AxiosInstance } from 'axios';
type DownloadFileProps = {
  fileCode?: string;
  fileName: string;
  url?: string;
  axiosInstance: AxiosInstance;
};
export const downloadFile = ({ fileCode, fileName, url, axiosInstance }: DownloadFileProps) => {
  if (url == null && fileCode == null) throw new Error('fileCode and url not be both null');
  return axiosInstance
    .get(url ?? `/uploads/${fileCode}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage['rememberMe'] == 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']}`
      }
    })
    .then((response) => {
      const href = window.URL.createObjectURL(response.data);

      const anchorElement = document.createElement('a');

      anchorElement.href = href;
      anchorElement.download = fileName;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
    .catch((error) => {
      console.log('error: ', error);
      return Promise.reject(error);
    });
};
