import { PaymentCard } from '../OrderSummary/CardPaymentPlafond';
import { Box, SliderProps, Typography } from '@mui/material';
import PaymentRange from '../../PaymentRange';
import { FieldSummary } from '../../../../types/dto/orders.dto';
import { JSX } from 'react';
import Grid from '@mui/material/Grid';
import formatNumber from '../../../../utils/formatNumber';
type CardRentalProps = {
  title: string;
  company: string;
  monthlyFee?: FieldSummary;
  partnerLogo?: JSX.Element;
  updateLoading: boolean;
  deposit: {
    oldData: () =>
      | {
          min: number;
          max: number;
          value: number;
        }
      | undefined;
    step: SliderProps['step'];
    min: number;
    max: SliderProps['max'];
    value: SliderProps['value'];
    onChange: SliderProps['onChange'];
    onChangeCommited?: SliderProps['onChangeCommitted'];
  };
  duration: {
    oldData: () =>
      | {
          min: number;
          max: number;
          value: number;
        }
      | undefined;
    step?: SliderProps['step'];
    marks?: SliderProps['marks'];
    min?: SliderProps['min'];
    max?: SliderProps['max'];
    value: SliderProps['value'];
    onChange: SliderProps['onChange'];
    onChangeCommited?: SliderProps['onChangeCommitted'];
  };
  taxSaving: { ires: number; irap: number };
};

const CardRentalNewOrder = (props: CardRentalProps) => {
  const { deposit, duration, title, monthlyFee, updateLoading, taxSaving } = props;
  return (
    <PaymentCard disabled={false} maxWidth={'800px'} marginX={{ xs: 'auto', lg: '0' }}>
      <Box sx={{ px: { xs: 1, md: 1.5, xxl: '60px' } }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap={{ xs: 'wrap', lg: 'nowrap' }} mb={2}>
          <Typography fontSize={'1.4rem'} fontWeight={500} sx={{ mb: 2, mt: 1 }}>
            {title}
          </Typography>

          {monthlyFee != null && (
            <Typography fontSize={'1.2rem'} fontWeight={400}>
              Importo canone mensile:{' '}
              <span className={'fw_600'}>
                {monthlyFee.value.toLocaleString('it', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
                {monthlyFee.unit}
              </span>
            </Typography>
          )}
        </Box>

        {props.partnerLogo != null && props.partnerLogo}

        {/*Anticipo*/}
        <Box
          sx={{
            pt: 4,
            pb: 3,
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <Box width={{ xs: '100%', md: '20%' }} pb={{ xs: '35px', md: '25px' }}>
            <Typography variant={'h6'}>Anticipo</Typography>
          </Box>

          {/*Range*/}
          <Box width={{ xs: '100%', md: '70%' }} maxWidth={700}>
            <PaymentRange
              disabled={deposit.value == null || updateLoading}
              valueLabelDisplay={deposit.value == null ? 'off' : 'on'}
              value={deposit.value == null ? 0 : deposit.value}
              // min={deposit.value == null ? 0 : deposit.min}
              realMin={deposit.min ?? 0}
              min={0}
              onChange={(event, value, activeThumb) => {
                if (typeof value !== 'number' || deposit.onChange == null) return;
                if (value >= (deposit.min ?? 0)) {
                  deposit.onChange(event, value, activeThumb);
                } else {
                  deposit.onChange(event, deposit.min, activeThumb);
                }
              }}
              // onChange={deposit.onChange}
              max={deposit.max}
              step={deposit.step}
              // onChangeCommitted={deposit.onChangeCommited}
              onChangeCommitted={(event, value) => {
                if (deposit.onChangeCommited) {
                  if (value >= (deposit.min ?? 0)) {
                    deposit.onChangeCommited(event, value);
                  } else {
                    deposit.onChangeCommited(event, deposit.min ?? 0);
                  }
                }
              }}
              oldData={props.deposit.oldData()}
            />
          </Box>
        </Box>

        {/*Durata*/}
        <Box
          sx={{
            py: 3,
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <Box width={{ xs: '100%', md: '20%' }} pb={{ xs: '35px', md: '25px' }}>
            <Typography variant={'h6'}>Durata</Typography>
          </Box>
          <Box width={{ xs: '100%', md: '70%' }} maxWidth={700}>
            <PaymentRange
              monthlyLabel={true}
              value={duration.value}
              marks={duration.marks}
              min={duration.min}
              max={duration.max}
              onChange={duration.onChange}
              step={duration.step}
              onChangeCommitted={duration.onChangeCommited}
              disabled={updateLoading}
              oldData={props.duration.oldData()}
            />
          </Box>
        </Box>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} md={6}>
            <Box
              width={'100%'}
              minHeight={100}
              border={'1px solid #041C2214'}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography variant={'body1'} fontWeight={400} gutterBottom>
                Risparmio fiscale IRES
              </Typography>
              <Typography variant={'body1'} fontWeight={600} color={'success.dark'}>
                - {formatNumber(taxSaving.ires, { fractionDigits: 2 })} €
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              width={'100%'}
              minHeight={100}
              border={'1px solid #041C2214'}
              borderRadius={2}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography variant={'body1'} fontWeight={400} gutterBottom>
                Risparmio fiscale IRAP
              </Typography>
              <Typography variant={'body1'} fontWeight={600} color={'success.dark'}>
                - {formatNumber(taxSaving.irap, { fractionDigits: 2 })} €
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={2} mb={3}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Altri vantaggi per il cliente</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ul style={{ margin: 0, paddingLeft: 15 }}>
              <li>
                <Typography variant={'body1'}>Permette di crescere</Typography>
              </li>
              <li>
                <Typography variant={'body1'}>
                  Permette di avviare strategie innovative con <span className={'fw_600'}>contenimento dei rischi</span>
                </Typography>
              </li>
              <li>
                <Typography variant={'body1'}>
                  Minore oneri interni (il <span className={'fw_600'}>cespite non è a libri</span>)
                </Typography>
              </li>
              <li>
                <Typography variant={'body1'}>
                  Contribuisce a ridurre l'utile di bilancio (il <span className={'fw_600'}>canone</span> si contabilizza come costo
                  operativo <span className={'fw_600'}>integralmente deducibile</span>)
                </Typography>
              </li>
              <li>
                <Typography variant={'body1'}>
                  Gestione meno onerosa dei cespiti (<span className={'fw_600'}>manutenzione</span> concordata col fornitore)
                </Typography>
              </li>
              <li>
                <Typography variant={'body1'}>
                  <span className={'fw_600'}>Minore esposizione finanziaria</span>
                </Typography>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} md={6}>
            <ul style={{ margin: 0, paddingLeft: 15 }}>
              <li>
                <Typography variant={'body1'}>
                  <span className={'fw_600'}>Nessuna segnalazione in centrale rischi</span> perchè il noleggio non è un servizio finanziario
                  bensì commerciale
                </Typography>
              </li>

              <li>
                <Typography variant={'body1'}>
                  Capacità di mantenere la competitività grazie alla possibilità di <span className={'fw_600'}>aggiornare</span>{' '}
                  costantemente il proprio parco di <span className={'fw_600'}>beni</span> strumentali
                </Typography>
              </li>

              <li>
                <Typography variant={'body1'}>
                  Un unico <span className={'fw_600'}>interlocutore</span> (il locatore) che potrà servire a noleggio qualsivoglia bene
                  strumentale
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </PaymentCard>
  );
};

export default CardRentalNewOrder;
