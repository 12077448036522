import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Fade, FormHelperText, Grid, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '../../../components/custom/LoadingButton';
import { ShippingAddress } from '../../../types/dto/orders.dto';
import AsyncAutoComplete from '../../../components/custom/AsyncAutoComplete';
import { CityDTO, PostalCodeDTO, ProvinceDTO, RegionDTO } from '../../../types/formik/manual-integration-step';
import { ShippingAddressFormik } from '../../../types/formik/shipping-address';
import { PatternFormat } from 'react-number-format';
import { useEndpoint } from '../../../hooks/useEndpoint';
import useModal from '../../../hooks/useModal';
import { dispatch } from '../../../store';
import { openSnackbar } from '../../../store/reducers/snackbar';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';

const EditAddressForm = ({
  address,
  isDefault,
  buildingId,
  refetchAddress
}: {
  address: ShippingAddress;
  isDefault: boolean;
  buildingId: number;
  refetchAddress: () => Promise<any>;
}) => {
  const { closeModal } = useModal();
  const { trackAction } = useNavigationTracker();

  const editAddress = useEndpoint<Partial<ShippingAddressFormik>, 'patch'>({
    method: 'patch',
    endpoint: `/shipping-addresses/${address.id}`,
    mutationKey: `edit-shipping-address-${address.id}-${buildingId}`,
    options: {
      onSuccess: () => {
        refetchAddress();
        dispatch(
          openSnackbar({
            variant: 'success',
            message: 'Indirizzo modificato con successo!'
          })
        );

        closeModal();
      }
    }
  });
  const handleEditAddress = (values: ShippingAddressFormik, formikHelpers: FormikHelpers<ShippingAddressFormik>): void => {
    if (
      values.cap === address.cap &&
      values.address === address.address &&
      values.region === address.region &&
      values.city === address.city &&
      values.country === address.country &&
      values.province === address.province &&
      values.region === address.region
    ) {
      editAddress.mutate({
        isDefault: values.isDefault,
        contactPhone: values.contactPhone,
        contactFullname: values.contactFullname
      });
    } else editAddress.mutate(values);
    trackAction({
      type: ClientTypeEnum.Form,
      action: ClientActionEnum.Submit,
      name: "Ha modificato l'indirizzo di spedizione"
    });
  };

  return (
    <Formik<ShippingAddressFormik>
      initialValues={{
        isDefault: isDefault,
        contactPhone: address.contactPhone,
        contactFullname: address.contactFullname,
        region: address.region,
        country: address.country,
        province: address.province,
        city: address.city,
        cap: address.cap,
        address: address.address
      }}
      validationSchema={Yup.object().shape({
        contactFullname: Yup.string().required('error-referent-required'),
        city: Yup.object().required('error-city-required'),
        cap: Yup.string()
          .matches(/^\d{5}$/, 'error-postal-code-length')
          .required('error-postal-code-required'),
        province: Yup.object().required('error-province-required'),
        region: Yup.object().required('error-region-required'),
        country: Yup.object().required('error-nation-required'),
        address: Yup.string().required('error-address-required')
      })}
      onSubmit={handleEditAddress}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, setValues }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Fade in>
            <Grid container spacing={3}>
              {/*Referente*/}
              <Grid item xs={12}>
                <TextField
                  onBlur={(e) => {
                    handleBlur(e);
                    trackAction({
                      type: ClientTypeEnum.Input,
                      action: ClientActionEnum.Typed,
                      name: `Ha inserito il referente: ${values.contactFullname}`
                    });
                  }}
                  label={<FormattedMessage id="referent-label" />}
                  variant="outlined"
                  id="referent"
                  type="text"
                  name="contactFullname"
                  value={values.contactFullname}
                  onChange={handleChange}
                  fullWidth
                  error={Boolean(touched.contactFullname && errors.contactFullname)}
                />
                {touched.contactFullname && errors.contactFullname && (
                  <FormHelperText error id="standard-weight-helper-text-.contactFullname-register">
                    <FormattedMessage id={errors.contactFullname} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Regione */}
              <Grid item xs={12} md={6}>
                <AsyncAutoComplete<RegionDTO>
                  fullWidth={true}
                  endpoint={'/regions'}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name.it)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  query={{ filterBy: JSON.stringify([{ field: 'countryCode', value: { equals: values.country?.code } }]) }}
                  disabled={!values.country}
                  noOptionsText={'Nessuna regione trovata'}
                  onBlur={handleBlur}
                  id="region-register"
                  value={values.region}
                  renderInput={(params) => (
                    <TextField
                      label={<FormattedMessage id="region-label" />}
                      {...params}
                      error={Boolean(errors.region && touched.region && values.country)}
                      name="region"
                    />
                  )}
                  onChange={(value) => {
                    const castValue = value as RegionDTO;

                    setValues({
                      ...values,
                      region: castValue,
                      province: null,
                      city: null,
                      cap: '',
                      address: ''
                    });
                    trackAction({
                      type: ClientTypeEnum.Select,
                      action: ClientActionEnum.Selected,
                      name: castValue != null ? `Ha selezionato una regione: ${castValue.name.it}` : 'Ha rimosso la regione'
                    });
                  }}
                />
                {touched.region && errors.region && values.country && (
                  <FormHelperText error id="standard-weight-helper-text-region-register">
                    <FormattedMessage id={errors.region} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Province */}
              <Grid item xs={12} md={6}>
                <AsyncAutoComplete<ProvinceDTO>
                  disabled={!values.region}
                  fullWidth={true}
                  endpoint={'/provinces'}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name.it)}
                  query={{ filterBy: JSON.stringify([{ field: 'regionId', value: { equals: values.region?.id } }]) }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  onBlur={handleBlur}
                  noOptionsText={`Nessun risultato per la regione "${values.region?.name.it}"`}
                  id="province-register"
                  value={values.province}
                  renderInput={(params) => (
                    <TextField
                      label={<FormattedMessage id="province-label" />}
                      {...params}
                      error={Boolean(errors.province && touched.province && values.region)}
                      name="province"
                    />
                  )}
                  onChange={(value) => {
                    const castValue = value as ProvinceDTO;
                    setValues({
                      ...values,
                      province: castValue,
                      city: null,
                      cap: '',
                      address: ''
                    });
                    trackAction({
                      type: ClientTypeEnum.Select,
                      action: ClientActionEnum.Selected,
                      name: castValue != null ? `Ha selezionato una provincia: ${castValue.name.it}` : 'Ha rimosso la provincia'
                    });
                  }}
                />
                {touched.province && errors.province && values.region && (
                  <FormHelperText error id="standard-weight-helper-text-province-register">
                    <FormattedMessage id={errors.province} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Città */}
              <Grid item xs={12} md={6}>
                <AsyncAutoComplete<CityDTO>
                  disabled={!values.province}
                  fullWidth={true}
                  endpoint={'/cities'}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.name.it)}
                  query={{ filterBy: JSON.stringify([{ field: 'provinceCode', value: { equals: values.province?.code } }]) }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onBlur={handleBlur}
                  noOptionsText={`Nessun risultato per la provincia "${values.province?.name.it}"`}
                  id="city-register"
                  value={values.city}
                  renderInput={(params) => (
                    <TextField
                      label={<FormattedMessage id="city-label" />}
                      {...params}
                      error={Boolean(errors.city && touched.city && values.province)}
                      name="city"
                    />
                  )}
                  onChange={(value, serverOptions) => {
                    const castValue = value as CityDTO;
                    setValues({
                      ...values,
                      city: castValue,
                      cap: '',
                      address: ''
                    });
                    trackAction({
                      type: ClientTypeEnum.Select,
                      action: ClientActionEnum.Selected,
                      name: castValue != null ? `Ha selezionato una città: ${castValue.name.it}` : 'Ha rimosso la città'
                    });
                  }}
                />
                {touched.city && errors.city && values.province && (
                  <FormHelperText error id="standard-weight-helper-text-city-register">
                    <FormattedMessage id={errors.city} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Postal code */}
              <Grid item xs={12} md={6}>
                <AsyncAutoComplete<PostalCodeDTO, string>
                  disabled={!values.city}
                  fullWidth={true}
                  endpoint={'/postal-codes'}
                  isOptionEqualToValue={(option, value) => option === value}
                  query={{ filterBy: JSON.stringify([{ field: 'cityId', value: { equals: values.city?.id } }]) }}
                  mapServerResponse={(item) => item.code}
                  onBlur={handleBlur}
                  noOptionsText={`Nessun risultato per la città ${
                    values.city?.fraction != null ? values.city.fraction.name.it : values.city?.name.it
                  }`}
                  id="postal-code-register"
                  value={values.cap}
                  renderInput={(params) => (
                    <TextField
                      label={<FormattedMessage id="postal-code-label" />}
                      {...params}
                      error={Boolean(errors.cap && touched.cap && values.city)}
                      name="cap"
                    />
                  )}
                  onChange={(value, serverOptions) => {
                    const castValue = value as string;
                    setValues({
                      ...values,
                      cap: castValue,
                      address: ''
                    });
                    trackAction({
                      type: ClientTypeEnum.Select,
                      action: ClientActionEnum.Selected,
                      name: castValue != null ? `Ha selezionato un cap: ${castValue}` : 'Ha rimosso il cap'
                    });
                  }}
                />
                {touched.cap && errors.cap && values.city && (
                  <FormHelperText error id="standard-weight-helper-text-cap-register">
                    <FormattedMessage id={errors.cap} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Indirizzo */}
              <Grid item xs={12}>
                <TextField
                  onBlur={(e) => {
                    handleBlur(e);
                    trackAction({
                      type: ClientTypeEnum.Input,
                      action: ClientActionEnum.Typed,
                      name: `Ha inserito un indirizzo: ${values.address}`
                    });
                  }}
                  disabled={!values.cap}
                  label={<FormattedMessage id="address-label" />}
                  placeholder="Inserisci indirizzo"
                  variant="outlined"
                  id="address-register"
                  type="text"
                  name="address"
                  value={values.address ?? ''}
                  onChange={handleChange}
                  fullWidth
                  error={Boolean(touched.address && errors.address && values.cap)}
                />
                {touched.address && errors.address && values.cap && (
                  <FormHelperText error id="standard-weight-helper-text-address-register">
                    <FormattedMessage id={errors.address} />
                  </FormHelperText>
                )}
                <FormHelperText>Inserire l'indirizzo completo di numero civico</FormHelperText>
              </Grid>

              {/* Numero di telefono */}
              <Grid item xs={12}>
                <PatternFormat
                  id="structure-phone-register"
                  onBlur={(e) => {
                    handleBlur(e);
                    trackAction({
                      type: ClientTypeEnum.Input,
                      action: ClientActionEnum.Typed,
                      name: `Ha inserito un numero di telefono: ${values.contactPhone}`
                    });
                  }}
                  label={<FormattedMessage id="phone-label" />}
                  name="contactPhone"
                  variant="outlined"
                  error={Boolean(touched.contactPhone && errors.contactPhone)}
                  value={values.contactPhone}
                  format="+39##########"
                  onChange={handleChange}
                  fullWidth
                  customInput={TextField}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                />
                {touched.contactPhone && errors.contactPhone && values.address && (
                  <FormHelperText error id="standard-weight-helper-text-contactPhone-register">
                    <FormattedMessage id={errors.contactPhone} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Default */}
              {/*<Grid item xs={12}>*/}
              {/*  <FormControlLabel*/}
              {/*    color="default"*/}
              {/*    control={*/}
              {/*      <Checkbox*/}
              {/*        color="default"*/}
              {/*        value={values.isDefault}*/}
              {/*        onChange={(e, checked) => {*/}
              {/*          handleChange(e);*/}

              {/*          trackAction({*/}
              {/*            type: ClientTypeEnum.Checkbox,*/}
              {/*            action: ClientActionEnum.Checked,*/}
              {/*            name: checked ? `Ha selezionato l'indirizzo come defualt` : "Ha selezionato l'indirizzo come non di default"*/}
              {/*          });*/}
              {/*        }}*/}
              {/*        onBlur={handleBlur}*/}
              {/*        name="isDefault"*/}
              {/*        size="small"*/}
              {/*        defaultChecked={isDefault}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    sx={{*/}
              {/*      '.MuiTypography-root': {*/}
              {/*        fontSize: '.8rem',*/}
              {/*        color: '#3E4142'*/}
              {/*      }*/}
              {/*    }}*/}
              {/*    label="Imposta come indirizzo di spedizione predefinito"*/}
              {/*  />*/}
              {/*</Grid>*/}

              <Grid item xs={12} textAlign={'end'}>
                <LoadingButton
                  loading={editAddress.isLoading}
                  size="medium"
                  variant="contained"
                  color="primary"
                  type="submit"
                  // disabled={Object.keys(errors).length !== 0}
                >
                  Aggiorna
                </LoadingButton>
              </Grid>
            </Grid>
          </Fade>
        </form>
      )}
    </Formik>
  );
};

export default EditAddressForm;
