export type ContactForm = {
  phone: string;
  email: string;
  message: string;
  tag: ContactTag;
};

export enum ContactTag {
  General = 'general',
  EditUser = 'edit-user',
  UseSlowPartner = 'use-slow-partner',
  MoreCredit = 'more-credit',
  WalletRefused = 'wallet-refused',
  Credit = 'credit',
  Dashboard = 'dashboard',
  Document = 'document'
}
