import { TourProvider } from '@reactour/tour';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';

type TourNavigationProps = {
  children: React.ReactElement;
};

const TourNavigation = (props: TourNavigationProps) => {
  const theme = useTheme();
  const { children } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TourProvider
      scrollSmooth
      onClickMask={(clickProps) => clickProps.setIsOpen(true)}
      disableInteraction={true}
      showDots={false}
      showBadge={false}
      showCloseButton={false}
      defaultOpen={false}
      className={'custom_tour'}
      ContentComponent={(props) => {
        return (
          <Box width={'100%'} height={'100%'}>
            <Typography variant={'body1'}>{props.steps[props.currentStep]?.content as any}</Typography>
            <Box textAlign={isMobile ? 'center' : 'end'} mt={isMobile ? 2 : 3}>
              {props.currentStep === props.steps.length - 1 ? (
                <Button
                  size={'small'}
                  variant={'contained'}
                  onClick={() => {
                    props.setIsOpen(false);
                  }}
                >
                  Termina
                </Button>
              ) : (
                <Button
                  size={'small'}
                  variant={'contained'}
                  disabled={props.currentStep >= props.steps.length - 1}
                  onClick={() => {
                    props.setCurrentStep(props.currentStep + 1);
                  }}
                >
                  Avanti
                </Button>
              )}
            </Box>
          </Box>
        );
      }}
      steps={[]}
      // {
      //   selector: '#root',
      //   content: 'Benvenuto su buyplus',
      //   action: () => {
      //     navigate('/dashboard');
      //   }
      // },
      // {
      //   selector: '#navigation-drawer',
      //   content: 'Questo è il menu di navigazione della piattaforma, da qui potrai accedere alle diverse pagine'
      // },
      // {
      //   selector: '#header',
      //   content: "Questo è l'header"
      // },
      // {
      //   selector: '#button-info-page',
      //   content: 'Questo è un pratico bottone che ti spiega ciò che puoi fare in ogni pagine',
      //   stepInteraction: true,
      //   mutationObservables: ['.MuiDialog-root.MuiModal-root'],
      //   highlightedSelectors: ['.MuiDialog-root.MuiModal-root .MuiPaper-root'],
      //   actionAfter: () => {
      //     closeModal();
      //   }
      // },
      // {
      //   selector: '#notification_button',
      //   content: 'Da qui potrai accedere alle tue ultime notifiche'
      //   // mutationObservables: ['#notification-drawer'],
      //   // highlightedSelectors: ['#notification-drawer .MuiPaper-root'],
      //   // actionAfter: () => {
      //   //   console.log('action after');
      //   //   closeDrawer();
      //   // }
      // },
      // {
      //   selector: '#root',
      //   content: 'Iniziamo a scoprire le varie pagine della piattaforma'
      // },
      // {
      //   selector: '#main',
      //   content: 'Questa è la tua dashboard, qui hai tutto ciò che ti interessa a portata di mano'
      // },
      // {
      //   selector: '[data-tour="dashboard-chart"]',
      //   content:
      //     'Qui hai a disposizione il grafico sul completamento dei tuoi dati, il conto degli ordini effettuati e un riepilogo generale del tuo wallet'
      // },
      // {
      //   selector: '[data-tour="activity-box"]',
      //   content: "Qui hai una checklist delle azioni che ti consigliamo di fare per ottimizzare l'utilizzo della piattaforma"
      // },
      // {
      //   selector: '[data-tour="box-learning"]',
      //   content: 'Questo è un box con le domande più frequenti e con il materiale che potrebbe esserti utile',
      //   action: () => {
      //     navigate('/dashboard');
      //   }
      // },
      // {
      //   selector: '#nav-item-wallet',
      //   content: 'Navighiamo al nostro wallet',
      //   actionAfter: () => {
      //     navigate('/wallet');
      //   }
      // },
      // {
      //   selector: '#main',
      //   content: 'Questa è la pagina del tuo wallet, qui puoi visualizzare tutte le soluzioni di pagamento'
      // },
      // {
      //   selector: 'div[data-tour="wallet-box"]',
      //   content:
      //     'Questo è il riepilogo del tuo wallet, da qui puoi controllare quanto plafond hai a disposizione e quanto ne hai utilizzato'
      // },
      // {
      //   selector: '#summary-plafond',
      //   content:
      //     'Da qui puoi consultare tutti i plafond a tua disposizione, con i relativi stati di attivazione, disponibilità e utilizzo',
      //   position: 'top'
      // },
      // {
      //   selector: '[data-tour="activity-box"]',
      //   content: 'Qui abbiamo il nostro box che ci ricorda le attività da fare, lo stesso che abbiamo in dashboard'
      // },
      // {
      //   selector: '#nav-button-new-order-button',
      //   resizeObservables: ['#nav-button-new-order-button'],
      //   content: 'Creiamo un ordine',
      //   // actionAfter: () => {
      //   //   navigate('/orders/new-order');
      //   // },
      //   disableActions: true,
      //   stepInteraction: true
      // }
    >
      {children}
    </TourProvider>
  );
};

export default TourNavigation;
