import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  alpha,
  styled,
  useMediaQuery
} from '@mui/material';

// project import
import Transitions from 'components/Transitions';
import useAuth from 'hooks/useAuth';

// assets
import PeopleIcon from 'components/custom/BuyPlusIcons/PeopleIcon';
import { useSelector } from 'store';
import { useNavigate } from 'react-router';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({ isDrawerMobile }: { isDrawerMobile?: boolean }) => {
  const theme = useTheme();
  const isBreakDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const ListItemButtonCustom = styled(ListItemButton)(({ theme }) => ({
    '&:hover': { color: theme.palette.primary.main },
    color: alpha(theme.palette.text.secondary, 0.6)
  }));

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        sx={{ ...((!isBreakDownSM || isDrawerMobile) && { borderRadius: 2 }) }}
        size="medium"
        color="info"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" alignItems="center">
          <PeopleIcon fontSize="medium" />

          {(!isTablet || isDrawerMobile) && (
            <Typography variant="body2" color={'common.white'} ml={1} {...(isTablet && !isBreakDownSM && { maxWidth: 90, noWrap: true })}>
              {user?.building?.name}
            </Typography>
          )}
        </Stack>
      </IconButton>
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 9999 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  minWidth: 100
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box width={'100%'} height={'100%'} bgcolor={alpha(theme.palette.primary.main, 0.04)}>
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButtonCustom
                          onClick={() => {
                            handleToggle();
                            navigate('/account/settings');
                          }}
                        >
                          <ListItemText primary="Account" />
                        </ListItemButtonCustom>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButtonCustom onClick={handleLogout}>
                          <ListItemText primary="Logout" />
                        </ListItemButtonCustom>
                      </ListItem>
                    </List>
                  </Box>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
