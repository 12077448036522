import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { NewValuesStep4Type } from '../../types/formik/manual-integration-step';
import { useMemo } from 'react';
import { ErrorMessage, FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NumericFormat } from 'react-number-format';
import BigNumber from 'bignumber.js';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { Link } from '@mui/material';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import * as Yup from 'yup';
import FormHelperText from '@mui/material/FormHelperText';
import { useEndpoint } from '../../hooks/useEndpoint';
import { useSelector } from '../../store';
import LoadingButton from '../../components/custom/LoadingButton';
import { BuildingSalesDto } from '../../types/dto/building-sales.dto';
import useModal from '../../hooks/useModal';
interface Props {
  refetchBuilding: () => Promise<any>;
}
const ModalNewBuildingSales = (props: Props) => {
  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const { closeModal } = useModal();
  const { user } = useSelector((state) => state.auth);
  const newBuildingSales = useEndpoint<BuildingSalesDto, 'post'>({
    method: 'post',
    endpoint: `/buildings/${user?.building.id}/sales`,
    mutationKey: 'add-new-building-sales',
    options: {
      onSuccess: async () => {
        await props.refetchBuilding();
        closeModal();
      }
    }
  });

  const startMonthPeriods = dayjs().subtract(1, 'month');
  const periods: NewValuesStep4Type['periods'] = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => ({
        opening: true,
        month: startMonthPeriods.subtract(i, 'month').get('month'),
        year: startMonthPeriods.subtract(i, 'month').get('year'),
        closingDays: undefined,
        turnover: undefined,
        occupancy: undefined
      })).reverse(),
    []
  );

  const manualIntegrationStep4Form = useFormik<Partial<NewValuesStep4Type> & Required<Pick<NewValuesStep4Type, 'periods' | 'channels'>>>({
    initialValues: {
      directChannelsPercentage: undefined,
      otaPercentage: undefined,
      periods,
      channels: []
    },
    validationSchema: Yup.object().shape({
      directChannelsPercentage: Yup.number().required('Inserisci la percentuale del canale diretto'),
      channels: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Inserisci il nome del canale'),
          percentage: Yup.number().required('Inserisci la percentuale')
        })
      ),
      periods: Yup.array()
        .of(
          Yup.object().shape({
            opening: Yup.boolean(),
            month: Yup.string(),
            year: Yup.string(),
            turnover: Yup.number().test('turnover', 'Inserisci il fatturato', function (value, context) {
              return (value != null && context.parent.opening) || (value == null && !context.parent.opening);
            }),
            occupancy: Yup.number().test('occupancy', "Inserisci l'occupazione", function (value, context) {
              return (value != null && context.parent.opening) || (value == null && !context.parent.opening);
            })
          })
        )
        .test(function (value, context) {
          if (value?.some((v) => v.opening)) return;
          return context.createError({
            path: 'periods',
            message: 'Inserisci almeno un mese di apertura'
          });
          // return value?.some((v) => v.opening);
        })
    }),
    onSubmit: (values) => {
      newBuildingSales.mutate({
        directChannelsPercentage: values.directChannelsPercentage!,
        otaPercentage: values.otaPercentage!,
        channels: values.channels,
        periods: values.periods
          .filter((p) => p.opening)
          .map((p) => ({
            month: p.month!,
            year: p.year!,
            closingDays: p.closingDays!,
            turnover: p.turnover!,
            occupancy: p.occupancy!
          }))
      });
    }
  });
  return (
    <Box px={'5px'}>
      <FormikProvider value={manualIntegrationStep4Form}>
        <form noValidate onSubmit={manualIntegrationStep4Form.handleSubmit}>
          <Grid container spacing={3} pb={5} maxWidth={700}>
            {!isDownMD && (
              <Grid item xs={12}>
                <Grid container alignItems={'center'} columnSpacing={1}>
                  <Grid item xs={6} md={2.5} />

                  <Grid item xs={6} md={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Apertura
                    </Typography>
                  </Grid>

                  <Grid item xs={4} md={2.5}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Giorni chiusura
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={2.5}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Fatturato
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={2.5}>
                    <Typography variant={'body1'} fontWeight={500}>
                      Occupancy
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {manualIntegrationStep4Form.values.periods.map((period, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <Grid container alignItems={'flex-start'} columnSpacing={1} rowSpacing={0.5}>
                    <Grid item xs={4} md={2.5} display={'flex'} alignItems={'center'}>
                      <Typography variant={'body1'} color={'primary'}>
                        {dayjs().set('year', period.year!).set('month', period.month!).format('MMMM YYYY')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={2} display={'flex'} justifyContent={{ xs: 'flex-start', md: 'center' }} alignItems={'center'}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Apertura
                        </Typography>
                      )}
                      <Switch
                        checked={period.opening}
                        value={period.opening}
                        name={`periods[${i}].opening`}
                        onChange={(event, checked) => {
                          // manualIntegrationStep4Form.handleChange(event);
                          manualIntegrationStep4Form.setFieldValue(`periods[${i}]`, {
                            opening: checked,
                            month: period.month,
                            year: period.year,
                            closingDays: undefined,
                            turnover: undefined,
                            occupancy: undefined
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} md={2.5}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Giorni chiusura
                        </Typography>
                      )}
                      <NumericFormat
                        customInput={TextField}
                        disabled={!period.opening}
                        size={'medium'}
                        onBlur={(e) => {
                          manualIntegrationStep4Form.handleBlur(e);
                          // trackAction({
                          //   type: ClientTypeEnum.Input,
                          //   action: ClientActionEnum.Typed,
                          //   name: `Ha inserito il fatturato diretto annuale: ${values.annual.directSales}`
                          // });
                        }}
                        variant="outlined"
                        name={`periods[${i}].closingDays`}
                        value={manualIntegrationStep4Form.values.periods[i].closingDays ?? ''}
                        onValueChange={(values) => manualIntegrationStep4Form.setFieldValue(`periods[${i}].closingDays`, values.floatValue)}
                        fullWidth
                        valueIsNumericString={false}
                        decimalScale={0}
                        allowNegative={false}
                        isAllowed={(values) => {
                          if (values.floatValue == null) return true;
                          const monthDays = dayjs().set('month', period.month!).set('year', period.year!).daysInMonth();
                          return BigNumber(values.floatValue).isLessThan(BigNumber(monthDays));
                        }}
                        // error={Boolean(touched.annual?.directSales && errors.annual?.directSales)}
                      />
                    </Grid>
                    <Grid item xs={4} md={2.5}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Fatturato
                        </Typography>
                      )}
                      <NumericFormat
                        customInput={TextField}
                        disabled={!period.opening}
                        size={'medium'}
                        onBlur={(e) => {
                          manualIntegrationStep4Form.handleBlur(e);
                          // trackAction({
                          //   type: ClientTypeEnum.Input,
                          //   action: ClientActionEnum.Typed,
                          //   name: `Ha inserito il fatturato diretto annuale: ${values.annual.directSales}`
                          // });
                        }}
                        variant="outlined"
                        name={`periods[${i}].turnover`}
                        value={manualIntegrationStep4Form.values.periods[i].turnover ?? ''}
                        allowNegative={false}
                        onValueChange={(values) => {
                          manualIntegrationStep4Form.setFieldValue(`periods[${i}].turnover`, values.floatValue);
                        }}
                        fullWidth
                        valueIsNumericString={false}
                        suffix={' €'}
                        decimalScale={2}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        error={
                          getIn(manualIntegrationStep4Form.errors, `periods[${i}].turnover`) &&
                          getIn(manualIntegrationStep4Form.touched, `periods[${i}].turnover`)
                        }
                      />
                      <ErrorMessage
                        name={`periods[${i}].turnover`}
                        render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                      />
                    </Grid>
                    <Grid item xs={4} md={2.5}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Occupancy
                        </Typography>
                      )}
                      <NumericFormat
                        customInput={TextField}
                        disabled={!period.opening}
                        size={'medium'}
                        onBlur={(e) => {
                          manualIntegrationStep4Form.handleBlur(e);
                          // trackAction({
                          //   type: ClientTypeEnum.Input,
                          //   action: ClientActionEnum.Typed,
                          //   name: `Ha inserito il fatturato diretto annuale: ${values.annual.directSales}`
                          // });
                        }}
                        variant="outlined"
                        name={`periods[${i}].occupancy`}
                        value={manualIntegrationStep4Form.values.periods[i].occupancy ?? ''}
                        onValueChange={(values) => {
                          manualIntegrationStep4Form.setFieldValue(`periods[${i}].occupancy`, values.floatValue);
                        }}
                        fullWidth
                        valueIsNumericString={false}
                        allowNegative={false}
                        suffix={' %'}
                        decimalScale={2}
                        decimalSeparator={','}
                        isAllowed={(values) => {
                          if (values.floatValue == null) return true;
                          return BigNumber(values.floatValue).isLessThanOrEqualTo(BigNumber(100));
                        }}
                        error={
                          getIn(manualIntegrationStep4Form.errors, `periods[${i}].occupancy`) &&
                          getIn(manualIntegrationStep4Form.touched, `periods[${i}].occupancy`)
                        }
                      />
                      <ErrorMessage
                        name={`periods[${i}].occupancy`}
                        render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            {manualIntegrationStep4Form.errors.periods && typeof manualIntegrationStep4Form.errors.periods === 'string' && (
              <Grid item xs={12}>
                <FormHelperText error>{manualIntegrationStep4Form.errors.periods}</FormHelperText>
              </Grid>
            )}

            <Grid item xs={12} my={1}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant={'body1'} fontWeight={500}>
                Inserisci qui gli ultimi dati relativi alla tua struttura per completare il profilo.
              </Typography>
              <Typography variant={'body1'} fontWeight={300}>
                *dati relativi sull’anno attuale.
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <NumericFormat
                customInput={TextField}
                size={'medium'}
                label={'Percentuale canale diretti'}
                onBlur={(e) => {
                  manualIntegrationStep4Form.handleBlur(e);
                  // trackAction({
                  //   type: ClientTypeEnum.Input,
                  //   action: ClientActionEnum.Typed,
                  //   name: `Ha inserito il fatturato diretto annuale: ${values.annual.directSales}`
                  // });
                }}
                variant="outlined"
                name={`directChannelsPercentage`}
                value={manualIntegrationStep4Form.values.directChannelsPercentage ?? ''}
                onValueChange={(values) => {
                  manualIntegrationStep4Form.setFieldValue(`directChannelsPercentage`, values.floatValue);
                }}
                fullWidth
                valueIsNumericString={false}
                allowNegative={false}
                suffix={' %'}
                decimalScale={2}
                decimalSeparator={','}
                isAllowed={(values) => {
                  if (values.floatValue == null) return true;
                  return BigNumber(values.floatValue).isLessThanOrEqualTo(BigNumber(100));
                }}
                error={Boolean(
                  manualIntegrationStep4Form.errors.directChannelsPercentage && manualIntegrationStep4Form.touched.directChannelsPercentage
                )}
              />
              {Boolean(
                manualIntegrationStep4Form.errors.directChannelsPercentage && manualIntegrationStep4Form.touched.directChannelsPercentage
              ) && <FormHelperText error>{manualIntegrationStep4Form.errors.directChannelsPercentage}</FormHelperText>}
            </Grid>
            <Grid item xs={6}>
              <NumericFormat
                customInput={TextField}
                size={'medium'}
                label={'Percentuale OTA'}
                onBlur={(e) => {
                  manualIntegrationStep4Form.handleBlur(e);
                  // trackAction({
                  //   type: ClientTypeEnum.Input,
                  //   action: ClientActionEnum.Typed,
                  //   name: `Ha inserito il fatturato diretto annuale: ${values.annual.directSales}`
                  // });
                }}
                variant="outlined"
                name={`otaPercentage`}
                value={manualIntegrationStep4Form.values.otaPercentage ?? ''}
                onValueChange={(values) => {
                  manualIntegrationStep4Form.setFieldValue(`otaPercentage`, values.floatValue);
                }}
                fullWidth
                valueIsNumericString={false}
                allowNegative={false}
                suffix={' %'}
                decimalScale={2}
                decimalSeparator={','}
                isAllowed={(values) => {
                  if (values.floatValue == null) return true;
                  return BigNumber(values.floatValue).isLessThanOrEqualTo(BigNumber(100));
                }}
                // error={Boolean(touched.annual?.directSales && errors.annual?.directSales)}
              />
            </Grid>

            <FieldArray
              name={'channels'}
              render={(arrayHelpers) => {
                return (
                  <>
                    {manualIntegrationStep4Form.values.channels.map((c, i) => (
                      <Fade in key={i}>
                        <Grid item xs={12}>
                          <Grid container columnSpacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                size={'medium'}
                                label={'Nome canale'}
                                fullWidth
                                value={manualIntegrationStep4Form.values.channels[i].name ?? ''}
                                name={`channels[${i}].name`}
                                onChange={manualIntegrationStep4Form.handleChange}
                                onBlur={manualIntegrationStep4Form.handleBlur}
                                error={
                                  getIn(manualIntegrationStep4Form.errors, `channels[${i}].name`) &&
                                  getIn(manualIntegrationStep4Form.touched, `channels[${i}].name`)
                                }
                              />
                              <ErrorMessage
                                name={`channels[${i}].name`}
                                render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <NumericFormat
                                customInput={TextField}
                                size={'medium'}
                                label={'Percentuale canale'}
                                onBlur={(e) => {
                                  manualIntegrationStep4Form.handleBlur(e);
                                  // trackAction({
                                  //   type: ClientTypeEnum.Input,
                                  //   action: ClientActionEnum.Typed,
                                  //   name: `Ha inserito il fatturato diretto annuale: ${values.annual.directSales}`
                                  // });
                                }}
                                variant="outlined"
                                name={`channels[${i}].percentage`}
                                value={manualIntegrationStep4Form.values.channels[i].percentage ?? ''}
                                onValueChange={(values) => {
                                  manualIntegrationStep4Form.setFieldValue(`channels[${i}].percentage`, values.floatValue);
                                }}
                                fullWidth
                                valueIsNumericString={false}
                                allowNegative={false}
                                suffix={' %'}
                                decimalScale={2}
                                decimalSeparator={','}
                                isAllowed={(values) => {
                                  if (values.floatValue == null) return true;
                                  return BigNumber(values.floatValue).isLessThanOrEqualTo(BigNumber(100));
                                }}
                                error={
                                  getIn(manualIntegrationStep4Form.errors, `channels[${i}].percentage`) &&
                                  getIn(manualIntegrationStep4Form.touched, `channels[${i}].percentage`)
                                }
                              />
                              <ErrorMessage
                                name={`channels[${i}].percentage`}
                                render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                              />
                            </Grid>
                            <Grid item xs={1} alignItems={'center'} justifyContent={'center'} display={'flex'}>
                              <IconButton
                                size={'small'}
                                onClick={() => {
                                  arrayHelpers.remove(i);
                                }}
                              >
                                <CancelIcon fontSize={'small'} sx={{ color: 'text.disabled' }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Fade>
                    ))}

                    <Grid item xs={12}>
                      <Typography
                        variant={'body2'}
                        component={Link}
                        underline={'hover'}
                        onClick={() => {
                          arrayHelpers.push({
                            percentage: undefined,
                            name: ''
                          });
                        }}
                      >
                        + Aggiungi un nuovo canale
                      </Typography>
                    </Grid>
                  </>
                );
              }}
            />

            <Grid item xs={12} mt={3} textAlign={'end'}>
              <LoadingButton loading={newBuildingSales.isLoading} size={'medium'} type={'submit'} variant={'contained'}>
                Aggiungi dati di vendita
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default ModalNewBuildingSales;
