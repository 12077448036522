import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const WalletNegativeIcon = ({ fontSize, sx }: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={sx ?? {}} fontSize={fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9602_112433)">
        <path
          d="M13.0869 11.7889C13.0869 10.1162 14.4429 8.76058 16.1152 8.76058H22.0219V5.96277C22.0219 4.32657 20.6956 3.00024 19.0594 3.00024H0V20.0194H22.0219V14.8175H16.1152C14.4426 14.8175 13.0869 13.4615 13.0869 11.7892V11.7889Z"
          fill="#F0FF32"
        />
        <path d="M24.0001 8.76025H22.022V14.8171H24.0001V8.76025Z" fill="#91DCF9" />
        <path
          d="M16.1156 8.76031C14.4429 8.76031 13.0873 10.1163 13.0873 11.7886C13.0873 13.4612 14.4432 14.8169 16.1156 14.8169H22.0223V8.76001H16.1156V8.76031Z"
          fill="#1ED367"
        />
        <path
          d="M16.9566 12.9767C17.6125 12.9767 18.1443 12.4449 18.1443 11.789C18.1443 11.1331 17.6125 10.6013 16.9566 10.6013C16.3007 10.6013 15.7689 11.1331 15.7689 11.789C15.7689 12.4449 16.3007 12.9767 16.9566 12.9767Z"
          fill="#F0FF32"
        />
        <path
          d="M16.9566 13.4207C17.8578 13.4207 18.5883 12.6902 18.5883 11.789C18.5883 10.8878 17.8578 10.1572 16.9566 10.1572C16.0554 10.1572 15.3248 10.8878 15.3248 11.789C15.3248 12.6902 16.0554 13.4207 16.9566 13.4207Z"
          fill="#094754"
        />
      </g>
      <defs>
        <clipPath id="clip0_9602_112433">
          <rect width="24" height="17.0191" fill="white" transform="translate(0 3)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default WalletNegativeIcon;
