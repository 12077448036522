import { Box, IconButton, List, Typography } from '@mui/material';
import { ListItemNotifyStyle } from 'pages/notifications/NotificationsPage';
import SimpleBar from 'simplebar-react';
import { NotificationDTO } from 'types/dto/notification.dto';
import getTimeSpent from 'utils/getTimeSpent';
import NotificationSkeleton from '../../../../components/custom/Skeletons/NotificationSkeleton';
import { getNotificationAction, getNotificationTitle } from '../../../../utils/notificationUtils';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useNavigate } from 'react-router-dom';

type ContentNotificationProps = {
  notifications?: NotificationDTO[];
  handleReadNotification: (id: number) => void;
  canRead: boolean;
};

const ContentNotifcationDrawer = ({ notifications, handleReadNotification, canRead }: ContentNotificationProps) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, width: '100%', '.MuiTypography-root': { whiteSpace: 'normal' }, overflowY: 'hidden' }}>
      <SimpleBar style={{ paddingTop: '15px', paddingBottom: '15px', height: '100%' }}>
        <List>
          {notifications && notifications.length > 0 ? (
            notifications.map((notify, i) => {
              const notifyAction = notify.operation === 'delete' && notify.resource === 'order' ? undefined : getNotificationAction(notify);
              return (
                <ListItemNotifyStyle
                  read={notify.readAt != null ? 'false' : 'true'}
                  key={i}
                  cursor={notifyAction != null ? 'pointer' : 'default'}
                  onClick={() => {
                    if (notifyAction == null) return;

                    navigate(notifyAction);
                    if (notify.readAt == null && canRead) handleReadNotification(notify.id);
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '3px',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {getNotificationTitle(notify)}
                    </Typography>
                    {notify.description != null && <Typography variant={'body1'}>{notify.description.it}</Typography>}
                    <Typography variant={'body2'}>{getTimeSpent(notify.at)}</Typography>
                  </Box>

                  {notify.readAt == null && canRead && (
                    <IconButton
                      sx={{ width: '30px', height: '30px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReadNotification(notify.id);
                      }}
                    >
                      <RadioButtonUncheckedIcon color={'primary'} fontSize={'small'} />
                    </IconButton>
                  )}
                </ListItemNotifyStyle>
              );
            })
          ) : notifications?.length === 0 ? (
            <ListItemNotifyStyle read="false" cursor={'default'}>
              <Typography variant="h6" fontWeight={400}>
                Nessuna Notifica
              </Typography>
            </ListItemNotifyStyle>
          ) : (
            <NotificationSkeleton item={10} />
          )}
        </List>
      </SimpleBar>
    </Box>
  );
};

export default ContentNotifcationDrawer;
