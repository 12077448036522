import { useEndpoint } from '../hooks/useEndpoint';
import { SingleDataDTO } from '../types/server/single-data';
import { AppInfoDTO } from '../types/dto/app-info.dto';
import { Box, Slide, Typography } from '@mui/material';
import UnderMaintenanceIllustration from 'assets/images/illustrations/auth/under_maintenance.svg';
import Logo from 'assets/images/logo/logo-full.svg';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { dispatch, useSelector } from '../store';
import { setRefetchAppState } from '../store/reducers/websocket';
import * as process from 'process';
const UnderMaintenance = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { refetchAppState } = useSelector((state) => state.websocket);
  const onlineEnvironment =
    process.env.REACT_APP_ENVIRONMENT === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'test';
  const isTecnonewsTeam = localStorage['team'] === 'tecnonews';

  const getAppInfo = useEndpoint<SingleDataDTO<AppInfoDTO>, 'get'>({
    method: 'get',
    endpoint: '/info/public',
    queryKey: 'get-info-app'
  });

  useEffect(() => {
    if (getAppInfo.data?.data.data.maintenance && onlineEnvironment && !isTecnonewsTeam) {
      document.body.classList.add('body-bootstrap');
    }
  }, [getAppInfo.data?.data.data]);

  useEffect(() => {
    if (!isTecnonewsTeam && searchParams.get('team') === 'tecnonews') {
      localStorage.setItem('team', 'tecnonews');
      searchParams.delete('team');
      setSearchParams(searchParams);
    } else if (searchParams.get('team') === 'tecnonews') {
      searchParams.delete('team');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (refetchAppState) {
      getAppInfo.refetch().then(() => dispatch(setRefetchAppState(false)));
    }
  }, [refetchAppState]);

  return (
    <Slide in={getAppInfo.data?.data.data.maintenance === true && onlineEnvironment && !isTecnonewsTeam} direction={'down'} unmountOnExit>
      <Box
        sx={(theme) => ({
          padding: 3,
          textAlign: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          borderTop: `4px solid ${theme.palette.secondary.main}`,
          zIndex: 9999999,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          img: {
            width: '100%',
            maxWidth: 550
          }
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 20,
            left: 20,
            img: {
              width: 170
            }
          }}
        >
          <img alt={'logo buyplus'} src={Logo} />
        </Box>
        <img src={UnderMaintenanceIllustration} alt={'under maintenance illustration'} />
        <Typography variant="h2" fontWeight={400} mt={3}>
          Piattaforma in manutenzione
        </Typography>
        <Typography variant="h4" color={'white'} fontWeight={600} mt={3}>
          Riprova più tardi
        </Typography>
      </Box>
    </Slide>
  );
};

export default UnderMaintenance;
