import { styled } from '@mui/material/styles';
import { alpha, Box, Slider, SliderProps, Stack, Typography } from '@mui/material';
import formatNumber from '../../utils/formatNumber';
// interface CustomRangeProps extends SliderProps {
//   minOpacityWidth: number;
// }
export const CustomRange = styled(Slider)(() => ({
  padding: '0!important',
  height: 28,
  width: '100%',
  // width: { sx: 200, md: 300, lg: 400 },
  // position: 'relative',
  // '&::after': {
  //   content: '""',
  //   display: 'block',
  //   // width: `${minOpacityWidth ?? 0}%`,
  //   height: '100%',
  //   backgroundColor: alpha('#EBEBEB', 1),
  //   position: 'absolute',
  //   zIndex: 10,
  //   top: 0,
  //   left: 0,
  //   bottom: 0,
  //   borderTopLeftRadius: 50,
  //   borderBottomLeftRadius: 50
  // },
  borderRadius: 50,
  '&.Mui-disabled': {
    '& .MuiSlider-track': {
      color: alpha('#F0FF32', 0.3)
    }
  },
  '& .MuiSlider-track': {
    height: 26,
    color: '#F0FF32'
    // backgroundImage: 'linear-gradient(to right, #F0FF32 50%, #EB694E 50%)'
  },
  '& .MuiSlider-rail': {
    height: 26,
    color: '#EBEBEB'
  },
  '& .MuiSlider-thumb': {
    height: 26,
    width: 26,
    zIndex: 20,
    backgroundColor: '#C8D600!important',
    border: '2px solid #FFFFFF',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
    '& .Mui-active, &:hover, &.Mui-focusVisible': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)'
    },
    '&:before': {
      display: 'none'
    }
    // '&.Mui-disabled': {
    //   boxShadow: 'none',
    //   backgroundColor: '#c7d500!important'
    // }
  },
  '& .MuiSlider-valueLabel': {
    background: '#094754',
    padding: '4px 8px',
    // minWidth: '80px',
    height: 26,
    borderRadius: '4px',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    position: 'relative',
    zIndex: 10
  },
  '& .MuiSlider-mark': {
    height: '15px',
    backgroundColor: '#EBEBEB',
    top: '100%',
    transform: 'none'
  }
}));

type PaymentRangeProps = SliderProps & {
  monthlyLabel?: boolean;
  oldData?: {
    min: number;
    max: number;
    value: number;
  };
  realMin?: number;
};

// const percentageThreshold = (value: number, threshold: number, min: number, max: number) => {
//   return ((value - threshold) / (max - min)) * 100;
// };

const PaymentRange = (props: PaymentRangeProps) => {
  const { monthlyLabel, oldData, realMin, ...other } = props;

  const minOpacityWidth = () => {
    if (props.realMin === 0 || props.realMin == null || props.max == null || props.max == 0) return 0;

    return (props.realMin / props.max) * 100;
  };

  return (
    <Stack sx={{ width: '100%' }} data-tour={'plafond_slider'}>
      <Box position={'relative'} sx={{ height: 28 }}>
        <CustomRange
          {...other}
          min={props.min === props.max ? 0 : props.min}
          disabled={props.disabled || props.min === props.max}
          // minOpacityWidth={minOpacityWidth()}
          // sx={{
          //   '&::after': {
          //     width: `${minOpacityWidth()}%`
          //   }
          // }}
          color={'primary'}
          className={
            oldData != null && props.value != null && oldData.value.toFixed(0) !== (props.value as number).toFixed(0)
              ? 'change-animation'
              : ''
          }
          valueLabelDisplay={props.valueLabelDisplay ?? 'on'}
          valueLabelFormat={(value) =>
            `${value === props.realMin ? 'Minimo: ' : ''} ${formatNumber(value, { fractionDigits: 0 })} ${
              monthlyLabel != null && monthlyLabel ? ' mesi' : monthlyLabel != null && !monthlyLabel ? ' €' : ' €'
            }`
          }
        />
        {realMin != null && (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                height: 28,
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
                left: 0,
                backgroundColor: alpha('#f5f5f5', 0.8),
                width: `${minOpacityWidth()}%`
              }}
            />
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    position: 'absolute',*/}
            {/*    top: -32.5,*/}
            {/*    left: 0,*/}
            {/*    textAlign: 'end',*/}
            {/*    width: `${minOpacityWidth() + 4}%`*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Typography*/}
            {/*    sx={{ color: 'text.disabled', fontWeight: 500, fontSize: '0.8rem' }}*/}
            {/*    mb={0}*/}
            {/*    className={*/}
            {/*      oldData != null && props.realMin != null && oldData.min.toFixed(0) !== props.realMin.toFixed(0) ? 'change-animation' : ''*/}
            {/*    }*/}
            {/*  >*/}
            {/*    {props.realMin != null ? formatNumber(props.realMin, { fractionDigits: 0 }) : 'min'}*/}
            {/*    {monthlyLabel != null && monthlyLabel ? ' mesi' : monthlyLabel != null && !monthlyLabel ? ' €' : ' €'}*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
          </>
        )}
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingX={0}
        paddingTop={props.marks != null ? '25px' : '15px'}
      >
        <Typography
          sx={{ color: 'text.disabled', fontWeight: 500, fontSize: '0.8rem' }}
          className={oldData != null && props.min != null && oldData.min.toFixed(0) !== props.min.toFixed(0) ? 'change-animation' : ''}
        >
          {props.min != null ? formatNumber(props.min, { fractionDigits: 0 }) : 'min'}
          {monthlyLabel != null && monthlyLabel ? ' mesi' : monthlyLabel != null && !monthlyLabel ? ' €' : ' €'}
        </Typography>
        <Typography
          sx={{ color: 'text.disabled', fontWeight: 500, fontSize: '0.8rem' }}
          className={oldData != null && props.max != null && oldData.max.toFixed(0) !== props.max.toFixed(0) ? 'change-animation' : ''}
        >
          {props.max != null ? formatNumber(props.max, { fractionDigits: 0 }) : 'max'}
          {monthlyLabel != null && monthlyLabel ? ' mesi' : monthlyLabel != null && !monthlyLabel ? ' €' : ' €'}
        </Typography>
      </Box>
    </Stack>
  );
};

export default PaymentRange;
