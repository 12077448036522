import useModal from '../../../hooks/useModal';
import { Box, Button, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import Illustrion from 'assets/images/illustrations/illustration_modal_upload_code.svg';
import { useEndpoint } from '../../../hooks/useEndpoint';
import { useFormik } from 'formik';
import { dispatch, useSelector } from '../../../store';
import useAuth from '../../../hooks/useAuth';
import * as Yup from 'yup';
import { openSnackbar } from '../../../store/reducers/snackbar';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '../LoadingButton';
import { queryClient } from '../../../App';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';

export const openModalUploadPmsCode = () => {
  const { openModal } = useModal();
  return () => {
    openModal({
      content: <ModalUploadPmsCode />
    });
  };
};

const ModalUploadPmsCode = () => {
  const { user } = useSelector((state) => state.auth);
  const { closeModal } = useModal();
  const { authTokenLogin } = useAuth();
  const navigate = useNavigate();
  const { trackAction } = useNavigationTracker();
  const uploadPmsCode = useEndpoint<{ pmsHotelCode: string }, 'post'>({
    method: 'post',
    endpoint: `/buildings/${user?.building.id}/pms`,
    mutationKey: 'upload-pms-code'
  });

  const pmsCodeForm = useFormik<{ pmsHotelCode: string }>({
    initialValues: {
      pmsHotelCode: ''
    },
    validationSchema: Yup.object().shape({
      pmsHotelCode: Yup.string().required('Inserisci il codice fornito dal tuo pms')
    }),
    onSubmit: async (values, { setErrors }) => {
      if (values.pmsHotelCode !== '') {
        uploadPmsCode.mutate(
          {
            pmsHotelCode: values.pmsHotelCode
          },
          {
            onSuccess: async () => {
              await authTokenLogin();
              closeModal();
              queryClient.refetchQueries('get-wallets-wallet-page');
              queryClient.refetchQueries('get-wallets-dashboard');
              queryClient.refetchQueries('registration-progress');
              queryClient.refetchQueries('registration-progress-dashboard');
              dispatch(
                openSnackbar({
                  variant: 'success',
                  message: 'Codice inserito con successo!'
                })
              );
              trackAction({
                type: ClientTypeEnum.Form,
                action: ClientActionEnum.Submit,
                name: 'Ha inserito il codice del pms'
              });
            },
            onError: (error) => {
              console.log(error?.response?.data);
              setErrors({
                pmsHotelCode: 'Il codice inserito è errato'
              });
            }
          }
        );
      }
    }
  });
  return (
    <form noValidate onSubmit={pmsCodeForm.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={'h4'} fontWeight={500} textAlign={'center'}>
            Inserisci il codice del PMS
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Box width={'100%'} maxWidth={500}>
            <Typography variant={'body1'} mb={2}>
              Per procedere con l’integrazione del PMS consulta la tua mail e <span style={{ fontWeight: 600 }}>inserisci il codice</span>{' '}
              qui
            </Typography>

            <Stack width={'100%'}>
              <TextField
                fullWidth
                value={pmsCodeForm.values.pmsHotelCode}
                name={'pmsHotelCode'}
                error={Boolean(pmsCodeForm.errors.pmsHotelCode && pmsCodeForm.touched.pmsHotelCode)}
                onChange={pmsCodeForm.handleChange}
                onBlur={(e) => {
                  pmsCodeForm.handleBlur(e);
                  trackAction({
                    type: ClientTypeEnum.Button,
                    action: ClientActionEnum.Clicked,
                    name: `Ha inserito il codice del pms: ${pmsCodeForm.values.pmsHotelCode}`
                  });
                }}
                size={'small'}
                sx={(theme) => ({
                  '& .MuiOutlinedInput-root:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.secondary.main
                  },
                  '& .MuiOutlinedInput-root:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.secondary.main
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '2px',
                    borderColor: theme.palette.secondary.main
                  }
                })}
              />
              {pmsCodeForm.errors.pmsHotelCode && pmsCodeForm.touched.pmsHotelCode && (
                <FormHelperText error>{pmsCodeForm.errors.pmsHotelCode}</FormHelperText>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            minHeight: '400px',
            img: {
              width: '90%',
              maxWidth: 500
            }
          }}
        >
          <img src={Illustrion} alt={'illustrazione upload code'} />
        </Grid>

        <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'10px'}>
          <Button
            size={'medium'}
            variant={'outlined'}
            onClick={() => {
              navigate('/building-register');
              closeModal();
              trackAction({
                type: ClientTypeEnum.Button,
                action: ClientActionEnum.Clicked,
                name: 'Ha cliccato cambia metodo'
              });
            }}
          >
            Cambia metodo
          </Button>
          <LoadingButton
            loading={uploadPmsCode.isLoading}
            size={'medium'}
            type={'submit'}
            variant={'contained'}
            disabled={Object.keys(pmsCodeForm.errors).length !== 0}
          >
            Conferma
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
