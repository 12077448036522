import { Box, Divider, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { Circle } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import formatNumber from '../../../../../../utils/formatNumber';
import {
  OrderDTO,
  OrderPurchaseConfiguration,
  OrderRentConfiguration,
  WalletOrder,
  WalletRent
} from '../../../../../../types/dto/orders.dto';
import Grid from '@mui/material/Grid';

type CreditAndPaymentReviewProps = {
  creditAndPaymentReview: Omit<OrderDTO, 'orderConfigurations' | 'activeConfiguration'> & {
    userConfiguration: OrderDTO['activeConfiguration'] | OrderPurchaseConfiguration | OrderRentConfiguration;
  };
};

const CreditAndPaymentReview = ({ creditAndPaymentReview }: CreditAndPaymentReviewProps) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      // sx={{
      //   position: 'relative',
      //   '&::after': {
      //     content: '""',
      //     display: { xs: 'none', md: 'block' },
      //     position: 'absolute',
      //     top: '50%',
      //     transform: 'translateY(-50%)',
      //     height: '90%',
      //     right: 0,
      //     bottom: 0,
      //     width: '1px',
      //     backgroundColor: 'divider'
      //   }
      // }}
    >
      <Box ml={{ xs: 0, md: 3 }}>
        <Typography variant={'h6'} fontWeight={600} mb={1}>
          Credito e pagamenti
        </Typography>

        <Grid container maxWidth={500} mt={1} rowSpacing={3}>
          {creditAndPaymentReview.type === 'purchase' && (
            <>
              <Grid item xs={8}>
                <Typography variant={'body1'} fontWeight={600}>
                  Acconto
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant={'body1'} fontWeight={600}>
                  {BigNumber(creditAndPaymentReview.userConfiguration.depositAmount).toNumber().toLocaleString()} €
                </Typography>
              </Grid>

              {(creditAndPaymentReview.userConfiguration.wallets as WalletOrder[])
                .filter((ow) => ow.orderWallet != null)
                .map((wallet) => (
                  <Grid item xs={12} key={wallet.id}>
                    <Grid container>
                      <Grid item xs={8} display={'flex'} alignItems={'center'} gap={'10px'}>
                        <Circle fontSize={'medium'} sx={{ color: wallet.walletPartner.color }} />
                        <Typography variant={'body1'} fontWeight={600} sx={{ color: wallet.walletPartner.color }}>
                          {wallet.walletPartner.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant={'body1'} fontWeight={600} sx={{ color: wallet.walletPartner.color }}>
                          {BigNumber(wallet.orderWallet!.amount).toNumber().toLocaleString()} €
                        </Typography>
                      </Grid>

                      <Grid item xs={12} my={1}>
                        <Divider
                          orientation={'horizontal'}
                          variant={'fullWidth'}
                          sx={{ backgroundColor: wallet.walletPartner.color, maxWidth: 450 }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {wallet.walletPartner.hideNameIn != null && !wallet.walletPartner.hideNameIn.includes('orders') ? (
                          <Typography variant={'body1'}>
                            Servizio di <span style={{ fontWeight: 600 }}>{wallet.walletPartner.company}</span>
                          </Typography>
                        ) : (
                          <Typography variant={'body1'} color="primary">
                            Servizio erogato da partner
                          </Typography>
                        )}
                      </Grid>

                      {/*{wallet.orderWallet?.state != null && (*/}
                      {/*  <Grid item xs={4}>*/}
                      {/*    {wallet.orderWallet?.state.key === OrderWalletState.Pending ? (*/}
                      {/*      <Chip label={'In lavorazione'} color={'warning'} size={'small'} />*/}
                      {/*    ) : wallet.orderWallet?.state.key === OrderWalletState.Accepted ? (*/}
                      {/*      <Chip label={'Approvato'} color={'success'} size={'small'} />*/}
                      {/*    ) : wallet.orderWallet?.state.key === OrderWalletState.Refused ? (*/}
                      {/*      <Chip label={'Rifiutato'} color={'error'} size={'small'} />*/}
                      {/*    ) : wallet.orderWallet?.state.key === OrderWalletState.Created ? (*/}
                      {/*      <Chip label={'In attesa'} color={'info'} size={'small'} />*/}
                      {/*    ) : (*/}
                      {/*      <></>*/}
                      {/*    )}*/}
                      {/*  </Grid>*/}
                      {/*)}*/}

                      <Grid item xs={12}>
                        <Grid container my={2}>
                          {wallet.orderWallet?.entries
                            .filter((e) => e.key !== 'markupPercentage')
                            .map((entry, i) => (
                              <React.Fragment key={i}>
                                <Grid item xs={8}>
                                  <Typography variant={'body1'}>
                                    <FormattedMessage id={entry.key} />
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant={'body1'}>
                                    {formatNumber(BigNumber(entry.value).toNumber(), {
                                      fractionDigits: 2
                                    })}{' '}
                                    {entry.unit === 'N' ? 'Mesi' : entry.unit}
                                  </Typography>
                                </Grid>
                              </React.Fragment>
                            ))}

                          {wallet.orderWallet?.returnTime != null && (
                            <>
                              <Grid item xs={8}>
                                <Typography variant={'body1'}>Durata:</Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant={'body1'}>{wallet.orderWallet?.returnTime} Mesi</Typography>
                              </Grid>
                            </>
                          )}

                          <Grid item xs={8}>
                            <Typography variant={'body1'} fontWeight={600}>
                              Importo:
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant={'body1'} fontWeight={600}>
                              {formatNumber(BigNumber(wallet.orderWallet!.amount).toNumber(), {
                                fractionDigits: 2
                              })}{' '}
                              €
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </>
          )}

          {creditAndPaymentReview.type === 'rent' && (
            <>
              <Grid item xs={8}>
                <Typography variant={'body1'} fontWeight={600}>
                  Anticipo cash
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant={'body1'} fontWeight={600}>
                  {BigNumber(creditAndPaymentReview.userConfiguration.depositAmount).toNumber().toLocaleString()} €
                </Typography>
              </Grid>

              {(creditAndPaymentReview.userConfiguration.wallets as WalletRent[])
                .filter((ow) => ow.orderWallet != null)
                .map((wallet) => (
                  <Grid item xs={12} key={wallet.id}>
                    <Grid container>
                      <Grid item xs={8} display={'flex'} alignItems={'center'} gap={'10px'}>
                        <Circle fontSize={'medium'} sx={{ color: '#CE93D8' }} />
                        <Typography variant={'body1'} fontWeight={600} sx={{ color: '#CE93D8' }}>
                          Dilazione {wallet.walletPartner.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant={'body1'} fontWeight={600} sx={{ color: '#CE93D8' }}>
                          {BigNumber(wallet.orderWallet.amount).toNumber().toLocaleString()} €
                        </Typography>
                      </Grid>

                      <Grid item xs={12} my={1}>
                        <Divider orientation={'horizontal'} variant={'fullWidth'} sx={{ backgroundColor: '#CE93D8', maxWidth: 450 }} />
                      </Grid>

                      <Grid item xs={12}>
                        {wallet.walletPartner.hideNameIn != null && !wallet.walletPartner.hideNameIn.includes('orders') ? (
                          <Typography variant={'body1'}>
                            Servizio di <span style={{ fontWeight: 600 }}>{wallet.walletPartner.company}</span>
                          </Typography>
                        ) : (
                          <Typography variant={'body1'}>Servizio erogato da partner</Typography>
                        )}
                      </Grid>

                      {/*{wallet.orderWallet?.state != null && (*/}
                      {/*  <Grid item xs={4}>*/}
                      {/*    {wallet.orderWallet?.state.key === OrderWalletState.Pending ? (*/}
                      {/*      <Chip label={'In lavorazione'} color={'warning'} size={'small'} />*/}
                      {/*    ) : wallet.orderWallet?.state.key === OrderWalletState.Accepted ? (*/}
                      {/*      <Chip label={'Approvato'} color={'success'} size={'small'} />*/}
                      {/*    ) : wallet.orderWallet?.state.key === OrderWalletState.Refused ? (*/}
                      {/*      <Chip label={'Rifiutato'} color={'error'} size={'small'} />*/}
                      {/*    ) : wallet.orderWallet?.state.key === OrderWalletState.Created ? (*/}
                      {/*      <Chip label={'In attesa'} color={'info'} size={'small'} />*/}
                      {/*    ) : (*/}
                      {/*      <></>*/}
                      {/*    )}*/}
                      {/*  </Grid>*/}
                      {/*)}*/}

                      <Grid item xs={12}>
                        <Grid container my={2}>
                          <Grid item xs={8}>
                            <Typography variant={'body1'}>Durata</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant={'body1'}>{wallet.orderWallet?.returnTime} Mesi</Typography>
                          </Grid>
                          {wallet.orderWallet?.entries.map((entry, i) => (
                            <React.Fragment key={i}>
                              <Grid item xs={8}>
                                <Typography variant={'body1'}>
                                  <FormattedMessage id={entry.key} />
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant={'body1'}>
                                  {formatNumber(BigNumber(entry.value).toNumber(), {
                                    fractionDigits: entry.unit === 'N' ? 0 : 2
                                  })}{' '}
                                  {entry.unit === 'N' ? 'Mesi' : entry.unit}
                                </Typography>
                              </Grid>
                            </React.Fragment>
                          ))}

                          {/*<Grid item xs={8}>*/}
                          {/*  <Typography variant={'body1'} fontWeight={600}>*/}
                          {/*    Importo:*/}
                          {/*  </Typography>*/}
                          {/*</Grid>*/}
                          {/*<Grid item xs={4}>*/}
                          {/*  <Typography variant={'body1'} fontWeight={600}>*/}
                          {/*    {formatNumber(BigNumber(wallet.orderWallet!.).toNumber(), {*/}
                          {/*      fractionDigits: 2*/}
                          {/*    })}{' '}*/}
                          {/*    €*/}
                          {/*  </Typography>*/}
                          {/*</Grid>*/}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

export default CreditAndPaymentReview;
