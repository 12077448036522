import { Box, Fade, Grid, useMediaQuery, useTheme } from '@mui/material';
import Header, { commonHeaderHeightDesktop, commonHeaderHeightMobile } from './Header';
import { OutletWithError } from '../../components/custom/FallbackErrorBoundary';
import { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { dispatch, useSelector } from '../../store';
import HolderBar from '../../components/custom/HolderBar';
import { setPawn } from '../../store/reducers/auth';

type CommonLayoutProps = {
  hasHolderBar?: boolean;
  testMode?: boolean;
};
// ==============================|| COMMON LAYOUT ||============================== //

const CommonLayout = (props: CommonLayoutProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { holder } = useSelector((state) => state.auth);
  const holderBarRef = useRef<HTMLDivElement | null>(null);
  const [holderBarHeight, setHolderBarHeight] = useState<number>();

  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('root')?.classList.remove('body-bootstrap');
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.testMode) {
      dispatch(setPawn(undefined));
    }
  }, []);

  useEffect(() => {
    setHolderBarHeight(holderBarRef.current?.clientHeight);
  }, [holderBarRef.current]);

  return (
    <Box width={'100%'} height={'100dvh'} display={'flex'} flexDirection={'column'} overflow={'hidden'}>
      <div ref={holderBarRef}>{holder !== undefined && holder.$.hasAdminPermissions && props.hasHolderBar && <HolderBar />}</div>
      <Box
        overflow={isMobile ? 'auto' : 'hidden'}
        position={'relative'}
        width={'100%'}
        height={holder !== undefined && holder.$.hasAdminPermissions && props.hasHolderBar ? `calc(100% - ${holderBarHeight}px)` : '100%'}
      >
        <Header />

        <Fade in>
          <Grid
            container
            sx={{
              width: '100%',
              height: !isMobile ? `calc(100% - ${commonHeaderHeightDesktop}px)` : `calc(100% - ${commonHeaderHeightMobile}px)`,
              overflow: 'auto'
            }}
          >
            <Suspense fallback={<></>}>
              <OutletWithError />
            </Suspense>
          </Grid>
        </Fade>
      </Box>
    </Box>
  );
};

export default CommonLayout;
