import { Box, Divider, Fade, Grid, Skeleton, Typography } from '@mui/material';

const NewOrderStep3Skeletons = () => {
  return (
    <Fade in>
      <Box width={'100%'}>
        <Typography gutterBottom variant="h5">
          Carica i tuoi preventivi
        </Typography>
        <Typography gutterBottom variant="body2">
          Inserisci i preventivi a tua disposizione caricando i file e assicurati che siano coerenti con l'importo richiesto
        </Typography>

        <Divider sx={{ mb: 3 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            {/*Anticipo*/}
            <Box maxWidth={800} marginX={'auto'} mb={2}>
              <Skeleton variant={'rounded'} width={'100%'} height={'350px'} />
            </Box>

            <Box maxWidth={800} marginX={'auto'} mb={2}>
              <Skeleton variant={'rounded'} width={'100%'} height={'250px'} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box>
              <Skeleton variant={'rectangular'} width={'90%'} height={'450px'} sx={{ maxWidth: 400, margin: '0 auto' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default NewOrderStep3Skeletons;
