import { Box, Fade, Grid, Link, styled, Tooltip, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { AddressBoxStyle } from './NewAddressBox';
import { ShippingAddress } from '../../../../types/dto/orders.dto';
import { useSelector } from '../../../../store';

export const AddressCheckedLabel = styled(Box)<{ isdemo?: string }>(({ theme, isdemo }) => ({
  height: 22.5,
  width: 22.5,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  backgroundColor: isdemo != null && isdemo === 'true' ? 'grey' : theme.palette.primary.main,
  position: 'absolute',
  top: -12,
  right: -11,
  zIndex: 100
}));
type AddressBoxPropsType = {
  isDefault: boolean;
  isSelected: boolean;
  address: ShippingAddress;
  onClickEditAddress: (address: ShippingAddress) => void;
  onClickDeleteAddress?: (address: ShippingAddress) => void;
  onClickDefaultAddress?: (address: ShippingAddress) => void;
  onClickBox?: () => void;
};
const AddressBox = (props: AddressBoxPropsType) => {
  const { address, onClickBox, isSelected } = props;
  const { user } = useSelector((state) => state.auth);
  return (
    <Tooltip title={user?.isDemo ? "L'account demo non può modificare gli indirizzi di spedizione" : undefined} arrow>
      <AddressBoxStyle isdefault={isSelected && !user?.isDemo ? 'true' : 'false'} data-cy="shipping_address_box">
        {isSelected && (
          <Fade in>
            <AddressCheckedLabel isdemo={user?.isDemo ? 'true' : undefined}>
              <Check fontSize="small" />
            </AddressCheckedLabel>
          </Fade>
        )}
        <Box flexGrow={1} width={'100%'} onClick={onClickBox} sx={{ cursor: onClickBox != null ? 'pointer' : 'normal' }}>
          <Typography variant="body2" gutterBottom>
            Referente: <span style={{ fontWeight: '500' }}>{address.contactFullname}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Numero di telefono: <span style={{ fontWeight: '500' }}>{address.contactPhone}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Indirizzo: <span style={{ fontWeight: '500' }}>{address.address}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <span style={{ fontWeight: '500' }}>
              {address.fraction != null ? address.fraction.name.it + ' -' : ''} {address.city.name.it} - {address.cap} -{' '}
              {address.country.name.it}
            </span>
          </Typography>
        </Box>
        <Grid container flexGrow={0}>
          <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
            <Link
              variant="body1"
              color={'inherit'}
              fontSize={'.8rem'}
              sx={{ cursor: 'pointer' }}
              gutterBottom
              onClick={() => {
                if (!user?.isDemo) props.onClickEditAddress(address);
              }}
            >
              Modifica l'indirizzo
            </Link>
            {/*<Link*/}
            {/*  variant="body1"*/}
            {/*  color="inherit"*/}
            {/*  fontSize={'.8rem'}*/}
            {/*  sx={{ cursor: 'pointer' }}*/}
            {/*  onClick={() => {*/}
            {/*    if (!user?.isDemo) props.onClickDeleteAddress(address);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Elimina l'indirizzo*/}
            {/*</Link>*/}
          </Grid>
          {/*<Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} textAlign={'end'}>*/}
          {/*  {isDefault ? (*/}
          {/*    <Typography variant="body2" fontWeight={600} color={'inherit'}>*/}
          {/*      Predefinito*/}
          {/*    </Typography>*/}
          {/*  ) : (*/}
          {/*    <Typography*/}
          {/*      variant="body2"*/}
          {/*      fontWeight={600}*/}
          {/*      color={'inherit'}*/}
          {/*      sx={{ cursor: 'pointer' }}*/}
          {/*      onClick={() => {*/}
          {/*        if (!user?.isDemo) props.onClickDefaultAddress(address);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Imposta come predefinito*/}
          {/*    </Typography>*/}
          {/*  )}*/}
          {/*</Grid>*/}
        </Grid>
      </AddressBoxStyle>
    </Tooltip>
  );
};

export default AddressBox;
