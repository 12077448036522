// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
// import Login from 'pages/auth/login';
// import Register from 'pages/auth/register';
// import ForgotPassword from 'pages/auth/forgot-password';
// import ResetPassword from 'pages/auth/reset-password';
// import AuthCheckEmail from 'pages/auth/check-email';
// import AuthNewEmailVerification from 'sections/auth/auth-forms/AuthNewEmailVerification';
// import Error404 from 'pages/maintenance/404';

import { lazy, Suspense } from 'react';
import FastRegister from '../pages/auth/fast-register';
const Login = lazy(() => import('pages/auth/login'));
const Register = lazy(() => import('pages/auth/register'));
const ForgotPassword = lazy(() => import('pages/auth/forgot-password'));
const ResetPassword = lazy(() => import('pages/auth/reset-password'));
const AuthCheckEmail = lazy(() => import('pages/auth/check-email'));
const AuthNewEmailVerification = lazy(() => import('pages/auth/new-email-verification'));
const Error404 = lazy(() => import('pages/maintenance/404'));

// ==============================|| AUTH ROUTING ||============================== //
const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <Login />
        },
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'register',
          element: <Register />
        },
        {
          path: 'fast-register',
          element: <FastRegister />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: 'reset-password/:code',
          element: <ResetPassword />
        },
        {
          path: 'verify-email/:code',
          element: <AuthCheckEmail />
        },
        {
          path: 'new-email-verification',
          element: <AuthNewEmailVerification />
        }
        //Pagine di test
        // {
        //   path: 'prova',
        //   element: <NewManualIntegrationStep4 />
        // },
        // {
        //   path: 'test-simone',
        //   element: <Test />
        // },
        // {
        //   path: 'push-state-test',
        //   element: <PushStateTest />,
        //   children: [
        //     {
        //       path: '',
        //       element: <Step0 />
        //     },
        //     {
        //       path: 'step-1',
        //       element: <Step1 />
        //     },
        //     {
        //       path: 'step-2',
        //       element: <Step2 />
        //     },
        //     {
        //       path: 'step-3',
        //       element: <Step3 />
        //     }
        //   ]
        // }
      ]
    },
    {
      path: '*',
      element: (
        <GuestGuard>
          <Suspense fallback={<></>}>
            <Error404 />
          </Suspense>
        </GuestGuard>
      )
    }
  ]
};

export default LoginRoutes;
