import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const PreventivoIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="591" height="786" viewBox="0 0 591 786" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M590.14 785.86H0.859863V0.140015H511.32C554.86 0.140015 590.15 35.43 590.15 78.97V785.85L590.14 785.86Z" fill="#094754" />
      <path d="M590.14 137.64V70.67C590.14 31.72 558.56 0.140015 519.61 0.140015H452.64L590.14 137.64Z" fill="#91DCF9" />
      <path d="M452.64 137.64H590.14L452.64 0.140015V137.64Z" fill="#1ED367" />
      <path d="M472.28 605.6H118.71V684.17H472.28V605.6Z" fill="#F0FF32" />
      <path d="M471.11 428.23H117.54V551.28H471.11V428.23Z" fill="#91DCF9" />
      <path d="M472.29 216.5H320.93V255.79H472.29V216.5Z" fill="#91DCF9" />
      <path d="M472.29 286.23H320.93V325.52H472.29V286.23Z" fill="#91DCF9" />
      <path d="M294.33 168.09H118.71V343.48H294.33V168.09Z" fill="#F0FF32" />
      <path
        d="M457.21 544.62H427.19C407.99 544.62 392.38 529 392.38 509.81V469.71C392.38 450.51 408 434.9 427.19 434.9H457.21V454.85H427.19C419 454.85 412.33 461.52 412.33 469.71V509.8C412.33 518 419 524.66 427.19 524.66H457.21V544.61V544.62Z"
        fill="#094754"
      />
      <path d="M439.2 479.47H370.04V500.04H439.2V479.47Z" fill="#094754" />
    </svg>
  </SvgIcon>
);

export default PreventivoIcon;
