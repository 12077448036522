import { useContext } from 'react';
import { DrawerContext } from '../contexts/DrawerContext';

const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useDrawer;
