import { memo, ReactNode, useEffect, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, AppBarProps, Box, Collapse, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets
import MenuIcon from '@mui/icons-material/Menu';
import LogoIcon from 'components/logo/LogoIcon';
import { useNavigate } from 'react-router';
import InfoIcon from '../../../components/custom/BuyPlusIcons/InfoIcon';
import { useLocation } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import { Breadcrumb } from '../../../components/custom/TCN_Breadcrumb';
import { dispatch, useSelector } from '../../../store';
import { setStatusChip } from '../../../store/reducers/breadcrumbHelper';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TCN_BreadcrumbProps } from '../../../components/custom/TCN_Breadcrumb/TCN_Breadcrumb';
import Button from '@mui/material/Button';
import { useTour } from '@reactour/tour';
import OrderChip from '../../../components/custom/Chips/OrderChip';
import { OrderStatus } from '../../../types/dto/orders.dto';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
  handleDrawerNotificationToggle: () => void;
  isScrolling?: boolean;
}

const infoDrawerPaths: string[] = [
  '/dashboard',
  '/wallet',
  '/orders',
  '/account/settings',
  '/account/building',
  '/account/address',
  '/account/billing',
  '/account/documents'
];

const Header = ({ open, handleDrawerToggle, handleDrawerNotificationToggle, isScrolling }: Props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openModal, closeModal } = useModal();
  const tour = useTour();
  const { trackAction } = useNavigationTracker();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { statusChip } = useSelector((state) => state.breadcrumbHelper);
  useEffect(() => {
    if (statusChip) dispatch(setStatusChip(undefined));
  }, [navigate]);

  const openDashboardModal = () => {
    openModal({
      content: (
        <Box textAlign={'center'}>
          <InfoIcon sx={{ fontSize: '40px', mb: 1 }} />
          <Typography variant={'h4'} fontWeight={500} mb={4}>
            La tua dashboard
          </Typography>

          <Typography variant={'body1'} mb={3}>
            Grazie alla tua dashboard avrai sempre la situazione sotto controllo. Qui potrai vedere le attività e azioni richieste o
            raccomandate per sfruttare al meglio la piattaforma, lo status del tuo wallet, le azioni necessarie per sbloccare credito o
            ordini, lo status dei tuo ordini, nuove promozioni e molto altro.
          </Typography>

          <Button
            variant={'contained'}
            size={'medium'}
            onClick={() => {
              closeModal();
              tour.setIsOpen(true);
            }}
          >
            Inizia tour
          </Button>
        </Box>
      )
    });

    trackAction({
      type: ClientTypeEnum.Button,
      action: ClientActionEnum.Clicked,
      name: `Ha aperto il modal informativo della pagina dashboard`
    });
  };

  const openWalletModal = () => {
    openModal({
      content: (
        <Box textAlign={'center'}>
          <InfoIcon sx={{ fontSize: '40px', mb: 1 }} />
          <Typography variant={'h4'} fontWeight={500} mb={4}>
            Il tuo wallet
          </Typography>

          <Typography variant={'body1'} mb={3}>
            Il tuo wallet è a tutti gli effetti il tuo portafoglio di credito dove potrai vedere il plafond totale, parziale, utilizzato,
            restituito per ogni soluzione di credito, approfondire la conoscenza su cosa è, come funziona, quali condizioni ha ogni
            soluzione di credito per trovare quella più adatta alla tua esigenza.
          </Typography>

          <Button
            variant={'contained'}
            size={'medium'}
            onClick={() => {
              closeModal();
              tour.setIsOpen(true);
            }}
          >
            Inizia tour
          </Button>
        </Box>
      )
    });

    trackAction({
      type: ClientTypeEnum.Button,
      action: ClientActionEnum.Clicked,
      name: `Ha aperto il modal informativo della pagina wallet`
    });
  };

  const openOrdersModal = () => {
    openModal({
      content: (
        <Box textAlign={'center'}>
          <InfoIcon sx={{ fontSize: '40px', mb: 1 }} />
          <Typography variant={'h4'} fontWeight={500} mb={4}>
            I tuoi ordini
          </Typography>

          <Typography variant={'body1'} mb={3}>
            Nella pagina dei tuoi ordini potrai visualizzare tutti i tuoi ordini, i relativi status e azioni necessarie e accedere
            velocemente ad ogni singolo ordine.
          </Typography>
        </Box>
      )
    });

    trackAction({
      type: ClientTypeEnum.Button,
      action: ClientActionEnum.Clicked,
      name: `Ha aperto il modal informativo della pagina ordini`
    });
  };

  const openAccountModal = () => {
    openModal({
      content: (
        <Box textAlign={'center'}>
          <InfoIcon sx={{ fontSize: '40px', mb: 1 }} />
          <Typography variant={'h4'} fontWeight={500} mb={4}>
            Il tuo profilo
          </Typography>

          <Typography variant={'body1'} mb={3}>
            Nella sezione del tuo profilo potrai visualizzare, modificare, aggiornare i dati relativi alla tua struttura, cosi come caricare
            i documenti necessari per abilitare tutte le funzionalità della piattaforma e velocizzare le fasi di verifica e conferma degli
            ordini.
          </Typography>
        </Box>
      )
    });

    trackAction({
      type: ClientTypeEnum.Button,
      action: ClientActionEnum.Clicked,
      name: `Ha aperto il modal informativo della pagina account`
    });
  };

  const headerMapRoutes: TCN_BreadcrumbProps['mapRoutes'] = [
    {
      pathname: '/dashboard',
      breadcrumbs: {
        title: 'Dashboard'
      }
    },
    {
      pathname: '/wallet',
      breadcrumbs: {
        title: 'Wallet'
      }
    },
    {
      pathname: '/orders',
      breadcrumbs: {
        title: 'Ordini'
      }
    },
    {
      pathname: '/orders/:code/:title',
      breadcrumbs: (params) => [
        {
          title: 'Ordini',
          href: '/orders'
        },
        { title: params.title! }
      ],
      customIcon: {
        position: 'end',
        component: (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {/*{statusChip === OrderStatus.Filled ? (*/}
            {/*  <FilledChip />*/}
            {/*) : statusChip === OrderStatus.Revised ? (*/}
            {/*  <RevisedChip />*/}
            {/*) : statusChip === OrderStatus.Accepted ? (*/}
            {/*  <AcceptedChip />*/}
            {/*) : statusChip === OrderStatus.Shipped ? (*/}
            {/*  <ShippedChip />*/}
            {/*) : statusChip === OrderStatus.Delivered ? (*/}
            {/*  <CompletedChip />*/}
            {/*) : statusChip === OrderStatus.Deleted ? (*/}
            {/*  <DeletedChip />*/}
            {/*) : (*/}
            {/*  <></>*/}
            {/*)}*/}
            {statusChip != null && <OrderChip state={statusChip.state} isProduct={statusChip.isProduct} />}
          </Box>
        )
      }
    },
    {
      pathname: '/orders/new-order',
      breadcrumbs: {
        title: 'Nuovo ordine'
      }
    },
    {
      pathname: [
        '/orders/new-order/:code/:title',
        '/orders/new-order/:code/:title/step-2',
        '/orders/new-order/:code/:title/step-3',
        '/orders/new-order/:code/:title/step-4',
        '/orders/new-order/:code/:title/step-5',
        '/orders/new-order/:code/:title/step-6'
      ],
      breadcrumbs: (params) => [{ title: 'Nuovo ordine', href: '/orders/new-order' }, { title: params.title! }],
      customIcon: {
        component: <OrderChip state={OrderStatus.Draft} isProduct={false} />,
        position: 'end'
      }
    },
    {
      pathname: '/account/settings',
      breadcrumbs: [
        {
          title: 'Profilo'
        },
        {
          title: 'Impostazioni'
        }
      ]
    },
    {
      pathname: '/account/building',
      breadcrumbs: [
        {
          title: 'Profilo'
        },
        {
          title: 'Impostazioni'
        }
      ]
    },
    {
      pathname: '/account/address',
      breadcrumbs: [
        {
          title: 'Profilo'
        },
        {
          title: 'Impostazioni'
        }
      ]
    },
    {
      pathname: '/account/billing',
      breadcrumbs: [
        {
          title: 'Profilo'
        },
        {
          title: 'Impostazioni'
        }
      ]
    },
    {
      pathname: '/account/documents',
      breadcrumbs: [
        {
          title: 'Profilo'
        },
        {
          title: 'Documenti'
        }
      ]
    },
    {
      pathname: '/support/help-center',
      breadcrumbs: [
        {
          title: 'Supporto'
        },
        {
          title: 'Centro assistenza'
        }
      ]
    },
    {
      pathname: '/support/faqs',
      breadcrumbs: [
        {
          title: 'Supporto'
        },
        {
          title: 'Faqs'
        }
      ]
    },
    {
      pathname: '/contacts',
      breadcrumbs: {
        title: 'Contatti'
      }
    },
    {
      pathname: '/notifications',
      breadcrumbs: {
        title: 'Notifiche'
      }
    }
  ];

  // header content
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const headerContent = useMemo(() => <HeaderContent handleDrawerNotificationToggle={handleDrawerNotificationToggle} />, []);

  // common header
  const desktopHeader: ReactNode = (
    <Box
      id={'header'}
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingX: 1.5
      }}
    >
      <Box flexGrow={1} display={'flex'} alignItems={'center'} color={'white'}>
        <IconButton onClick={handleDrawerToggle} color={'info'} size="medium">
          <MenuIcon fontSize="medium" />
        </IconButton>

        <Box marginLeft={useMediaQuery(theme.breakpoints.up('xl')) ? 1.5 : 2}>
          <Breadcrumb
            BreadcrumbsProps={{
              separator: isDownMd ? '' : <NavigateNextIcon fontSize="medium" />,
              color: 'white'
            }}
            TypographyProps={{
              active: {
                sx: {
                  color: 'white',
                  fontSize: '1.8rem',
                  fontWeight: 700,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: 400,
                  textDecoration: 'none'
                }
              },
              previous: {
                sx: {
                  color: 'secondary.main',
                  fontSize: '1.7rem',
                  fontWeight: 400,
                  display: { xs: 'none', md: 'block' },
                  textDecoration: 'none'
                }
              }
            }}
            mapRoutes={headerMapRoutes}
          />

          {/*<HeaderBreadcrumb />*/}
        </Box>

        {infoDrawerPaths.includes(pathname) && (
          <Box
            id={'button-info-page'}
            display={'flex'}
            alignItems={'center'}
            onClick={() => {
              if (pathname.includes('dashboard')) {
                openDashboardModal();
              } else if (pathname.includes('wallet')) {
                openWalletModal();
              } else if (pathname.includes('account')) {
                openAccountModal();
              } else if (pathname.includes('orders')) {
                openOrdersModal();
              }
            }}
          >
            <InfoIcon fontSize={'large'} sx={{ cursor: 'pointer', marginX: 2 }} />
          </Box>
        )}
      </Box>
      <Box flexGrow={0}>{headerContent}</Box>
    </Box>
  );

  const mobileHeader: ReactNode = (
    <Toolbar sx={{ width: '100%', height: '100%', padding: 0, display: 'flex', flexWrap: 'wrap' }}>
      <Box
        sx={{
          width: '100%',
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid #E0E0E0`,
          paddingLeft: 1.5
        }}
      >
        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
          onClick={() => navigate('/dashboard')}
          sx={{
            img: {
              width: 25
            }
          }}
        >
          <LogoIcon />
        </Box>
        <Box flexGrow={0} color={'white'} display={'flex'} alignItems={'center'}>
          <Box mr={1}>{headerContent}</Box>
          <IconButton onClick={handleDrawerToggle} color={'info'} size="medium">
            <MenuIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>

      <Collapse in={isScrolling}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            paddingY: 1,
            paddingLeft: 1.5
          }}
        >
          {/*<HeaderBreadcrumb />*/}
          <Breadcrumb
            BreadcrumbsProps={{
              separator: '',
              color: 'white',
              sx: {
                '.MuiBreadcrumbs-separator': {
                  display: 'none'
                }
              }
            }}
            TypographyProps={{
              active: {
                sx: {
                  color: 'white',
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  textDecoration: 'none',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: 220
                }
              },
              previous: {
                sx: {
                  color: 'secondary.main',
                  fontSize: '1.4rem',
                  fontWeight: 400,
                  textDecoration: 'none',
                  display: 'none'
                }
              }
            }}
            mapRoutes={headerMapRoutes}
          />
          {infoDrawerPaths.includes(pathname) && (
            <Box
              display={'flex'}
              alignItems={'center'}
              onClick={() => {
                if (pathname.includes('dashboard')) {
                  openDashboardModal();
                } else if (pathname.includes('wallet')) {
                  openWalletModal();
                } else if (pathname.includes('account')) {
                  openAccountModal();
                } else if (pathname.includes('orders')) {
                  openOrdersModal();
                }
              }}
            >
              <InfoIcon fontSize={'large'} sx={{ cursor: 'pointer', marginX: 2 }} />
            </Box>
          )}
        </Box>
      </Collapse>
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    // position: 'fixed',
    // color: 'inherit',
    elevation: 0,
    sx: {
      width: '100%',
      position: 'relative',
      padding: 0,
      minHeight: 'none',
      zIndex: 1200,
      backgroundColor: theme.palette.primary.main,
      flexGrow: 0
    }
  };

  return (
    <>
      {!matchDownSM ? (
        //Desktop
        <AppBarStyled open={open} {...appBar}>
          {desktopHeader}
        </AppBarStyled>
      ) : (
        //Mobile
        <AppBar {...appBar}>{mobileHeader}</AppBar>
      )}
    </>
  );
};

export default memo(Header);
