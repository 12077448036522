import { Alert } from '@mui/material';
import { useEndpoint } from '../../hooks/useEndpoint';
import LoadingButton from '../../components/custom/LoadingButton';
import useAuth from '../../hooks/useAuth';
import { dispatch } from '../../store';
import { openSnackbar } from '../../store/reducers/snackbar';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';

const AlertDeletedAccount = () => {
  const { authTokenLogin } = useAuth();
  const { trackAction } = useNavigationTracker();
  const cancelDeleteRequest = useEndpoint<undefined, 'post'>({
    method: 'post',
    endpoint: '/users/me/cancel-delete-request',
    mutationKey: 'cancel-delete-request',
    options: {
      onSuccess: async () => {
        await authTokenLogin();
        dispatch(
          openSnackbar({
            message: 'Richiesta annullata con successo',
            variant: 'success'
          })
        );
      }
    }
  });

  return (
    <Alert
      variant={'filled'}
      severity={'warning'}
      color={'error'}
      action={
        <LoadingButton
          loading={cancelDeleteRequest.isLoading}
          variant={'text'}
          size={'small'}
          sx={{ color: '#ffffff' }}
          onClick={() => {
            cancelDeleteRequest.mutate(undefined);
            trackAction({
              type: ClientTypeEnum.Button,
              action: ClientActionEnum.Clicked,
              name: "Ha annullato la richiesta dell'eliminazione dell'account"
            });
          }}
        >
          Annulla
        </LoadingButton>
      }
    >
      Hai richiesto l'eliminazione dell'account
    </Alert>
  );
};

export default AlertDeletedAccount;
