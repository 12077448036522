import { NewOrderStepProps } from '../../types/formik/new-order/new-order-step-props';
import { Box, Button, Divider, Fade, Grid, InputLabel, styled, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import OrderSummaryCard from '../../components/custom/Cards/OrderSummary/OrderSummaryCard';
import { NewOrderFormikStep6 } from '../../types/formik/new-order/new-order-step-6';
import useModal from '../../hooks/useModal';
import ConfirmOrderModal from '../../components/custom/Modal/ConfirmOrderModal';
import AlertFreeLicense from '../../components/custom/Alert/AlertFreeLicense';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import { useEndpoint } from '../../hooks/useEndpoint';

import useAuth from '../../hooks/useAuth';
import { textToUrl } from '../../utils/urlFormatting';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import { useEffect } from 'react';

const PaymentTextField = styled(TextField)({
  '.Mui-disabled': {
    backgroundColor: '#F0F0F0'
  }
});

// { orderId, orderSummary, refetchOrderSummary, setLoadingButton }: NewOrderStepProps
const NewOrderStep6 = () => {
  const { orderSummary, initialRenders, setNavigateLoading, refetchOrderSummary, orderId, setLoadingButton } =
    useOutletContext<NewOrderStepProps>();

  const { openModal } = useModal();
  const { trackAction } = useNavigationTracker();
  const { authTokenLogin } = useAuth();
  const navigate = useNavigate();

  const confirmOrder = useEndpoint<undefined, 'put'>({
    method: 'put',
    endpoint: `/orders/${orderId}`,
    mutationKey: `complete-order-${orderId}`,
    options: {
      onSuccess: async () => {
        await authTokenLogin();
        setLoadingButton(false);
        // dispatch(
        //   openSnackbar({
        //     variant: 'success',
        //     message: `Ordine ${orderSummary!.title} creato con successo!`
        //   })
        // );

        // console.log('navigo al link: ', `/orders/${orderId}/${textToUrl(orderSummary!.title)}`);
        //
        navigate(`/orders/${orderId}/${textToUrl(orderSummary!.title)}`, {
          replace: true
        });
      },
      onError: () => {
        refetchOrderSummary();
      }
    }
  });

  const formStep6 = useFormik<NewOrderFormikStep6>({
    initialValues: {
      fullname: '',
      creditNumber: '',
      iban: '',
      cvv: '',
      expireDate: '',
      note: ''
    },
    onSubmit: () => {
      if (orderId == null) throw new Error('order id must not be null');
      //se ha utilizzato solo ivh oppure nessun plafond non deve vedere il modal
      if (!orderSummary?.activeConfiguration.wallets.some((w) => w.orderWallet != null && w.walletPartner.slug !== 'ivh')) {
        setLoadingButton(true);
        confirmOrder.mutate(undefined);
        trackAction({
          type: ClientTypeEnum.Button,
          action: ClientActionEnum.Clicked,
          name: `Ha confermato l'ordine: ${orderId}`
        });
      } else {
        openModal({
          content: <ConfirmOrderModal orderId={orderId} onError={refetchOrderSummary} title={orderSummary!.title} />
        });
        trackAction({
          type: ClientTypeEnum.Button,
          action: ClientActionEnum.Clicked,
          name: "Ha aperto il modal per confermare l'ordine"
        });
      }
    }
  });

  useEffect(() => {
    if (initialRenders.step5) {
      setNavigateLoading(false);
    }
  }, [initialRenders]);

  return orderSummary != null && orderId != null ? (
    <Fade in>
      <Box>
        <Typography gutterBottom variant="h5">
          Conferma e Paga
        </Typography>
        <Typography gutterBottom variant="body2">
          Inserisci i dati della tua carta
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <form noValidate id={'new-order-step-6'} onSubmit={formStep6.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid item xs={12} mb={5}>
                <AlertFreeLicense />
              </Grid>

              <Typography variant={'body1'} fontWeight={600}>
                Inserisci i dati della carta di credito
              </Typography>
              <Typography gutterBottom variant="body2">
                Grazie per aver scelto buyplus! Completa il pagamento per attivare la licenza, accedere a tutte le funzionalità, e far
                partire il tuo primo ordine.
              </Typography>

              <Grid container maxWidth={650} spacing={3} mt={1}>
                <Grid item xs={12}>
                  <PaymentTextField label={'Titolare della carta'} value={formStep6.values.fullname} fullWidth disabled={true} />
                </Grid>

                <Grid item xs={12}>
                  <PaymentTextField label={'Numero della carta'} value={formStep6.values.creditNumber} fullWidth disabled={true} />
                </Grid>

                <Grid item xs={12}>
                  <PaymentTextField label={'Iban'} value={formStep6.values.iban} fullWidth disabled={true} />
                </Grid>

                <Grid item xs={6}>
                  <PaymentTextField label={'CVV'} value={formStep6.values.cvv} fullWidth disabled={true} />
                </Grid>

                <Grid item xs={6}>
                  <PaymentTextField label={'Data di scadenza'} value={formStep6.values.expireDate} fullWidth disabled={true} />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel variant={'standard'}>Note</InputLabel>
                  <PaymentTextField
                    disabled={true}
                    fullWidth
                    minRows={5}
                    maxRows={10}
                    multiline
                    placeholder={'Campo note'}
                    value={formStep6.values.note}
                  />
                </Grid>

                <Grid item xs={12} textAlign={'end'}>
                  <Button variant={'contained'} size={'medium'} disabled={true}>
                    Inserisci carta
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/*Riepilogo*/}
            <Grid item xs={12} lg={4}>
              <Box display={'flex'} width={'100%'} justifyContent={{ xs: 'center', lg: 'flex-start' }}>
                <OrderSummaryCard order={orderSummary!} refetchOrderSummary={refetchOrderSummary} />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Fade>
  ) : (
    <></>
  );
};

export default NewOrderStep6;
