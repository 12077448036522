import useModal from '../../../hooks/useModal';
import { Button, Stack, Typography } from '@mui/material';
import { useEndpoint } from '../../../hooks/useEndpoint';
import useAuth from '../../../hooks/useAuth';
import LoadingButton from '../LoadingButton';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import { useNavigate } from 'react-router-dom';
import { textToUrl } from '../../../utils/urlFormatting';

type ConfirmOrderModalProps = {
  orderId: string;
  title: string;
  onError?: () => Promise<any>;
  setIsSuccess?: (value: boolean) => void;
};
const ConfirmOrderModal = ({ orderId, onError, title, setIsSuccess }: ConfirmOrderModalProps) => {
  const { closeModal } = useModal();
  const { authTokenLogin } = useAuth();
  const { trackAction } = useNavigationTracker();
  const navigate = useNavigate();
  const confirmOrder = useEndpoint<undefined, 'put'>({
    method: 'put',
    endpoint: `/orders/${orderId}`,
    mutationKey: `complete-order-${orderId}`,
    options: {
      onSuccess: async () => {
        await authTokenLogin();
        closeModal();
        // dispatch(
        //   openSnackbar({
        //     variant: 'success',
        //     message: `Ordine ${title} creato con successo!`
        //   })
        // );
        navigate(`/orders/${orderId}/${textToUrl(title)}`, {
          replace: true
        });
        trackAction({
          type: ClientTypeEnum.Button,
          action: ClientActionEnum.Clicked,
          name: `Ha confermato l'ordine: ${orderId}`
        });
      },
      onError: async () => {
        if (onError) await onError();
      }
    }
  });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
      }}
    >
      <Typography variant={'h4'} fontWeight={600} mb={4}>
        Conferma invio
      </Typography>
      <Typography variant={'body1'}>
        Hai concluso con successo il tuo ordine, i tuoi documenti verranno inviati al/ai Partner, confermi l’invio?
      </Typography>

      <Stack flexDirection={'row'} gap={'10px'} justifyContent={'flex-end'} mt={4}>
        <Button variant={'outlined'} size={'medium'} onClick={closeModal}>
          Annulla
        </Button>
        <LoadingButton
          loading={confirmOrder.isLoading}
          variant={'contained'}
          size={'medium'}
          onClick={() => {
            confirmOrder.mutate(undefined);
          }}
        >
          Invia documenti
        </LoadingButton>
      </Stack>
    </div>
  );
};

export default ConfirmOrderModal;
