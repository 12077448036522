import { Components } from '@mui/material';

export default function Link() {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    } as Components['MuiLink']
  };
}
