import { createSlice } from '@reduxjs/toolkit';

interface HelpersAuthProps {
  //Boolean per dirci se salvare i token nel localStorage o nel SessionStorage
  rememberMe: boolean;
}

const initialState: HelpersAuthProps = {
  rememberMe:
    localStorage['rememberMe'] !== 'undefined' && (localStorage['rememberMe'] === 'true' || localStorage['rememberMe'] === 'false')
      ? localStorage['rememberMe'] === 'true'
        ? true
        : false
      : false
};

const helpersAuth = createSlice({
  name: 'helpersAuth',
  initialState,
  reducers: {
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
      localStorage.setItem('rememberMe', action.payload);
    }
  }
});

export default helpersAuth.reducer;

export const { setRememberMe } = helpersAuth.actions;
