import { PlafondState, WalletDTO } from '../types/dto/plafond.dto';
import { BuildingDTO } from '../types/dto/building.dto';

enum NotActiveState {
  NotActive = 'not-active'
}
export type WalletStatus = PlafondState | NotActiveState;

/**
 * Stato generale del wallet
 * Caso report: se il report è stato caricato e la registrazione non è completata allora sarà in attesa
 * Mi scorro tutti i wallet e vado a cercare se c'è un attivo, allora sarà attivo, se non ce ne sono attivi, cerco uno se cè uno in attivazione, altrimenti non attivo
 *
 */
export const getWalletState = (
  wallet: WalletDTO[],
  report: boolean,
  registrationComplete: boolean,
  registrationConfirmed: boolean,
  registrationMethod: BuildingDTO['registrationMethod']
): WalletStatus => {
  let state = 'not-active' as WalletStatus;
  if (report && !registrationComplete && registrationConfirmed && registrationMethod === 'report') return 'pending' as WalletStatus;
  if (!registrationComplete && registrationConfirmed && registrationMethod === 'pms') return 'not-active' as WalletStatus;
  if (!registrationComplete && !registrationConfirmed) return 'not-active' as WalletStatus;

  const plafondHistory = wallet.map((plafond) => (plafond.wallet != null ? plafond.wallet : undefined));
  for (const plafond of plafondHistory) {
    if (plafond === undefined) continue;
    if (plafond.state.key === PlafondState.Approved) {
      state = PlafondState.Approved;
      break;
    } else if (plafond.state.key === PlafondState.Pending && PlafondState.Approved !== state) {
      state = PlafondState.Pending;
    }
  }

  return state;
};
