import { Box } from '@mui/material';
import LoadingButton from '../../components/custom/LoadingButton';
import { useEndpoint } from '../../hooks/useEndpoint';
import { queryClient } from '../../App';
import { useSelector } from '../../store';
import WalletPage from '../../pages/wallet/WalletPage';

const TestWalletGenerateTab = () => {
  const { holder } = useSelector((state) => state.auth);
  const recalculteWallet = useEndpoint<undefined, 'put'>({
    method: 'put',
    endpoint: `/buildings/${holder?.holderBuilding?.id}/wallets`,
    mutationKey: 'recalculate-wallet',
    queryParams: {
      mode: 'test'
    },
    options: {
      onSuccess: async () => {
        await queryClient.refetchQueries('get-wallets-wallet-page');
      }
    }
  });

  return (
    <Box>
      <Box mb={3}>
        <LoadingButton
          loading={recalculteWallet.isLoading}
          onClick={() => {
            recalculteWallet.mutate(undefined);
          }}
          variant={'outlined'}
          size={'medium'}
        >
          Calcola Wallet
        </LoadingButton>
      </Box>
      <WalletPage testMode={true} />
    </Box>
  );
};

export default TestWalletGenerateTab;
