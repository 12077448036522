import { Box, Button, Typography } from '@mui/material';
import useModal from '../../../hooks/useModal';
import { useEndpoint } from '../../../hooks/useEndpoint';
import LoadingButton from '../LoadingButton';
type DeleteBuildingSalesModalProps = {
  refetchBuildingSales: () => Promise<any>;
  buildingSaleId: number;
};
const DeleteBuildingSalesModal = (props: DeleteBuildingSalesModalProps) => {
  const { closeModal } = useModal();

  const deleteData = useEndpoint<undefined, 'delete'>({
    method: 'delete',
    endpoint: `/building-sales/${props.buildingSaleId}`,
    mutationKey: `delete-sales-data-${props.buildingSaleId}`,
    queryParams: {
      mode: 'test'
    },
    options: {
      onSuccess: async () => {
        await props.refetchBuildingSales();
        closeModal();
      }
    }
  });
  return (
    <Box>
      <Typography>Vuoi davvero eliminare questi dati di vendita?</Typography>
      <Box mt={3} display="flex" justifyContent="flex-end">
        <Button onClick={closeModal}>annulla</Button>
        <LoadingButton loading={deleteData.isLoading} size={'medium'} variant="contained" onClick={() => deleteData.mutate(undefined)}>
          conferma
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default DeleteBuildingSalesModal;
