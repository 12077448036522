import { NotificationDTO, NotifyTag, ResourceEnum } from '../types/dto/notification.dto';
import { textToUrl } from './urlFormatting';

export const getNotificationTitle = (notification: NotificationDTO) => {
  const subjectName = notification.subjectName;
  let message: string = '';

  if (notification.customMessage != null) {
    return notification.customMessage;
  }

  switch (notification.resource) {
    case ResourceEnum.Building:
      switch (notification.operation) {
        case 'create':
          message = 'Creata la struttura';
          break;

        case 'update':
          if (notification.tag != null) {
            if (notification.tag === NotifyTag.Wallet) {
              message = 'Aggiornato il tuo wallet';
            } else if (notification.tag === NotifyTag.PmsExports) {
              message = 'Aggiornamento report struttura';
            } else if (notification.tag === NotifyTag.Document) {
              message = 'Aggiornato un documento';
            }
          } else {
            message = 'Aggiornata la tua struttura';
          }
          break;
      }
      break;

    case ResourceEnum.Order:
      switch (notification.operation) {
        case 'create':
          message = `Creato l'ordine "${subjectName}"`;
          break;

        case 'update':
          message = `Aggiornato l'ordine "${subjectName}"`;
          break;

        case 'delete':
          message = `Eliminato l'ordine "${subjectName}"`;
          break;
      }
      break;

    case ResourceEnum.User:
      switch (notification.operation) {
        case 'update':
          if (notification.tag === NotifyTag.Contact) {
            message = 'Richiesta di contatto';
            break;
          }
          message = 'Aggiornato il tuo profilo';
          break;
      }
      break;

    case ResourceEnum.DocumentAutoRequest:
      switch (notification.operation) {
        case 'create':
          message = 'Aggiunta richiesta documento';
          break;

        case 'update':
          message = 'Aggiornato documento';
          break;

        case 'delete':
          message = 'Eliminato documento';
          break;
      }
      break;
  }

  // return message + ' ' + notification.subjectName;

  // return `${message} "${notification.subjectName}"`;
  return message;
};

export const getNotificationAction = (notification: NotificationDTO): string | null => {
  const subject = notification.subject;
  const subjectName = notification.subjectName;
  const resource = notification.resource;

  switch (resource) {
    case ResourceEnum.Order:
      return `/orders/${subject}/${textToUrl(subjectName ?? '')}`;

    case ResourceEnum.User:
      if (notification.tag === NotifyTag.Contact) {
        return '/account/building';
      }
      return '/account/settings';

    case ResourceEnum.Building:
      if (notification.tag != null && notification.tag === NotifyTag.Wallet) {
        return '/wallet';
      } else if (notification.tag != null && notification.tag === NotifyTag.PmsExports) {
        return null;
      } else if (notification.tag != null && notification.tag === NotifyTag.Document) {
        return '/account/documents';
      } else {
        return '/account/building';
      }

    case ResourceEnum.DocumentAutoRequest:
      return '/account/documents';

    default:
      return null;
  }
};
