// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';
import WalletIcon from '../components/custom/BuyPlusIcons/WalletIcon';
import WalletNegativeIcon from 'components/custom/BuyPlusIcons/WalletNegativeIcon';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const wallet: NavItemType = {
  id: 'wallet-group',
  title: '',
  type: 'group',
  children: [
    {
      id: 'wallet',
      title: <FormattedMessage id="wallet" />,
      type: 'item',
      url: '/wallet',
      icon: [WalletIcon, WalletNegativeIcon]
    }
  ]
};

export default wallet;
