import { Box, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { urlToText } from '../../utils/urlFormatting';

const nonClicableLinks: string[] = ['account', 'support'];

const Breadcrumb = () => {
  const location = useLocation();
  const params = useParams();
  const [paths, setPaths] = useState<string[]>([]);

  useEffect(() => {
    const locations = location.pathname.split('/');
    locations.shift();
    setPaths(locations);
  }, [location, params]);

  return !(paths.length === 1 && paths[0] === 'dashboard') ? (
    <Box height={'35px'} display={'flex'} alignItems={'center'} component={'nav'} flexGrow={0}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link component={RouterLink} underline="hover" color="inherit" to={`/dashboard`}>
          Home
        </Link>
        {paths.map((item, i) => {
          let href = '';
          paths.forEach((link, j) => {
            if (j <= i) {
              href += `/${link}`;
            }
          });

          if (paths[i - 1] === 'orders') {
            return false;
          }
          return (
            item !== 'dashboard' && (
              <Link
                component={i < paths.length - 1 && !nonClicableLinks.includes(href.replace(/\//g, '')) ? RouterLink : Link}
                key={i}
                underline="none"
                sx={{ cursor: i < paths.length - 1 && !nonClicableLinks.includes(href.replace(/\//g, '')) ? 'pointer' : 'default' }}
                color="inherit"
                {...(i < paths.length - 1 &&
                  !nonClicableLinks.includes(href.replace(/\//g, '')) && {
                    to: href,
                    underline: 'hover'
                  })}
              >
                {/*{paths[i - 1] === 'new-order' ? item : <FormattedMessage id={item} />}*/}
                {paths[i - 2] === 'orders' ? urlToText(item) : <FormattedMessage id={item} />}
              </Link>
            )
          );
        })}
      </Breadcrumbs>
    </Box>
  ) : (
    <></>
  );
};

export default Breadcrumb;
