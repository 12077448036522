// material-ui
import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main
        }
      }
    } as Components['MuiFormHelperText']
  };
}
