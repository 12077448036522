import { List, ListItem, Typography } from '@mui/material';
import { PasswordValidatorType, StringColorProps } from '../../types/password';
import { strengthColor, strengthIndicator } from '../../utils/password-strength';
import { getPasswordValidationLabel, passwordValidation } from '../../utils/password-validation';
import { useEffect } from 'react';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DoneIcon from '@mui/icons-material/Done';
type PasswordSecurityProps = {
  password: string;
  setIsSafe?: (v: boolean) => void;
};
const PasswordSecurity = ({ password, setIsSafe }: PasswordSecurityProps) => {
  const level: StringColorProps = strengthColor(strengthIndicator(password));
  const requirements: PasswordValidatorType = passwordValidation(password);

  useEffect(() => {
    if (!setIsSafe) return;
    if (level.label !== 'good' && level.label !== 'strong') {
      setIsSafe(false);
    } else {
      setIsSafe(true);
    }
  }, [password]);
  return (
    <List disablePadding>
      {Object.keys(requirements).map((label, i) => (
        <ListItem key={i} disablePadding sx={{ gap: '7px', mb: 1 }}>
          {requirements[label] ? (
            <DoneIcon color={'success'} fontSize={'small'} />
          ) : (
            <HorizontalRuleIcon color={'error'} fontSize={'small'} />
          )}

          <Typography variant={'body2'}>{getPasswordValidationLabel(label)}</Typography>
        </ListItem>
      ))}

      {/*<Grid container spacing={2} alignItems="center">*/}
      {/*  <Grid item>*/}
      {/*    <Box sx={{ bgcolor: level.color, width: 85, height: 8, borderRadius: '7px' }} />*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <Typography variant="subtitle1" fontSize="0.75rem">*/}
      {/*      <FormattedMessage id={level.label} />*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </List>
  );
};

export default PasswordSecurity;
