// material-ui
import { Theme } from '@mui/material/styles';

// third-party
import { merge } from 'lodash';

// project import
import Button from './Button';
import Chip from './Chip';
import IconButton from './IconButton';
import Tabs from './Tabs';
import Tab from './Tab';
import Snackbar from './Snackbar';
import Alert from './Alert';
import ListItemText from './ListItemText';
import InputLabel from './InputLabel';
import Drawer from './Drawer';
import Dialog from './Dialog';
import Accordion from './Accordion';
import Link from './Link';
import FormHelperText from './FormHelperText';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    Chip(theme),
    IconButton(theme),
    Tabs(theme),
    Tab(),
    Snackbar(theme),
    Alert(theme),
    ListItemText(),
    InputLabel(theme),
    Drawer(),
    Dialog(),
    Accordion(),
    Link(),
    FormHelperText(theme)
  );
}
