import { useLocation, useParams } from 'react-router';
import { Box, BoxProps, Breadcrumbs, BreadcrumbsProps, Fade, Grow, Typography, TypographyProps } from '@mui/material';
import { textToUrl } from '../../../utils/urlFormatting';
import { BreadcrumbsType, MapRoutesType } from './types/breadcrumb-props';
import { Link as RouterLink } from 'react-router-dom';

export type TCN_BreadcrumbProps = {
  ContainerProps?: BoxProps;
  BreadcrumbsProps?: BreadcrumbsProps;
  TypographyProps?: { active?: Omit<TypographyProps, 'key'>; previous?: Omit<TypographyProps, 'key'> };
  mapRoutes: MapRoutesType[];
};

const TCN_Breadcrumb = (props: TCN_BreadcrumbProps) => {
  const { mapRoutes, BreadcrumbsProps, TypographyProps, ContainerProps } = props;
  const params = useParams();
  let pathname = useLocation().pathname;
  Object.keys(params).forEach((param) => {
    pathname = pathname.replace(`/${textToUrl(params[param]!)}`, `/:${param}`);
  });

  const mapRoute = mapRoutes.find((mr) => (Array.isArray(mr.pathname) ? mr.pathname.includes(pathname) : mr.pathname === pathname));

  if (mapRoute == null) {
    throw new Error(`pathname: ${pathname} it's not included in mapRoutes`);
  }

  const breadcrumbs =
    typeof mapRoute.breadcrumbs === 'object'
      ? (mapRoute.breadcrumbs as BreadcrumbsType)
      : typeof mapRoute.breadcrumbs === 'function'
      ? mapRoute.breadcrumbs(params)
      : mapRoute.breadcrumbs;

  if (!Array.isArray(breadcrumbs)) {
    return (
      <Box display={'flex'} alignItems={'center'} gap={'20px'} {...ContainerProps}>
        {mapRoute.customIcon != null && mapRoute.customIcon.position === 'start' && mapRoute.customIcon.component}
        <Breadcrumbs {...BreadcrumbsProps}>
          <Typography
            component={breadcrumbs.href ? RouterLink : Typography}
            to={breadcrumbs.href}
            {...(TypographyProps != null ? (TypographyProps.active != null ? TypographyProps.active : {}) : {})}
            {...breadcrumbs.TypographyProps}
          >
            {breadcrumbs.title}
          </Typography>
        </Breadcrumbs>
        {mapRoute.customIcon != null && mapRoute.customIcon.position === 'end' && mapRoute.customIcon.component}
      </Box>
    );
  }

  return (
    <Box display={'flex'} alignItems={'center'} gap={'20px'} {...ContainerProps}>
      {mapRoute.customIcon != null && mapRoute.customIcon.position === 'start' && (
        <Fade in>
          <Box>{mapRoute.customIcon.component}</Box>
        </Fade>
      )}
      <Breadcrumbs {...BreadcrumbsProps}>
        {breadcrumbs.map((path, i) => (
          <Grow
            in
            key={i}
            enter={i !== 0}
            timeout={{
              enter: i * 250
            }}
          >
            <Typography
              component={path.href ? RouterLink : Typography}
              to={path.href ?? ''}
              {...(TypographyProps != null
                ? breadcrumbs.length > 0 && i === breadcrumbs.length - 1
                  ? TypographyProps.active != null
                    ? TypographyProps.active
                    : {}
                  : TypographyProps.previous != null
                  ? TypographyProps.previous
                  : {}
                : {})}
              {...path.TypographyProps}
            >
              {path.title}
            </Typography>
          </Grow>
        ))}
      </Breadcrumbs>
      {mapRoute.customIcon != null && mapRoute.customIcon.position === 'end' && (
        <Fade in>
          <Box>{mapRoute.customIcon.component}</Box>
        </Fade>
      )}
    </Box>
  );
};

export default TCN_Breadcrumb;
