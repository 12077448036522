import { Box, Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { PlafondLabel } from '../WalletCard';
import { WalletDTO } from '../../../../types/dto/plafond.dto';
type WalletBarLabelProps = {
  wallet: WalletDTO[];
};
const WalletBarLabel = ({ wallet }: WalletBarLabelProps) => {
  return (
    <Grid item xs={12} display={'flex'} alignItems={'center'} flexWrap={'wrap'} gap={'10px'} mt={2} mb={1}>
      {wallet.map((item, i) => {
        if (item.wallet == null) return false;

        const activePlafond = item.wallet;
        if (activePlafond == null || activePlafond.state.key !== 'approved' || BigNumber(activePlafond.usedAmount).isEqualTo(0))
          return false;

        return (
          <Box display={'flex'} alignItems={'center'} key={'label' + i}>
            <PlafondLabel sx={{ backgroundColor: item.color }} />
            <Typography variant="body1">{item.title}</Typography>
          </Box>
        );
      })}
    </Grid>
  );
};

export default WalletBarLabel;
