// third-party
import OrdersIcon from 'components/custom/BuyPlusIcons/OrdersIcon';
import OrdersNegativeIcon from 'components/custom/BuyPlusIcons/OrdersNegativeIcon';
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const orders: NavItemType = {
  id: 'orders-group',
  title: '',
  type: 'group',
  children: [
    {
      id: 'orders',
      title: <FormattedMessage id="orders" />,
      type: 'collapse',
      icon: [OrdersIcon, OrdersNegativeIcon],
      children: [
        {
          id: 'new-order',
          title: <FormattedMessage id="new-order" />,
          type: 'item',
          url: '/orders/new-order'
        },
        {
          id: 'all-orders',
          title: <FormattedMessage id="all-orders" />,
          type: 'item',
          url: '/orders'
        }
      ]
    }
  ]
};

export default orders;
