import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const FileIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="32" height="42" viewBox="0 0 32 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_556_186867)">
        <path d="M31.9956 41.3335H0.995605V0H27.8492C30.1396 0 31.9961 1.85649 31.9961 4.14698V41.3335H31.9956Z" fill="#094754" />
        <path d="M25.796 12.3999H13.6392V16.5332H25.796V12.3999Z" fill="#91DCF9" />
        <path d="M25.7959 28.9336H7.1958V33.0669H25.7959V28.9336Z" fill="#91DCF9" />
        <path d="M25.7959 20.667H7.1958V24.8003H25.7959V20.667Z" fill="#91DCF9" />
        <path d="M31.9956 7.2334V3.71034C31.9956 1.66132 30.3343 0 28.2853 0H24.7622L31.9956 7.2334Z" fill="#91DCF9" />
        <path d="M24.7622 7.2334H31.9956L24.7622 0V7.2334Z" fill="#1ED367" />
        <path
          d="M9.14645 16.4174C10.2238 16.4174 11.0971 15.5441 11.0971 14.4668C11.0971 13.3894 10.2238 12.5161 9.14645 12.5161C8.06914 12.5161 7.1958 13.3894 7.1958 14.4668C7.1958 15.5441 8.06914 16.4174 9.14645 16.4174Z"
          fill="#F0FF32"
        />
      </g>
      <defs>
        <clipPath id="clip0_556_186867">
          <rect width="31" height="41.3335" fill="white" transform="translate(0.995605)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default FileIcon;
