// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab() {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: 'uppercase'
          // '&:hover': {
          //   backgroundColor: theme.palette.primary.lighter + 60,
          //   color: theme.palette.primary.main
          // },
          // '&:focus-visible': {
          //   borderRadius: 4,
          //   outline: `2px solid ${theme.palette.secondary.dark}`,
          //   outlineOffset: -3
          // }
        }
      }
    }
  };
}
