import { Link, List, ListItem, Typography } from '@mui/material';
import { FaqDto } from '../../../../types/dto/faq.dto';
import { useNavigate } from 'react-router-dom';
import useNavigationTracker from '../../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../../types/dataToSend/clientLog';
import { dispatch } from '../../../../store';
import { activeItem } from '../../../../store/reducers/menu';

type FaqListProps = {
  faqs: FaqDto[];
  withTitle?: boolean;
};
const FaqList = ({ faqs, withTitle }: FaqListProps) => {
  const navigate = useNavigate();
  const { trackAction } = useNavigationTracker();

  return (
    <List disablePadding>
      {withTitle && (
        <ListItem>
          <Typography variant="h5">FAQ</Typography>
        </ListItem>
      )}
      {faqs.map((f) => (
        <ListItem key={f.id} sx={{ paddingX: !withTitle ? 0 : undefined }}>
          <Link
            color={'primary'}
            onClick={() => {
              trackAction({
                type: ClientTypeEnum.Link,
                action: ClientActionEnum.Clicked,
                name: `Ha cliccato sulla faq '${f.question.it}'`
              });
              navigate(`/support/faqs#${f.categories[0]}#${f.anchorSlug}`);
              dispatch(activeItem({ openItem: ['faqs'] }));
            }}
            underline={'always'}
          >
            {f.question.it}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

export default FaqList;
