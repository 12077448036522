import { AxiosResponse } from 'axios';
import { ReactElement } from 'react';
import { BuildingToSend } from './dataToSend/building';

import { BuildingDTO } from './dto/building.dto';
import { SingleDataDTO } from './server/single-data';
import { UserDTO } from './dto/user.dto';

// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null;
};

export type UserProfile = Omit<UserDTO, 'buildings'> & {
  building: BuildingDTO;
};

export type HolderType = {
  buildings: (Pick<BuildingDTO, 'name' | 'businessName' | 'id'> & { users: Omit<PawnType, 'building'>[] })[];
  holderBuilding?: Pick<BuildingDTO, 'name' | 'businessName' | 'id'> & { users: Omit<PawnType, 'building'>[] };
} & Omit<UserDTO, '_count'>;

export type PawnType = {
  id: number;
  firstname: string | null;
  lastname: string | null;
  email: string;
  building: {
    name: string | null;
    businessName: string | null;
  };
};

export interface AuthProps {
  isLoggedIn: boolean;
  isInitialized: boolean;
  user: UserProfile | null;
  holder?: HolderType;
  pawn?: PawnType;
}

export interface AuthActionProps {
  // type: string;
  payload: {
    user: UserProfile | null;
    holder?: HolderType;
  };
}
export type AxiosAuthResponseType = {
  accessToken: string;
  refreshToken: string;
  accessExpireAt: string;
  user: UserDTO | null;
  holder?: HolderType;
};

export type AuthContextType = {
  authEmailPasswordSingIn: (email: string, password: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  authTokenLogin: () => Promise<
    AxiosResponse<Omit<AxiosAuthResponseType, 'accessToken' | 'refreshToken' | 'accessExpireAt'>, any> | undefined
  >;
  logout: () => Promise<void>;
  forgotPassword: (email: string) => Promise<AxiosResponse>;
  checkPasswordResetCode: (code: string) => Promise<AxiosResponse>;
  resetPassword: (password: string, newPassword: string, code: string) => Promise<AxiosResponse>;
  register: (data: {
    firstname: string;
    lastname: string;
    tag: string;
    vat: string;
    email: string;
    password: string;
    confirmPassword: string;
    phone: string;
    name: string;
    businessName: string;
    origin: RegistrationEnum;
    documents: {
      autoRequestId: number;
      accepted: boolean;
    }[];
  }) => Promise<AxiosResponse>;
  completeFastRegister: (data: {
    firstname: string;
    lastname: string;
    tag: string;
    phone: string;
    businessName: string;
    userlastEdit: string;
    buildingLastEdit: string;
    name?: string;
  }) => Promise<AxiosResponse>;
  fastRegister: (data: {
    email: string;
    password: string;
    confirmPassword: string;
    origin: RegistrationEnum;
    vat: string;
    name?: string;
    referralCode?: string;
    documents: {
      autoRequestId: number;
      accepted: boolean;
    }[];
  }) => Promise<AxiosResponse>;
  buildingRegister: (
    building: BuildingToSend,
    lastEdit: string,
    referralCode?: string | undefined
  ) => Promise<AxiosResponse<SingleDataDTO<{ building: { lastEdit: string } }>>>;
  checkEmail: (code: string) => Promise<void>;
  getCodeCheckEmail: (email: string) => Promise<AxiosResponse>;
  editPassword: (oldPassword: string, newPassword: string, confirmNewPassword: string) => Promise<AxiosResponse>;
};

export enum RegistrationEnum {
  RegistrationComplete = 'complete-platform',
  RegistrationFast = 'fast-platform'
}
