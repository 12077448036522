import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const SupportNegativeIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9602_112411)">
        <g clipPath="url(#clip1_9602_112411)">
          <path
            d="M24 12.0002C24 18.6271 18.6271 24.0003 11.9998 24.0003C5.37263 24.0003 0 18.6271 0 12.0002C0 5.37326 5.37294 0 12.0002 0C18.6274 0 24.0003 5.37294 24.0003 12.0002H24Z"
            fill="#F0FF32"
          />
          <path
            d="M11.3524 19.4287C12.2317 19.4287 12.9445 18.7158 12.9445 17.8365C12.9445 16.9572 12.2317 16.2444 11.3524 16.2444C10.4731 16.2444 9.76025 16.9572 9.76025 17.8365C9.76025 18.7158 10.4731 19.4287 11.3524 19.4287Z"
            fill="#094754"
          />
          <path d="M12.383 11.1472H10.3217V14.7544H12.383V11.1472Z" fill="#91DCF9" />
          <path
            d="M12.383 13.2081H10.3217V11.1467H12.383C13.5199 11.1467 14.4444 10.2222 14.4444 9.08536C14.4444 7.94848 13.5199 7.024 12.383 7.024H10.3217V4.96265H12.383C14.6562 4.96265 16.5054 6.81222 16.5054 9.08505C16.5054 11.3579 14.6558 13.2075 12.383 13.2075V13.2081Z"
            fill="#91DCF9"
          />
          <path d="M12.383 11.1472H10.3217V13.2086H12.383V11.1472Z" fill="#1ED367" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9602_112411">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_9602_112411">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default SupportNegativeIcon;
