import { Box, Button, Typography } from '@mui/material';
import MainCard from '../../components/custom/Cards/MainCard';
import { Link } from 'react-router-dom';

const BoxAccount = () => {
  return (
    <MainCard
      title={
        <Typography variant={'body1'} fontWeight={600} textTransform={'uppercase'}>
          il tuo profilo
        </Typography>
      }
    >
      <Box>
        <Typography variant={'h6'} mb={3} mt={1}>
          Hai completato i tuoi dati?
        </Typography>
        <Typography variant={'body1'} mb={2}>
          Fallo subito per attivare il tuo Wallet e fare il primo ordine{' '}
        </Typography>
        <Button variant={'outlined'} size={'medium'} component={Link} to={'/building-register'}>
          completa i tuoi dati
        </Button>
      </Box>
    </MainCard>
  );
};

export default BoxAccount;
