import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const LockOpenIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_444_993691)">
        <path
          d="M22.9813 12.1553V17.0033H19.3694V12.1553H9.59073V17.0033H5.9788V12.1553H2.5V30H22.5088C24.6907 30 26.4593 28.2314 26.4593 26.0495V12.1553H22.9809H22.9813Z"
          fill="#094754"
        />
        <path
          d="M9.59045 8.50145C9.59045 5.80524 11.7838 3.61193 14.48 3.61193C17.1762 3.61193 19.3695 5.80524 19.3695 8.50145V12.1549H22.9814V8.50145C22.9814 6.23071 22.0973 4.09587 20.4914 2.48999C18.8855 0.884119 16.7507 0 14.48 0C12.2092 0 10.0744 0.884119 8.46851 2.48999C6.86263 4.09587 5.97852 6.23071 5.97852 8.50145V12.1549H9.59045V8.50145Z"
          fill="#91DCF9"
        />
        <path d="M9.59045 17.0029V13.391V12.1553H5.97852V17.0029H9.59045Z" fill="#49EBAA" />
        <path d="M19.3691 13.391V17.0029H22.9815V12.1553H19.3691V13.391Z" fill="#094754" />
        <path
          d="M14.5607 22.9378C15.7624 22.9378 16.7366 21.9636 16.7366 20.7619C16.7366 19.5601 15.7624 18.5859 14.5607 18.5859C13.359 18.5859 12.3848 19.5601 12.3848 20.7619C12.3848 21.9636 13.359 22.9378 14.5607 22.9378Z"
          fill="#F0FF32"
        />
        <path d="M15.7413 21.7773H13.3809V26.4283H15.7413V21.7773Z" fill="#F0FF32" />
      </g>
      <path d="M18.75 8.39955V12.125H25V7.125H18.75V8.39955Z" fill="white" />
      <defs>
        <clipPath id="clip0_444_993691">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default LockOpenIcon;
