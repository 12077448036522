/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import Logo from '../../assets/images/logo/logo-full.svg';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    // <>
    //   <svg width="170" height="37" viewBox="0 0 170 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path
    //       d="M15.8389 28.1182H11.3562V24.3827C11.3562 20.1812 7.93702 16.7621 3.73559 16.7621H0V12.2794H3.73559C10.4069 12.2794 15.8389 17.7113 15.8389 24.3827V28.1182Z"
    //       fill="#F0FF32"
    //     />
    //     <path
    //       d="M27.1951 16.7709H23.4595C16.7882 16.7709 11.3562 11.3389 11.3562 4.66755V0.931961H15.8389V4.66755C15.8389 8.86899 19.2581 12.2881 23.4595 12.2881H27.1951V16.7709Z"
    //       fill="#F0FF32"
    //     />
    //     <path
    //       d="M54.4429 18.4057C54.4429 14.7756 52.1049 12.2354 48.7736 12.2354C45.4423 12.2354 43.0164 14.7405 43.0164 18.4057C43.0164 22.071 45.3544 24.576 48.7736 24.576C52.1928 24.576 54.4429 22.0358 54.4429 18.4057ZM58.9871 18.4057C58.9871 24.409 55.067 28.6193 49.4416 28.6193C46.8575 28.6193 44.3964 27.3711 43.1834 25.4902V28.1182H38.6743V0.931961H43.1746V11.3213C44.3876 9.4491 46.9717 8.19218 49.7229 8.19218C55.2779 8.19218 58.9871 12.2794 58.9871 18.4057Z"
    //       fill="#F0FF32"
    //     />
    //     <path
    //       d="M61.1054 20.9108V8.69319H65.6497V19.7857C65.6497 22.9148 66.6517 24.7518 69.6138 24.7518C73.0769 24.7518 74.325 22.4577 74.325 19.6626V8.69319H78.8692V28.127H74.325V25.4198C73.156 27.503 71.1168 28.628 68.3217 28.628C64.0675 28.6193 61.1054 26.123 61.1054 20.9108Z"
    //       fill="#F0FF32"
    //     />
    //     <path
    //       d="M95.6135 8.69319H100.158V28.2061C100.158 31.2474 99.2436 33.0316 97.6175 34.6138C96.0354 36.0729 93.7852 36.8639 91.0253 36.8639C88.2653 36.8639 85.8921 36.152 84.354 34.8599C82.974 33.6469 82.2269 31.9417 82.1829 29.8146H86.7711C86.9821 31.9417 88.0192 33.1108 90.9374 33.1108C93.6446 33.1108 95.6047 32.0033 95.6047 27.8721V25.411C94.3917 27.4942 92.2295 28.6193 89.3465 28.6193C84.5473 28.6193 82.1741 25.2001 82.1741 20.5328V8.69319H86.7184V19.5747C86.7184 22.4489 87.6764 24.62 90.9286 24.62C94.1807 24.62 95.5959 22.0798 95.5959 18.7397V8.69319H95.6135Z"
    //       fill="#F0FF32"
    //     />
    //     <path
    //       d="M119.381 18.4057C119.381 14.7756 117.043 12.2354 113.711 12.2354C110.38 12.2354 107.954 14.7405 107.954 18.4057C107.954 22.071 110.292 24.576 113.711 24.576C117.13 24.576 119.381 22.0358 119.381 18.4057ZM123.925 18.4057C123.925 24.409 120.005 28.6193 114.379 28.6193C111.795 28.6193 109.334 27.3711 108.121 25.4902V35.8443H103.621V8.6844H108.121V11.3125C109.334 9.44031 111.918 8.1834 114.669 8.1834C120.216 8.19219 123.925 12.2794 123.925 18.4057Z"
    //       fill="#F0FF32"
    //     />
    //     <path d="M130.798 0.931961H126.254V28.1182H130.798V0.931961Z" fill="#F0FF32" />
    //     <path
    //       d="M134.094 20.9108V8.69319H138.639V19.7857C138.639 22.9148 139.641 24.7518 142.603 24.7518C146.066 24.7518 147.314 22.4577 147.314 19.6626V8.69319H151.858V28.127H147.314V25.4198C146.145 27.503 144.106 28.628 141.311 28.628C137.057 28.6193 134.094 26.123 134.094 20.9108Z"
    //       fill="#F0FF32"
    //     />
    //     <path
    //       d="M154.073 22.1589H158.617C158.617 23.8289 160.244 24.9979 162.582 24.9979C164.296 24.9979 165.377 24.0838 165.377 22.7038C165.377 21.6227 164.92 20.9547 162.836 20.4098L158.96 19.3638C156.253 18.6167 154.706 16.6566 154.706 13.9054C154.706 10.6093 157.835 8.19218 162.168 8.19218C166.924 8.19218 169.842 10.4863 169.842 14.2394H165.377C165.377 12.6134 164.129 11.6553 162.001 11.6553C160.375 11.6553 159.206 12.4903 159.206 13.6593C159.206 14.7053 159.83 15.4085 161.623 15.9095L165.5 17.0345C168.251 17.8256 170 19.8296 170 22.5808C170 26.3779 167.038 28.628 162.327 28.628C157.58 28.6193 154.073 25.8681 154.073 22.1589Z"
    //       fill="#F0FF32"
    //     />
    //   </svg>
    // </>
    <img src={Logo} alt="logo buyplus" />
  );
};

export default LogoMain;
