import { Box, Button, Typography } from '@mui/material';
import IllustrationOrder from '../../../assets/images/illustrations/illustration-order-2.svg';
import { Link } from 'react-router-dom';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import Stack from '@mui/material/Stack';

type NewOrderCtaProps = {
  firstOrder: boolean;
  isWaitingReport: boolean;
  numberDraftOrders: number;
};

const NewOrderCta = ({ firstOrder, isWaitingReport, numberDraftOrders }: NewOrderCtaProps) => {
  const { trackAction } = useNavigationTracker();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: '#E3F9F8',
        borderRadius: '6px',
        paddingY: '40px',
        paddingX: '30px',
        gap: '5px'
      }}
    >
      <Stack width={'100%'} direction={'row'} alignItems={'flex-start'} gap={'20px'}>
        <img src={IllustrationOrder} alt="illustration new order" style={{ maxWidth: 150 }} />
        <Typography variant="h6" maxWidth={350}>
          {isWaitingReport
            ? 'Attendi il caricamento dei tuoi dati per effettuare il tuo ordine'
            : firstOrder
            ? 'Cosa aspetti, procedi con il tuo primo ordine.'
            : numberDraftOrders > 0
            ? numberDraftOrders === 1
              ? 'Hai ancora un ordine in bozza, vai a concluderlo'
              : `Hai ancora ${numberDraftOrders} ordini in bozza, vai a concluderli`
            : 'Non aspettare, procedi con il tuo ordine di acquisto '}
        </Typography>
      </Stack>
      {!isWaitingReport && (
        <Button
          variant="contained"
          size="medium"
          component={Link}
          state={{
            filterByDraft: !(firstOrder || numberDraftOrders === 0)
          }}
          to={firstOrder || numberDraftOrders === 0 ? '/orders/new-order' : '/orders'}
          sx={{ mt: 2 }}
          // onClick={async () => {
          //   await trackAction({
          //     name: 'Box Nuovo Ordine',
          //     type: 'button',
          //     action: 'click'
          //   });
          // }}
          onClick={() =>
            trackAction({
              type: ClientTypeEnum.Link,
              action: ClientActionEnum.Clicked,
              name: 'Nuovo ordine'
            })
          }
        >
          {firstOrder
            ? 'Nuovo ordine'
            : numberDraftOrders > 0 && numberDraftOrders === 1
            ? 'Concludi ordine'
            : numberDraftOrders > 0
            ? 'Concludi ordini'
            : 'Nuovo ordine'}
        </Button>
      )}
    </Box>
  );
};

export default NewOrderCta;
