import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const RibbonEnvironment = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box
        sx={{
          width: '100px',
          height: '30px',
          position: 'absolute',
          top: 5,
          left: -24,
          zIndex: 1300,
          transform: 'rotate(-40deg)',
          color: 'white',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor:
            process.env.REACT_APP_ENVIRONMENT === 'development'
              ? 'warning.dark'
              : process.env.REACT_APP_ENVIRONMENT === 'test'
              ? 'info.dark'
              : 'error.main',
          textTransform: 'uppercase'
        }}
      >
        <Typography variant={'body2'} fontSize={'12px'} fontWeight={600}>
          {process.env.REACT_APP_ENVIRONMENT === 'development'
            ? 'Sviluppo'
            : process.env.REACT_APP_ENVIRONMENT === 'test'
            ? 'Test'
            : 'Locale'}
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        width: '200px',
        height: '30px',
        position: 'absolute',
        top: 20,
        left: -50,
        zIndex: 1300,
        transform: 'rotate(-40deg)',
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:
          process.env.REACT_APP_ENVIRONMENT === 'development'
            ? 'warning.dark'
            : process.env.REACT_APP_ENVIRONMENT === 'test'
            ? 'info.dark'
            : 'error.main',
        textTransform: 'uppercase'
      }}
    >
      <Typography variant={'body1'} fontWeight={600} marginRight={'8px'}>
        {process.env.REACT_APP_ENVIRONMENT === 'development'
          ? 'Sviluppo'
          : process.env.REACT_APP_ENVIRONMENT === 'test'
          ? 'Test'
          : 'Locale'}
      </Typography>
    </Box>
  );
};

export default RibbonEnvironment;
