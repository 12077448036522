import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const PeopleIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 24V13.0213C5 11.8357 5.96181 10.8757 7.14557 10.8757H15.8704C17.056 10.8757 18.016 11.8375 18.016 13.0213V24H5Z"
        fill="#F0FF32"
      />
      <path
        d="M11.5089 15.5117C12.3511 15.5117 13.0338 14.829 13.0338 13.9869C13.0338 13.1447 12.3511 12.462 11.5089 12.462C10.6668 12.462 9.98413 13.1447 9.98413 13.9869C9.98413 14.829 10.6668 15.5117 11.5089 15.5117Z"
        fill="#094754"
      />
      <path
        d="M11.5088 10.874C14.5116 10.874 16.9458 8.43978 16.9458 5.43701C16.9458 2.43423 14.5116 0 11.5088 0C8.50601 0 6.07178 2.43423 6.07178 5.43701C6.07178 8.43978 8.50601 10.874 11.5088 10.874Z"
        fill="#7DEBF5"
      />
    </svg>
  </SvgIcon>
);

export default PeopleIcon;
