import React, { useEffect, useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import * as process from 'process';
import { dispatch, useSelector } from 'store';
import { SocketResponse } from 'types/socket-response';
import {
  setNotificationCount,
  setRefetchAppState,
  setRefetchBuilding,
  setRefetchNotifications,
  setRefetchOrders,
  setRefetchWallets
} from 'store/reducers/websocket';

import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { useEndpoint } from '../hooks/useEndpoint';
import { NotificationDTO } from '../types/dto/notification.dto';
import useNotification from '../hooks/useNotification';
import { getNotificationTitle } from '../utils/notificationUtils';
import { SingleDataDTO } from '../types/server/single-data';

let refreshCount: number = 0;
export const Websocket = ({ children }: { children: React.ReactElement }) => {
  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_SERVER_URL, {
        path: '/tcnwbsckt',
        transports: ['websocket', 'polling'],
        auth: {
          token: localStorage['rememberMe'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']
        },
        extraHeaders: {
          Authorization: `Bearer ${localStorage['rememberMe'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']}`
        },
        reconnection: false,
        autoConnect: true
      }),
    []
  );

  const { authTokenLogin } = useAuth();
  const { notificationCount } = useSelector((state) => state.websocket);
  const { rememberMe } = useSelector((state) => state.helpersAuth);
  const location = useLocation();
  const { holder } = useSelector((state) => state.auth);
  const { sendNotification } = useNotification();
  const [notificationId, setNotificationId] = useState<number | null>(null);
  const getSocketNotification = useEndpoint<SingleDataDTO<NotificationDTO>, 'get'>({
    method: 'get',
    endpoint: `/notifications/${notificationId}`,
    queryKey: `get-socket-notification-${notificationId}`,
    options: {
      enabled: false,
      onSuccess: async (data) => {
        console.log(data.data.data);
        await sendNotification(getNotificationTitle(data.data.data));
      }
    }
  });

  useEffect(() => {
    if (notificationId != null) {
      getSocketNotification.refetch().then(() => {
        setNotificationId(null);
      });
    }
  }, [notificationId]);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    socket.on('connect', () => {
      console.log('websocket connesso');
      refreshCount = 0;
    });

    socket.on('connect_error', async (err) => {
      console.log('Errore connessione websocketttt: ', err);
      if (refreshCount <= 3) {
        refreshCount++;
        //@ts-ignore
        socket.auth.token = rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken'];
        socket.connect();
      } else {
        socket.disconnect();
      }
    });

    socket.on('disconnect', () => {
      console.log('websocket disconnesso');
    });

    socket.on('notifications', (data: { id: number; env: string; userId: number; holderId: number }) => {
      console.log('socket notifications', data);
      dispatch(
        setRefetchNotifications({
          refetchDrawer: true,
          refetchPage: true
        })
      );
      setNotificationId(data.id);
      dispatch(setNotificationCount(notificationCount + 1));
    });

    //Aggiornamenti sulla building, tipo(nella registrazione, nella pagina account)
    socket.on('resource/building', async (data: SocketResponse) => {
      console.log('resource/building', data);
      sessionStorage.removeItem('canRent');

      if (data.env === 'api') return;

      await authTokenLogin();

      if (holder !== undefined) return;

      dispatch(setRefetchWallets(true));
      dispatch(setRefetchBuilding(true));
    });

    socket.on('resource/order', (data: SocketResponse) => {
      console.log('resource/order', data);
      // if (user?.id === data.userId) return;

      console.log('refeccio orders');
      dispatch(
        setRefetchOrders({
          refetch: true,
          subjects: data.subjects,
          userId: data.userId
        })
      );
    });

    socket.on('appState', (data: SocketResponse) => {
      console.log('appState', data);
      dispatch(setRefetchAppState(true));
    });

    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('disconnect');
      socket.off('notification');
      socket.off('resource/wallet');
      socket.off('resource/building');
      socket.off('resource/document');
      socket.off('resource/order');
      socket.off('resource/shippingAddress');
      socket.off('resource/orderQuote');
      socket.off('resource/orderWallet');
      socket.off('appState');
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, [location]);

  return children;
};
