import { NewOrderStepProps } from '../../../types/formik/new-order/new-order-step-props';
import { Box, Container, Fade, FormHelperText, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { NewOrderFormikStep5 } from '../../../types/formik/new-order/new-order-step-5';
import { useEndpoint } from '../../../hooks/useEndpoint';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import * as Yup from 'yup';
import { useEffect } from 'react';
import OrderReview from '../../../components/custom/Cards/OrderSummary/OrderReview';
import DocumentsToSign from './DocumentsToSign';
import { DocumentUploadMutate } from '../../../types/server/document-upload';
import { useOutletContext } from 'react-router';
// {
//   orderId,
//     orderSummary,
//     setLoadingButton,
//     refetchOrderSummary,
//     setFormikErrors,
//     handleNextStep
// }: NewOrderStepProps

const NewOrderStep5 = () => {
  const {
    orderSummary,
    setNavigateLoading,
    navigateLoading,
    initialRenders,
    refetchOrderSummary,
    orderId,
    setLoadingButton,
    handleNextStep,
    setFormikErrors,
    setInitialRenders
  } = useOutletContext<NewOrderStepProps>();

  const { trackAction } = useNavigationTracker();
  const updateOrderStep5 = useEndpoint<Omit<NewOrderFormikStep5, 'documentsToSign'>, 'patch'>({
    method: 'patch',
    endpoint: `/orders/${orderId}`,
    mutationKey: `/orders/${orderId}/step-5`
  });

  const signDocuments = useEndpoint<DocumentUploadMutate, 'put'>({
    method: 'put',
    endpoint: '/documents/sign',
    mutationKey: `sign-optional-documents`
  });

  const formStep5 = useFormik<Omit<NewOrderFormikStep5, 'lastStep'>>({
    enableReinitialize: true,
    initialValues: {
      documentsToSign: orderSummary?.documents.filter((d) => d.signatureType === 'check' && !d.ignore)
    },
    onSubmit: async (values) => {
      setLoadingButton(true);
      // if (orderSummary?.documents.some((d) => d.signedAt == null && d.signatureType != null && !d.ignore)) {
      //   // const documentsToRefuse: DocumentUploadMutate[] = [];
      //   orderSummary?.documents.forEach((d) => {
      //     if (d.signedAt == null && d.signatureType != null && !d.ignore) {
      //       refuseOptionalDocuments.mutate({
      //         id: d.id ?? undefined,
      //         autoRequestId: d.id != null ? undefined : d.autoRequestId,
      //         reference: d.id != null ? undefined : orderId,
      //         accepted: false
      //       });
      //     }
      //   });
      // }

      await Promise.all(
        values.documentsToSign.map((item) => {
          return signDocuments.mutate({
            id: item.id ?? undefined,
            autoRequestId: item.id != null ? undefined : item.autoRequestId,
            reference: item.id != null ? undefined : orderId,
            accepted: item.accepted
          });
        })
      );

      updateOrderStep5.mutate(
        {
          lastStep: 6
        },
        {
          onSuccess: async () => {
            await refetchOrderSummary();
            setLoadingButton(false);
            handleNextStep(6);
          }
        }
      );

      trackAction({
        type: ClientTypeEnum.Form,
        action: ClientActionEnum.Submit,
        name: `E' andato allo step 6 dell'ordine: ${orderId}`
      });
    },
    validationSchema: Yup.object().shape({
      documentsToSign: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          optional: Yup.boolean(),
          accepted: Yup.boolean().test('accepted', 'documento da accettare', (value, context) => {
            return !(!context.parent.optional && !value);
          })
        })
      )
    })
  });

  useEffect(() => {
    console.log(formStep5.errors);
    setFormikErrors(formStep5.errors);
  }, [formStep5.errors]);

  useEffect(() => {
    // const paths = location.pathname.split('/');
    // const urlStep = paths[paths.length - 1]?.split('-')[1];

    if (orderSummary.lastStep > 5 && !initialRenders.step5 && initialRenders.step4) {
      console.log('navigo allo step 6');
      handleNextStep(6);
      setNavigateLoading(true);
      // navigate(`/orders/new-order/${orderSummary.code}/${textToUrl(orderSummary.title)}/step-6`);

      setInitialRenders({
        ...initialRenders,
        step5: true
      });
    } else if (!initialRenders.step5 && initialRenders.step4) {
      setNavigateLoading(false);
      setInitialRenders({
        ...initialRenders,
        step5: true
      });
    }
  }, [initialRenders]);

  if (navigateLoading) {
    return <></>;
  }

  return orderSummary != null && orderId != null ? (
    <Fade in>
      <Container maxWidth={'lg'}>
        <form noValidate id={'new-order-step-5'} onSubmit={formStep5.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OrderReview
                order={{
                  billingInfo: orderSummary.billingInfo,
                  shippingAddress: orderSummary.shippingAddress,
                  code: orderSummary.code,
                  type: orderSummary.type,
                  createdAt: orderSummary.createdAt,
                  deleted: orderSummary.deleted,
                  building: orderSummary.building,
                  deliveryDateHistory: orderSummary.deliveryDateHistory,
                  deliveryDateState: orderSummary.deliveryDateState,
                  fee: orderSummary.fee,
                  discountCode: orderSummary.discountCode,
                  documents: orderSummary.documents,
                  lastEdit: orderSummary.lastEdit,
                  orderCategory: orderSummary.orderCategory,
                  state: orderSummary.state,
                  title: orderSummary.title,
                  lastStep: orderSummary.lastStep,
                  shippingNotes: orderSummary.shippingNotes,
                  userConfiguration: orderSummary.activeConfiguration
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {/*Documenti da firmare*/}
              <Typography gutterBottom variant={'h5'}>
                Firma e invia la tua proposta di ordine
              </Typography>
              <Typography gutterBottom variant="body2">
                Finalizza la tua proposta di ordine e inviala
              </Typography>

              <Box my={2}>
                {/*<Documents*/}
                {/*  isNewOrderView*/}
                {/*  documents={orderSummary.documents.filter((d) => d.signatureType === 'check' && !d.ignore)}*/}
                {/*  onUpdateSuccess={async (document) => {}}*/}
                {/*  withIcon*/}
                {/*  reference={orderId}*/}
                {/*/>*/}
                {/*{formStep5.errors.checkbox != null && <FormHelperText error>Accetta tutti i documenti richiesti</FormHelperText>}*/}
                <DocumentsToSign
                  documentsToSign={formStep5.values.documentsToSign}
                  setDocumentsToSign={(documentsToSign) => {
                    formStep5.setValues({
                      ...formStep5.values,
                      documentsToSign
                    });
                  }}
                />
                {formStep5.errors.documentsToSign && <FormHelperText error>Accetta tutti i documenti obbligatori</FormHelperText>}
              </Box>

              {/*{orderSummary.documents.some((d) => d.ignore) && (*/}
              {/*  <>*/}
              {/*    <Typography gutterBottom variant="h5">*/}
              {/*      Documenti futuri richiesti dai partner*/}
              {/*    </Typography>*/}
              {/*    <Typography gutterBottom variant="body2">*/}
              {/*      Questi documenti ti verranno richiesti in futuro dai partner*/}
              {/*    </Typography>*/}

              {/*    <Divider sx={{ mb: 3 }} />*/}

              {/*    <Box>*/}
              {/*      <Documents*/}
              {/*        isNewOrderView*/}
              {/*        documents={orderSummary.documents.filter((d) => d.ignore)}*/}
              {/*        withIcon*/}
              {/*        reference={''}*/}
              {/*        onUpdateSuccess={() => {}}*/}
              {/*      />*/}
              {/*    </Box>*/}
              {/*  </>*/}
              {/*)}*/}
            </Grid>
          </Grid>
        </form>
      </Container>
    </Fade>
  ) : (
    <></>
  );
};

export default NewOrderStep5;
