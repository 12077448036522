import { Dialog, DialogContent, DialogTitle, IconButton, Slide, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { createContext, JSX, memo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import useNavigationTracker from '../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../types/dataToSend/clientLog';
import { Property } from 'csstype';

export type OpenModalProps = {
  /**
   * title: Titolo del modal
   * @optional
   */
  title?: string;
  /**
   * titleEllipse: Imposta il titolo come ellipsis, ovvero con una maxWidth
   * esempio: Titolo del Mod...
   */
  titleEllipse?: boolean;
  /**
   * content: Contenuto del modal (Componente React)
   */
  content: JSX.Element;
  /**
   * backgroundColor: Background del modal
   * @optional
   * @default: #ffff
   */
  backgroundColor?: Property.BackgroundColor;
  /**
   * fullHeight: Se true allora imposta l'altezza del modal all 100%, altrimenti prende l'altezza del contento
   * @default: false
   */
  fullHeight?: boolean;
  /**
   * contentFullWidth: Se true allora il contenuto del modal non ha padding e margin
   * @default: false
   */
  contentFullWidth?: boolean;
};

type ModalContextType = {
  openModal: (props: OpenModalProps) => void;
  closeModal: () => void;
};

const DialogBuyplus = styled(Dialog)(({ theme, fullScreen }) => ({
  '.MuiPaper-root': {
    overflow: 'hidden',
    ...(!fullScreen && { maxWidth: { md: 800, xl: 900 } }),
    // height: '100%',
    borderRadius: 0,
    borderTop: `3px solid ${theme.palette.secondary.main}`
  }
}));

const TransitionMobile = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  const { children, ...other } = props;
  return (
    <Slide direction="up" ref={ref} {...other}>
      {children}
    </Slide>
  );
});

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = memo(({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<JSX.Element>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [textEllipse, setTextEllipse] = useState<boolean>();
  const [backgroundColor, setBackgroundColor] = useState<Property.BackgroundColor>();
  const [fullHeight, setFullHeight] = useState(false);
  const [contentFullWidth, setContentFullWidth] = useState(false);
  const { trackAction } = useNavigationTracker();

  const closeModal: ModalContextType['closeModal'] = () => {
    setOpen(false);
  };

  const openModal: ModalContextType['openModal'] = ({ title, content, titleEllipse, backgroundColor, fullHeight, contentFullWidth }) => {
    setTitle(title ?? undefined);
    setTextEllipse(titleEllipse != null ? titleEllipse : undefined);
    setContent(content);
    setBackgroundColor(backgroundColor ?? undefined);
    setFullHeight(fullHeight ?? false);
    setContentFullWidth(contentFullWidth ?? false);
    setOpen(true);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal
      }}
    >
      {children}
      <DialogBuyplus
        open={open}
        {...(isMobile && { fullScreen: true })}
        fullWidth
        onClose={closeModal}
        {...(isMobile && { TransitionComponent: TransitionMobile })}
        sx={{
          '.MuiPaper-root': {
            height: fullHeight || isMobile ? '100%' : 'auto',
            maxHeight: !isMobile ? { md: '650px', xxl: '800px' } : '100%',
            ...(backgroundColor != null && { backgroundColor: backgroundColor })
          }
        }}
      >
        <DialogTitle
          sx={{ flexGrow: 0, height: title ? 70 : 30, padding: 0, display: 'flex', alignItems: 'center' }}
          width={'95%'}
          marginX={'auto'}
          maxWidth={!contentFullWidth ? 600 : undefined}
        >
          {title && (
            <Typography
              fontSize={'24px'}
              fontWeight={500}
              sx={{
                ...(textEllipse != null && textEllipse
                  ? {
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: '100%'
                    }
                  : {})
              }}
            >
              {title}
            </Typography>
          )}
          <IconButton
            data-cy={'close_modal'}
            onClick={() => {
              closeModal();
              trackAction({
                type: ClientTypeEnum.Button,
                action: ClientActionEnum.Clicked,
                name: 'Chiuso il modal'
              });
            }}
            sx={{ position: 'absolute', right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            flexGrow: 1,
            height: 'calc(100% - 70px)',
            position: 'relative',
            padding: 0,
            '&::-webkit-scrollbar': { width: 0 },
            ...(!contentFullWidth
              ? {
                  width: '95%',
                  marginX: 'auto',
                  maxWidth: 600,
                  paddingY: '24px!important',
                  paddingX: '3px'
                }
              : {})
          }}
        >
          {content}
        </DialogContent>
      </DialogBuyplus>
    </ModalContext.Provider>
  );
});
