import { GuardProps } from 'types/auth';
import { useSelector } from 'store';
import HolderChoice from '../../pages/auth/holder-choice';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, holder } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn]);

  if (isLoggedIn && user === null && holder !== undefined && !pathname.includes('test')) {
    return <HolderChoice />;
  }

  return isLoggedIn && (user !== null || holder !== undefined) ? children : <></>;
};

export default AuthGuard;
