import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainCard from '../../components/custom/Cards/MainCard';
import { FaqDto } from '../../types/dto/faq.dto';
import FaqList from '../../components/custom/Cards/Faq/FaqList';
// import SuggestionList from '../../components/custom/SuggestionList';
type BoxLearningProps = {
  faqs: FaqDto[];
};
const BoxLearning = (props: BoxLearningProps) => {
  const { faqs } = props;
  return (
    <MainCard
      title={
        <Typography variant={'body1'} fontWeight={600} textTransform={'uppercase'}>
          Box learning
        </Typography>
      }
    >
      <Box>
        <List disablePadding>
          <ListItem disablePadding divider>
            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon color={'primary'} />}>
                <Typography variant={'h6'}>Manuale utente</Typography>
              </AccordionSummary>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          </ListItem>
          <ListItem disablePadding>
            <Accordion sx={{ width: '100%' }} defaultExpanded={true}>
              <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon color={'primary'} />}>
                <Typography variant={'h6'}>Faq</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <FaqList faqs={faqs} />
              </AccordionDetails>
            </Accordion>
          </ListItem>
          {/*<ListItem disablePadding>*/}
          {/*  <Accordion sx={{ width: '100%' }} defaultExpanded={true}>*/}
          {/*    <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon color={'primary'} />}>*/}
          {/*      <Typography variant={'h6'}>Altre risorse</Typography>*/}
          {/*    </AccordionSummary>*/}
          {/*    <AccordionDetails sx={{ padding: 0 }}>*/}
          {/*      <SuggestionList />*/}
          {/*    </AccordionDetails>*/}
          {/*  </Accordion>*/}
          {/*</ListItem>*/}
        </List>
      </Box>
    </MainCard>
  );
};

export default BoxLearning;
