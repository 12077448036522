// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';
import AddIcon from '@mui/icons-material/Add';
// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const newOrder: NavItemType = {
  id: 'new-order',
  title: '',
  type: 'group',
  children: [
    {
      id: 'new-order-button',
      title: <FormattedMessage id="new-order" />,
      type: 'button',
      url: '/orders/new-order',
      icon: [AddIcon, undefined]
    }
  ]
};

export default newOrder;
