// types
import { createSlice } from '@reduxjs/toolkit';
import { AuthActionProps, AuthProps, PawnType } from 'types/auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  holder: undefined,
  pawn: undefined
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInizialized: (state) => {
      state.isInitialized = true;
    },
    setLogin: (state, action: AuthActionProps) => {
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = action.payload.user;
      state.holder = action.payload.holder;
      if (action.payload.user !== null && action.payload.holder != null) {
        state.pawn = {
          id: action.payload.user.id,
          firstname: action.payload.user.firstname,
          lastname: action.payload.user.lastname,
          email: action.payload.user.email,
          building: {
            name: action.payload.user.building.name,
            businessName: action.payload.user.building.businessName
          }
        };
      }
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.holder = undefined;
      state.pawn = undefined;
    },
    setPawn: (state, action: { payload: PawnType | undefined }) => {
      state.pawn = action.payload;

      sessionStorage.removeItem('canRent');
      if (action.payload !== undefined) {
        localStorage.setItem('pawnId', action.payload.id.toString());
      } else {
        localStorage.removeItem('pawnId');
        state.user = null;
      }
    }
  }
});

export default auth.reducer;
export const { setInizialized, setLogin, setLogout, setPawn } = auth.actions;
