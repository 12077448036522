// third-party
import SupportIcon from 'components/custom/BuyPlusIcons/SupportIcon';
import SupportNegativeIcon from 'components/custom/BuyPlusIcons/SupportNegativeIcon';
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support: NavItemType = {
  id: 'support-group',
  title: '',
  type: 'group',
  children: [
    {
      id: 'support',
      title: <FormattedMessage id="support" />,
      type: 'collapse',
      icon: [SupportIcon, SupportNegativeIcon],
      children: [
        // {
        //   id: 'help-center',
        //   title: <FormattedMessage id={'help-center'} />,
        //   type: 'item',
        //   url: '/support/help-center'
        // },
        // {
        //   id: 'ticket',
        //   title: <FormattedMessage id="ticket" />,
        //   type: 'item',
        //   url: '/support/ticket'
        // },
        // {
        //   id: 'orders',
        //   title: <FormattedMessage id="orders" />,
        //   type: 'item',
        //   url: '/support/orders'
        // },
        {
          id: 'faqs',
          title: <FormattedMessage id="faqs" />,
          type: 'item',
          url: '/support/faqs'
        }
      ]
    }
  ]
};

export default support;
