import { Close } from '@mui/icons-material';
import { alpha, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import { OrderQuote } from '../../types/dto/orders.dto';
import { useEffect, useState } from 'react';
import { useEndpoint } from '../../hooks/useEndpoint';
import { dispatch } from '../../store';
import { openSnackbar } from '../../store/reducers/snackbar';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useOpenFileModal } from './EmbedFile';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import UploadIcon from './BuyPlusIcons/UploadIcon';

type BoxUploadedFileProps = {
  files: OrderQuote[];
  orderId: string;
  refetch: () => Promise<any>;
};

const ListUploadedFile = ({ files, orderId, refetch }: BoxUploadedFileProps) => {
  const theme = useTheme();
  const openFileModal = useOpenFileModal();
  const { trackAction } = useNavigationTracker();
  const [deleteFileId, setDeleteFileId] = useState<number>();
  const deleteQuoteFile = useEndpoint<undefined, 'delete'>({
    method: 'delete',
    endpoint: `orders/${orderId}/quotes/${deleteFileId}`,
    mutationKey: 'delete-quotes',
    options: {
      onSuccess: async () => {
        await refetch();

        dispatch(
          openSnackbar({
            variant: 'success',
            message: 'Preventivo eliminato con successo!'
          })
        );
        setDeleteFileId(undefined);
      }
    }
  });

  useEffect(() => {
    if (deleteFileId) {
      trackAction({
        type: ClientTypeEnum.File,
        name: `Ha rimosso un preventivo`,
        action: ClientActionEnum.Removed
      });
      deleteQuoteFile.mutate(undefined);
    }
  }, [deleteFileId]);

  return (
    <Box>
      <List disablePadding data-cy={'lista_preventivi'}>
        {files.length > 0 &&
          files.map((file, i) => (
            <ListItem divider sx={{ my: 2 }} key={i}>
              <ListItemIcon>
                <UploadIcon sx={{ fontSize: '40px' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.9rem',
                      md: '1rem',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: 550
                    }
                  }}
                  fontWeight={600}
                  gutterBottom
                >
                  {file.upload.metadata.filename}
                </Typography>
                <Typography fontSize={'.8rem'} fontWeight={400} sx={{ color: alpha(theme.palette.text.secondary, 0.6) }} gutterBottom>
                  {(file.upload.metadata.size / 1024).toFixed(2)}Kb • Completato
                </Typography>
              </ListItemText>
              <IconButton
                disabled={
                  deleteQuoteFile.isLoading ||
                  file.upload.metadata.mimetype === 'image/svg+xml' ||
                  (file.upload.metadata.mimetype.includes('application') && file.upload.metadata.mimetype !== 'application/pdf')
                }
                onClick={() =>
                  openFileModal({
                    title: file.upload.metadata.filename,
                    titleEllipse: true,
                    fileCode: file.upload.code
                  })
                }
                size={'small'}
              >
                <RemoveRedEyeIcon fontSize={'medium'} />
              </IconButton>
              <IconButton
                disabled={deleteQuoteFile.isLoading && file.id === deleteFileId}
                onClick={() => {
                  setDeleteFileId(file.id);
                }}
                size="small"
              >
                <Close fontSize="medium" />
              </IconButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default ListUploadedFile;
