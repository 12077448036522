//@ts-nocheck
import { useEndpoint } from '../../hooks/useEndpoint';
import { SingleDataDTO } from '../../types/server/single-data';
import { FullBuildingDTO } from '../../types/dataToSend/building';
import { useAddBuildingSalesModal } from '../../components/custom/Test/AddBuildingSalesModal';
import { useState } from 'react';
import { getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from '../../store';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AsyncSelect from '../../components/custom/AsyncSelect';
import TooltipInputInfo from '../../components/custom/TooltipInputInfo';
import { NumericFormat, numericFormatter, removeNumericFormat } from 'react-number-format';
import LoadingButton from '../../components/custom/LoadingButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditBuildingSalesModal from '../../components/custom/Test/EditBuildingSalesModal';
import { Delete, Edit } from '@mui/icons-material';
import DeleteBuildingSalesModal from '../../components/custom/Test/DeleteBuilldingSalesModal';
import useModal from '../../hooks/useModal';

const insertMonth = (i: number) => {
  switch (i) {
    case 0:
      return 'Gen';
    case 1:
      return 'Feb';
    case 2:
      return 'Mar';
    case 3:
      return 'Apr';
    case 4:
      return 'Mag';
    case 5:
      return 'Giu';
    case 6:
      return 'Lug';
    case 7:
      return 'Ago';
    case 8:
      return 'Set';
    case 9:
      return 'Ott';
    case 10:
      return 'Nov';
    default:
      return 'Dic';
  }
};

const currencyFormatter = (value: any) => {
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ','
  };
  const options = { ...defaultOptions };
  let copy = Number(value);
  let newVal = copy.toFixed(options.significantDigits);
  let stringVal = newVal.toString();

  const [currency, decimal] = stringVal.split('.');
  return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator)}${options.decimalSeparator}${decimal}`;
};

const GenerateTestWalletTab = () => {
  const { holder } = useSelector((state) => state.auth);
  const { openModal } = useModal();
  const theme = useTheme();

  const getBuildingData = useEndpoint<SingleDataDTO<FullBuildingDTO>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${holder?.holderBuilding?.id}`,
    queryKey: 'get-building-test',
    queryParams: {
      mode: 'test'
    },
    options: {
      enabled: holder?.holderBuilding?.id != null
    }
  });
  const building = getBuildingData.data?.data.data;
  const updateBuilding = useEndpoint<any, 'patch'>({
    method: 'patch',
    endpoint: `/users/${holder?.id}/entry`,
    mutationKey: 'update-test-building',
    queryParams: {
      mode: 'test'
    },
    options: {
      onSuccess: async () => {
        await getBuildingData.refetch();
      }
    }
  });
  const updateCreditSafeData = useEndpoint<any, 'post'>({
    method: 'post',
    endpoint: `buildings/${holder?.holderBuilding?.id}/risk-assessments`,
    queryParams: {
      mode: 'test'
    },
    mutationKey: 'update-credit-safe',
    options: {
      onSuccess: () => {
        getBuildingData.refetch();
      }
    }
  });

  const getChangeCapitalData = useEndpoint<
    {
      result?: {
        cc_rank_04: {
          value: number;
        };
        cc_rank_05: {
          value: number;
        };
      };
    },
    'get'
  >({
    method: 'get',
    endpoint: `buildings/${holder?.holderBuilding?.id}/test-fakers/change-capital`,
    queryParams: {
      mode: 'test'
    },
    queryKey: 'get-change-capital',
    options: {
      enabled: holder?.holderBuilding?.id != null
    }
  });
  const updateChangeCapitalData = useEndpoint<any, 'put'>({
    method: 'put',
    endpoint: `buildings/${holder?.holderBuilding?.id}/test-fakers/change-capital`,
    queryParams: {
      mode: 'test'
    },
    mutationKey: 'update-change-capital',
    options: {
      onSettled: async () => {
        await getChangeCapitalData.refetch();
      }
    }
  });

  const openModalAddBuildingSales = useAddBuildingSalesModal({
    refetchBuildingSales: async () => {
      await getBuildingData.refetch();
    }
  });

  //Building Data Form
  const [isChannelManagerCustom, setIsChannelManagerCustom] = useState(building?.channelManagerCustom != null);
  const [isPmsCustom, setIsPmsCustom] = useState(building?.pmsCustom != null);
  const buildingDataForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessName: building?.businessName ?? '',
      channelManagerCustom: building?.channelManagerCustom ?? undefined,
      channelManager: building?.channelManager ?? undefined,
      pmsCustom: building?.pmsCustom ?? undefined,
      pms: building?.pms ?? undefined,
      roomsNum: building?.roomsNum ?? undefined,
      bookingRating: building?.bookingRating ?? undefined,
      destinationType: building?.destinationType ?? undefined,
      priceListType: building?.priceListType ?? '',
      knownLocation: building?.knownLocation != null ? building.knownLocation : false
    },
    onSubmit: (values, { setSubmitting }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { pms, channelManager, destinationType, ...other } = values;

      updateBuilding.mutate(
        {
          building: {
            ...other,
            pmsId: pms != null && pms.id !== -1 ? pms.id : undefined,
            channelManagerId: channelManager != null && channelManager.id !== +-1 ? channelManager.id : undefined,
            destinationTypeKey: destinationType!.key,
            lastEdit: building!.lastEdit
          }
        },
        {
          onSettled: () => {
            setSubmitting(false);
          }
        }
      );
    },
    validationSchema: Yup.object().shape({
      businessName: Yup.string().max(255).required('companyName-error-required'),
      channelManager: Yup.object().test('channelManagerCustom', 'error-channel-manager-required', function (value) {
        const channelManagerCustomValue = this.resolve(Yup.ref('channelManagerCustom'));
        return !(channelManagerCustomValue === undefined && !value);
      }),
      channelManagerCustom: Yup.string().test('channelManager', 'error-channel-manager-required', function (value) {
        const channelManagerValue = this.resolve(Yup.ref('channelManager')) as { id: number; name: string };
        return !((channelManagerValue === undefined || channelManagerValue.id === -1) && !value);
      }),
      pms: Yup.object().test('pmsCustom', 'error-pms-required', function (value) {
        const pmsCustomValue = this.resolve(Yup.ref('pmsCustom'));
        return !(pmsCustomValue === undefined && !value);
      }),
      pmsCustom: Yup.string().test('pms', 'error-pms-required', function (value) {
        const pmsValue = this.resolve(Yup.ref('pms')) as { id: number; name: string };
        return !((pmsValue === undefined || pmsValue.id === -1) && !value);
      }),
      roomsNum: Yup.number()
        .integer('error-number-rooms-integer')
        .min(1, 'error-number-rooms-min')
        .max(1000, 'error-number-rooms-max')
        .required('error-number-rooms-required'),
      bookingRating: Yup.number().min(0, 'error-rating-min').max(10, 'error-rating-max').required('error-rating-required'),
      destinationType: Yup.object().required('error-destination-type-required'),
      priceListType: Yup.string().required('error-list')
    })
  });

  //Credit Safe Form
  const creditSafeDataForm = useFormik<{
    creditLimit?: number;
    score?: number;
    operatingProfit?: number;
  }>({
    enableReinitialize: true,
    initialValues: {
      creditLimit: building?.lastRiskAssessment?.rawData.report.report.creditScore.currentCreditRating.creditLimit.value ?? undefined,
      score: building?.lastRiskAssessment?.score ?? undefined,
      operatingProfit: building?.lastRiskAssessment?.operatingProfit ?? undefined
    },
    onSubmit: (values, { setSubmitting }) => {
      updateCreditSafeData.mutate(
        {
          ...values
        },
        {
          onSettled: () => {
            setSubmitting(false);
          }
        }
      );
    },
    validationSchema: Yup.object().shape({
      creditLimit: Yup.number().required('Inserisci il credit limit'),
      score: Yup.number().required('Insersisci lo score'),
      operatingProfit: Yup.number().required("Insersci l'operating profit")
    })
  });

  //Change Capital Form
  const changeCapitalForm = useFormik<{
    investmentsRank?: number;
    liquidityRank?: number;
  }>({
    enableReinitialize: true,
    initialValues: {
      investmentsRank: getChangeCapitalData.data?.data?.result?.cc_rank_04.value ?? undefined,
      liquidityRank: getChangeCapitalData.data?.data?.result?.cc_rank_05.value ?? undefined
    },
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      updateChangeCapitalData.mutate(
        { ...values },
        {
          onSettled: () => setSubmitting(false)
        }
      );
    },
    validationSchema: Yup.object().shape({
      investmentsRank: Yup.number().required("Inserisci l'investiments rank"),
      liquidityRank: Yup.number().required('Inserisci il liquidity rank')
    })
  });

  if (getBuildingData.isLoading || getChangeCapitalData.isLoading) {
    return <></>;
  }

  return (
    <Box>
      {/*Form Building*/}
      <form noValidate onSubmit={buildingDataForm.handleSubmit}>
        <Grid container mb={3} rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h5'} gutterBottom>
              Dati struttura
            </Typography>

            <Grid container width={'100%'} p={0} mt={1} spacing={3}>
              {/* Business Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label={<FormattedMessage id="companyName-label" />}
                  variant="outlined"
                  id="comapany-name-register"
                  type="text"
                  name="businessName"
                  value={buildingDataForm.values.businessName}
                  onChange={buildingDataForm.handleChange}
                  onBlur={buildingDataForm.handleBlur}
                  fullWidth
                  error={Boolean(buildingDataForm.touched.businessName && buildingDataForm.errors.businessName)}
                />
                {buildingDataForm.touched.businessName && buildingDataForm.errors.businessName && (
                  <FormHelperText error>
                    <FormattedMessage id={buildingDataForm.errors.businessName} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Channel manager */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="channel-manager-select-label">
                    <FormattedMessage id="channelManager-label" />
                  </InputLabel>
                  <AsyncSelect<{ id: number; name: string }>
                    defaultValue=""
                    value={buildingDataForm.values.channelManager != null ? JSON.stringify(buildingDataForm.values.channelManager) : ''}
                    setIsCustom={setIsChannelManagerCustom}
                    labelId="channel-manager-select-label"
                    label={<FormattedMessage id="channelManager-label" />}
                    name="channelManager"
                    variant="outlined"
                    fullWidth={true}
                    endpoint="/channel-managers"
                    renderMenuItem={(value) => ({ value: value, label: value.name })}
                    onChange={(e) => {
                      if (e.target.value !== 'other') {
                        buildingDataForm.setValues({
                          ...buildingDataForm.values,
                          channelManager: JSON.parse(e.target.value as string),
                          channelManagerCustom: undefined
                        });
                      } else {
                        buildingDataForm.setValues({
                          ...buildingDataForm.values,
                          channelManager: {
                            id: -1,
                            name: 'other'
                          },
                          channelManagerCustom: ''
                        });
                      }
                    }}
                    error={Boolean(buildingDataForm.errors.channelManager && buildingDataForm.touched.channelManager)}
                  />
                </FormControl>
                {buildingDataForm.touched.channelManager && buildingDataForm.errors.channelManager && (
                  <FormHelperText error id="standard-weight-helper-text-channelManager-register">
                    <FormattedMessage id={buildingDataForm.errors.channelManager} />
                  </FormHelperText>
                )}
                <TooltipInputInfo
                  title={'Maggiori informazioni'}
                  description="Inserisci il channel manager che utilizzi, se non è presente nella lista seleziona 'altro...' per poterlo specificare manualmente."
                />
              </Grid>

              {isChannelManagerCustom && (
                <Grid item xs={12} md={6}>
                  <TextField
                    placeholder="Inserisci il tuo Channel Manager"
                    fullWidth
                    variant="outlined"
                    value={buildingDataForm.values.channelManagerCustom ?? ''}
                    onBlur={(e) => {
                      buildingDataForm.handleBlur(e);
                    }}
                    onChange={(e) => buildingDataForm.setFieldValue('channelManagerCustom', e.target.value)}
                    label={'Channel Manager personalizzato'}
                    error={Boolean(buildingDataForm.errors.channelManagerCustom && buildingDataForm.touched.channelManagerCustom)}
                    name="channelManagerCustom"
                  />
                  {buildingDataForm.touched.channelManagerCustom && buildingDataForm.errors.channelManagerCustom && (
                    <FormHelperText error id="standard-weight-helper-text-channelManagerCustom-register">
                      <FormattedMessage id={buildingDataForm.errors.channelManagerCustom} />
                    </FormHelperText>
                  )}
                </Grid>
              )}

              {/* PMS */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="pms-select-label">
                    <FormattedMessage id="pms-label" />
                  </InputLabel>
                  <AsyncSelect<{ id: number; name: string }>
                    value={buildingDataForm.values.pms ? JSON.stringify(buildingDataForm.values.pms) : ''}
                    defaultValue={''}
                    setIsCustom={setIsPmsCustom}
                    labelId="pms-select-label"
                    label={<FormattedMessage id="pms-label" />}
                    name="pms"
                    variant="outlined"
                    fullWidth={true}
                    endpoint="/pms"
                    renderMenuItem={(value) => ({ value: value, label: value.name })}
                    onChange={(e) => {
                      if (e.target.value !== 'other') {
                        buildingDataForm.setValues({
                          ...buildingDataForm.values,
                          pms: JSON.parse(e.target.value as string),
                          pmsCustom: undefined
                        });
                      } else {
                        buildingDataForm.setValues({
                          ...buildingDataForm.values,
                          pms: {
                            id: -1,
                            name: 'other',
                            supported: false
                          },
                          pmsCustom: ''
                        });
                      }
                    }}
                    error={Boolean(buildingDataForm.errors.pms && buildingDataForm.touched.pms)}
                  />
                </FormControl>
                {buildingDataForm.touched.pms && buildingDataForm.errors.pms && (
                  <FormHelperText error id="standard-weight-helper-text-pms-register">
                    <FormattedMessage id={buildingDataForm.errors.pms} />
                  </FormHelperText>
                )}
                <TooltipInputInfo
                  title={'Maggiori informazioni'}
                  description="Inserisci il PMS che utilizzi, se non è presente nella lista seleziona 'altro...' per poterlo inserire manualmente."
                />
              </Grid>

              {isPmsCustom && (
                <Grid item xs={12} md={6}>
                  <TextField
                    placeholder="Inserisci il tuo PMS"
                    fullWidth
                    variant="outlined"
                    value={buildingDataForm.values.pmsCustom ?? ''}
                    onBlur={(e) => {
                      buildingDataForm.handleBlur(e);
                    }}
                    onChange={(e) => buildingDataForm.setFieldValue('pmsCustom', e.target.value)}
                    label={'PMS Personalizzato'}
                    error={Boolean(buildingDataForm.errors.pmsCustom && buildingDataForm.touched.pmsCustom)}
                    name="pmsCustom"
                  />
                  {buildingDataForm.touched.pmsCustom && buildingDataForm.errors.pmsCustom && (
                    <FormHelperText error id="standard-weight-helper-text-pmsCustom-register">
                      <FormattedMessage id={buildingDataForm.errors.pmsCustom} />
                    </FormHelperText>
                  )}
                </Grid>
              )}

              {/* Number Rooms */}
              <Grid item xs={6}>
                <NumericFormat
                  label={<FormattedMessage id="number-rooms-label" />}
                  value={buildingDataForm.values.roomsNum ?? ''}
                  onBlur={(e) => {
                    buildingDataForm.handleBlur(e);
                  }}
                  customInput={TextField}
                  onChange={(e) =>
                    buildingDataForm.setFieldValue(
                      'roomsNum',
                      e.target.value ? parseInt(e.target.value.replace('.', '').replace(',', '')) : ''
                    )
                  }
                  name="roomsNum"
                  fullWidth
                  variant="outlined"
                  allowNegative={false}
                  error={Boolean(buildingDataForm.touched.roomsNum && buildingDataForm.errors.roomsNum)}
                />
                {buildingDataForm.touched.roomsNum && buildingDataForm.errors.roomsNum && (
                  <FormHelperText error id="standard-weight-helper-text-roomsNum-register">
                    <FormattedMessage id={buildingDataForm.errors.roomsNum} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Rating booking  */}
              <Grid item xs={6}>
                <NumericFormat
                  customInput={TextField}
                  onBlur={(e) => {
                    buildingDataForm.handleBlur(e);
                  }}
                  label={<FormattedMessage id="rating-booking-label" />}
                  variant="outlined"
                  id="rating-booking-register"
                  name="bookingRating"
                  type="text"
                  value={buildingDataForm.values.bookingRating ?? ''}
                  onChange={(e) =>
                    buildingDataForm.setFieldValue('bookingRating', e.target.value ? parseFloat(e.target.value.replace(',', '.')) : '')
                  }
                  fullWidth
                  error={Boolean(buildingDataForm.touched.bookingRating && buildingDataForm.errors.bookingRating)}
                  allowNegative={false}
                  decimalSeparator=","
                />

                {buildingDataForm.touched.bookingRating && buildingDataForm.errors.bookingRating && (
                  <FormHelperText error id="standard-weight-helper-text-bookingRating-register">
                    <FormattedMessage id={buildingDataForm.errors.bookingRating} />
                  </FormHelperText>
                )}
                {/* <FormHelperText>Inserisci la valutazione booking </FormHelperText> */}
                <TooltipInputInfo
                  title={'Maggiori informazioni'}
                  description="Inserisci la valutazione booking della tua struttura. La valutazione ha un range da 0 a 10."
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="destination-type-label">
                    <FormattedMessage id="destination-type-label" />
                  </InputLabel>
                  <AsyncSelect<{ key: string; name: string }>
                    defaultValue=""
                    value={buildingDataForm.values.destinationType != null ? JSON.stringify(buildingDataForm.values.destinationType) : ''}
                    labelId="destination-type-label"
                    label={<FormattedMessage id="destination-type-label" />}
                    name="destinationType"
                    variant="outlined"
                    onBlur={buildingDataForm.handleBlur}
                    fullWidth={true}
                    endpoint="/destination-types"
                    renderMenuItem={(value) => ({ value: value, label: value.name })}
                    onChange={(e) => {
                      buildingDataForm.setValues({
                        ...buildingDataForm.values,
                        destinationType: JSON.parse(e.target.value as string)
                      });
                    }}
                    error={getIn(buildingDataForm.errors, 'destinationType') && getIn(buildingDataForm.touched, 'destinationType')}
                  />
                </FormControl>
                {getIn(buildingDataForm.errors, 'destinationType') && getIn(buildingDataForm.touched, 'destinationType') && (
                  <FormHelperText error>
                    <FormattedMessage id={buildingDataForm.errors.destinationType} />
                  </FormHelperText>
                )}
              </Grid>

              {/* Listino */}
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} size="medium">
                  <InputLabel id="list-label">
                    <FormattedMessage id="list-label" />
                  </InputLabel>
                  <Select
                    onBlur={buildingDataForm.handleBlur}
                    label={<FormattedMessage id="list-label" />}
                    labelId="list-label"
                    variant="outlined"
                    id="list-register"
                    type="text"
                    name="priceListType"
                    value={buildingDataForm.values.priceListType}
                    onChange={(e) => {
                      buildingDataForm.handleChange(e);
                    }}
                    fullWidth
                    error={Boolean(buildingDataForm.touched.priceListType && buildingDataForm.errors.priceListType)}
                  >
                    <MenuItem value={'fit_with_allotment'}>Fit con allotment</MenuItem>
                    <MenuItem value={'fit_without_allotment'}>Fit senza allotment</MenuItem>
                    <MenuItem value={'bar'}>Bar</MenuItem>
                  </Select>
                </FormControl>
                {buildingDataForm.touched.priceListType && buildingDataForm.errors.priceListType && (
                  <FormHelperText error id="standard-weight-helper-text-priceListType-register">
                    <FormattedMessage id={buildingDataForm.errors.priceListType} />
                  </FormHelperText>
                )}
                <TooltipInputInfo
                  title={'Maggiori informazioni'}
                  description="Inserisci il listino prezzi utilizzato dalla tua struttura"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={buildingDataForm.values.knownLocation}
                      value={buildingDataForm.values.knownLocation}
                      onChange={(event, checked) => buildingDataForm.setFieldValue('knownLocation', checked)}
                    />
                  }
                  label="Località conosciuta"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} textAlign={'end'} px={3}>
            <LoadingButton
              disabled={Object.keys(buildingDataForm.errors).length !== 0}
              loading={buildingDataForm.isSubmitting}
              type={'submit'}
              variant={'contained'}
              size={'medium'}
            >
              Salva Dati struttura
            </LoadingButton>
          </Grid>
        </Grid>
      </form>

      {/*Building Sales*/}
      <Grid container mb={3} rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant={'h5'} gutterBottom>
            Dati di vendita
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button variant={'outlined'} size={'medium'} onClick={() => openModalAddBuildingSales()}>
            Aggiungi dati di vendita
          </Button>
        </Grid>

        {building?.buildingSales &&
          building.buildingSales.length > 0 &&
          building.buildingSales.map((sales, i) => (
            <Grid item xs={12} key={`sales-data-${i}`}>
              <Accordion sx={{ width: '100%' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: 'action.hover' }}>
                  <Typography variant="h6">
                    {sales.indirectChannelName ? sales.indirectChannelName : sales.isIndirect ? 'Vendite Indirette' : 'Vendite dirette'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ pl: 1, border: 'none', py: 1 }}>
                    <Box>
                      <IconButton
                        onClick={() => {
                          openModal({
                            title: 'Modifica dati di vendita',
                            content: (
                              <EditBuildingSalesModal
                                refetchBuildingSales={async () => {
                                  await getBuildingData.refetch();
                                }}
                                buildingSale={sales}
                              />
                            )
                          });
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          openModal({
                            title: 'Elimina dati di vendita',
                            content: (
                              <DeleteBuildingSalesModal
                                refetchBuildingSales={async () => {
                                  await getBuildingData.refetch();
                                }}
                                buildingSaleId={sales.id}
                              />
                            )
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>

                  <TableContainer sx={{ width: '100%' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            sx={{
                              pl: 1,
                              borderLeft: `none`,
                              borderTop: `1px solid ${theme.palette.divider}`,
                              color: 'text.secondary'
                            }}
                          >
                            <Typography>
                              Mensile
                              <br />
                              <Typography
                                sx={
                                  sales.interval === 'monthly'
                                    ? {
                                        color: 'success.dark',
                                        fontWeight: 700
                                      }
                                    : {
                                        color: 'error.main',
                                        fontWeight: 700
                                      }
                                }
                                component="span"
                              >
                                {sales.interval === 'monthly' ? 'Inseriti' : 'Assenti'}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell
                            colSpan={3}
                            sx={{
                              pl: 1,
                              borderLeft: `1px solid ${theme.palette.divider}`,
                              borderTop: `1px solid ${theme.palette.divider}`,
                              color: 'text.secondary'
                            }}
                          >
                            <Typography>
                              Trimestrale <br />
                              <Typography
                                sx={
                                  sales.interval === 'quarterly' || sales.interval === 'monthly'
                                    ? {
                                        fontWeight: 700,
                                        color: 'success.dark'
                                      }
                                    : {
                                        fontWeight: 700,
                                        color: 'error.main'
                                      }
                                }
                                component="span"
                              >
                                {' '}
                                {sales.interval === 'quarterly' ? 'Inseriti' : sales.interval === 'monthly' ? 'Ricavati' : 'Assenti'}
                              </Typography>
                            </Typography>
                          </TableCell>

                          <TableCell
                            colSpan={3}
                            sx={{
                              pl: 1,
                              borderLeft: `1px solid ${theme.palette.divider}`,
                              borderTop: `1px solid ${theme.palette.divider}`,
                              color: 'text.secondary'
                            }}
                          >
                            <Typography>
                              Annuale {sales.year}
                              <br />
                              <Typography
                                sx={{
                                  color: 'success.dark',
                                  fontWeight: 700
                                }}
                                component="span"
                              >
                                {' '}
                                {sales.interval === 'annual'
                                  ? 'Inseriti'
                                  : sales.interval === 'monthly' || sales.interval === 'quarterly'
                                  ? 'Ricavati'
                                  : 'Assenti'}
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          {sales.interval === 'monthly' ? (
                            <>
                              <TableCell sx={{ px: 0.1, textAlign: 'left', pl: 1, borderLeft: `none` }}>Mese</TableCell>
                              <TableCell sx={{ px: 0.1, textAlign: 'center' }}>Vendite</TableCell>
                              <TableCell sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `1px solid ${theme.palette.divider}` }}>
                                Occ.
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell sx={{ border: 'none' }} />
                              <TableCell sx={{ border: 'none' }} />
                              <TableCell sx={{ border: 'none' }} />
                            </>
                          )}
                          {sales.interval === 'quarterly' || sales.interval === 'monthly' ? (
                            <>
                              <TableCell sx={{ px: 0.1, textAlign: 'left', pl: 2.5, borderLeft: `1px solid ${theme.palette.divider}` }}>
                                <span>&#188;</span>
                              </TableCell>
                              <TableCell sx={{ px: 0.1, textAlign: 'center' }}>Vendite</TableCell>
                              <TableCell sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `1px solid ${theme.palette.divider}` }}>
                                Occ.
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell sx={{ border: 'none' }} />
                              <TableCell sx={{ border: 'none' }} />
                              <TableCell sx={{ border: 'none' }} />
                            </>
                          )}
                          {sales.interval === 'annual' || sales.interval === 'quarterly' || sales.interval === 'monthly' ? (
                            <>
                              <TableCell colSpan={2} sx={{ px: 1, textAlign: 'left', borderLeft: `1px solid ${theme.palette.divider}` }}>
                                Vendite
                              </TableCell>
                              <TableCell sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `none` }}>Occ.</TableCell>
                            </>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sales.periods.map((period, i) => (
                          <TableRow key={`table-row-${i}`}>
                            {sales.interval === 'monthly' && (
                              <>
                                <TableCell sx={{ px: 0.1, textAlign: 'left', pl: 1, borderLeft: `none` }}>{insertMonth(i)}</TableCell>
                                <TableCell sx={{ px: 0.1, textAlign: 'right' }}>{`${currencyFormatter(period.amount)}€`}</TableCell>
                                <TableCell sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `1px solid ${theme.palette.divider}` }}>
                                  {period.occupancy}
                                </TableCell>
                                {i < 4 && (
                                  <>
                                    <TableCell
                                      sx={{
                                        px: 0.1,
                                        textAlign: 'left',
                                        pl: 1,
                                        width: '5%',
                                        borderLeft: `1px solid ${theme.palette.divider}`
                                      }}
                                    >
                                      {i + 1}
                                    </TableCell>
                                    <TableCell sx={{ px: 0.1, textAlign: 'right' }}>{`${currencyFormatter(
                                      sales.$processed![0].periods[i].amount
                                    )}€`}</TableCell>
                                    <TableCell
                                      sx={{
                                        px: 0.1,
                                        textAlign: 'right',
                                        borderRight: `1px solid ${theme.palette.divider}`,
                                        paddingRight: 1
                                      }}
                                    >
                                      {sales.$processed![0].periods[i].occupancy}
                                    </TableCell>
                                  </>
                                )}
                                {i < 1 && (
                                  <>
                                    <TableCell
                                      colSpan={2}
                                      sx={{ textAlign: 'left', borderLeft: `1px solid ${theme.palette.divider}` }}
                                    >{`${currencyFormatter(sales.$processed![1].periods[i].amount)}€`}</TableCell>

                                    <TableCell
                                      sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `1px solid ${theme.palette.divider}` }}
                                    >
                                      {sales.$processed![1].periods[i].occupancy}
                                    </TableCell>
                                  </>
                                )}
                              </>
                            )}
                            {sales.interval === 'quarterly' && (
                              <>
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />

                                {i < 4 && (
                                  <>
                                    <TableCell sx={{ px: 0.1, textAlign: 'left', pl: 3, borderLeft: `1px solid ${theme.palette.divider}` }}>
                                      {i + 1}
                                    </TableCell>
                                    <TableCell sx={{ px: 0.1, textAlign: 'right' }}>{`${currencyFormatter(period.amount)}€`}</TableCell>
                                    <TableCell
                                      sx={{
                                        px: 0.1,
                                        textAlign: 'right',
                                        borderRight: `1px solid ${theme.palette.divider}`,
                                        paddingRight: 1
                                      }}
                                    >
                                      {period.occupancy}
                                    </TableCell>
                                  </>
                                )}
                                {i < 1 && (
                                  <>
                                    <TableCell
                                      colSpan={2}
                                      sx={{ px: 0.1, textAlign: 'center', borderLeft: `1px solid ${theme.palette.divider}` }}
                                    >{`${currencyFormatter(sales.$processed![0]?.periods[i].amount)}€`}</TableCell>

                                    <TableCell
                                      sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `1px solid ${theme.palette.divider}` }}
                                    >
                                      {sales.$processed![0]?.periods?.[i].occupancy}
                                    </TableCell>
                                  </>
                                )}
                              </>
                            )}
                            {sales.interval === 'annual' && (
                              <>
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell sx={{ border: 'none' }} />
                                <TableCell
                                  colSpan={2}
                                  sx={{ pl: 1, textAlign: 'left', borderLeft: `1px solid ${theme.palette.divider}` }}
                                >{`${currencyFormatter(period.amount)}€`}</TableCell>

                                <TableCell sx={{ px: 0.1, textAlign: 'right', pr: 1, borderRight: `1px solid ${theme.palette.divider}` }}>
                                  {period.occupancy}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
      </Grid>

      {/*Form Credit Safe*/}
      <form noValidate onSubmit={creditSafeDataForm.handleSubmit}>
        <Grid container mb={3} rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h5'} gutterBottom>
              Dati Credit Safe
            </Typography>

            <Grid container width={'100%'} p={0} mt={1} spacing={3}>
              <Grid item xs={12} md={4}>
                <NumericFormat
                  onBlur={creditSafeDataForm.handleBlur}
                  label={'Credit Limit'}
                  variant="outlined"
                  name="creditLimit"
                  allowNegative={false}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  value={
                    creditSafeDataForm.values.creditLimit
                      ? numericFormatter(creditSafeDataForm.values.creditLimit.toString(), {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        })
                      : undefined
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value as string;
                    creditSafeDataForm.setFieldValue(
                      'creditLimit',
                      +removeNumericFormat(
                        inputValue,
                        {
                          from: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          to: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          lastValue: inputValue
                        },
                        {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        }
                      )
                    );
                  }}
                  fullWidth
                  error={Boolean(creditSafeDataForm.touched.creditLimit && creditSafeDataForm.errors.creditLimit)}
                  customInput={TextField}
                />
                {creditSafeDataForm.touched.creditLimit && creditSafeDataForm.errors.creditLimit && (
                  <FormHelperText error>{creditSafeDataForm.errors.creditLimit}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <NumericFormat
                  onBlur={creditSafeDataForm.handleBlur}
                  label={'Score'}
                  variant="outlined"
                  name="score"
                  allowNegative={false}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  value={
                    creditSafeDataForm.values.score
                      ? numericFormatter(creditSafeDataForm.values.score.toString(), {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        })
                      : undefined
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value as string;
                    creditSafeDataForm.setFieldValue(
                      'score',
                      +removeNumericFormat(
                        inputValue,
                        {
                          from: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          to: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          lastValue: inputValue
                        },
                        {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        }
                      )
                    );
                  }}
                  fullWidth
                  error={Boolean(creditSafeDataForm.touched.score && creditSafeDataForm.errors.score)}
                  customInput={TextField}
                />
                {creditSafeDataForm.touched.score && creditSafeDataForm.errors.score && (
                  <FormHelperText error>{creditSafeDataForm.errors.score}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <NumericFormat
                  onBlur={creditSafeDataForm.handleBlur}
                  label={'Margine operativo Lordo'}
                  variant="outlined"
                  name="operatingProfit"
                  allowNegative={false}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  value={
                    creditSafeDataForm.values.operatingProfit
                      ? numericFormatter(creditSafeDataForm.values.operatingProfit.toString(), {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        })
                      : undefined
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value as string;
                    creditSafeDataForm.setFieldValue(
                      'operatingProfit',
                      +removeNumericFormat(
                        inputValue,
                        {
                          from: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          to: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          lastValue: inputValue
                        },
                        {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        }
                      )
                    );
                  }}
                  fullWidth
                  error={Boolean(creditSafeDataForm.touched.operatingProfit && creditSafeDataForm.errors.operatingProfit)}
                  customInput={TextField}
                />
                {creditSafeDataForm.touched.operatingProfit && creditSafeDataForm.errors.operatingProfit && (
                  <FormHelperText error>{creditSafeDataForm.errors.operatingProfit}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} textAlign={'end'}>
                <LoadingButton loading={creditSafeDataForm.isSubmitting} type={'submit'} variant={'contained'} size={'medium'}>
                  Salva Credit Safe
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {/*Form Change Capital*/}
      <form noValidate onSubmit={changeCapitalForm.handleSubmit}>
        <Grid container mb={3} rowSpacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h5'} gutterBottom>
              Dati Change Capital
            </Typography>

            <Grid container width={'100%'} p={0} mt={1} spacing={3}>
              <Grid item xs={12} md={4}>
                <NumericFormat
                  onBlur={changeCapitalForm.handleBlur}
                  label={'Investiments Rank(Esatto valore Rank)'}
                  variant="outlined"
                  name="investmentsRank"
                  allowNegative={false}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  value={
                    changeCapitalForm.values.investmentsRank
                      ? numericFormatter(changeCapitalForm.values.investmentsRank.toString(), {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        })
                      : undefined
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value as string;
                    changeCapitalForm.setFieldValue(
                      'investmentsRank',
                      +removeNumericFormat(
                        inputValue,
                        {
                          from: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          to: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          lastValue: inputValue
                        },
                        {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        }
                      )
                    );
                  }}
                  fullWidth
                  error={Boolean(changeCapitalForm.touched.investmentsRank && changeCapitalForm.errors.investmentsRank)}
                  customInput={TextField}
                />
                {changeCapitalForm.touched.investmentsRank && changeCapitalForm.errors.investmentsRank && (
                  <FormHelperText error>{changeCapitalForm.errors.investmentsRank}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <NumericFormat
                  onBlur={changeCapitalForm.handleBlur}
                  label={'Liquidity Rank(Esatto valore Rank)'}
                  variant="outlined"
                  name="liquidityRank"
                  allowNegative={false}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  value={
                    changeCapitalForm.values.liquidityRank
                      ? numericFormatter(changeCapitalForm.values.liquidityRank.toString(), {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        })
                      : undefined
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value as string;
                    changeCapitalForm.setFieldValue(
                      'liquidityRank',
                      +removeNumericFormat(
                        inputValue,
                        {
                          from: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          to: {
                            start: 0,
                            end: inputValue.length - 1
                          },
                          lastValue: inputValue
                        },
                        {
                          allowNegative: false,
                          thousandSeparator: '.',
                          decimalSeparator: ','
                        }
                      )
                    );
                  }}
                  fullWidth
                  error={Boolean(changeCapitalForm.touched.liquidityRank && changeCapitalForm.errors.liquidityRank)}
                  customInput={TextField}
                />
                {changeCapitalForm.touched.liquidityRank && changeCapitalForm.errors.liquidityRank && (
                  <FormHelperText error>{changeCapitalForm.errors.liquidityRank}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} textAlign={'end'}>
                <LoadingButton loading={changeCapitalForm.isSubmitting} size={'medium'} type={'submit'} variant={'contained'}>
                  Salva Change Capital
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default GenerateTestWalletTab;
