import { FilterBy, FormatFilterParam, FormatFilterTransformer } from './types/filterByValue';

export const formatFilters = (transformers: FormatFilterParam[]) => {
  return transformers.map<FilterBy>((param) => ({
    field: param.id.includes('.') ? param.id.split('.') : param.id,
    value: param.transformer(param.value)
  }));
};

export const toEquals: FormatFilterTransformer = (value) => {
  if (typeof value === 'string' || value === null || typeof value === 'number' || typeof value === 'boolean')
    return {
      equals: value
    };

  throw new Error('toEquals not supported type');
};

export const toContains: FormatFilterTransformer = (value) => {
  if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')
    return {
      contains: value as string
    };

  throw new Error('toNot not supported type');
};

export const toNot: FormatFilterTransformer = (value) => {
  if (typeof value === 'string' || value === null || typeof value === 'number' || typeof value === 'boolean')
    return {
      not: value
    };

  throw new Error('toNot not supported type');
};

export const toGte: FormatFilterTransformer = (value) => {
  if (typeof value === 'string' || typeof value === 'number')
    return {
      gte: value
    };

  throw new Error('toGte not supported type');
};

export const toLte: FormatFilterTransformer = (value) => {
  if (typeof value === 'string' || typeof value === 'number')
    return {
      lte: value
    };

  throw new Error('toLte not supported type');
};
