// material-ui
import { Components } from '@mui/material/styles';

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Drawer() {
  return {
    MuiDrawer: {
      styleOverrides: {
        root: {
          '.MuiBackdrop-root': {
            backgroundColor: 'rgba(4, 28, 34, 0.5)'
          }
        }
      }
    }
  } as Components['MuiDrawer'];
}
