import { CityDTO, CountryDTO, FractionDTO, ProvinceDTO, RegionDTO } from '../formik/manual-integration-step';
import { BillingInfoDto } from './billing-info.dto';
import { OrderDeliveryDateState, OrderQuoteState, StateDto } from './state.dto';
import { DocumentDto } from './document.dto';
import { OrderCategoriesDto } from './order-categories.dto';

export type OrderDTO = {
  code: string;
  createdAt: string;
  lastEdit: string;
  deleted: boolean;
  title: string;
  orderCategory: OrderCategoriesDto;
  building: Building;
  billingInfo: BillingInfoDto | null;
  shippingAddress: ShippingAddress | null;
  deliveryDateState: StateDto<OrderDeliveryDateState>;
  deliveryDateHistory: string[];
  shippingNotes: string | null;
  lastStep: number;
  fee?: number;
  state: StateDto<OrderStatus>;
  discountCode: string | null;
  documents: DocumentDto[];
} & (WalletRentDto | WalletOrderDto);

export type PurchaseOrderConfigurationDto = {
  isAlternativeOffer: boolean;
  amount: string;
  total: number;
  areQuotesManual: boolean;
  maxResponseDays: number;
  byCustomer: boolean;
  createdAt: string;
  depositAmount: string | number;
  id: number;
  maxDepositAmount: string | number;
  minDepositAmount: string | number;
  orderCode: string;
  orderQuotes?: OrderQuote[];
  quotesExtraInfo?: string;
  requestedProducts: OrderManualProductDto[] | null;
  discountedTotal?: number;
  orderDepositReturns?: Pick<OrderWalletReturn, 'amount' | 'id'>[];
  wallets: WalletOrder[];
};

export type RentOrderConfigurationDto = {
  isAlternativeOffer: boolean;
  amount: string;
  total: number;
  areQuotesManual: boolean;
  byCustomer: boolean;
  createdAt: string;
  depositAmount: string | number;
  maxResponseDays: number;
  id: number;
  maxDepositAmount: string | number;
  minDepositAmount: string | number;
  orderCode: string;
  orderQuotes?: OrderQuote[];
  quotesExtraInfo?: string;
  requestedProducts: OrderManualProductDto[] | null;
  discountedTotal?: number;
  orderDepositReturns?: Pick<OrderWalletReturn, 'amount' | 'id'>[];
  wallets: WalletRent[];
};

export type WalletRentDto = {
  type: 'rent';
  activeConfiguration: RentOrderConfigurationDto;
  orderConfigurations: OrderRentConfiguration[];
};

export type OrderRentConfiguration = RentOrderConfigurationDto & {
  publishedAt: string;
  termsDocument: {
    id: number;
    providedUpload: UploadDto;
    title: string;
  };
};

export type WalletOrderDto = {
  type: 'purchase';
  activeConfiguration: PurchaseOrderConfigurationDto;
  orderConfigurations: OrderPurchaseConfiguration[];
};

export type OrderPurchaseConfiguration = PurchaseOrderConfigurationDto & {
  publishedAt: string;
  termsDocument: {
    id: number;
    providedUpload: UploadDto;
    title: string;
  };
};

export type WalletOrder = {
  id: number;
  walletPartner: WalletPartner;
  orderWallet: OrderWallet | null;
};

export type WalletRent = {
  id: number;
  walletPartner: WalletPartner;
  orderWallet: OrderRent;
};

export type OrderRent = {
  id: number;
  returnTime: number;
  amount: number;
  entries: FieldSummary[];
  orderWalletReturns: OrderWalletReturn[];
};

export type OrderWallet = {
  id: number;
  amount: number;
  returnTime?: number;
  minAmount: number;
  maxAmount: number;
  realAvailableAmount: number;
  entries: FieldSummary[];
  orderWalletReturns: OrderWalletReturn[];
};

export type FieldSummary = {
  key: string;
  unit: string;
  value: number;
  info?: string;
};

export type WalletPartner = {
  title: string;
  type: 'rent' | 'purchase';
  color: string;
  orderExcerpt: string;
  description: string;
  company: string;
  timeValidity: number;
  hideNameIn: string[] | null;
  imageUploadCode: string | null;
  returnTimes?: number[]; //se non ce allora non si puo gestire
} & (IVHPartner | PosCashPartner | IntesaRentPartner | ChangeCapitalInvestimentsPartner | ChangeCapitalLiquidityPartner);

type IVHPartner = {
  slug: 'ivh';
  customData?: {};
};

type PosCashPartner = {
  slug: 'pos-cash';
  customData?: {};
};

type IntesaRentPartner = {
  slug: 'intesa-rent';
};

type ChangeCapitalInvestimentsPartner = {
  slug: 'change-capital-investments';
};

type ChangeCapitalLiquidityPartner = {
  slug: 'change-capital-liquidity';
};

export enum OrderStatus {
  Draft = 'draft', // In bozza
  Filled = 'filled', // Compilazione completata e non più modificabile, quindi in attesa di conferma di un operatore
  Refused = 'refused',
  UserApproved = 'user-approved', //richiesta approvata
  AdminProcessing = 'admin-processing',
  AdminPublished = 'admin-published',
  AdminRefused = 'admin-refused', //modulo rifiutato
  Accepted = 'accepted', //modulo accettato
  IssuedInvoice = 'issued-invoice',
  DepositPaid = 'deposit-paid',
  Setup = 'setup',
  InProgress = 'in-progress', // Ordine spedito - Servizio in corso
  Completed = 'completed' // Ordine consegnato - Servizio terminato
}

export type OrderQuote = {
  id: number;
  upload: UploadDto;
  state: StateDto<OrderQuoteState>;
};

export type UploadDto = {
  code: string;
  metadata: MetaDataDto;
};

export type MetaDataDto = {
  filename: string;
  encoding: string;
  mimetype: string;
  size: number;
};

export type OrderManualProductDto = {
  name: string;
  quantity?: number;
  extraInfo: string;
  company?: string;
};

// export type OrderCategory = {
//   id: number;
//   name: string;
//   isProduct: boolean;
// };

export type Building = {
  id: number;
  name: string;
};

export type ShippingAddress = {
  address: string;
  buildingId: number;
  cap: string;
  city: CityDTO;
  contactFullname: string;
  country: CountryDTO;
  fraction: FractionDTO | null;
  id: number;
  latitude: number;
  longitude: number;
  province: ProvinceDTO;
  region: RegionDTO;
  contactPhone: string;
  $: {
    isDefault: boolean;
  };
};

export type OrderWalletReturn = {
  id: number;
  method: PaymentMethod;
  expectedAmount: number;
  amount: string | number | null;
  expectedPaymentDate: string;
  paidAt: string | null;
  at: string;
};

export enum PaymentMethod {
  Transfer = 'transfer',
  PartnerMethod = 'partner'
}
