import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const ChatNegativeIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9602_112414)">
        <g clipPath="url(#clip1_9602_112414)">
          <path d="M0 23.0405L4.15587 17.0017H0V23.0405Z" fill="#91DCF9" />
          <path d="M20.5819 0H0V14.0126H6.21298L4.15587 17.0018H24V3.41812C24 1.53029 22.4697 0 20.5819 0Z" fill="#F0FF32" />
          <path
            d="M7.18702 9.90261C8.05382 9.90261 8.75649 9.19994 8.75649 8.33314C8.75649 7.46635 8.05382 6.76367 7.18702 6.76367C6.32023 6.76367 5.61755 7.46635 5.61755 8.33314C5.61755 9.19994 6.32023 9.90261 7.18702 9.90261Z"
            fill="#094754"
          />
          <path
            d="M12.0117 9.90261C12.8785 9.90261 13.5812 9.19994 13.5812 8.33314C13.5812 7.46635 12.8785 6.76367 12.0117 6.76367C11.1449 6.76367 10.4423 7.46635 10.4423 8.33314C10.4423 9.19994 11.1449 9.90261 12.0117 9.90261Z"
            fill="#094754"
          />
          <path
            d="M16.9553 9.90261C17.8221 9.90261 18.5248 9.19994 18.5248 8.33314C18.5248 7.46635 17.8221 6.76367 16.9553 6.76367C16.0885 6.76367 15.3859 7.46635 15.3859 8.33314C15.3859 9.19994 16.0885 9.90261 16.9553 9.90261Z"
            fill="#094754"
          />
          <path d="M0 14.0127V17.0019H4.15587L6.21298 14.0127H0Z" fill="#1ED367" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9602_112414">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_9602_112414">
          <rect width="24" height="23.0406" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default ChatNegativeIcon;
