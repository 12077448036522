// material-ui
import { alpha, Components, Theme } from '@mui/material/styles';
// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme: Theme) {
  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200]
    }
  };
  const iconStyle = {
    '&>*:nth-of-type(1)': {
      fontSize: 'inherit'
    }
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 900,
          textTransform: 'uppercase',
          borderRadius: '50px'
        },
        contained: {
          ...disabledStyle
        },
        outlined: {
          ...disabledStyle
        },
        text: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        endIcon: {
          ...iconStyle
        },
        startIcon: {
          ...iconStyle
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.04)
          }
        },
        textSecondary: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.04)
          }
        },
        textError: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.04)
          }
        },
        textSuccess: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.success.main, 0.04)
          }
        },
        textInfo: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.info.main, 0.04)
          }
        },
        textWarning: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.warning.main, 0.04)
          }
        },
        sizeExtraSmall: {
          minWidth: 56,
          fontSize: '0.625rem',
          padding: '2px 8px'
        },
        sizeLarge: {
          fontSize: '0.95rem',
          padding: '8px 22px'
        },
        sizeMedium: {
          fontSize: '0.9rem',
          padding: '6px 16px'
        },
        sizeSmall: {
          fontSize: '0.825rem',
          padding: '4px 10px'
        }
      }
    } as Components['MuiButton']
  };
}
