import { DocumentDto } from '../../../types/dto/document.dto';
import { Chip, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { TooltipInfo } from '../../../components/custom/Cards/OrderSummary/OrderSummaryCard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import UploadArrowUp from '../../../components/custom/BuyPlusIcons/UploadArrorUp';
import getFileSizeInMb from '../../../utils/getSizeFileInMb';
import { dispatch } from '../../../store';
import { openSnackbar } from '../../../store/reducers/snackbar';
import { useEndpoint } from '../../../hooks/useEndpoint';
import { DocumentUploadMutate } from '../../../types/server/document-upload';
import useModal from '../../../hooks/useModal';
import ConfirmUploadFile from '../../../components/custom/Documents/Modals/ConfirmUploadFile';
import { useOpenFileModal } from '../../../components/custom/EmbedFile';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadFile } from '../../../utils/downloadFile';
import useAxiosIstance from '../../../hooks/useAxiosIstance';
// import ConfirmUploadFile from '../../../components/custom/Documents/Modals/ConfirmUploadFile';
import CachedIcon from '@mui/icons-material/Cached';
type DocumentTableRowProps = {
  doc: DocumentDto;
  partner: string;
  reference: number | string;
  divider?: boolean;
  refetchDocuments: () => Promise<void>;
};

const DocumentTableRow = (props: DocumentTableRowProps) => {
  const { doc, partner, reference, divider, refetchDocuments } = props;
  const documentInputName = `upload-document-${partner}-${doc.id != null ? doc.id : doc.autoRequestId}`;
  const { openModal } = useModal();
  const openFileModal = useOpenFileModal();
  const { axiosInstance } = useAxiosIstance();

  const sendUploadFile = useEndpoint<DocumentUploadMutate, 'put'>({
    method: 'put',
    endpoint: '/documents',
    mutationKey: `upload-document-${documentInputName}`,
    options: {
      onSuccess: async () => {
        dispatch(
          openSnackbar({
            variant: 'success',
            message: 'Documento caricato con successo'
          })
        );
        await refetchDocuments();
      }
    }
  });

  //Only document to upload for now
  return (
    <Grid
      container
      mt={0.5}
      mb={0.5}
      py={{ xs: 1, sm: 0 }}
      alignItems={'center'}
      borderBottom={{ xs: divider ? '1px solid #041C221A' : 'none', sm: 'none' }}
      rowSpacing={1}
    >
      <Grid item xs={8} sm={6}>
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'body2'} fontWeight={partner === 'wallet' ? 600 : undefined}>
            {doc.title}
            {!doc.optional && <span className={'fw_600'}> *</span>}
          </Typography>
          {doc.signatureType === 'upload' && (
            <TooltipInfo title={'Scarica e compila'} arrow>
              <IconButton
                sx={{ width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={async () => {
                  await downloadFile({ fileCode: doc.providedUpload!.code, fileName: doc.title, axiosInstance });
                }}
              >
                <DownloadIcon fontSize={'small'} color={'primary'} />
              </IconButton>
            </TooltipInfo>
          )}
        </Stack>
      </Grid>

      <Grid item xs={6} sm={2} order={{ xs: 1, sm: 0 }} textAlign={{ xs: 'start', sm: 'center' }}>
        {doc.expireAt != null && (
          <>
            <Typography variant={'body1'} fontWeight={500} display={{ xs: 'block', sm: 'none' }}>
              Valido fino al
            </Typography>
            <Typography variant={'body1'}>{dayjs(doc.expireAt).format('DD/MM/YYYY')}</Typography>
          </>
        )}
      </Grid>

      <Grid item xs={4} sm={2} order={{ xs: 0, sm: 1 }} textAlign={{ xs: 'end', sm: 'center' }}>
        {doc.signatureType !== 'check' ? (
          <>
            {doc.state == null ? (
              <Chip
                label={doc.signatureType === 'upload' ? 'Firma e carica' : 'Da caricare'}
                size={'small'}
                sx={{ backgroundColor: '#FFB300', color: 'white' }}
              />
            ) : dayjs().isAfter(doc.expireAt) ? (
              <Chip label={'Scaduto'} size={'small'} color={'warning'} />
            ) : doc.state.key === 'created' ? (
              <Chip label={'Preso in carico'} size={'small'} color={'info'} />
            ) : doc.state.key === 'pending' ? (
              <Chip label={'In valutazione'} size={'small'} sx={{ backgroundColor: '#9EA7A8', color: 'white' }} />
            ) : doc.state.key === 'accepted' ? (
              <Chip label={'Accettato'} size={'small'} color={'success'} />
            ) : doc.state.key === 'refused' ? (
              <Chip label={'Non accettato'} size={'small'} color={'error'} sx={{ color: 'white' }} />
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {doc.signedAt == null ? (
              <Chip label={'Da accettare'} size={'small'} sx={{ backgroundColor: '#FFB300', color: 'white' }} />
            ) : (
              <>
                {doc.accepted ? (
                  <Chip label={'Accettato'} size={'small'} color={'success'} />
                ) : (
                  <Chip label={'Non accettato'} size={'small'} color={'error'} sx={{ color: 'white' }} />
                )}
              </>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={6} sm={2} order={1}>
        {doc.signatureType == null && (
          <Stack flexDirection={'row'} justifyContent={'flex-end'}>
            {doc.userUpload != null && (
              <TooltipInfo title={'Visualizza il file'} arrow>
                <IconButton
                  sx={{ width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onClick={() =>
                    openFileModal({
                      title: doc.title,
                      fileCode: doc.userUpload!.code
                    })
                  }
                >
                  <RemoveRedEyeIcon fontSize={'small'} sx={{ color: '#B6BFC1' }} />
                </IconButton>
              </TooltipInfo>
            )}

            {(doc.userUpload == null || doc.state?.key === 'created' || doc.state?.key === 'refused' || dayjs().isAfter(doc.expireAt)) && (
              <>
                <input
                  id={documentInputName}
                  type={'file'}
                  style={{ display: 'none' }}
                  name={'uploads'}
                  accept={'application/pdf, image/*'}
                  multiple={true}
                  onChange={async (event) => {
                    if (event.target.files == null) return;
                    // setErrorMessage(undefined);

                    const uploadFile = event.target.files[0];

                    if (getFileSizeInMb(uploadFile.size) > 3) {
                      dispatch(openSnackbar({ message: 'Il file supera la dimensione consentita(MAX 3MB)', variant: 'error' }));
                      return;
                    }

                    openModal({
                      title: 'Conferma caricamento documento',
                      fullHeight: true,
                      contentFullWidth: true,
                      content: (
                        <ConfirmUploadFile
                          file={uploadFile}
                          onAccept={(file) => {
                            //TODO: rivedere con server il ricaricamento quando scaduto e quando rifiutato
                            sendUploadFile.mutate({
                              id: doc.id && dayjs().isBefore(doc.expireAt) ? doc.id : undefined,
                              autoRequestId: doc.id != null && dayjs().isBefore(doc.expireAt) ? undefined : doc.autoRequestId,
                              upload: file,
                              reference: doc.id != null && dayjs().isBefore(doc.expireAt) ? undefined : reference
                            });
                            // trackAction({
                            //   type: ClientTypeEnum.Button,
                            //   action: ClientActionEnum.Clicked,
                            //   name: `Ha caricato il documento richiesto "${documentToUpload.title}"`
                            // });
                          }}
                        />
                      )
                    });

                    event.target.value = '';
                  }}
                />
                <TooltipInfo title={doc.userUpload == null ? 'Carica il file' : 'Sostituisci il file'} arrow>
                  <IconButton
                    disabled={sendUploadFile.isLoading}
                    sx={{ width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => document.getElementById(documentInputName)?.click()}
                  >
                    {sendUploadFile.isLoading ? (
                      <CircularProgress variant={'indeterminate'} size={10} />
                    ) : (
                      <>
                        {doc.userUpload == null ? (
                          <UploadArrowUp fontSize={'small'} />
                        ) : (
                          <CachedIcon fontSize={'small'} sx={{ color: '#B6BFC1' }} />
                        )}
                      </>
                    )}
                  </IconButton>
                </TooltipInfo>
              </>
            )}
          </Stack>
        )}

        {doc.signatureType === 'upload' && (
          <Stack flexDirection={'row'} justifyContent={'flex-end'}>
            {(doc.userUpload != null || doc.providedUpload != null) && (
              <TooltipInfo title={'Visualizza il file'} arrow>
                <IconButton
                  sx={{ width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onClick={() =>
                    openFileModal({
                      title: doc.title,
                      fileCode: doc.userUpload != null ? doc.userUpload.code : doc.providedUpload!.code
                    })
                  }
                >
                  <RemoveRedEyeIcon fontSize={'small'} sx={{ color: '#B6BFC1' }} />
                </IconButton>
              </TooltipInfo>
            )}

            {(doc.userUpload == null || doc.state?.key === 'created' || doc.state?.key === 'refused' || dayjs().isAfter(doc.expireAt)) && (
              <>
                <input
                  id={documentInputName}
                  type={'file'}
                  style={{ display: 'none' }}
                  name={'uploads'}
                  accept={'application/pdf, image/*'}
                  multiple={true}
                  onChange={async (event) => {
                    if (event.target.files == null) return;
                    // setErrorMessage(undefined);

                    const uploadFile = event.target.files[0];

                    if (getFileSizeInMb(uploadFile.size) > 3) {
                      dispatch(openSnackbar({ message: 'Il file supera la dimensione consentita(MAX 3MB)', variant: 'error' }));
                      return;
                    }

                    openModal({
                      title: 'Conferma caricamento documento',
                      fullHeight: true,
                      contentFullWidth: true,
                      content: (
                        <ConfirmUploadFile
                          file={uploadFile}
                          onAccept={(file) => {
                            sendUploadFile.mutate({
                              id: doc.id ?? undefined,
                              autoRequestId: doc.id != null ? undefined : doc.autoRequestId,
                              upload: file,
                              reference: doc.id != null ? undefined : reference
                            });
                            // trackAction({
                            //   type: ClientTypeEnum.Button,
                            //   action: ClientActionEnum.Clicked,
                            //   name: `Ha caricato il documento richiesto "${documentToUpload.title}"`
                            // });
                          }}
                        />
                      )
                    });

                    event.target.value = '';
                  }}
                />
                <TooltipInfo title={doc.userUpload == null ? 'Carica il file compilato' : 'Sostituisci il file compilato'} arrow>
                  <IconButton
                    disabled={sendUploadFile.isLoading}
                    sx={{ width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => document.getElementById(documentInputName)?.click()}
                  >
                    {sendUploadFile.isLoading ? (
                      <CircularProgress variant={'indeterminate'} size={10} />
                    ) : (
                      <>
                        {doc.userUpload == null ? (
                          <UploadArrowUp fontSize={'small'} />
                        ) : (
                          <CachedIcon fontSize={'small'} sx={{ color: '#B6BFC1' }} />
                        )}
                      </>
                    )}
                  </IconButton>
                </TooltipInfo>
              </>
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentTableRow;
