import { List, ListItem, Skeleton } from '@mui/material';

const NotificationSkeleton = ({ item }: { item: number }) => {
  return (
    <List>
      {Array(item)
        .fill('')
        .map((_, i) => (
          <ListItem key={i} disablePadding>
            <Skeleton width={'100%'} height={'75px'} />
          </ListItem>
        ))}
    </List>
  );
};

export default NotificationSkeleton;
