import { BillingInfoDto } from '../../../types/dto/billing-info.dto';
import { useFormik } from 'formik';
import { BillingInfoFormik } from '../../../types/formik/billing-info';
import { Fade, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import AsyncAutoComplete from '../../../components/custom/AsyncAutoComplete';
import {
  CityDTO,
  CityFractionLocationDTO,
  CityLocationDTO,
  PostalCodeDTO,
  ProvinceDTO,
  RegionDTO
} from '../../../types/formik/manual-integration-step';
import TooltipInputInfo from '../../../components/custom/TooltipInputInfo';
import { FormattedMessage } from 'react-intl';
import { PatternFormat } from 'react-number-format';
import LoadingButton from '../../../components/custom/LoadingButton';
import { useEndpoint } from '../../../hooks/useEndpoint';
import useModal from '../../../hooks/useModal';
import { dispatch } from '../../../store';
import { openSnackbar } from '../../../store/reducers/snackbar';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';

type NewBillingInfoFormProps = {
  billing: BillingInfoDto;
  isDefault: boolean;
  refetchBillings: () => Promise<any>;
  onSuccessEditBillings?: (billing: BillingInfoDto) => void;
};

const EditBillingForm = (props: NewBillingInfoFormProps) => {
  const { billing, isDefault } = props;
  const { closeModal } = useModal();
  const { trackAction } = useNavigationTracker();

  const updateBilling = useEndpoint<Partial<BillingInfoFormik>, 'patch'>({
    method: 'patch',
    endpoint: `/billing-infos/${billing.id}`,
    mutationKey: `edit-billing-info-${billing.id}`,
    options: {
      onSuccess: async () => {
        await props.refetchBillings();
        closeModal();
        dispatch(
          openSnackbar({
            variant: 'success',
            message: 'Indirizzo modificato con successo!'
          })
        );
      }
    }
  });
  const editBillingFormik = useFormik<Partial<BillingInfoFormik> & { isDefault: boolean }>({
    initialValues: {
      name: billing.name ?? '', //req
      taxCode: billing.taxCode ?? '', //req
      vat: billing.vat ?? '',
      iban: billing.iban ?? undefined,
      pec: billing.pec ?? undefined,
      recipientCode: billing.recipientCode ?? undefined, //o pec o recipientCode, se country !== it allora recipientCode XXXXXXX(7 x)
      country: billing.country, //req
      region: billing.region ?? null, //req
      province: billing.province ?? null, //req
      city: billing.city ?? null, //req
      cap: billing.cap ?? '', //req
      address: billing.address ?? '', //req
      isDefault: isDefault
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('companyName-error-required'),
      city: Yup.object().required('error-city-required'),
      cap: Yup.string()
        .matches(/^\d{5}$/, 'error-postal-code-length')
        .required('error-postal-code-required'),
      province: Yup.object().required('error-province-required'),
      region: Yup.object().required('error-region-required'),
      country: Yup.object().required('error-nation-required'),
      address: Yup.string().required('error-address-required'),
      taxCode: Yup.string().required('error-tax-code-required').min(11, 'error-tax-code-lenght').max(16, 'error-tax-code-lenght'),
      vat: Yup.string().matches(/^\d{11}$/, 'error-vat-number-length'),
      iban: Yup.string().matches(/^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/, 'error-iban-invalid'),
      pec: Yup.string().test('pec', 'error-pec-required', function (value) {
        const recipientCodeValue = this.resolve(Yup.ref('recipientCode'));
        if (value == null && recipientCodeValue == null) {
          return false;
        }
        return true;
      }),
      recipientCode: Yup.string()
        .matches(/^[a-zA-Z0-9]{6}$/, 'error-recipient-code-invalid')
        .test('recipientCode', 'error-recipientCode-required', function (value) {
          const pec = this.resolve(Yup.ref('pec'));
          if (value == null && pec == null) {
            return false;
          }
          return true;
        })
    }),
    onSubmit: (values) => {
      console.log(values);
      updateBilling.mutate(values, {
        onError: (error) => {
          console.log(error);
        }
      });
      trackAction({
        type: ClientTypeEnum.Form,
        action: ClientActionEnum.Submit,
        name: 'Ha modificato i  dati di fatturazione'
      });
    }
  });

  console.log(editBillingFormik.errors);

  return (
    <form noValidate onSubmit={editBillingFormik.handleSubmit}>
      <Fade in>
        <Grid container spacing={3}>
          {/* Location Search */}
          <Grid item xs={12} mb={3}>
            <AsyncAutoComplete<CityLocationDTO, CityFractionLocationDTO>
              fullWidth={true}
              query={{
                skip: 0,
                take: 25
              }}
              noOptionsText={'Nessuna città trovata'}
              loadingText={'Cerco le città...'}
              endpoint={'/cities/locations'}
              inputChangeFilterField="locationName"
              getOptionLabel={(option) =>
                typeof option === 'string'
                  ? option
                  : `${option.fraction?.name.it && option.fraction.name.it !== option.name.it ? option.fraction.name.it + ', ' : ''}${
                      option.name.it !== option.province.name.it ? option.name.it + ', ' : ''
                    } ${option.province.name.it}, ${option.province.region.name.it}`
              }
              flatMapServerResponse={(item) =>
                ([{ ...item, fraction: undefined }] as CityFractionLocationDTO[]).concat(
                  ...item.fractions.map((f) => ({ ...item, fraction: f }))
                )
              }
              isOptionEqualToValue={(option, value) =>
                (option.fraction ? option.fraction.id : option.id) === (value.fraction ? value.fraction.id : value.id)
              }
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${option.id}-${option.fraction?.id ?? ''}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    paddingTop: '8px',
                    paddingBottom: '8px'
                  }}
                >
                  {option.fraction?.name.it ? (
                    <Typography variant="body1" fontWeight={700}>
                      {option.name.it} - <span style={{ fontWeight: 500 }}>{option.fraction.name.it}</span>
                    </Typography>
                  ) : (
                    <Typography variant="body1" fontWeight={700}>
                      {option.name.it}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {option.province.name.it}, {option.province.region.name.it}
                  </Typography>
                </li>
              )}
              onBlur={editBillingFormik.handleBlur}
              onChange={(item) => {
                if (!item) return;
                const itemCast = item as CityFractionLocationDTO;
                console.log(itemCast);
                editBillingFormik.setValues({
                  ...editBillingFormik.values,
                  country: {
                    name: itemCast.province.region.country.name,
                    code: itemCast.province.region.country.code
                  },
                  region: {
                    id: itemCast.province.region.id,
                    name: itemCast.province.region.name
                  },
                  province: {
                    name: itemCast.province.name,
                    code: itemCast.province.code
                  },
                  city: {
                    id: itemCast.id,
                    name: itemCast.name,
                    fraction: itemCast.fraction
                  },
                  cap: itemCast.postalCodes[0].code
                });
                trackAction({
                  type: ClientTypeEnum.Select,
                  action: ClientActionEnum.Selected,
                  name: `Ha selezionato un indirizzo dal campo di ricerca: ${
                    itemCast.fraction?.name.it != null ? itemCast.fraction?.name.it : itemCast.name.it
                  }, ${itemCast.province.name.it} ${itemCast.province.region.name.it}`
                });
              }}
              id="search-city-register"
              renderInput={(params) => <TextField variant="outlined" label={'Cerca la città'} {...params} />}
            />
            <TooltipInputInfo
              title={'Maggiori informazioni'}
              description="Cerca la tua città per completare automaticamente tutti i campi"
            />
          </Grid>

          {/*Referente*/}
          <Grid item xs={12}>
            <TextField
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito il referente: ${editBillingFormik.values.name}`
                });
              }}
              label={'Ragione sociale'}
              variant="outlined"
              id="name"
              type="text"
              name="name"
              value={editBillingFormik.values.name}
              onChange={editBillingFormik.handleChange}
              fullWidth
              error={Boolean(editBillingFormik.touched.name && editBillingFormik.errors.name)}
            />
            {editBillingFormik.touched.name && editBillingFormik.errors.name && (
              <FormHelperText error>
                <FormattedMessage id={editBillingFormik.errors.name} />
              </FormHelperText>
            )}
          </Grid>

          {/*Tax Code*/}
          <Grid item xs={12}>
            <TextField
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito il codice fiscale: ${editBillingFormik.values.taxCode}`
                });
              }}
              label={<FormattedMessage id="tax-code-label" />}
              variant="outlined"
              id="taxCode"
              type="text"
              name="taxCode"
              value={editBillingFormik.values.taxCode}
              onChange={editBillingFormik.handleChange}
              fullWidth
              error={Boolean(editBillingFormik.touched.taxCode && editBillingFormik.errors.taxCode)}
            />
            {editBillingFormik.touched.taxCode && editBillingFormik.errors.taxCode && (
              <FormHelperText error>
                <FormattedMessage id={editBillingFormik.errors.taxCode} />
              </FormHelperText>
            )}
          </Grid>

          {/* Input Partita IVA */}
          <Grid item xs={12}>
            <PatternFormat
              disabled={true}
              format="###########"
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito la partita iva: ${editBillingFormik.values.vat}`
                });
              }}
              label={'Partita IVA'}
              variant="outlined"
              id="vat-number-register"
              type="text"
              name="vat"
              value={editBillingFormik.values.vat}
              onChange={(e) => editBillingFormik.setFieldValue('vat', e.target.value.replace(/\s/g, ''))}
              fullWidth
              error={Boolean(editBillingFormik.touched.vat && editBillingFormik.errors.vat)}
              customInput={TextField}
            />

            {editBillingFormik.touched.vat && editBillingFormik.errors.vat && (
              <FormHelperText error>
                <FormattedMessage id={editBillingFormik.errors.vat} />
              </FormHelperText>
            )}
          </Grid>

          {/*Pec*/}
          <Grid item xs={12}>
            <TextField
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito la pec: ${editBillingFormik.values.pec}`
                });
              }}
              label={<FormattedMessage id="pec-label" />}
              variant="outlined"
              id="pec"
              type="text"
              name="pec"
              value={editBillingFormik.values.pec}
              onChange={editBillingFormik.handleChange}
              fullWidth
              error={Boolean(editBillingFormik.touched.pec && editBillingFormik.errors.pec)}
            />
            {editBillingFormik.touched.pec && editBillingFormik.errors.pec && (
              <FormHelperText error>
                <FormattedMessage id={editBillingFormik.errors.pec} />
              </FormHelperText>
            )}
          </Grid>

          {/*Recipient code*/}
          <Grid item xs={12}>
            <TextField
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito il codice destinatario: ${editBillingFormik.values.recipientCode}`
                });
              }}
              label={'Codice destinatario'}
              variant="outlined"
              id="recipientCode"
              type="text"
              name="recipientCode"
              value={editBillingFormik.values.recipientCode}
              onChange={editBillingFormik.handleChange}
              fullWidth
              error={Boolean(editBillingFormik.touched.recipientCode && editBillingFormik.errors.recipientCode)}
            />
            {editBillingFormik.touched.recipientCode && editBillingFormik.errors.recipientCode && (
              <FormHelperText error>
                <FormattedMessage id={editBillingFormik.errors.recipientCode} />
              </FormHelperText>
            )}
          </Grid>

          {/*IBAN*/}
          <Grid item xs={12}>
            <TextField
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito l'iban: ${editBillingFormik.values.iban}`
                });
              }}
              label={'IBAN'}
              variant="outlined"
              id="iban"
              type="text"
              name="iban"
              value={editBillingFormik.values.iban}
              onChange={editBillingFormik.handleChange}
              fullWidth
              error={Boolean(editBillingFormik.touched.iban && editBillingFormik.errors.iban)}
            />
            {editBillingFormik.touched.iban && editBillingFormik.errors.iban && (
              <FormHelperText error>
                <FormattedMessage id={editBillingFormik.errors.iban} />
              </FormHelperText>
            )}
          </Grid>

          {/* Regione */}
          <Grid item xs={12} md={6}>
            <AsyncAutoComplete<RegionDTO>
              fullWidth={true}
              endpoint={'/regions'}
              noOptionsText={'Nessuna regione trovata'}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name.it)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              query={{ filterBy: JSON.stringify([{ field: 'countryCode', value: { equals: editBillingFormik.values.country?.code } }]) }}
              disabled={!editBillingFormik.values.country}
              onBlur={editBillingFormik.handleBlur}
              id="region-register"
              value={editBillingFormik.values.region}
              renderInput={(params) => (
                <TextField
                  label={<FormattedMessage id="region-label" />}
                  {...params}
                  error={Boolean(editBillingFormik.errors.region && editBillingFormik.touched.region && editBillingFormik.values.country)}
                  name="region"
                />
              )}
              onChange={(value, serverOptions) => {
                const castValue = value as RegionDTO;
                editBillingFormik.setValues({
                  ...editBillingFormik.values,
                  region: castValue,
                  province: null,
                  city: null,
                  cap: '',
                  address: ''
                });
                trackAction({
                  type: ClientTypeEnum.Select,
                  action: ClientActionEnum.Selected,
                  name: castValue != null ? `Ha selezionato una regione: ${castValue.name.it}` : `Ha rimosso la regione`
                });
              }}
            />
            {editBillingFormik.touched.region && editBillingFormik.errors.region && editBillingFormik.values.country && (
              <FormHelperText error id="standard-weight-helper-text-region-register">
                <FormattedMessage id={editBillingFormik.errors.region} />
              </FormHelperText>
            )}
          </Grid>

          {/* Province */}
          <Grid item xs={12} md={6}>
            <AsyncAutoComplete<ProvinceDTO>
              disabled={!editBillingFormik.values.region}
              fullWidth={true}
              endpoint={'/provinces'}
              noOptionsText={`Nessun risultato per la regione "${editBillingFormik.values.region?.name.it}"`}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name.it)}
              query={{ filterBy: JSON.stringify([{ field: 'regionId', value: { equals: editBillingFormik.values.region?.id } }]) }}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              onBlur={editBillingFormik.handleBlur}
              id="province-register"
              value={editBillingFormik.values.province}
              renderInput={(params) => (
                <TextField
                  label={<FormattedMessage id="province-label" />}
                  {...params}
                  error={Boolean(
                    editBillingFormik.errors.province && editBillingFormik.touched.province && editBillingFormik.values.region
                  )}
                  name="province"
                />
              )}
              onChange={(value, serverOptions) => {
                const castValue = value as ProvinceDTO;
                editBillingFormik.setValues({
                  ...editBillingFormik.values,
                  province: castValue,
                  city: null,
                  cap: '',
                  address: ''
                });
                trackAction({
                  type: ClientTypeEnum.Select,
                  action: ClientActionEnum.Selected,
                  name: castValue != null ? `Ha selezionato una provincia: ${castValue.name.it}` : 'Ha rimosso la provincia'
                });
              }}
            />
            {editBillingFormik.touched.province && editBillingFormik.errors.province && editBillingFormik.values.region && (
              <FormHelperText error id="standard-weight-helper-text-province-register">
                <FormattedMessage id={editBillingFormik.errors.province} />
              </FormHelperText>
            )}
          </Grid>

          {/* Città */}
          <Grid item xs={12} md={6}>
            <AsyncAutoComplete<CityDTO>
              disabled={!editBillingFormik.values.province}
              fullWidth={true}
              endpoint={'/cities'}
              noOptionsText={`Nessun risultato per la provincia "${editBillingFormik.values.province?.name.it}"`}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name.it)}
              query={{
                filterBy: JSON.stringify([{ field: 'provinceCode', value: { equals: editBillingFormik.values.province?.code } }])
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onBlur={editBillingFormik.handleBlur}
              id="city-register"
              value={editBillingFormik.values.city}
              renderInput={(params) => (
                <TextField
                  label={<FormattedMessage id="city-label" />}
                  {...params}
                  error={Boolean(editBillingFormik.errors.city && editBillingFormik.touched.city && editBillingFormik.values.province)}
                  name="city"
                />
              )}
              onChange={(value, serverOptions) => {
                const castValue = value as CityDTO;
                editBillingFormik.setValues({
                  ...editBillingFormik.values,
                  city: castValue,
                  cap: '',
                  address: ''
                });
                trackAction({
                  type: ClientTypeEnum.Select,
                  action: ClientActionEnum.Selected,
                  name: castValue != null ? `Ha selezionato una città: ${castValue.name.it}` : 'Ha rimosso la città'
                });
              }}
            />
            {editBillingFormik.touched.city && editBillingFormik.errors.city && editBillingFormik.values.province && (
              <FormHelperText error id="standard-weight-helper-text-city-register">
                <FormattedMessage id={editBillingFormik.errors.city} />
              </FormHelperText>
            )}
          </Grid>

          {/* Postal code */}
          <Grid item xs={12} md={6}>
            <AsyncAutoComplete<PostalCodeDTO, string>
              disabled={!editBillingFormik.values.city}
              fullWidth={true}
              endpoint={'/postal-codes'}
              noOptionsText={`Nessun risultato per la città ${
                editBillingFormik.values.city?.fraction != null
                  ? editBillingFormik.values.city.fraction.name.it
                  : editBillingFormik.values.city?.name.it
              }`}
              isOptionEqualToValue={(option, value) => option === value}
              query={{ filterBy: JSON.stringify([{ field: 'cityId', value: { equals: editBillingFormik.values.city?.id } }]) }}
              mapServerResponse={(item) => item.code}
              onBlur={editBillingFormik.handleBlur}
              id="postal-code-register"
              value={editBillingFormik.values.cap}
              renderInput={(params) => (
                <TextField
                  label={<FormattedMessage id="postal-code-label" />}
                  {...params}
                  error={Boolean(editBillingFormik.errors.cap && editBillingFormik.touched.cap && editBillingFormik.values.city)}
                  name="cap"
                />
              )}
              onChange={(value, serverOptions) => {
                const castValue = value as string;
                editBillingFormik.setValues({
                  ...editBillingFormik.values,
                  cap: castValue,
                  address: ''
                });
                trackAction({
                  type: ClientTypeEnum.Select,
                  action: ClientActionEnum.Selected,
                  name: castValue != null ? `Ha selezionato un cap: ${castValue}` : 'Ha rimosso il cap'
                });
              }}
            />
            {editBillingFormik.touched.cap && editBillingFormik.errors.cap && editBillingFormik.values.city && (
              <FormHelperText error id="standard-weight-helper-text-cap-register">
                <FormattedMessage id={editBillingFormik.errors.cap} />
              </FormHelperText>
            )}
          </Grid>

          {/* Indirizzo */}
          <Grid item xs={12}>
            <TextField
              onBlur={(e) => {
                editBillingFormik.handleBlur(e);
                trackAction({
                  type: ClientTypeEnum.Input,
                  action: ClientActionEnum.Typed,
                  name: `Ha inserito l'indirizzo: ${editBillingFormik.values.address}`
                });
              }}
              disabled={!editBillingFormik.values.cap}
              label={<FormattedMessage id="address-label" />}
              placeholder="Inserisci indirizzo"
              variant="outlined"
              id="address-register"
              type="text"
              name="address"
              value={editBillingFormik.values.address ?? ''}
              onChange={editBillingFormik.handleChange}
              fullWidth
              error={Boolean(editBillingFormik.touched.address && editBillingFormik.errors.address && editBillingFormik.values.cap)}
            />
            {editBillingFormik.touched.address && editBillingFormik.errors.address && editBillingFormik.values.cap && (
              <FormHelperText error id="standard-weight-helper-text-address-register">
                <FormattedMessage id={editBillingFormik.errors.address} />
              </FormHelperText>
            )}
            <FormHelperText>Inserire l'indirizzo completo di numero civico</FormHelperText>
          </Grid>

          {/* Default */}
          {/*<Grid item xs={12}>*/}
          {/*  <FormControlLabel*/}
          {/*    color="default"*/}
          {/*    control={*/}
          {/*      <Checkbox*/}
          {/*        color="default"*/}
          {/*        value={editBillingFormik.values.isDefault}*/}
          {/*        onChange={(e, checked) => {*/}
          {/*          editBillingFormik.handleChange(e);*/}

          {/*          trackAction({*/}
          {/*            type: ClientTypeEnum.Checkbox,*/}
          {/*            action: ClientActionEnum.Checked,*/}
          {/*            name: checked ? `Ha selezionato l'indirizzo come defualt` : "Ha selezionato l'indirizzo come non di default"*/}
          {/*          });*/}
          {/*        }}*/}
          {/*        onBlur={editBillingFormik.handleBlur}*/}
          {/*        name="isDefault"*/}
          {/*        size="small"*/}
          {/*        defaultChecked={isDefault}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    sx={{*/}
          {/*      '.MuiTypography-root': {*/}
          {/*        fontSize: '.8rem',*/}
          {/*        color: '#3E4142'*/}
          {/*      }*/}
          {/*    }}*/}
          {/*    label="Imposta come indirizzo di fatturazione predefinito"*/}
          {/*  />*/}
          {/*</Grid>*/}

          <Grid item xs={12} textAlign={'end'}>
            <LoadingButton
              loading={updateBilling.isLoading}
              variant={'contained'}
              size={'medium'}
              disabled={Object.keys(editBillingFormik.errors).length !== 0}
              type={'submit'}
            >
              Modifica
            </LoadingButton>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};

export default EditBillingForm;
