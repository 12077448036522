import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
  Chip,
  ChipProps,
  List,
  ListItem,
  Stack,
  styled,
  SvgIconProps,
  Typography
} from '@mui/material';
import MainCard from '../../components/custom/Cards/MainCard';
import { AccordionProps } from '@mui/material/Accordion';
import { Circle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { OrderListDto } from '../../types/dto/order-list.dto';
import { textToUrl } from '../../utils/urlFormatting';
import { BuildingRegistrationMethodEnum } from '../../types/dto/building.dto';
import { openModalUploadPmsCode } from '../../components/custom/Modal/ModalUploadPmsCode';
import OrderChip from '../../components/custom/Chips/OrderChip';

type OrderActions = Record<
  'new-order' | 'order-proposal' | 'confirm-order-module',
  | {
      chip: {
        message: string;
        color: ChipProps['color'] & SvgIconProps['color'];
        customComponent?: React.ReactNode;
      };
      action?: {
        title: string;
        link: string;
      };
    }
  | undefined
>;

const AccordionActivity = styled((props: AccordionProps) => (
  <Accordion elevation={0} {...props}>
    {props.children}
  </Accordion>
))({
  '&:before': {
    background: 'none'
  },
  '&.Mui-expanded': {
    margin: '0'
  }
});

const AccordionSummaryActivity = styled((props: AccordionSummaryProps & { title: string; description: string }) => {
  const { title, description, ...other } = props;
  return (
    <AccordionSummary {...other}>
      <Stack flexDirection={'row'} flexWrap={'nowrap'} gap={'10px'} width={'100%'}>
        <Typography variant={'body1'} fontWeight={600}>
          {title}
        </Typography>
        <Typography variant={'body1'} fontWeight={600}>
          |
        </Typography>
        <Typography variant={'body1'}>{description}</Typography>
      </Stack>
      {props.children}
    </AccordionSummary>
  );
})({
  backgroundColor: '#E3F9F885'
});

const ListItemActivity = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
});

type BoxActivityProps = {
  isMini?: boolean;
  registrationData: {
    user: {
      total: number;
      count: number;
    };
    building: {
      total: number;
      count: number;
    };
    documents: {
      total: number;
      count: number;
    };
    registrationConfirmed: boolean;
    registrationMethod: BuildingRegistrationMethodEnum | null;
    registrationCompleted: boolean;
  };
  orderData: {
    totalCount: number;
    pendingCount: number;
    lastOrder: OrderListDto;
  };
};
const BoxActivity = (props: BoxActivityProps) => {
  const { isMini, registrationData, orderData } = props;
  const navigate = useNavigate();
  const modalUploadPmsCode = openModalUploadPmsCode();

  const registrationDataStatus = ((): {
    message: string;
    color: ChipProps['color'] & SvgIconProps['color'];
    action?: {
      title: string;
      link?: string;
      onClick?: () => void;
    };
  } => {
    //Se registrazione completata
    if (
      registrationData.user.count === registrationData.user.total &&
      registrationData.building.count === registrationData.building.total &&
      registrationData.registrationCompleted
    ) {
      return {
        message: 'Fatto',
        color: 'success',
        action: undefined
      };
    }

    if (
      registrationData.registrationConfirmed &&
      registrationData.registrationMethod === BuildingRegistrationMethodEnum.Report &&
      !registrationData.registrationCompleted
    ) {
      return {
        message: 'In Elaborazione',
        color: 'warning',
        action: undefined
      };
    }

    if (
      registrationData.registrationConfirmed &&
      registrationData.registrationMethod === BuildingRegistrationMethodEnum.Pms &&
      !registrationData.registrationCompleted
    ) {
      return {
        message: 'Da fare',
        color: 'warning',
        action: {
          title: 'Termina',
          onClick: () => {
            modalUploadPmsCode();
          }
        }
      };
    }

    return {
      message: 'Da fare',
      color: 'warning',
      action: {
        title: 'completa',
        link: '/building-register'
      }
    };
  })();

  const documentsToUpload = ((): {
    message: string;
    color: ChipProps['color'] & SvgIconProps['color'];
    action: boolean;
  } => {
    if (registrationData.documents.count === registrationData.documents.total) {
      return {
        message: 'Fatto',
        color: 'success',
        action: false
      };
    }

    return {
      message: 'Da fare',
      color: 'warning',
      action: true
    };
  })();

  const orderActions: OrderActions = (() => {
    //Se non ci sono ordini
    if (orderData.totalCount === 0 || orderData.lastOrder == null) {
      return {
        'new-order': {
          chip: {
            message: 'Da fare',
            color: 'warning'
          },
          action: {
            title: 'crea',
            link: '/orders/new-order'
          }
        },
        'order-proposal': {
          chip: {
            message: 'Da fare',
            color: 'warning'
          }
        },
        'confirm-order-module': undefined
      };
    }

    const order = orderData.lastOrder;

    return {
      'new-order':
        order.state.key === 'draft'
          ? {
              chip: {
                message: 'In bozza',
                color: 'warning'
              },
              action: {
                title: 'aggiorna o invia',
                link: `/orders/new-order/${order.code}/${textToUrl(order.title)}`
              }
            }
          : {
              chip: {
                message: 'Inviato',
                color: 'success'
              },
              action: {
                title: 'verifica status',
                link: `/orders/${order.code}/${textToUrl(order.title)}`
              }
            },
      'order-proposal':
        order.state.key === 'draft'
          ? {
              chip: {
                message: 'Da fare',
                color: 'warning'
              }
            }
          : {
              chip: {
                message: '',
                color: 'success',
                customComponent: <OrderChip state={order.state.key} isProduct={order.orderCategory.isProduct} />
              }
            },
      'confirm-order-module':
        order.state.key === 'admin-published' && order.lastOrderConfiguration.isAlternativeOffer
          ? {
              chip: {
                message: 'inviata',
                color: 'warning'
              },
              action: {
                title: 'vedi',
                link: `/orders/${order.code}/${textToUrl(order.title)}`
              }
            }
          : undefined
    };

    // //Se ordine in bozza
    // if (order.state.key === OrderStatus.Draft) {
    //   return {
    //     'new-order': {
    //       chip: {
    //         message: 'In bozza',
    //         color: 'warning'
    //       },
    //       action: {
    //         title: 'aggiorna o invia',
    //         link: `/orders/new-order/${order.code}/${textToUrl(order.title)}`
    //       }
    //     },
    //     'order-proposal': {
    //       chip: {
    //         message: 'Da fare',
    //         color: 'warning'
    //       }
    //     },
    //     'confirm-order-module': undefined
    //   };
    // }
    //
    // //Se ordine inoltrato ma non accettato o rifiutato dal backoffice
    // if (order.lastOrderConfiguration.byCustomer && order.state.key === 'filled') {
    //   return {
    //     'new-order': {
    //       chip: {
    //         message: 'Inviato',
    //         color: 'success'
    //       },
    //       action: {
    //         title: 'verifica status',
    //         link: `/orders/${order.code}/${textToUrl(order.title)}`
    //       }
    //     },
    //     'order-proposal': {
    //       chip: {
    //         message: '',
    //         color: 'success',
    //         customComponent: <OrderChip state={order.state.key} isProduct={order.orderCategory.isProduct} />
    //       }
    //     },
    //     'confirm-order-module': undefined
    //   };
    // }
    //
    // //Ordine accettato dal backoffice(senza controproposta) ma non ancora dal cliente
    // if (!order.lastOrderConfiguration.byCustomer && order.state.key === 'user-approved') {
    //   return {
    //     'new-order': {
    //       chip: {
    //         message: 'Inviato',
    //         color: 'success'
    //       },
    //       action: {
    //         title: 'verifica status',
    //         link: `/orders/${order.code}/${textToUrl(order.title)}`
    //       }
    //     },
    //     'order-proposal': {
    //       chip: {
    //         message: 'accettata',
    //         color: 'success'
    //       }
    //     },
    //     'confirm-order-module': undefined
    //   };
    // }
    //
    // //Ordine accettato dal backoffice(con controproposta) ma non ancora dal cliente
    // if (!order.lastOrderConfiguration.byCustomer && order.state.key === 'admin-published') {
    //   return {
    //     'new-order': {
    //       chip: {
    //         message: 'Inviato',
    //         color: 'success'
    //       },
    //       action: {
    //         title: 'verifica status',
    //         link: `/orders/${order.code}/${textToUrl(order.title)}`
    //       }
    //     },
    //     'order-proposal': {
    //       chip: {
    //         message: 'confermata',
    //         color: 'success'
    //       }
    //     },
    //     'confirm-order-module': {
    //       chip: {
    //         message: 'inviata',
    //         color: 'warning'
    //       },
    //       action: {
    //         title: 'vedi',
    //         link: `/orders/${order.code}/${textToUrl(order.title)}`
    //       }
    //     }
    //   };
    // }
    //
    // //Proposta rifiutata dal cliente
    // if (!order.lastOrderConfiguration.byCustomer && order.state.key === 'admin-processing') {
    //   return {
    //     'new-order': {
    //       chip: {
    //         message: 'Inviato',
    //         color: 'success'
    //       },
    //       action: {
    //         title: 'verifica status',
    //         link: `/orders/${order.code}/${textToUrl(order.title)}`
    //       }
    //     },
    //     'order-proposal': {
    //       chip: {
    //         message: 'confermata',
    //         color: 'success'
    //       }
    //     },
    //     'confirm-order-module': {
    //       chip: {
    //         message: 'In lavorazione',
    //         color: 'info'
    //       }
    //     }
    //   };
    // }
    //
    // //Proposta accettata dal cliente
    // if (!order.lastOrderConfiguration.byCustomer && order.state.key === 'accepted') {
    //   return {
    //     'new-order': {
    //       chip: {
    //         message: 'Inviato',
    //         color: 'success'
    //       },
    //       action: {
    //         title: 'verifica status',
    //         link: `/orders/${order.code}/${textToUrl(order.title)}`
    //       }
    //     },
    //     'order-proposal': {
    //       chip: {
    //         message: 'confermata',
    //         color: 'success'
    //       }
    //     },
    //     'confirm-order-module': order.lastOrderConfiguration.isAlternativeOffer
    //       ? {
    //           chip: {
    //             message: 'accettata',
    //             color: 'success'
    //           }
    //         }
    //       : undefined
    //   };
    // }
    //
    // return {
    //   'new-order': undefined,
    //   'order-proposal': undefined,
    //   'confirm-order-module': undefined
    // };
  })();

  return (
    <MainCard
      padding={'0'}
      title={
        <Typography variant={'body1'} fontWeight={600} textTransform={'uppercase'}>
          completa le attività
        </Typography>
      }
    >
      <Box paddingY={!isMini ? '10px' : undefined}>
        {!isMini && (
          <Box paddingX={'30px'} mb={2}>
            <Typography variant={'body1'}>
              Qui trovi un riepilogo del tuo profilo e del tuo percorso con buyplus e anche alcune attività e azioni che ti consigliamo di
              fare per ottimizzare i tuoi acquisti, incrementare il tuo credito e migliorare le performance del tuo hotel.
            </Typography>
          </Box>
        )}

        <AccordionActivity defaultExpanded={true}>
          <AccordionSummaryActivity
            title={'Registrazione e documenti'}
            description={'Completa e tieni aggiornati i tuoi dati per utilizzare buyplus al massimo del suo potenziale'}
          />
          <AccordionDetails>
            <List>
              <ListItemActivity>
                <Typography flexGrow={1} order={1}>
                  Registrazione a buyplus
                </Typography>
                <Box width={!isMini ? '15%' : undefined} order={isMini ? 0 : 1} display={'flex'} alignItems={'center'}>
                  {!isMini ? (
                    <Chip color={'success'} label={'Fatto'} size={'small'} sx={{ width: '100%' }} />
                  ) : (
                    <Circle fontSize={'small'} color={'success'} />
                  )}
                </Box>
                <Box minWidth={'20%'} order={1}></Box>
              </ListItemActivity>

              <ListItemActivity>
                <Typography flexGrow={1} order={1}>
                  Completamento dati
                </Typography>
                <Box width={!isMini ? '15%' : undefined} order={isMini ? 0 : 1} display={'flex'} alignItems={'center'}>
                  {!isMini ? (
                    <Chip
                      color={registrationDataStatus.color}
                      label={registrationDataStatus.message}
                      size={'small'}
                      sx={{ width: '100%' }}
                    />
                  ) : (
                    <Circle fontSize={'small'} color={registrationDataStatus.color} />
                  )}
                </Box>
                <Box minWidth={'20%'} order={1} textAlign={'end'}>
                  {registrationDataStatus.action && (
                    <Button
                      size={'small'}
                      variant={'text'}
                      onClick={() => {
                        if (registrationDataStatus.action?.link) {
                          navigate(registrationDataStatus.action.link);
                        } else if (registrationDataStatus.action?.onClick) {
                          registrationDataStatus.action.onClick();
                        }
                      }}
                    >
                      {registrationDataStatus.action.title}
                    </Button>
                  )}
                </Box>
              </ListItemActivity>

              <ListItemActivity>
                <Typography flexGrow={1} order={1}>
                  Caricamento documenti
                </Typography>
                <Box width={!isMini ? '15%' : undefined} order={isMini ? 0 : 1} display={'flex'} alignItems={'center'}>
                  {!isMini ? (
                    <Chip color={documentsToUpload.color} label={documentsToUpload.message} size={'small'} sx={{ width: '100%' }} />
                  ) : (
                    <Circle fontSize={'small'} color={documentsToUpload.color} />
                  )}
                </Box>
                <Box minWidth={'20%'} order={1} textAlign={'end'}>
                  {documentsToUpload.action && (
                    <Button
                      size={'small'}
                      variant={'text'}
                      onClick={() => {
                        navigate('/account/documents');
                      }}
                    >
                      carica
                    </Button>
                  )}
                </Box>
              </ListItemActivity>
            </List>
          </AccordionDetails>
        </AccordionActivity>
        <AccordionActivity defaultExpanded={true}>
          <AccordionSummaryActivity title={'Ordini'} description={'Monitora e completa le attività sui tuoi ordini'} />
          <AccordionDetails>
            <List>
              <ListItemActivity>
                <Typography flexGrow={1} order={1}>
                  Nuovo ordine
                </Typography>
                <Box width={!isMini ? '15%' : undefined} order={isMini ? 0 : 1} display={'flex'} alignItems={'center'}>
                  {!isMini ? (
                    <Chip
                      color={orderActions['new-order']?.chip.color}
                      label={orderActions['new-order']?.chip.message}
                      size={'small'}
                      sx={{ width: '100%' }}
                    />
                  ) : (
                    <Circle fontSize={'small'} color={orderActions['new-order']?.chip.color} />
                  )}
                </Box>
                <Box minWidth={'20%'} order={1} textAlign={'end'}>
                  {orderActions['new-order']?.action != null && (
                    <Button
                      size={'small'}
                      variant={'text'}
                      onClick={() => {
                        navigate(orderActions['new-order']!.action!.link);
                      }}
                    >
                      {orderActions['new-order']!.action!.title}
                    </Button>
                  )}
                </Box>
              </ListItemActivity>

              <ListItemActivity>
                <Typography flexGrow={1} order={1}>
                  Ordine confermato
                </Typography>
                <Box width={!isMini ? '15%' : undefined} order={isMini ? 0 : 1} display={'flex'} alignItems={'center'}>
                  {!isMini ? (
                    <>
                      {orderActions['order-proposal']?.chip.customComponent != null ? (
                        orderActions['order-proposal']?.chip.customComponent
                      ) : (
                        <Chip
                          color={orderActions['order-proposal']?.chip.color}
                          label={orderActions['order-proposal']?.chip.message}
                          size={'small'}
                          sx={{ width: '100%' }}
                        />
                      )}
                    </>
                  ) : (
                    <Circle fontSize={'small'} color={orderActions['order-proposal']?.chip.color} />
                  )}
                </Box>
                <Box minWidth={'20%'} order={1} textAlign={'end'}></Box>
              </ListItemActivity>

              {orderActions['confirm-order-module'] != null && (
                <ListItemActivity>
                  <Typography flexGrow={1} order={1}>
                    Controproposta da confermare
                  </Typography>
                  <Box width={!isMini ? '15%' : undefined} order={isMini ? 0 : 1} display={'flex'} alignItems={'center'}>
                    {!isMini ? (
                      <Chip
                        color={orderActions['confirm-order-module'].chip.color}
                        label={orderActions['confirm-order-module'].chip.message}
                        size={'small'}
                        sx={{ width: '100%' }}
                      />
                    ) : (
                      <Circle fontSize={'small'} color={orderActions['confirm-order-module'].chip.color} />
                    )}
                  </Box>
                  <Box minWidth={'20%'} order={1} textAlign={'end'}>
                    {orderActions['confirm-order-module'].action != null && (
                      <Button
                        size={'small'}
                        variant={'text'}
                        onClick={() => {
                          navigate(orderActions['confirm-order-module']!.action!.link);
                        }}
                      >
                        {orderActions['confirm-order-module'].action.title}
                      </Button>
                    )}
                  </Box>
                </ListItemActivity>
              )}
            </List>
          </AccordionDetails>
        </AccordionActivity>
      </Box>
    </MainCard>
  );
};

export default BoxActivity;
