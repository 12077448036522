import { InfoOutlined } from '@mui/icons-material';
import { Box, FormHelperText, Tooltip, TooltipProps, alpha, styled, tooltipClasses } from '@mui/material';
import { SystemCssProperties } from '@mui/system';
import { useState } from 'react';

type TooltipInputProps = Omit<TooltipProps, 'children'> & {
  title: string;
  description: string;
  sx?: SystemCssProperties;
  fontSize?: string;
};

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '2px',
  color: alpha(theme.palette.primary.main, 0.8),
  cursor: 'default'
}));

const TooltipInputInfo = (props: TooltipInputProps) => {
  const { description, title, fontSize, sx, ...other } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => {
      return {
        [`& .${tooltipClasses.tooltip}`]: sx
          ? {
              ...sx,
              ...((sx.fontSize || fontSize) && { fontSize: sx.fontSize ? sx.fontSize : fontSize })
            }
          : {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              ...(fontSize != null ? { fontSize } : { fontSize: '1rem' })
            },
        [`& .${tooltipClasses.arrow}`]: sx ? { color: sx.backgroundColor } : { color: theme.palette.primary.main }
      };
    }
  );
  return (
    <InfoBox onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
        <CustomTooltip
          {...other}
          open={open}
          // onClose={handleClose}
          // onOpen={handleOpen}
          arrow
          placement="bottom-start"
          title={description}
          disableHoverListener
        >
          <InfoOutlined sx={{ fontSize: '18px' }} />
        </CustomTooltip>
        <FormHelperText style={{ color: 'inherit', fontSize: '.8rem', margin: 0 }}>{title}</FormHelperText>
      </Box>
    </InfoBox>
  );
};

export default TooltipInputInfo;
