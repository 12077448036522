import { Box, Fade, Grid } from '@mui/material';
import { commonHeaderHeightMobile } from './Header';

const IllustrationCommonLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={(theme) => ({
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        bgcolor: theme.palette.secondary.main,
        order: { xs: 0, sm: 1 },
        pt: { xs: `${commonHeaderHeightMobile}px`, sm: 0 },
        pb: { xs: 3, md: 0 },
        px: 2
      })}
    >
      <Fade in>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            img: {
              width: '100%',
              maxHeight: { xs: 300, md: 400, xxl: 500 },
              objectFit: 'contain',
              objectPosition: 'center center'
            }
          }}
        >
          {children}
        </Box>
      </Fade>
    </Grid>
  );
};

export default IllustrationCommonLayout;
