import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const DashboardNegativeIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
      <g clipPath="url(#clip0_635_192440)">
        <path d="M3.40346 3.40356H0V21.1782H3.40346H24V14.705V7.16808V3.40356H3.40346Z" fill="#F0FF32" />
        <path d="M3.40344 0V3.40346H24C24 1.52363 22.4761 0 20.5965 0H3.40344Z" fill="#91DCF9" />
        <path d="M3.40345 0H0V3.40346H3.40345V0Z" fill="#1ED367" />
        <path d="M21.3211 10.1201H14.9841V19.0435H21.3211V10.1201Z" fill="#91DCF9" />
        <path d="M12.6794 15.8496H2.93774V19.0435H12.6794V15.8496Z" fill="#91DCF9" />
        <path d="M21.3211 5.72974H3.40344V8.11267H21.3211V5.72974Z" fill="#91DCF9" />
        <path
          d="M4.89818 14.041C5.98089 14.041 6.85861 13.1633 6.85861 12.0805C6.85861 10.9978 5.98089 10.1201 4.89818 10.1201C3.81546 10.1201 2.93774 10.9978 2.93774 12.0805C2.93774 13.1633 3.81546 14.041 4.89818 14.041Z"
          fill="#094754"
        />
        <path
          d="M10.7192 14.041C11.8019 14.041 12.6797 13.1633 12.6797 12.0805C12.6797 10.9978 11.8019 10.1201 10.7192 10.1201C9.6365 10.1201 8.75879 10.9978 8.75879 12.0805C8.75879 13.1633 9.6365 14.041 10.7192 14.041Z"
          fill="#094754"
        />
      </g>
      <defs>
        <clipPath id="clip0_635_192440">
          <rect width="24" height="21.1781" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default DashboardNegativeIcon;
