import formatNumber from '../../../../../../utils/formatNumber';
import { Box, Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { OrderDTO, OrderPurchaseConfiguration, OrderRentConfiguration } from '../../../../../../types/dto/orders.dto';

type TotalReviewProps = {
  order: Pick<OrderDTO, 'shippingNotes'> & {
    userConfiguration: OrderDTO['activeConfiguration'] | OrderPurchaseConfiguration | OrderRentConfiguration;
  };
};

const TotalReview = ({ order }: TotalReviewProps) => {
  // const configuration = order.activeConfiguration != null ? order.activeConfiguration : order.orderConfigurations[0];
  return (
    <>
      <Grid item xs={12} md={6}>
        {order.userConfiguration.quotesExtraInfo != null && (
          <Box mb={2}>
            <Typography variant={'body1'} fontWeight={500} color={'text.disabled'} gutterBottom>
              Nota sui preventivi:
            </Typography>
            <Typography variant={'body2'}>{order.userConfiguration.quotesExtraInfo}</Typography>
          </Box>
        )}

        {order.shippingNotes != null && (
          <Box>
            <Typography variant={'body1'} color={'text.disabled'} fontWeight={500} gutterBottom>
              Nota sulla spedizione:
            </Typography>
            <Typography variant={'body2'}>{order.shippingNotes}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Box ml={{ xs: 0, md: 3 }}>
          <Typography variant={'h6'} fontWeight={400} color={'text.disabled'} mb={2}>
            Totale
          </Typography>
          <Grid container maxWidth={500}>
            <Grid item xs={8}>
              <Typography variant={'body1'} fontWeight={600}>
                Licenza buyplus
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'body1'} fontWeight={600} sx={{ textDecoration: 'line-through' }}>
                0,5%
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant={'body1'} fontWeight={600}>
                TOTALE
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'body1'} fontWeight={600}>
                {formatNumber(BigNumber(order.userConfiguration.total).toNumber(), { fractionDigits: 2 })} €
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default TotalReview;
