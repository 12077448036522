import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { Box, Fade, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TooltipInputInfo from '../../components/custom/TooltipInputInfo';
import { PatternFormat } from 'react-number-format';
import LoadingButton from '../../components/custom/LoadingButton';
import { useSelector } from '../../store';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useEndpoint } from '../../hooks/useEndpoint';
import { SingleDataDTO } from '../../types/server/single-data';
import { FullBuildingDTO } from '../../types/dataToSend/building';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import ContentCommonLayout from '../../layout/CommonLayout/ContentCommonLayout';
import IllustrationCommonLayout from '../../layout/CommonLayout/IllustrationCommonLayout';
import IllustrationSignUp from 'assets/images/illustrations/auth/sign_up.svg';
import { useLocation } from 'react-router-dom';
import { formattingPath } from '../../hooks/useGaPageView';

const CompleteFastRegister = () => {
  const { completeFastRegister, authTokenLogin } = useAuth();
  const { trackAction, trackPageView } = useNavigationTracker();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const buildingData = useEndpoint<SingleDataDTO<FullBuildingDTO>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${user?.building.id}`,
    queryKey: 'building-register-data',
    options: {
      enabled: true,
      cacheTime: 0
    }
  });

  useEffect(() => {
    if (user != null && user.firstname != null && user.lastname != null) {
      navigate(-1);
    } else {
      trackPageView({
        path: pathname,
        name: formattingPath(pathname)
      });
    }
  }, []);

  if (user == null || buildingData.data?.data.data.lastEdit == null) return <></>;

  return (
    <>
      <ContentCommonLayout>
        <Fade in>
          <Box maxWidth={{ xs: 600, xxl: 800 }} marginX={'auto'}>
            <Box>
              <Typography variant="h4" color="primary">
                <FormattedMessage id="title-complete-fast-register" />
              </Typography>
              <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
                <FormattedMessage id="description-register" />
              </Typography>
            </Box>
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                tag: '',
                structureName: user.building.name ?? '',
                companyName: '',
                phone: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().required('firstname-error-required'),
                lastname: Yup.string().required('lastname-error-required'),
                tag: Yup.string().required('tag-error-required'),
                structureName: Yup.string().max(255).required('structureName-error-required'),
                companyName: Yup.string().max(255).required('companyName-error-required'),
                phone: Yup.string().required('phone-error-required')
              })}
              onSubmit={async (values, { setErrors }) => {
                trackAction({
                  type: ClientTypeEnum.Form,
                  action: ClientActionEnum.Submit,
                  name: 'Ha integrato i dati mancanti della registrazione fast'
                });
                try {
                  await completeFastRegister({
                    firstname: values.firstname,
                    lastname: values.lastname,
                    tag: values.tag,
                    phone: values.phone,
                    businessName: values.companyName,
                    userlastEdit: user.lastEdit,
                    buildingLastEdit: buildingData.data!.data.data.lastEdit,
                    name: values.structureName
                  });
                  await authTokenLogin();
                  navigate('/building-register', { replace: true });
                } catch (err) {
                  if (!axios.isAxiosError(err)) return;
                  let errorMessage: string;
                  if (err.response?.data.statusCode === 500) {
                    errorMessage = "C'è stato un errore nel server, riprovare più tardi.";
                    setErrors({ submit: errorMessage });
                  } else if (err.response?.data.statusCode === 400) {
                    const errors: { phone?: string; vat?: string } = {};
                    err.response.data.message.forEach((message: string) => {
                      if (message === 'numero di telefono non disponibile') {
                        errors.phone = 'error-phone-alredy-exists';
                      } else if (message === 'partita iva non disponibile') {
                        errors.vat = 'error-vat-alredy-exists';
                      } else if (message === 'user.il numero di telefono deve essere valido') {
                        errors.phone = 'phone-error-invalid';
                      } else {
                        console.error(message);
                      }
                    });
                    setErrors(errors);
                  }
                }
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3} paddingY={5}>
                    {/* Firstname */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={<FormattedMessage id="firstname-label" />}
                        variant="outlined"
                        type="text"
                        name="firstname"
                        value={values.firstname}
                        onBlur={(e) => {
                          handleBlur(e);
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha inserito il nome: ${values.firstname}`
                          });
                        }}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(errors.firstname && touched.firstname)}
                      />
                      {touched.firstname && errors.firstname && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.firstname} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Lastname */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={<FormattedMessage id="lastname-label" />}
                        variant="outlined"
                        type="text"
                        name="lastname"
                        value={values.lastname}
                        onBlur={(e) => {
                          handleBlur(e);
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha inserito il cognome: ${values.lastname}`
                          });
                        }}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(errors.lastname && touched.lastname)}
                      />
                      {touched.lastname && errors.lastname && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.lastname} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Tag */}
                    <Grid item xs={12}>
                      <TextField
                        label={<FormattedMessage id="tag-label" />}
                        variant="outlined"
                        type="text"
                        name="tag"
                        value={values.tag}
                        onBlur={(e) => {
                          handleBlur(e);
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha inserito il ruolo: ${values.tag}`
                          });
                        }}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(errors.tag && touched.tag)}
                      />
                      {touched.tag && errors.tag && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.tag} />
                        </FormHelperText>
                      )}
                      <TooltipInputInfo
                        title="Maggiori informazioni"
                        description="Inserisci il tuo ruolo lavorativo all'interno della struttura"
                        fontSize="1rem"
                      />
                    </Grid>

                    {/* Structure Name */}
                    <Grid item xs={12}>
                      <TextField
                        label={<FormattedMessage id="structureName-label" />}
                        variant="outlined"
                        id="structure-name-register"
                        type="text"
                        name="structureName"
                        value={values.structureName}
                        onBlur={(e) => {
                          handleBlur(e);
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha inserito il nome della struttura: ${values.structureName}`
                          });
                        }}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(touched.structureName && errors.structureName)}
                      />
                      {touched.structureName && errors.structureName && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.structureName} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Company Name */}
                    <Grid item xs={12}>
                      <TextField
                        label={<FormattedMessage id="companyName-label" />}
                        variant="outlined"
                        id="comapany-name-register"
                        type="text"
                        name="companyName"
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha inserito la ragione sociale: ${values.companyName}`
                          });
                        }}
                        fullWidth
                        error={Boolean(touched.companyName && errors.companyName)}
                      />
                      {touched.companyName && errors.companyName && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.companyName} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Phone */}
                    <Grid item xs={12}>
                      <PatternFormat
                        id="phone-register"
                        onBlur={(e) => {
                          handleBlur(e);
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha inserito il numero di telefono: ${values.phone}`
                          });
                        }}
                        label={<FormattedMessage id="phone-label" />}
                        name="phone"
                        variant="outlined"
                        error={Boolean(touched.phone && errors.phone)}
                        value={values.phone}
                        format="+39##########"
                        onChange={handleChange}
                        fullWidth
                        customInput={TextField}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.phone} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Button Submit */}
                    {errors.submit && (
                      <Grid item xs={12}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LoadingButton
                        loading={isSubmitting}
                        children={<FormattedMessage id="register-button" />}
                        // disabled={Object.keys(errors).length !== 0 && !Object.keys(errors).includes('submit')}
                        size={'large'}
                        variant="contained"
                        type="submit"
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Fade>
      </ContentCommonLayout>
      <IllustrationCommonLayout>
        <Fade in>
          <img src={IllustrationSignUp} alt={'illustration complete fast register'} />
        </Fade>
        <Typography variant="h6" fontWeight={400} maxWidth={400} mt={10} textAlign={'center'}>
          Continua <span style={{ fontWeight: 600 }}>la compilazione dei dati</span> e inizia ad usare buyplus
        </Typography>
      </IllustrationCommonLayout>
    </>
  );
};

export default CompleteFastRegister;
