// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { RouteObject } from 'react-router';
import { lazy } from 'react';
import CompleteFastRegister from '../pages/auth/complete-fast-register';
import { Websocket } from '../components/Websocket';
import GenerateTestWalletTab from '../sections/test/GenerateTestWalletTab';
import TestWalletGenerateTab from '../sections/test/TestWalletGenerateTab';
import BuildingInfoTab from '../sections/settings/Tabs/BuildingInfoTab';
import NewDashboardPage from '../pages/dashboard/NewDashboardPage';
import TourNavigation from '../components/custom/TourNavigation';
import NewOrderStep1 from '../sections/new-order/NewOrderStep1';
import NewOrderStep2 from '../sections/new-order/NewOrderStep2';
import NewOrderStep3 from '../sections/new-order/NewOrderStep3';
import NewOrderStep4 from '../sections/new-order/NewOrderStep4';
import NewOrderStep5 from '../sections/new-order/NewOrderStep5';
import NewOrderStep6 from '../sections/new-order/NewOrderStep6';
const TestWallet = lazy(() => import('pages/extra-pages/TestWallet'));
const TestOrders = lazy(() => import('pages/extra-pages/TestOrders'));

const SamplePage = lazy(() => import('pages/extra-pages/sample-page'));
const AllOrdersPage = lazy(() => import('pages/orders/AllOrdersPage'));
const NewOrderPage = lazy(() => import('pages/orders/NewOrderPage'));
const OrderDetailsPage = lazy(() => import('pages/orders/OrderDetailsPage'));
const WalletPage = lazy(() => import('pages/wallet/WalletPage'));
const SettingsPage = lazy(() => import('pages/account/SettingsPage'));
const DocumentsPage = lazy(() => import('pages/account/DocumentsPage'));
const ContactsPage = lazy(() => import('pages/contacts/ContactsPage'));
// const SupportTicketPage = lazy(() => import('pages/support/SupportTicketPage'));
// const SupportFaqsPage = lazy(() => import('pages/support/SupportFaqsPage'));
const SupportHelpCenter = lazy(() => import('pages/support/SupportHelpCenter'));
// const DashboardPage = lazy(() => import('pages/dashboard/DashboardPage'));
const NotificationsPage = lazy(() => import('pages/notifications/NotificationsPage'));
const SettingsTab = lazy(() => import('sections/settings/Tabs/SettingsTab'));
const AddressTab = lazy(() => import('sections/settings/Tabs/AddressTab'));
// const PrivacyTab = lazy(() => import('sections/settings/Tabs/PrivacyTab'));
const BillingTab = lazy(() => import('sections/settings/Tabs/BillingTab'));

const AuthDataIntegrationChoice = lazy(() => import('sections/auth/auth-forms/Register/AuthDataIntegrationChoice'));
const AuthPMSIntegration = lazy(() => import('sections/auth/auth-forms/PmsIntegration/AuthPMSIntegration'));
const AuthManualIntegrationSteps = lazy(() => import('sections/auth/auth-forms/ManualIntegration/AuthManualIntegrationSteps'));
const ManualIntegrationStep1 = lazy(
  () => import('sections/auth/auth-forms/ManualIntegration/StepsManualIntegration/ManualIntegrationStep1')
);
const ManualIntegrationStep2 = lazy(
  () => import('sections/auth/auth-forms/ManualIntegration/StepsManualIntegration/ManualIntegrationStep2')
);
const ManualIntegrationStep3 = lazy(
  () => import('sections/auth/auth-forms/ManualIntegration/StepsManualIntegration/ManualIntegrationStep3')
);
const ManualIntegrationStep4 = lazy(
  () => import('sections/auth/auth-forms/ManualIntegration/StepsManualIntegration/ManualIntegrationStep4')
);

const PmsIntegrationStep1 = lazy(() => import('sections/auth/auth-forms/PmsIntegration/StepsPmsIntegration/PmsIntegrationStep1'));
const PmsIntegrationStep2 = lazy(() => import('sections/auth/auth-forms/PmsIntegration/StepsPmsIntegration/PmsIntegrationStep2'));
const PmsIntegrationStep3 = lazy(() => import('sections/auth/auth-forms/PmsIntegration/StepsPmsIntegration/PmsIntegrationStep3'));
const PmsIntegrationStep4 = lazy(() => import('sections/auth/auth-forms/PmsIntegration/StepsPmsIntegration/PmsIntegrationStep4'));
const PmsIntegrationStep5 = lazy(() => import('sections/auth/auth-forms/PmsIntegration/StepsPmsIntegration/PmsIntegrationStep5'));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes: RouteObject = {
  path: '/',
  children: [
    {
      path: '',
      element: (
        <AuthGuard>
          <Websocket>
            <TourNavigation>
              <MainLayout />
            </TourNavigation>
          </Websocket>
        </AuthGuard>
      ),
      children: [
        {
          path: 'sample-page',
          element: <SamplePage />
        },
        {
          path: 'dashboard',
          element: <NewDashboardPage />
          // element: <DashboardPage />
        },
        // {
        //   path: 'dashboard-prova',
        //   element: <NewDashboardPage />
        // },
        {
          path: 'wallet',
          element: <WalletPage />
        },
        {
          path: 'orders',
          element: <AllOrdersPage />,

          children: [
            {
              path: ':code/:title',
              element: <OrderDetailsPage />
            },
            {
              path: 'new-order/:code?/:title?',
              element: <NewOrderPage />,
              children: [
                {
                  path: '',
                  element: <NewOrderStep1 />
                },
                {
                  path: 'step-2',
                  element: <NewOrderStep2 />
                },
                {
                  path: 'step-3',
                  element: <NewOrderStep3 />
                },
                {
                  path: 'step-4',
                  element: <NewOrderStep4 />
                },
                {
                  path: 'step-5',
                  element: <NewOrderStep5 />
                },
                {
                  path: 'step-6',
                  element: <NewOrderStep6 />
                }
              ]
            }
          ]
        },
        {
          path: 'account',
          element: <SettingsPage />,
          children: [
            {
              path: 'settings',
              element: <SettingsTab />
            },
            {
              path: 'address',
              element: <AddressTab />
            },
            {
              path: 'building',
              element: <BuildingInfoTab />
            },
            // {
            //   path: 'preferences',
            //   element: <PreferencesTab />
            // },
            // {
            //   path: 'privacy',
            //   element: <PrivacyTab />
            // },
            {
              path: 'billing',
              element: <BillingTab />
            }
          ]
        },
        {
          path: 'account/documents',
          element: <DocumentsPage />
        },
        {
          path: 'contacts',
          element: <ContactsPage />
        },
        // {
        //   path: 'support/ticket',
        //   element: <SupportTicketPage />
        // },
        {
          path: 'support/faqs',
          element: <SupportHelpCenter />
        },
        // {
        //   path: 'support/orders',
        //   element: <SupportOrdersPage />
        // },
        // {
        //   path: 'support/help-center',
        //   element: <SupportFaqsPage />
        // },
        // {
        //   path: 'support/faqs/:key',
        //   element: <FaqCategoryPage />
        // },
        {
          path: 'notifications',
          element: <NotificationsPage />
        }
      ]
    },
    {
      path: 'complete-fast-register',
      element: (
        <AuthGuard>
          <CommonLayout hasHolderBar={true} />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <CompleteFastRegister />
        }
      ]
    },
    {
      path: 'building-register',
      element: (
        <AuthGuard>
          <Websocket>
            <CommonLayout hasHolderBar={true} />
          </Websocket>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <AuthDataIntegrationChoice />
        },
        {
          path: 'pms-integration',
          element: <AuthPMSIntegration />,
          children: [
            {
              path: '',
              element: <PmsIntegrationStep1 />
            },
            {
              path: 'step-2',
              element: <PmsIntegrationStep2 />
            },
            {
              path: 'step-3',
              element: <PmsIntegrationStep3 />
            },
            {
              path: 'step-4',
              element: <PmsIntegrationStep4 />
            },
            {
              path: 'step-5',
              element: <PmsIntegrationStep5 />
            }
          ]
        },
        {
          path: 'manual-integration',
          element: <AuthManualIntegrationSteps />,
          children: [
            {
              path: '',
              element: <ManualIntegrationStep1 />
            },
            {
              path: 'step-2',
              element: <ManualIntegrationStep2 />
            },
            {
              path: 'step-3',
              element: <ManualIntegrationStep3 />
            },
            {
              path: 'step-4',
              element: <ManualIntegrationStep4 />
            }
          ]
        },
        {
          path: 'report-integration',
          element: <AuthManualIntegrationSteps />,
          children: [
            {
              path: '',
              element: <ManualIntegrationStep1 />
            },
            {
              path: 'step-2',
              element: <ManualIntegrationStep2 />
            },
            {
              path: 'step-3',
              element: <ManualIntegrationStep3 />
            },
            {
              path: 'step-4',
              element: <ManualIntegrationStep4 />
            }
          ]
        }
      ]
    },
    {
      path: 'test',
      element: (
        <AuthGuard>
          <CommonLayout hasHolderBar={true} testMode={true} />
        </AuthGuard>
      ),
      children: [
        {
          path: 'wallet',
          element: <TestWallet />,
          children: [
            {
              path: '',
              element: <TestWalletGenerateTab />
            },
            {
              path: 'generate',
              element: <GenerateTestWalletTab />
            }
          ]
        },
        {
          path: 'orders',
          element: (
            <TestOrders>
              <AllOrdersPage testMode={true} />
            </TestOrders>
          ),
          children: [
            // {
            //   path: ':code',
            //   element: <OrderDetailsPage />
            // },
            {
              path: 'new-order/:code?/:title?',
              element: <NewOrderPage testMode={true} />
            }
          ]
        }
      ]
    }
  ]
};
export default MainRoutes;
