import { CircularProgress, useTheme } from '@mui/material';
import { Box, Typography, alpha, styled } from '@mui/material';
import DragDropIcon from 'assets/images/icons/BP-icons-positivo_drag_drop.svg';
import React, { useState } from 'react';
import getFileSizeInMb from 'utils/getSizeFileInMb';

const BoxUpload = styled(Box)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  textAlign: 'center',
  maxWidth: '800px',
  margin: '0 auto',
  height: '180px',
  //   border: `1px dashed ${alpha(theme.palette.primary.main, 0.5)}`,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffff',
  img: {
    width: 60,
    objectFit: 'contain'
  }
}));
type BoxUploadProps<D extends File | File[]> = {
  name: string;
  multiple: boolean;
  files: D | undefined;
  setFileInput: (file: D) => void;
  acceptedFiles: string[];
  acceptedFilesLabel: string;
  maxFileSize: number;
  isLoading?: boolean;
};

const BoxUploadFile = <D extends File | File[] = File>({
  multiple,
  setFileInput,
  files,
  name,
  acceptedFiles,
  acceptedFilesLabel,
  maxFileSize,
  isLoading
}: BoxUploadProps<D>) => {
  const theme = useTheme();
  const [errorTypeFile, setErrorTypeFile] = useState<string>();
  const [errorSizeFile, setErrorSizeFile] = useState<string>();
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleUploadFile = (file: File | File[]) => {
    setErrorSizeFile(undefined);
    setErrorTypeFile(undefined);
    console.log(file);
    if (!Array.isArray(file)) {
      if (acceptedFiles.includes(file.type)) {
        if (getFileSizeInMb(file.size) < maxFileSize) {
          if (!multiple) {
            //@ts-ignore
            setFileInput(file);
          } else {
            //@ts-ignore
            setFileInput([...(files ?? []), file]);
          }
        } else {
          setErrorSizeFile(`La dimensione del file non puo essere superiore a ${maxFileSize}MB`);
        }
      } else {
        setErrorTypeFile(`Il tipo del file ${file.name} non è accettato`);
      }
    } else {
      const newFiles: File[] = [];
      const errorsSize: string[] = [];
      const errorType: string[] = [];

      for (const f of file) {
        if (!acceptedFiles.includes(f.type)) {
          errorType.push(f.name);
        } else if (getFileSizeInMb(f.size) >= maxFileSize) {
          errorsSize.push(f.name);
        } else {
          newFiles.push(f);
        }
      }

      if (errorsSize.length > 0) {
        const message =
          errorsSize.length === 1
            ? `Il file ${errorsSize[0]} supera la dimensione massima di ${maxFileSize}MB`
            : `I file ${errorsSize.join(', ')} superano la dimensione massima di ${maxFileSize}MB`;
        setErrorSizeFile(message);
      }

      if (errorType.length > 0) {
        const message =
          errorType.length === 1
            ? `Il file ${errorType[0]} ha un formato non consentito`
            : `I file ${errorType.join(', ')} hanno un formato non consentito`;
        setErrorTypeFile(message);
      }
      // @ts-ignore
      setFileInput([...newFiles, ...files]);
    }
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files.length === 1) {
      const file = e.dataTransfer.files[0];
      handleUploadFile(file);
    } else {
      handleUploadFile(Array.from(e.dataTransfer.files));
    }
  };

  return (
    <BoxUpload
      onClick={() => {
        document.getElementById(`upload-file-${name}`)?.click();
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDropFile}
      style={{
        border: !dragging ? `1px dashed ${alpha(theme.palette.primary.main, 0.5)}` : `1px dashed ${alpha(theme.palette.primary.main, 1)}`,
        backgroundColor: !dragging ? '#ffff' : alpha(theme.palette.primary.main, 0.05)
      }}
    >
      <input
        style={{ display: 'none' }}
        {...(multiple && { multiple: true })}
        id={`upload-file-${name}`}
        type="file"
        disabled={isLoading}
        accept={acceptedFiles.join(',')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (multiple && e.target.files !== null && e.target.files.length > 1) {
            handleUploadFile(Array.from(e.target.files));
          } else if (e.target.files !== null) {
            handleUploadFile(e.target.files[0]);
          }
          e.target.value = '';
        }}
      />
      <Box>
        {!isLoading ? <img src={DragDropIcon} alt="drag and drop icon" /> : <CircularProgress />}

        <Box>
          <Box display={'flex'} gap={1} justifyContent={'center'} mb={1}>
            <Typography fontSize={'1rem'} fontWeight={600} sx={{ textDecoration: 'underline' }}>
              Clicca per caricare
            </Typography>
            <Typography fontWeight={400}>o trascina e rilascia</Typography>
          </Box>
          <Typography fontWeight={400} fontSize={'.8rem'} maxWidth={270} marginX={'auto'} color={alpha(theme.palette.text.secondary, 0.6)}>
            {acceptedFilesLabel} (max. {maxFileSize}MB)
          </Typography>
        </Box>
        {errorTypeFile != null && (
          <Typography variant="body2" color={'error'}>
            {errorTypeFile}
          </Typography>
        )}
        {errorSizeFile != null && (
          <Typography variant="body2" color={'error'}>
            {errorSizeFile}
          </Typography>
        )}
      </Box>
    </BoxUpload>
  );
};

export default BoxUploadFile;
