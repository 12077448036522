import { NavItemType } from 'types/menu';
import dashboard from './dashboard';
import wallet from './wallet';
import newOrder from './new-order';
import orders from './orders';
import account from './account';
import contacts from './contacts';
import support from './support';
// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, wallet, orders, account, support, contacts, newOrder]
};

export default menuItems;
