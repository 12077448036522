import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const UploadArrowUp = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.97461 8.9231L4.02307 9.97156L8.17975 5.82232V14.8718H9.66693V5.82232L13.8162 9.979L14.8721 8.9231L8.92334 2.97437L2.97461 8.9231Z"
        fill="#B6BFC1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23092 13.3848L2.23092 17.8463H15.6155V13.3848L17.1027 13.3848V19.3335H17.1025V19.3335H1.4871V19.3335H0.743741L0.743741 13.3848L2.23092 13.3848Z"
        fill="#B6BFC1"
      />
    </svg>
  </SvgIcon>
);

export default UploadArrowUp;
