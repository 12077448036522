// third-party
import ChannelManagerIcon from 'components/custom/BuyPlusIcons/ChannelManagerIcon';
import ChatNegativeIcon from 'components/custom/BuyPlusIcons/ChatNegativeIcon';
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const contacts: NavItemType = {
  id: 'contacts',
  title: '',
  type: 'group',
  children: [
    {
      id: 'contacts',
      title: <FormattedMessage id="contacts" />,
      type: 'item',
      url: '/contacts',
      icon: [ChannelManagerIcon, ChatNegativeIcon]
    }
  ]
};

export default contacts;
