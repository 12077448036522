import React, { createContext, JSX, useState } from 'react';
import { Box, Drawer, IconButton, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
const BlogPlafondDrawerStyle = styled(Drawer)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: '#FFFFFF',
    borderTop: `4px solid ${theme.palette.secondary.main}`,
    width: '100%',
    maxWidth: 700,
    boxSizing: 'border-box',
    backgroundImage: 'none',
    boxShadow: 'inherit'
  }
}));

type DrawerContextType = {
  openDrawer: ({ content, title }: { content: JSX.Element; title: string }) => void;
  closeDrawer: () => void;
};
export const DrawerContext = createContext<DrawerContextType | null>(null);
const DrawerProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<JSX.Element>();

  const closeDrawer = () => {
    setOpen(false);
  };

  const openDrawer: DrawerContextType['openDrawer'] = ({ title, content }) => {
    setTitle(title);
    setContent(content);
    setOpen(true);
  };

  return (
    <DrawerContext.Provider
      value={{
        closeDrawer,
        openDrawer
      }}
    >
      {children}
      <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1100 }} aria-label="blog plafond folders">
        <BlogPlafondDrawerStyle
          anchor="right"
          variant="temporary"
          open={open}
          onClose={() => closeDrawer()}
          ModalProps={{ keepMounted: true }}
        >
          <Box
            width={'100%'}
            whiteSpace={'normal'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            paddingY={'15px'}
            paddingX={'30px'}
          >
            <Box mb={2} display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <Typography variant="h4" whiteSpace={'normal'}>
                {title}
              </Typography>
              <IconButton onClick={() => closeDrawer()}>
                <Close />
              </IconButton>
            </Box>

            <Box flexGrow={1}>{content}</Box>
            {/*<div dangerouslySetInnerHTML={{ __html: content ?? 'Nessuna descrizione' }} />*/}
            {/*{content}*/}
          </Box>
        </BlogPlafondDrawerStyle>
      </Box>
    </DrawerContext.Provider>
  );
};

export default React.memo(DrawerProvider);
