// material-ui
import { Components, Theme } from '@mui/material/styles';

// project import
// import getColors from 'utils/getColors';

// types
// import { ExtendedStyleProps } from 'types/extended';

// ==============================|| ALERT - COLORS ||============================== //

// function getColorStyle({ color, theme }: ExtendedStyleProps) {
//   const colors = getColors(theme, color);
//   const { lighter, light, main } = colors;

//   return {
//     borderColor: alpha(light, 0.5),
//     backgroundColor: lighter,
//     '& .MuiAlert-icon': {
//       color: main
//     }
//   };
// }

// ==============================|| OVERRIDES - ALERT ||============================== //

export default function Alert(theme: Theme) {
  // const primaryDashed = getColorStyle({ color: 'primary', theme });

  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: '500',
          // color: '#ffff',
          // alignItems: 'center',
          // paddingRight: '16px!important',
          // minWidth: 300
          padding: '6px 16px 6px 16px'
        },
        standard: {
          '&.MuiAlert-standardSuccess': {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.text.primary,
            borderTop: `4px solid ${theme.palette.success.main}`,
            borderRadius: '0px 0px 10px 10px'
          },
          '&.MuiAlert-standardError': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.common.white,
            borderTop: `4px solid ${theme.palette.error.main}`,
            borderRadius: '0px 0px 10px 10px'
          },
          '&.MuiAlert-standardInfo': {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.primary.main,
            borderTop: `4px solid ${theme.palette.info.main}`,
            borderRadius: '0px 0px 10px 10px'
          }
        },
        message: {
          flexGrow: 1
        },
        action: {
          margin: 0,
          padding: 0,
          // display: 'flex',
          alignItems: 'center',
          '& .MuiButton-root': {
            // marginTop: 1
          },
          //Icona del close
          '& .MuiIconButton-root': {}
        }
      }
    } as Components['MuiAlert']
  };
}
