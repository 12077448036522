import { Box, Button, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import MainCard from '../../components/custom/Cards/MainCard';
import { OrderListDto } from '../../types/dto/order-list.dto';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import OrderChip from '../../components/custom/Chips/OrderChip';

type BoxOrdersProps = {
  orders: OrderListDto[];
};

const BoxOrders = (props: BoxOrdersProps) => {
  const { orders } = props;
  const theme = useTheme();
  const isDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <MainCard
      title={
        <Typography variant={'body1'} fontWeight={600} textTransform={'uppercase'}>
          i tuoi ordini
        </Typography>
      }
      action={
        <Button size={'small'} variant={'outlined'} component={Link} to={'/orders'}>
          Vedi tutti
        </Button>
      }
    >
      <Grid container mt={1} spacing={3}>
        {orders.map((order, i) => {
          return (
            <Grid
              key={order.code}
              item
              xs={12}
              sm={4}
              height={'100%'}
              display={'flex'}
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              justifyContent={'space-between'}
            >
              <Box height={'100%'}>
                <Typography
                  variant={'body1'}
                  fontWeight={500}
                  mb={2}
                  sx={{ maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {order.title}
                </Typography>

                <Typography
                  variant={'body2'}
                  gutterBottom
                  sx={{ maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {order.orderCategory.name}
                </Typography>
                <Typography variant={'body2'} mb={2}>
                  {dayjs(order.createdAt).format('DD/MM/YYYY')}
                </Typography>

                <OrderChip state={order.state.key} isProduct={order.orderCategory.isProduct} />
              </Box>
              {i < 2 && (
                <>
                  {!isDownSM ? (
                    <Divider variant={'middle'} orientation={'vertical'} />
                  ) : (
                    <Divider variant={'fullWidth'} orientation={'horizontal'} sx={{ width: '100%', mt: 1 }} />
                  )}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </MainCard>
  );
};

export default BoxOrders;
