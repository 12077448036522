// material-ui
import { Badge, Box, IconButton } from '@mui/material';

// project import
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'store';

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //
type NotificationProps = {
  handleDrawerNotificationToggle: () => void;
};

const Notification = ({ handleDrawerNotificationToggle }: NotificationProps) => {
  const { notificationCount } = useSelector((state) => state.websocket);
  return (
    <Box sx={{ flexShrink: 0, ml: 1 }}>
      <IconButton
        id={'notification_button'}
        color="info"
        size="medium"
        aria-label="open notification"
        aria-haspopup="true"
        onClick={() => handleDrawerNotificationToggle()}
      >
        <Badge badgeContent={notificationCount} color="error" showZero={false}>
          <NotificationsIcon fontSize="medium" />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default Notification;
