import { alpha, Box, Button, Fade, Grid, styled, Tab, Tabs, Typography } from '@mui/material';
import WalletCard from 'components/custom/Cards/WalletCard';
import PromoCard from 'components/custom/Cta/PromoCard';
import FaqList from 'components/custom/Cards/Faq/FaqList';
import { dispatch, useSelector } from 'store';
import { useEffect, useState } from 'react';
import { SingleDataDTO } from 'types/server/single-data';
import { WalletDTO } from 'types/dto/plafond.dto';
import PlafondCard from 'components/custom/Cards/PlafondCard';
import { useEndpoint } from '../../hooks/useEndpoint';

import WalletSkeletons from '../../components/custom/Skeletons/WalletPageSkeleton';
import { Link } from 'react-router-dom';
import { setRefetchOrders, setRefetchWallets } from '../../store/reducers/websocket';
import AlertDeletedAccount from '../../sections/settings/AlertDeletedAccount';
import NewOrderCta from '../../components/custom/Cta/NewOrderCta';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import { CountedData } from '../../types/server/counted-data';
import { FaqDto, FaqSection } from '../../types/dto/faq.dto';
import UploadIcon from '../../components/custom/BuyPlusIcons/UploadIcon';
import getExtraDocumentsToUpload from '../../utils/getExtraDocumentsToUpload';
import { BuildingRegistrationMethodEnum } from '../../types/dto/building.dto';
import BoxActivity from '../../sections/dashboard/BoxActivity';
import { useTour } from '@reactour/tour';
import { OrderListDto } from '../../types/dto/order-list.dto';
import { formatSorting } from '../../utils/filtersUtils/formatSorting';
import { ContactFormPresetEnum } from '../../utils/contactFormPreset';

const PlafondTab = styled(Tabs)(({ theme }) => ({
  background: 'none',
  '.MuiTab-root': {
    transition: 'all .3s',
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    }
  }
}));

type WalletPageProps = {
  testMode?: boolean;
};

const WalletPage = ({ testMode }: WalletPageProps) => {
  const { refetchWallets, refetchOrders } = useSelector((state) => state.websocket);
  const { user, holder } = useSelector((state) => state.auth);
  const { trackAction } = useNavigationTracker();
  const tour = useTour();
  const [activeWalletTab, setActiveWalletTab] = useState<'fast' | 'slow'>('fast');
  const [wallet, setWallet] = useState<WalletDTO[]>();
  const getWallet = useEndpoint<SingleDataDTO<WalletDTO[]>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${testMode ? holder?.holderBuilding?.id : user?.building.id}/wallets`,
    queryKey: 'get-wallets-wallet-page',
    queryParams: {
      mode: testMode ? 'test' : undefined
    },
    options: {
      cacheTime: 0,
      enabled: testMode && holder?.holderBuilding !== undefined ? true : user !== undefined,
      onSuccess: (data) => {
        setWallet(data.data.data);
      }
    }
  });
  const getLastDraftOrders = useEndpoint<CountedData<OrderListDto>, 'get'>({
    method: 'get',
    queryParams: {
      skip: 0,
      take: 4,
      orderBy: formatSorting([
        {
          id: 'createdAt',
          desc: true
        }
      ])
      // filterBy: JSON.stringify([
      //   {
      //     field: ['state', 'key'],
      //     value: {
      //       equals: 'draft'
      //     }
      //   }
      // ])
    },
    queryKey: 'get-last-draft-orders',
    endpoint: `buildings/${user!.building.id}/orders`,
    options: {
      enabled: true,
      cacheTime: 0
    }
  });

  const registrationProgress = useEndpoint<
    SingleDataDTO<{
      user: {
        total: number;
        count: number;
      };
      building: {
        total: number;
        count: number;
      };
    }>,
    'get'
  >({
    method: 'get',
    endpoint: 'auth/registration-progress',
    queryKey: 'registration-progress',
    options: {
      cacheTime: 0
    }
  });

  const documentsRequests = useEndpoint<SingleDataDTO<{ total: number; count: number }>, 'get'>({
    method: 'get',
    endpoint: `buildings/${user?.building.id}/documents-progress`,
    queryKey: 'get-documents-requests-count',
    options: {
      cacheTime: 0
    }
  });

  // const getBuildingCredit = useEndpoint<SingleDataDTO<GeneralWalletDto>, 'get'>({
  //   method: 'get',
  //   endpoint: `/buildings/${user?.building.id}/credit`,
  //   queryKey: 'get-building-credit'
  // });

  const getFaqs = useEndpoint<CountedData<FaqDto>, 'get'>({
    method: 'get',
    endpoint: '/faqs',
    queryKey: `get-faqs-wallet-page`,
    queryParams: {
      filterBy: JSON.stringify([{ field: 'sections', value: { equals: FaqSection.WALLET } }])
    },
    options: {
      enabled: !testMode
    }
  });

  useEffect(() => {
    if (refetchWallets) {
      getWallet.refetch().then(() => dispatch(setRefetchWallets(false)));
    }

    if (refetchOrders.refetch) {
      getLastDraftOrders.refetch().then(() => {
        dispatch(
          setRefetchOrders({
            refetch: false,
            subjects: null,
            userId: null
          })
        );
      });
    }
  }, [refetchWallets, refetchOrders]);

  useEffect(() => {
    if (tour.isOpen && tour.setSteps) {
      tour.setSteps([
        {
          selector: '#main',
          content: 'Questa è la pagina del tuo wallet, qui puoi visualizzare tutte le soluzioni di pagamento'
        },
        {
          selector: 'div[data-tour="wallet-box"]',
          content: "Questo è il riepilogo del tuo wallet, qui puoi controllare i plafond a disposizione e l'ammontare utilizzato"
        },
        {
          selector: '[data-tour="summary-plafond"]',
          content: 'Qui puoi consultare tutti i plafond a tua disposizione, con i relativi stati di attivazione, disponibilità e utilizzo',
          position: [1100, 20]
        },
        {
          selector: '[data-tour="activity-box"]',
          content: 'Qui trovi le attività da completare, lo stesso che hai nella dashboard'
        }
      ]);
      tour.setCurrentStep(0);
    }
  }, [tour.isOpen]);

  const fastWallet = wallet?.filter((w) => w.isQuick);
  const slowWallet = wallet?.filter((w) => !w.isQuick);

  return wallet != null &&
    fastWallet != null &&
    slowWallet != null &&
    getLastDraftOrders.data?.data.data != null &&
    registrationProgress.data?.data.data != null &&
    documentsRequests.data?.data.data != null &&
    (user != null || (holder?.holderBuilding !== undefined && testMode)) ? (
    <>
      <Fade in>
        <Grid container spacing={3}>
          {user?.deleteRequestAt != null && !testMode && (
            <Grid item xs={12}>
              <AlertDeletedAccount />
            </Grid>
          )}
          {/* Main Column */}
          <Grid item xs={12} md={!testMode ? 8 : 12}>
            <Grid container spacing={3}>
              {/* Wallet */}
              <Grid item xs={12}>
                <WalletCard
                  title="Wallet"
                  testMode={testMode}
                  wallet={wallet}
                  report={testMode ? false : user!.building.pmsExportsDoneAt != null}
                  registrationComplete={testMode ? true : user!.$.registrationCompleted}
                  registrationMethod={testMode ? BuildingRegistrationMethodEnum.Manual : user!.building.registrationMethod}
                  registrationConfirmed={testMode ? true : user!.building.registrationConfirmed}
                />
              </Grid>

              {/* I tuoi prodotti finanziari */}
              <Grid item xs={12} mt={2} data-tour="summary-plafond">
                <Grid container spacing={3}>
                  {/* Title prodotti finanziari */}
                  <Grid item xs={12}>
                    <Typography variant="h6" color={'primary'}>
                      Le soluzioni di pagamento
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <PlafondTab value={activeWalletTab} onChange={(event, value) => setActiveWalletTab(value)}>
                      <Tab label="Soluzioni rapide" value={'fast'} />
                      <Tab label="altre soluzioni" value={'slow'} />
                    </PlafondTab>
                  </Grid>

                  {activeWalletTab === 'fast' && (
                    <Grid item xs={12}>
                      <Fade in>
                        <Grid container spacing={2}>
                          {fastWallet.map((item, i) => (
                            <Grid item xs={12} md={6} key={i}>
                              <PlafondCard
                                registrationConfirmed={testMode ? true : user!.building.registrationConfirmed}
                                registrationMethod={testMode ? BuildingRegistrationMethodEnum.Manual : user!.building.registrationMethod}
                                testMode={testMode}
                                report={testMode ? false : user!.building.pmsExportsDoneAt != null}
                                plafond={item}
                                registrationCompleted={testMode ? true : user!.$.registrationCompleted}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Fade>
                    </Grid>
                  )}

                  {activeWalletTab === 'slow' && (
                    <Grid item xs={12}>
                      <Fade in>
                        <Grid container spacing={2}>
                          {slowWallet.map((item, i) => (
                            <Grid item xs={12} md={6} key={i}>
                              <PlafondCard
                                registrationConfirmed={testMode ? true : user!.building.registrationConfirmed}
                                registrationMethod={testMode ? BuildingRegistrationMethodEnum.Manual : user!.building.registrationMethod}
                                report={testMode ? false : user!.building.pmsExportsDoneAt != null}
                                plafond={item}
                                registrationCompleted={testMode ? true : user!.$.registrationCompleted}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Fade>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Column */}
          {!testMode && user !== null && (
            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                {/* Feed Activity */}
                <Grid item xs={12} data-tour={'activity-box'}>
                  {/*<ActivityBox*/}
                  {/*  title="completa le attività"*/}
                  {/*  actions={{*/}
                  {/*    $: user.$,*/}
                  {/*    building: user.building*/}
                  {/*  }}*/}
                  {/*/>*/}
                  <BoxActivity
                    isMini
                    registrationData={{
                      ...registrationProgress.data.data.data,
                      documents: documentsRequests.data.data.data,
                      registrationCompleted: user.$.registrationCompleted,
                      registrationMethod: user.building.registrationMethod,
                      registrationConfirmed: user.building.registrationConfirmed
                    }}
                    orderData={{
                      totalCount: +user.building.draftOrdersCount + +user.building.pendingOrdersCount,
                      pendingCount: +user.building.pendingOrdersCount,
                      lastOrder: getLastDraftOrders.data.data.data[0]
                    }}
                  />
                </Grid>

                {/* Box Nuovo Ordine */}
                <Grid item xs={12}>
                  <NewOrderCta
                    firstOrder={user.building.pendingOrdersCount === 0 && user.building.draftOrdersCount === 0}
                    isWaitingReport={user.building.pmsExportsDoneAt != null && !user.$.registrationCompleted}
                    numberDraftOrders={user.building.draftOrdersCount}
                  />
                </Grid>

                {getExtraDocumentsToUpload(wallet) && (
                  <Grid item xs={12}>
                    <PromoCard
                      bgColor="#E3F9F8"
                      borderColor="#90E7E2"
                      title="Carica i documenti"
                      customIcon={<UploadIcon sx={{ fontSize: 50 }} />}
                      description="Inserisci i documenti necessari per completare la tua richiesta di ordine."
                      action={
                        <Button
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={'/account/documents'}
                          onClick={
                            () => {
                              trackAction({
                                action: ClientActionEnum.Clicked,
                                type: ClientTypeEnum.Link,
                                name: `Inserisci i documenti mancanti dal box carica i documenti`
                              });
                            }
                            // trackAction({
                            //   type: ClientTypeEnum.Link,
                            //   action: ClientActionEnum.Clicked,
                            //   name: 'Box contattaci per modificare i dati'
                            // })
                          }
                        >
                          Upload
                        </Button>
                      }
                    />
                  </Grid>
                )}

                {/* Promo card */}
                <Grid item xs={12}>
                  <PromoCard
                    bgColor="#F0FF32"
                    borderColor="#ABB800"
                    title="Siamo qui per te"
                    description="Vuoi saperne di più o hai bisogno di supporto?"
                    action={
                      <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={'/contacts'}
                        state={{
                          preset: ContactFormPresetEnum.Wallet
                        }}
                        onClick={() =>
                          trackAction({
                            action: ClientActionEnum.Clicked,
                            type: ClientTypeEnum.Link,
                            name: 'Contattaci'
                          })
                        }
                      >
                        Contattaci
                      </Button>
                    }
                  />
                </Grid>

                {/* FAQ */}
                {getFaqs.data?.data != null && (
                  <Grid item xs={12}>
                    <Box>
                      <FaqList faqs={getFaqs.data?.data.data} withTitle />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fade>
    </>
  ) : (
    <WalletSkeletons />
  );
};

export default WalletPage;
