import { Box, Button, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import { Circle } from '@mui/icons-material';
import NotActiveChip from '../../components/custom/Chips/NotActiveChip';
import { GeneralWalletDto } from '../../types/dto/general-wallet.dto';
import { Link } from 'react-router-dom';
import { PlafondState } from '../../types/dto/plafond.dto';
import ActiveChip from '../../components/custom/Chips/ActiveChip';
import InProgressChip from '../../components/custom/Chips/InProgressChip';
import NotActivetedChip from '../../components/custom/Chips/NotActivetedChip';
import CountUp from 'react-countup';
import { memo } from 'react';

type DashboardChartProps = {
  registration: {
    user: {
      total: number;
      count: number;
    };
    building: {
      total: number;
      count: number;
    };
    documents: {
      total: number;
      count: number;
    };
  };
  orders: {
    total: number;
    completed: number;
  };
  wallet: {
    state: GeneralWalletDto['state'];
    availableAmount: number;
    usedAmount: number;
  };
};
const DashboardChart = memo((props: DashboardChartProps) => {
  const { orders, wallet, registration } = props;
  const theme = useTheme();
  const isDownLG = useMediaQuery(theme.breakpoints.down('md'));
  // const {start} = useCountUp({
  //   end: wallet.availableAmount,
  //   suffix: '€',
  //   duration: 2,
  //   formattingFn: (n) => `${n.toLocaleString()}`
  // });

  return (
    <Grid
      container
      sx={{
        width: '100%',
        minHeight: 350,
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid #041C223B',
        paddingY: '22px'
      }}
    >
      <Grid item xs={12} lg={5.9} paddingX={'20px'}>
        <Typography variant={'h6'} fontWeight={500}>
          Registrazione
        </Typography>
        <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px'
            }}
          >
            <Stack flexDirection={'row'} gap={'10px'}>
              <Circle
                fontSize={'small'}
                sx={{
                  color: '#90E7E2'
                }}
              />
              <Typography variant={'body2'} color={'text.disabled'}>
                Dati profilo
              </Typography>
            </Stack>
            <Stack flexDirection={'row'} gap={'10px'}>
              <Circle
                fontSize={'small'}
                sx={{
                  color: '#094754'
                }}
              />
              <Typography variant={'body2'} color={'text.disabled'}>
                Dati struttura
              </Typography>
            </Stack>
            <Stack flexDirection={'row'} gap={'10px'}>
              <Circle
                fontSize={'small'}
                sx={{
                  color: '#F0FF32'
                }}
              />
              <Typography variant={'body2'} color={'text.disabled'}>
                Documenti necessari
              </Typography>
            </Stack>
          </Box>
          <Chart
            type={'radialBar'}
            width={250}
            height={275}
            series={[
              (registration.user.count / registration.user.total) * 100,
              (registration.building.count / registration.building.total) * 100,
              (registration.documents.count / registration.documents.total) * 100
            ]}
            options={{
              colors: ['#90E7E2', '#094754', '#F0FF32'],
              labels: ['Dati profilo', 'Dati struttura', 'Documenti richiesti'],
              stroke: {
                lineCap: 'round'
              },
              plotOptions: {
                radialBar: {
                  offsetX: 20,
                  dataLabels: {
                    name: {
                      show: false
                    },
                    value: {
                      show: false
                    }
                  },
                  track: {
                    show: true,
                    background: '#E4EEEF',
                    strokeWidth: '10px',
                    opacity: 1
                  }
                }
              }
            }}
          />
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'flex-end'} mt={2.5}>
          <Button variant={'outlined'} size={'small'} component={Link} to={'/account/settings'}>
            Vai al profilo
          </Button>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={0.2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          my: { xs: 3, lg: 0 }
        }}
      >
        {isDownLG ? (
          <Divider orientation={'horizontal'} sx={{ width: '90%', height: '1px', backgroundColor: '#041C223B' }} />
        ) : (
          <Divider orientation={'vertical'} sx={{ height: '100%', width: '1px', backgroundColor: '#041C223B' }} />
        )}
      </Grid>
      <Grid item xs={12} lg={5.9} paddingX={'20px'}>
        <Box width={'100%'}>
          <Typography variant={'h6'} fontWeight={500} gutterBottom>
            Ordini totali
          </Typography>

          <Typography variant={'h3'} fontWeight={700}>
            <CountUp end={orders.total} duration={3} />
          </Typography>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'body2'} color={'text.disabled'}>
              <span style={{ color: '#16C399' }}>{<CountUp end={(orders.completed / orders.total) * 100} duration={3} />}%</span> ordini
              completati
            </Typography>

            {orders.total === 0 ? (
              <Button component={Link} variant={'contained'} size={'small'} to={'/orders/new-order'}>
                crea ordine
              </Button>
            ) : (
              <Button component={Link} variant={'outlined'} size={'small'} to={'/orders'}>
                vedi tutti
              </Button>
            )}
          </Stack>
          <Box display={'flex'} justifyContent={'center'} my={3}>
            <Divider orientation={'horizontal'} sx={{ backgroundColor: '#041C223B', width: '100%', height: '1px' }} />
          </Box>

          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
            <Typography variant={'h6'} fontWeight={500} gutterBottom>
              Wallet
            </Typography>

            {wallet.state == null ? (
              <NotActiveChip />
            ) : wallet.state === PlafondState.Approved ? (
              <ActiveChip />
            ) : wallet.state === PlafondState.Pending ? (
              <InProgressChip />
            ) : wallet.state === PlafondState.Refused ? (
              <NotActivetedChip />
            ) : (
              <></>
            )}
          </Stack>

          {wallet.state === null ? (
            <Typography variant={'body2'} color={'text.disabled'} maxWidth={150} mb={3}>
              Completa i tuoi dati e accedi al tuo wallet
            </Typography>
          ) : wallet.state === PlafondState.Approved ? (
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
              <Stack>
                <Typography variant={'body2'} color={'text.disabled'}>
                  Plafond disponibile
                </Typography>
                <Typography variant={'h6'} fontWeight={500}>
                  <CountUp end={wallet.availableAmount} suffix={'€'} duration={2} formattingFn={(n) => `${n.toLocaleString()}`} /> €
                </Typography>
              </Stack>

              <Stack textAlign={'end'}>
                <Typography variant={'body2'} color={'text.disabled'}>
                  Utilizzato
                </Typography>
                <Typography variant={'h6'} fontWeight={500} color={'success.dark'}>
                  <CountUp end={wallet.usedAmount} suffix={'€'} duration={2} formattingFn={(n) => `${n.toLocaleString()}`} /> €
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <></>
          )}

          <Stack flexDirection={'row'} justifyContent={'flex-end'}>
            <Button variant={'outlined'} size={'small'} component={Link} to={'/wallet'}>
              Vai al wallet
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
});

export default DashboardChart;
