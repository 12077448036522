import { DocumentDto } from '../../../types/dto/document.dto';
import { Box, Checkbox, FormControlLabel, List, ListItem, Stack, Typography } from '@mui/material';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';

import FileIcon from '../../../components/custom/BuyPlusIcons/FileIcon';
import { useOpenFileModal } from '../../../components/custom/EmbedFile';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import useModal from '../../../hooks/useModal';
import ConfirmUnfairTerms from '../../../components/custom/Documents/Modals/ConfirmUnfairTerms';

type DocumentsToSignProps = {
  documentsToSign: DocumentDto[];
  setDocumentsToSign: (documentsToSign: DocumentDto[]) => void;
};

const DocumentsToSign = ({ documentsToSign, setDocumentsToSign }: DocumentsToSignProps) => {
  const { trackAction } = useNavigationTracker();
  const openFileModal = useOpenFileModal();
  const { openModal } = useModal();
  console.log(documentsToSign);

  return (
    <List disablePadding>
      {documentsToSign.map((docToSign) => (
        <ListItem key={docToSign.id != null ? docToSign.id : docToSign.autoRequestId} disablePadding sx={{ alignItems: 'flex-end' }}>
          <Stack direction={'row'} gap={'10px'} flexGrow={1}>
            <Box
              sx={{
                cursor: docToSign.signatureType !== null ? 'pointer' : undefined
              }}
              onClick={() => {
                if (docToSign.signatureType == null) return;
                openFileModal({
                  title: docToSign.title,
                  fileCode: docToSign.providedUpload!.code
                });
                trackAction({
                  type: ClientTypeEnum.Button,
                  action: ClientActionEnum.Clicked,
                  name: `Ha aperto il documento "${docToSign.title}"`
                });
              }}
            >
              <FileIcon sx={{ fontSize: '50px' }} />
            </Box>

            <Box>
              <Typography variant="body1" gutterBottom fontWeight={500}>
                {docToSign.title}
              </Typography>

              {docToSign.description != null && docToSign.description !== '' && (
                <Typography variant="body2">{docToSign.description}</Typography>
              )}
            </Box>
          </Stack>

          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                checked={docToSign.accepted != null ? docToSign.accepted : false}
                value={docToSign.accepted != null ? docToSign.accepted : false}
                onChange={(event, checked) => {
                  console.log(checked);
                  if (checked && docToSign.unfairTerms != null && docToSign.unfairTerms.length > 0) {
                    openModal({
                      title: docToSign.title,
                      titleEllipse: true,
                      content: (
                        <ConfirmUnfairTerms
                          onAccept={() => {
                            setDocumentsToSign(
                              documentsToSign.map((d) => ({
                                ...d,
                                accepted: d.id == docToSign.id && d.autoRequestId == docToSign.autoRequestId ? checked : d.accepted
                              }))
                            );
                            trackAction({
                              type: ClientTypeEnum.Button,
                              action: ClientActionEnum.Clicked,
                              name: `Ha accettato il documento "${docToSign.title}"`
                            });
                          }}
                          unfairTerms={docToSign.unfairTerms}
                        />
                      )
                    });
                  } else {
                    setDocumentsToSign(
                      documentsToSign.map((d) => ({
                        ...d,
                        accepted: d.id == docToSign.id && d.autoRequestId == docToSign.autoRequestId ? checked : d.accepted
                      }))
                    );
                  }
                }}
              />
            }
            label={<Typography variant={'body1'}>Ho letto e accettato{!docToSign.optional && '*'}</Typography>}
            labelPlacement="end"
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DocumentsToSign;
