import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../store';
import PawnAutoComplete from './PawnAutoComplete';
import { setPawn } from '../../store/reducers/auth';
import SettingsIcon from './BuyPlusIcons/SettingsIcon';
import { useNavigate } from 'react-router-dom';
import AccountIcon from './BuyPlusIcons/AccountIcon';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from '../../hooks/useAuth';

const HolderBar = () => {
  const { user, holder, pawn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const [activeMod, setActiveMod] = useState<'user' | 'test_wallet' | 'test_orders'>(
    pathname.includes('test/wallet') ? 'test_wallet' : pathname.includes('test/orders') ? 'test_orders' : 'user'
  );

  if (holder === undefined) {
    return <></>;
  }

  useEffect(() => {
    setActiveMod(pathname.includes('test/wallet') ? 'test_wallet' : pathname.includes('test/orders') ? 'test_orders' : 'user');
  }, [pathname]);

  return (
    <Box
      width={'100%'}
      sx={{
        backgroundColor: '#F8FF99',
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '10px'
      }}
    >
      <Typography variant={'h6'} fontWeight={400} color={'primary'}>
        Benvenuto {holder.firstname}{' '}
        {user !== null && (
          <span>
            stai visualizzando per l'utente,{' '}
            <span style={{ fontWeight: 600 }}>
              {user.firstname} {user.lastname} - {user.building.name}
            </span>
          </span>
        )}
      </Typography>

      <Box width={'100%'} maxWidth={500} display={'flex'} gap={'20px'} alignItems={'center'} justifyContent={'flex-end'}>
        <FormControl fullWidth size={'small'} sx={{ maxWidth: 250 }}>
          <InputLabel id="mod-select-label">Seleziona modalità</InputLabel>

          <Select
            label={'Seleziona modalità'}
            labelId={'mod-select-label'}
            value={activeMod}
            onChange={(event) => {
              setActiveMod(event.target.value as any);
              switch (event.target.value) {
                case 'user':
                  navigate('/dashboard');
                  break;

                case 'test_wallet':
                  navigate('/test/wallet');
                  break;

                case 'test_orders':
                  navigate('/test/orders');
                  break;

                default:
                  break;
              }
            }}
          >
            <MenuItem value={'user'}>
              <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                <AccountIcon fontSize={'small'} />
                <Typography
                  variant={'body1'}
                  sx={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Visualizzazione utente
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value={'test_wallet'}>
              <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                <SettingsIcon fontSize={'small'} />{' '}
                <Typography
                  variant={'body1'}
                  sx={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Pagina Test - Wallet
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value={'test_orders'}>
              <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                <SettingsIcon fontSize={'small'} />{' '}
                <Typography
                  variant={'body1'}
                  sx={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  Pagina Test - Ordini
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>

        {activeMod === 'user' && user !== null && (
          <PawnAutoComplete
            fullWidth
            holder={holder}
            pawn={pawn ?? null}
            onChange={(event, value) => {
              dispatch(setPawn(value ?? undefined));
            }}
          />
        )}

        <IconButton color={'primary'} onClick={logout}>
          <LogoutIcon fontSize={'medium'} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HolderBar;
