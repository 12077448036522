import { alpha, Box, Checkbox, CheckboxProps, Fade, SliderProps, styled, Switch, SwitchProps, Tooltip, Typography } from '@mui/material';
import PaymentRange from '../../PaymentRange';
import { JSX } from 'react';
import LockClosedIcon from '../../BuyPlusIcons/LockClosedIcon';
import LockOpenIcon from '../../BuyPlusIcons/LockOpenIcon';
import { WarningTooltip } from '../PlafondCard';
import WarningIcon from '@mui/icons-material/Warning';

export const PaymentCard = styled(Box)<{ disabled: boolean }>(() => ({
  width: '100%',
  border: `1px solid ${alpha('#041C226B', 0.42)}`,
  padding: '15px 20px',
  borderRadius: '6px',
  position: 'relative',
  transition: 'all .2s'
  // ...(disabled === true && {
  //   position: 'relative',
  //   '&:after': {
  //     content: '""',
  //     display: 'block',
  //     height: '100%',
  //     width: '100%',
  //     backgroundColor: alpha('#fffff', 1),
  //     position: 'absolute',
  //     top: 0,
  //     left: 0,
  //     right: 0,
  //     bottom: 0,
  //     zIndex: 12000
  //   }
  // })
}));

type CardPaymentPlafondProps = {
  oldData: () =>
    | {
        min: number;
        max: number;
        value: number;
      }
    | undefined;
  title: string;
  description?: string;
  updateLoading: boolean;
  partnerLogo?: JSX.Element;
  realAvaibleAmount?: number;
  minValue?: SliderProps['min'];
  maxValue?: SliderProps['max'];
  value?: SliderProps['value'];
  onChangeSlider: SliderProps['onChange'];
  onChangeCommittedSlider: SliderProps['onChangeCommitted'];
  onChangeSwitch?: SwitchProps['onChange'];
  active?: boolean;
  locked: boolean;
  onChangeLocked: CheckboxProps['onChange'];
  selectField: JSX.Element;
  handleOpenInfo?: () => void;
  disableSwitchActiveWallet?: boolean;
  showLockedButton?: boolean;
};

const CardPaymentPlafond = (props: CardPaymentPlafondProps) => {
  return (
    <PaymentCard
      disabled={props.minValue == null && props.maxValue == null && props.value == null}
      border={
        props.value != null && props.realAvaibleAmount != null
          ? props.value > props.realAvaibleAmount
            ? `2px solid #FF966F!important`
            : undefined
          : undefined
      }
    >
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          <Typography fontSize={'1.2rem'} fontWeight={500}>
            {props.title}
          </Typography>
          {props.realAvaibleAmount != null && props.value != null && props.value > props.realAvaibleAmount && (
            <Fade in>
              <div>
                <WarningTooltip
                  title={
                    <div>
                      <Typography variant={'body2'}>
                        Stai utilizzando del credito in più rispetto a quello disponibile tramite una previsione sull'anno corrente. Il
                        completamento di questo ordine consumerà tutto il credito disponibile rimuovendo questo planfond da altri ordini
                        attualmente in bozza.
                      </Typography>
                    </div>
                  }
                  arrow
                >
                  <WarningIcon fontSize={'medium'} color={'warning'} />
                </WarningTooltip>
              </div>
            </Fade>
          )}
        </Box>

        {props.active != null && props.onChangeSwitch != null && (
          <>
            {!props.active ? (
              <Tooltip
                title={!props.disableSwitchActiveWallet ? 'Usa questo plafond' : 'Non puoi utilizzare più di 2 plafond alla volta'}
                arrow
                placement={'top-start'}
              >
                <div>
                  <Switch
                    checked={props.active}
                    disabled={props.disableSwitchActiveWallet || props.updateLoading}
                    value={props.active}
                    onChange={props.onChangeSwitch}
                    data-cy="active_plafond_switch"
                    sx={{ position: 'relative', zIndex: 10 }}
                  />
                </div>
              </Tooltip>
            ) : (
              <Switch
                checked={props.active}
                value={props.active}
                onChange={props.onChangeSwitch}
                sx={{ position: 'relative', zIndex: 10 }}
              />
            )}
          </>
        )}
      </Box>

      <Box sx={{ width: '100%' }}>{props.partnerLogo != null && props.partnerLogo}</Box>
      {props.description != null && props.handleOpenInfo != null && (
        <Box mt={1}>
          <Typography fontSize={'.8rem'} fontWeight={300}>
            {props.description}{' '}
            <span
              onClick={props.handleOpenInfo}
              style={{
                fontWeight: 700,
                position: 'relative',
                zIndex: 10,
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              Scopri di più.
            </span>
          </Typography>
        </Box>
      )}

      <Box mt={10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: { xs: 'wrap', md: 'no-wrap' } }}>
        {props.minValue != null && props.maxValue != null && props.value != null ? (
          <>
            <Box display={'flex'} alignItems={'flex-start'} gap={'20px'} width={{ xs: '100%', md: 'calc(100% - 180px)' }}>
              <PaymentRange
                value={props.value}
                // min={props.minValue}
                // onChange={props.onChangeSlider}
                max={props.maxValue}
                min={0}
                realMin={props.minValue === props.maxValue ? undefined : props.minValue}
                onChange={(event, value, activeThumb) => {
                  if (typeof value !== 'number' || props.onChangeSlider == null) return;
                  if (value >= (props.minValue ?? 0)) {
                    props.onChangeSlider(event, value, activeThumb);
                  } else {
                    props.onChangeSlider(event, props.minValue ?? 0, activeThumb);
                  }
                }}
                step={1}
                disabled={props.updateLoading}
                data-cy="plafond_slider"
                // onChangeCommitted={props.onChangeCommittedSlider}
                onChangeCommitted={(event, value) => {
                  if (props.onChangeCommittedSlider) {
                    if (value >= (props.minValue ?? 0)) {
                      props.onChangeCommittedSlider(event, value);
                    } else {
                      props.onChangeCommittedSlider(event, props.minValue ?? 0);
                    }
                  }
                }}
                oldData={props.oldData()}
              />
              {props.showLockedButton && (
                <Checkbox
                  checked={props.locked}
                  onChange={props.onChangeLocked}
                  icon={<LockOpenIcon sx={{ fontSize: '40px' }} />}
                  checkedIcon={<LockClosedIcon sx={{ fontSize: '40px' }} />}
                  size={'large'}
                />
              )}
            </Box>
            <Box mt={{ xs: 2, md: 0 }} width={{ xs: '100%', md: 150 }}>
              {props.selectField}
            </Box>
          </>
        ) : (
          <Box display={'flex'} alignItems={'flex-end'} gap={'20px'} width={{ xs: '100%', md: 'calc(100% - 180px)' }}>
            <PaymentRange value={0} realMin={undefined} min={undefined} max={undefined} disabled={true} valueLabelDisplay={'off'} />
          </Box>
        )}
      </Box>

      {/*Opacity Disabled*/}
      {props.minValue == null && props.maxValue == null && props.value == null && (
        <Box
          sx={{
            position: 'absolute',
            width: 'calc(100% + 2px)',
            height: 'calc(100% + 2px)',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '6px',
            backgroundColor: alpha('#EDEDED', 0.4)
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '110px',
              height: '110px',
              top: '1px',
              right: '1px',
              borderTopRightRadius: '6px',
              borderBottomLeftRadius: '75%',

              backgroundColor: '#ffffff'
            }}
          ></Box>
        </Box>
      )}
    </PaymentCard>
  );
};

export default CardPaymentPlafond;
