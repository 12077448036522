import { Components } from '@mui/material';

export default function ListItemText() {
  return {
    MuiListItemText: {
      defaultProps: {
        secondaryTypographyProps: {
          color: '#6E7475',
          fontSize: '.8rem',
          fontWeight: 400
        }
      }
    } as Components['MuiListItemText']
  };
}
