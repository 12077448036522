import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { getIsColorDark } from '../../../../utils/getIsColorDark';
import BigNumber from 'bignumber.js';
import { WalletDTO } from '../../../../types/dto/plafond.dto';
import { WalletStatus } from '../../../../utils/getWalletState';
type WalletBarProps = {
  wallets: WalletDTO[];
  avaibleAmount: number;
  walletState: WalletStatus;
};

export const WalletStatusBar = styled(Box)(() => ({
  width: '100%',
  height: 20,
  borderRadius: '50px',
  backgroundColor: ' rgba(4, 28, 34, 0.12)',
  display: 'flex',
  overflow: 'hidden',
  marginBottom: '10px'
}));

export const StatusBarValue = styled(Box)(() => ({
  height: '100%'
  // backgroundColor: theme.palette.secondary.main
}));
const WalletBar = ({ wallets, avaibleAmount, walletState }: WalletBarProps) => {
  return (
    <WalletStatusBar>
      {walletState === 'approved' && (
        <>
          {wallets.map((item, i) => {
            if (item.wallet == null) return false;
            const activePlafond = item.wallet;
            if (activePlafond == null || activePlafond.state.key !== 'approved') return false;
            const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
              <Tooltip {...props} classes={{ popper: className }} children={props.children} />
            ))(() => {
              return {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: item.color,
                  color: getIsColorDark(item.color) ? '#fff' : '#252525'
                },
                [`& .${tooltipClasses.arrow}`]: { color: item.color }
              };
            });

            const value = BigNumber(activePlafond.usedAmount).dividedBy(avaibleAmount).multipliedBy(100);
            return (
              <CustomTooltip title={item.title} arrow key={i}>
                <StatusBarValue key={`status-bar-${i}`} width={`${value}%`} bgcolor={item.color} />
              </CustomTooltip>
            );
          })}
        </>
      )}
    </WalletStatusBar>
  );
};

export default WalletBar;
