import { Box, Fade, Grid } from '@mui/material';

const ContentCommonLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: { xs: 'auto', sm: '100%' },
        display: 'flex',
        justifyContent: 'center',
        overflowY: { xs: 'hidden', sm: 'auto' },
        '&::-webkit-scrollbar': { width: 0 },
        order: { xs: 1, sm: 0 }
      }}
    >
      <Fade in>
        <Box
          sx={{
            width: '90%',
            // maxWidth: 500,
            display: 'flex',
            pt: { xs: 4, sm: 8 },
            justifyContent: 'center'
          }}
        >
          {children}
        </Box>
      </Fade>
    </Grid>
  );
};

export default ContentCommonLayout;
