import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(duration);
dayjs.extend(relativeTime);

const getTimeSpent = (endDate: string) => {
  return `${dayjs.duration(dayjs().diff(dayjs(endDate))).humanize()} fa`;
};

export default getTimeSpent;
