import { Autocomplete, AutocompleteProps, Box, Paper, TextField, TextFieldProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { NumericFormat, numericFormatter, removeNumericFormat } from 'react-number-format';

type AmountAutoCompleteProps = {
  name: string;
  error: TextFieldProps['error'];
} & Omit<AutocompleteProps<BigNumber, false, true, boolean>, 'inputValue' | 'options' | 'renderInput' | 'onInputChange'>;

const AmountAutoComplete = (props: AmountAutoCompleteProps) => {
  const { error, onBlur, ...other } = props;
  const [inputValue, setInputValue] = useState(
    props.value != null && props.value !== ''
      ? numericFormatter(typeof props.value === 'string' ? props.value : props.value.toNumber().toString(), {
          prefix: '€ ',
          thousandSeparator: '.',
          thousandsGroupStyle: 'thousand',
          decimalScale: 2,
          decimalSeparator: ',',
          valueIsNumericString: true,
          fixedDecimalScale: false
        })
      : ''
  );

  const getOptions = (value: string) => {
    if (value == null) return [];
    const num = BigNumber(value);

    const downOptions = Array(1)
      .fill(num)
      .map((val: BigNumber, i) =>
        val
          .dividedToIntegerBy(10)
          .multipliedBy(10)
          .minus(10 * i)
      )
      .filter((v) => v.isGreaterThan(0));

    const upperOptions = Array(1)
      .fill(num)
      .map((val: BigNumber, i) =>
        val
          .dividedToIntegerBy(10)
          .multipliedBy(10)
          .plus(10 * (i + 1))
      )
      .filter((v) => v.isGreaterThan(0));

    if (num.isEqualTo(downOptions[0]) || num.isEqualTo(upperOptions[0])) return [];

    return downOptions.concat(...upperOptions);
  };

  const [options, setOptions] = useState<BigNumber[]>(inputValue != null ? getOptions(inputValue) : []);

  useEffect(() => {
    if (inputValue == null || inputValue === '') return;
    // const parsedNum = inputValue.replace(' €', '').replace(',', '');
    const parsedNum = removeNumericFormat(
      inputValue,
      {
        from: {
          start: 0,
          end: inputValue.length - 1
        },
        to: {
          start: 0,
          end: inputValue.length - 1
        },
        lastValue: inputValue
      },
      {
        prefix: '€ ',
        decimalScale: 2,
        thousandSeparator: '.',
        thousandsGroupStyle: 'thousand',
        decimalSeparator: ',',
        valueIsNumericString: true,
        fixedDecimalScale: false
      }
    );
    if (!Number.isNaN(parsedNum)) setOptions(getOptions(parsedNum));
  }, [inputValue]);

  return (
    <Autocomplete
      {...other}
      disableClearable={true}
      freeSolo={options.length === 0}
      defaultValue={BigNumber(0)}
      open={options.length !== 0}
      filterOptions={(options) => options}
      inputValue={inputValue}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return numericFormatter((option as BigNumber).toNumber().toString(), {
          prefix: '€ ',
          thousandSeparator: '.',
          decimalScale: 2,
          thousandsGroupStyle: 'thousand',
          decimalSeparator: ',',
          valueIsNumericString: true,
          fixedDecimalScale: false
        });
      }}
      noOptionsText={'Nessun arrotondamento necessario'}
      onBlurCapture={(event) => {
        const parsedNum = removeNumericFormat(
          inputValue,
          {
            from: {
              start: 0,
              end: inputValue.length - 1
            },
            to: {
              start: 0,
              end: inputValue.length - 1
            },
            lastValue: inputValue
          },
          {
            prefix: '€ ',
            decimalScale: 2,
            thousandSeparator: '.',
            thousandsGroupStyle: 'thousand',
            decimalSeparator: ',',
            valueIsNumericString: true,
            allowNegative: false,
            allowLeadingZeros: false
          }
        );

        if (props.onChange && BigNumber(parsedNum).mod(10).isEqualTo(0) && parsedNum[parsedNum.length - 1] !== '.') {
          props.onChange(event, BigNumber(parsedNum), 'selectOption');
        } else {
          if (props.onChange) props.onChange(event, '', 'selectOption');
          setInputValue('');
        }
      }}
      onChange={(event, value, reason) => {
        if (typeof value === 'string') {
          const parsedNum = removeNumericFormat(
            inputValue,
            {
              from: {
                start: 0,
                end: inputValue.length - 1
              },
              to: {
                start: 0,
                end: inputValue.length - 1
              },
              lastValue: inputValue
            },
            {
              prefix: '€ ',
              decimalScale: 2,
              thousandSeparator: '.',
              thousandsGroupStyle: 'thousand',
              decimalSeparator: ',',
              valueIsNumericString: true,
              allowNegative: false,
              allowLeadingZeros: false
            }
          );
          if (props.onChange && BigNumber(parsedNum).mod(10).isEqualTo(0) && parsedNum[parsedNum.length - 1] !== '.') {
            props.onChange(event, BigNumber(parsedNum), 'selectOption');
          }
        } else {
          if (props.onChange) props.onChange(event, value, reason);
        }
      }}
      onInputChange={(event, value) => {
        setInputValue(value);
      }}
      renderInput={(params) => {
        return (
          <NumericFormat
            {...params}
            customInput={TextField}
            name={props.name}
            error={error}
            placeholder={'Inserisci un importo'}
            value={typeof props.value === 'object' ? props.value.toNumber() : props.value}
            onBlur={onBlur}
            fixedDecimalScale={false}
            allowLeadingZeros={false}
            allowNegative={false}
            prefix={'€ '}
            valueIsNumericString={true}
            decimalScale={2}
            decimalSeparator={','}
            thousandSeparator={'.'}
            thousandsGroupStyle={'thousand'}
            onChange={(event) => {
              setInputValue(event.target.value);
              if (params.inputProps.onChange) params.inputProps.onChange(event);
            }}
          />
        );
      }}
      PaperComponent={({ children }) => (
        <Paper>
          <Box paddingX={2} paddingTop={1}>
            <Typography variant={'body1'} fontWeight={600}>
              Scegli un arrotondamento:
            </Typography>
          </Box>
          {children}
        </Paper>
      )}
      options={options}
      isOptionEqualToValue={(option, value) => false}
    />
  );
};

export default AmountAutoComplete;
