import {
  Box,
  Button,
  Chip,
  FormHelperText,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import SummaryListItemText from './SummaryListItemText';
import { OrderDTO } from '../../../../types/dto/orders.dto';
import UpdatePaymentLoader from '../../Skeletons/NewOrderSkeletons/UpdatePaymentLoader';
import BigNumber from 'bignumber.js';
import { ReactComponent as BuyplusLabel } from 'assets/images/icons/label.svg';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useEndpoint } from '../../../../hooks/useEndpoint';
import { Clear } from '@mui/icons-material';
import useNavigationTracker from '../../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../../types/dataToSend/clientLog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import formatNumber from '../../../../utils/formatNumber';

export const OrderSummaryBox = styled(Box)(() => ({
  width: '100%',
  maxWidth: 375,
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative'
}));

export const HeaderSummaryBox = styled(Box)(({ theme }) => ({
  width: '100%',
  // height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '15px 24px',
  backgroundColor: theme.palette.primary.main,
  borderTop: `4px solid ${theme.palette.secondary.main}`
}));

export const MainSummaryBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '18px 21px',
  borderLeft: `1px solid ${theme.palette.secondary.dark}`,
  borderRight: `1px solid ${theme.palette.secondary.dark}`
}));

export const ListItemSummary = styled(ListItem)(({ theme, divider }) => ({
  width: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  ...(divider && { borderTop: `1px solid ${theme.palette.secondary.dark}`, marginTop: '15px', paddingTop: '15px' }),
  borderBottom: 'none'
}));

export const FooterSummaryBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  borderTop: `4px solid ${theme.palette.secondary.main}`,
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  padding: '15px 24px'
}));

export const TooltipInfo = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => {
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: '0.8rem'
      },
      [`& .${tooltipClasses.arrow}`]: { color: theme.palette.primary.main }
    };
  }
);

type OrderSummaryProps = {
  order: OrderDTO;
  // updateLoading: boolean;
  refetchOrderSummary: () => Promise<any>;
};

const OrderSummaryCard = ({ order, refetchOrderSummary }: OrderSummaryProps) => {
  const { trackAction } = useNavigationTracker();
  const updateReferralCode = useEndpoint<{ discountCode: string | null }, 'patch'>({
    method: 'patch',
    endpoint: `/orders/${order.code}`,
    mutationKey: 'update-referral-code',
    options: {
      onSuccess: () => refetchOrderSummary(),
      onError: (err) => {
        if (err.response?.status === 400) {
          setErrorDiscount(err.response?.data.message[0]);
        }
      }
    }
  });
  const [inputDiscountCode, setInputDiscountCode] = useState(order.discountCode != null ? order.discountCode : '');
  const [errorDiscount, setErrorDiscount] = useState<string>();

  return (
    <OrderSummaryBox data-tour={'order-summary'}>
      {updateReferralCode.isLoading && <UpdatePaymentLoader />}
      <HeaderSummaryBox>
        <Typography fontWeight={700} fontSize={'1rem'} textTransform={'uppercase'} color={'white'}>
          Riepilogo
        </Typography>
      </HeaderSummaryBox>
      <MainSummaryBox>
        <List>
          <ListItemSummary>
            <SummaryListItemText
              primary={'Il tuo prodotto/servizio'}
              sx={{ alignItems: 'flex-start' }}
              secondary={order.orderCategory.name}
              secondaryTypographyProps={{ sx: { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 150 } }}
            />
            <SummaryListItemText primary={'Tipologia'} secondary={order.type === 'rent' ? 'Noleggio' : 'Acquisto'} />
            <SummaryListItemText
              primary={'Budget'}
              secondary={`${formatNumber(BigNumber(order.activeConfiguration.amount).toNumber(), {
                fractionDigits: 2
              })} €`}
            />
          </ListItemSummary>
          {order.type === 'purchase' && (
            <>
              <ListItemSummary divider>
                <Typography variant={'h6'} mb={1.5}>
                  Acconto
                </Typography>
                <SummaryListItemText
                  primary={'Acconto cash'}
                  secondary={`${formatNumber(BigNumber(order.activeConfiguration.depositAmount).toNumber(), {
                    fractionDigits: 2
                  })} €`}
                />

                {/*{order.extraCosts != null && (*/}
                {/*  <Stack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">*/}
                {/*    <TooltipInfo*/}
                {/*      title={*/}
                {/*        'Parte dei costi delle singole soluzioni di credito non integrabili nel valore dei wallet poichè utilizzati totalmente.'*/}
                {/*      }*/}
                {/*      arrow*/}
                {/*      placement={'left-start'}*/}
                {/*    >*/}
                {/*      <Stack flexDirection="row" gap="3px" alignItems="center">*/}
                {/*        <Typography variant={'body2'} color={'text.disabled'}>*/}
                {/*          Totale costi in eccedenza*/}
                {/*        </Typography>*/}
                {/*        <InfoOutlinedIcon sx={{ fontSize: 15 }} />*/}
                {/*      </Stack>*/}
                {/*    </TooltipInfo>*/}
                {/*    <Typography variant={'body1'} color={'text.primary'} whiteSpace={'normal'}>*/}
                {/*      {`+ ${BigNumber(order.extraCosts).toNumber().toLocaleString('it', {*/}
                {/*        maximumFractionDigits: 2,*/}
                {/*        minimumFractionDigits: 2*/}
                {/*      })} €`}*/}
                {/*    </Typography>*/}
                {/*  </Stack>*/}
                {/*)}*/}

                <SummaryListItemText primary={'Scadenza'} secondary={'Rimessa diretta'} />
              </ListItemSummary>
              {order.activeConfiguration.wallets.map((wallet) => {
                if (wallet.orderWallet == null) return false;
                return (
                  <ListItemSummary divider key={wallet.id}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mb={2}>
                      <Typography variant={'h6'}>{wallet.walletPartner.title}</Typography>
                      {!wallet.walletPartner.hideNameIn?.includes('orders') && (
                        <>
                          {wallet.walletPartner.slug === 'ivh' ? (
                            <BuyplusLabel />
                          ) : (
                            <Chip label={wallet.walletPartner.company} size={'medium'} color={'default'} />
                          )}
                        </>
                      )}
                    </Box>
                    {/*<SummaryListItemText*/}
                    {/*  primary={'Importo Richiesto'}*/}
                    {/*  secondary={`${BigNumber(wallet.orderWallet.amount).toNumber().toLocaleString('it', {*/}
                    {/*    maximumFractionDigits: 2,*/}
                    {/*    minimumFractionDigits: 2*/}
                    {/*  })} €`}*/}
                    {/*/>*/}
                    {wallet.orderWallet.returnTime != null && (
                      <SummaryListItemText primary={'Durata'} secondary={`${wallet.orderWallet.returnTime} Mesi`} />
                    )}
                    {/*{wallet.orderWallet.extraCost != null && (*/}
                    {/*  <SummaryListItemText*/}
                    {/*    primary={'Eccedenza'}*/}
                    {/*    secondary={`- ${BigNumber(wallet.orderWallet.extraCost).toNumber().toLocaleString('it', {*/}
                    {/*      maximumFractionDigits: 2,*/}
                    {/*      minimumFractionDigits: 2*/}
                    {/*    })} €`}*/}
                    {/*  />*/}
                    {/*)}*/}
                    {wallet.orderWallet.entries
                      .filter((e) => e.key !== 'markupPercentage')
                      .map((f, i) => (
                        <SummaryListItemText
                          key={i}
                          primary={<FormattedMessage id={f.key} />}
                          secondary={`${formatNumber(BigNumber(f.value).toNumber(), {
                            fractionDigits: 2
                          })} ${f.unit}`}
                        />
                      ))}
                    <SummaryListItemText
                      primary={'Totale corrispettivo'}
                      primaryTypographyProps={{ fontWeight: 700 }}
                      secondary={`${formatNumber(BigNumber(wallet.orderWallet.amount).toNumber(), {
                        fractionDigits: 2
                      })} €`}
                    />
                  </ListItemSummary>
                );
              })}
            </>
          )}

          {order.type === 'rent' &&
            order.activeConfiguration.wallets.map((wallet) => {
              if (wallet.orderWallet == null) return false;
              return (
                <ListItemSummary divider key={wallet.id} style={{ cursor: 'default' }}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mb={2}>
                    <Typography variant={'h6'}>{wallet.walletPartner.title}</Typography>
                    {!wallet.walletPartner.hideNameIn?.includes('orders') ? (
                      <>
                        {wallet.walletPartner.slug === 'ivh' ? (
                          <BuyplusLabel />
                        ) : (
                          <Chip label={wallet.walletPartner.company} size={'medium'} color={'default'} />
                        )}
                      </>
                    ) : (
                      <Chip label={'Partner'} size={'medium'} color={'default'} />
                    )}
                  </Box>
                  <SummaryListItemText
                    primary={'Importo anticipo'}
                    secondary={`${formatNumber(BigNumber(order.activeConfiguration.depositAmount).toNumber(), {
                      fractionDigits: 2
                    })} €`}
                  />
                  {/*<SummaryListItemText*/}
                  {/*  primary={'Importo noleggio'}*/}
                  {/*  secondary={`${formatNumber(BigNumber(wallet.orderWallet.amount).toNumber(), {*/}
                  {/*    fractionDigits: 2*/}
                  {/*  })} €`}*/}
                  {/*/>*/}

                  {/*{order.extraCosts != null && (*/}
                  {/*  <Stack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">*/}
                  {/*    <TooltipInfo*/}
                  {/*      title={*/}
                  {/*        'Parte dei costi delle singole soluzioni di credito non integrabili nel valore dei wallet poichè utilizzati totalmente.'*/}
                  {/*      }*/}
                  {/*      arrow*/}
                  {/*      placement={'left-start'}*/}
                  {/*    >*/}
                  {/*      <Stack flexDirection="row" gap="3px" alignItems="center">*/}
                  {/*        <Typography variant={'body2'} color={'text.disabled'}>*/}
                  {/*          Costi in eccedenza*/}
                  {/*        </Typography>*/}
                  {/*        <InfoOutlinedIcon sx={{ fontSize: 15 }} />*/}
                  {/*      </Stack>*/}
                  {/*    </TooltipInfo>*/}
                  {/*    <Typography variant={'body1'} color={'text.primary'} whiteSpace={'normal'}>*/}
                  {/*      {`+ ${BigNumber(order.extraCosts).toNumber().toLocaleString('it', {*/}
                  {/*        maximumFractionDigits: 2,*/}
                  {/*        minimumFractionDigits: 2*/}
                  {/*      })} €`}*/}
                  {/*    </Typography>*/}
                  {/*  </Stack>*/}
                  {/*)}*/}
                  <SummaryListItemText primary={'Durata'} secondary={`${wallet.orderWallet.returnTime} Mesi`} />
                  {wallet.orderWallet.entries
                    //Rimuovo i canoni scelta di giorgio perche uguale alla durata
                    .filter((f) => f.key !== 'remainingRates' && f.key !== 'irapTaxSavings' && f.key !== 'iresTaxSavings')
                    .map((f, i) => {
                      return (
                        <Stack key={i} width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
                          {f.info != null ? (
                            <TooltipInfo title={f.info} arrow placement={'left-start'}>
                              <Stack flexDirection="row" gap="3px" alignItems="center">
                                <Typography variant={'body2'} color={'text.disabled'}>
                                  <FormattedMessage id={f.key} />
                                </Typography>
                                <InfoOutlinedIcon sx={{ fontSize: 15 }} />
                              </Stack>
                            </TooltipInfo>
                          ) : (
                            <Typography variant={'body2'} color={'text.disabled'}>
                              <FormattedMessage id={f.key} />
                            </Typography>
                          )}
                          <Typography variant={'body1'} color={'text.primary'} whiteSpace={'normal'}>
                            {`${formatNumber(BigNumber(f.value).toNumber(), {
                              fractionDigits: 2
                            })} ${f.unit === 'N' ? 'Mesi' : f.unit}`}
                          </Typography>
                        </Stack>
                      );
                    })}
                  {/*<SummaryListItemText*/}
                  {/*  primary={'Totale'}*/}
                  {/*  primaryTypographyProps={{ fontWeight: 700 }}*/}
                  {/*  secondary={`${BigNumber(wallet.orderWallet.total).toNumber().toLocaleString('it', {*/}
                  {/*    maximumFractionDigits: 2,*/}
                  {/*    minimumFractionDigits: 2*/}
                  {/*  })} €`}*/}
                  {/*/>*/}
                </ListItemSummary>
              );
            })}

          <ListItemSummary divider>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} mb={2}>
              <Typography variant={'h6'}>Codice Sconto</Typography>
            </Box>
            <TextField
              fullWidth
              variant={'outlined'}
              size={'small'}
              color={'primary'}
              placeholder={'Inserisci il codice sconto'}
              value={inputDiscountCode}
              error={errorDiscount != null}
              onChange={(event) => {
                setInputDiscountCode(event.target.value);
                setErrorDiscount(undefined);
              }}
              disabled={order.activeConfiguration.discountedTotal != null}
              InputProps={{
                sx: {
                  paddingRight: 0
                },
                endAdornment: (
                  <>
                    {order.discountCode == null ? (
                      <InputAdornment position="end" style={{ height: '100%' }}>
                        <Button
                          variant={'contained'}
                          sx={{ borderRadius: '4px', height: '100%' }}
                          // disabled={errorDiscount != null}
                          onClick={() => {
                            if (inputDiscountCode) {
                              setErrorDiscount(undefined);
                              updateReferralCode.mutate({
                                discountCode: inputDiscountCode
                              });
                              trackAction({
                                action: ClientActionEnum.Clicked,
                                type: ClientTypeEnum.Button,
                                name: `Ha aggiunto il codice sconto "${inputDiscountCode}" all'ordine "${order.code}"`
                              });
                            } else {
                              setErrorDiscount('Inserisci un codice sconto');
                            }
                          }}
                        >
                          Aggiungi
                        </Button>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <Tooltip
                          title={'Rimuovi codice sconto'}
                          arrow
                          onClick={() => {
                            setInputDiscountCode('');
                            updateReferralCode.mutate({ discountCode: null });
                            trackAction({
                              action: ClientActionEnum.Clicked,
                              type: ClientTypeEnum.Button,
                              name: `Ha rimosso il codice sconto dall'ordine "${order.code}"`
                            });
                          }}
                        >
                          <IconButton size={'small'}>
                            <Clear fontSize={'small'} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
            />
            {errorDiscount != null && <FormHelperText error>{errorDiscount}</FormHelperText>}
          </ListItemSummary>
        </List>
      </MainSummaryBox>
      <FooterSummaryBox>
        {/*//TODO: Cancellata momentaneamente */}
        {/*{order.fee != null && (*/}
        {/*  <>*/}
        {/*    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} color={'white'}>*/}
        {/*      <Typography variant={'body2'}>Subtotale</Typography>*/}

        {/*      <Typography variant={'body2'}>*/}
        {/*        {BigNumber(order.total).minus(order.fee).toNumber().toLocaleString('it', {*/}
        {/*          maximumFractionDigits: 2,*/}
        {/*          minimumFractionDigits: 2*/}
        {/*        })}{' '}*/}
        {/*        €*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} color={'white'}>*/}
        {/*      <Typography variant={'body2'}>Licenza buyplus</Typography>*/}

        {/*      <Typography variant={'body2'}>*/}
        {/*        {BigNumber(order.fee).toNumber().toLocaleString('it', {*/}
        {/*          maximumFractionDigits: 2,*/}
        {/*          minimumFractionDigits: 2*/}
        {/*        })}{' '}*/}
        {/*        €*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </>*/}
        {/*)}*/}
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} color={'white'}>
          <Typography variant={'body2'}>Licenza buyplus</Typography>

          <Typography
            variant={'body2'}
            sx={{
              textDecorationLine: 'line-through'
            }}
          >
            0,5%
          </Typography>
        </Box>

        {order.activeConfiguration.discountedTotal != null && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} color={'white'}>
            <Typography variant={'body2'}>Sconto</Typography>

            <Typography variant={'body2'}>
              {formatNumber(BigNumber(order.activeConfiguration.total).minus(order.activeConfiguration.discountedTotal).toNumber(), {
                fractionDigits: 2
              })}{' '}
              €
            </Typography>
          </Box>
        )}

        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} color={'white'}>
          <Typography fontWeight={700} fontSize={'1rem'} textTransform={'uppercase'} color={'white'}>
            totale
          </Typography>

          <Typography fontWeight={700} variant={'body2'} fontSize={'1rem'}>
            {formatNumber(
              BigNumber(
                order.activeConfiguration.discountedTotal != null
                  ? order.activeConfiguration.discountedTotal
                  : order.activeConfiguration.total
              ).toNumber(),
              {
                fractionDigits: 2
              }
            )}{' '}
            €
          </Typography>
        </Box>
      </FooterSummaryBox>
    </OrderSummaryBox>
  );
};

export default OrderSummaryCard;
