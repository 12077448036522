import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const OrdersIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6442 3.60547H1.64417V19.8161H23.6442V3.60547Z" fill="#094754" />
      <path d="M15.5389 18.0789H9.74927V23.8685H15.5389V18.0789Z" fill="#91DCF9" />
      <path d="M19.5916 21.5527H5.69678V23.8686H19.5916V21.5527Z" fill="#91DCF9" />
      <path d="M15.5389 18.0789H9.74927V19.8156H15.5389V18.0789Z" fill="#49EBAA" />
      <path
        d="M17.8532 5.21559C17.8528 5.19893 17.8519 5.18226 17.8516 5.1656C17.8506 5.14092 17.85 5.11656 17.8487 5.0922C17.8477 5.07073 17.8464 5.04894 17.8452 5.02747C17.8442 5.00888 17.8429 4.99029 17.8416 4.9717C17.84 4.94702 17.8378 4.92235 17.8359 4.89767C17.8346 4.88325 17.8333 4.86882 17.832 4.8544C17.8294 4.82716 17.8269 4.79992 17.824 4.773C17.8227 4.76242 17.8218 4.75184 17.8205 4.74159C17.8169 4.71178 17.8134 4.68198 17.8096 4.65249C17.8089 4.64769 17.8083 4.6432 17.8077 4.63839C17.7996 4.57846 17.7903 4.51885 17.7804 4.45956C17.7788 4.4493 17.7772 4.43873 17.7753 4.42847C17.773 4.41629 17.7708 4.40411 17.7686 4.39193C17.7625 4.3586 17.7561 4.32559 17.7496 4.29258C17.7496 4.29226 17.7496 4.29162 17.7496 4.2913C17.7016 4.05254 17.6391 3.82371 17.5663 3.60513H17.559C16.8423 1.58124 14.9127 0.131348 12.6442 0.131348C10.3758 0.131348 8.44584 1.58124 7.7305 3.60513H7.72313C7.65038 3.82371 7.58789 4.05222 7.53981 4.2913C7.53981 4.29162 7.53981 4.29226 7.53981 4.29258C7.53308 4.32559 7.52699 4.3586 7.5209 4.39193C7.51866 4.40411 7.51642 4.41629 7.51417 4.42847C7.51225 4.43873 7.51065 4.4493 7.50905 4.45956C7.49879 4.51885 7.48982 4.57846 7.4818 4.63839C7.48116 4.6432 7.48052 4.64769 7.47988 4.65249C7.47604 4.68198 7.47251 4.71178 7.46898 4.74159C7.4677 4.75217 7.46642 4.76242 7.46546 4.773C7.46258 4.80024 7.45969 4.82716 7.45745 4.8544C7.45617 4.86882 7.45488 4.88325 7.4536 4.89767C7.45168 4.92235 7.44944 4.94702 7.44783 4.9717C7.44655 4.99029 7.44527 5.00888 7.44431 5.02747C7.44303 5.04894 7.44174 5.07041 7.44078 5.0922C7.4395 5.11656 7.43886 5.14124 7.4379 5.1656C7.43726 5.18226 7.43662 5.19893 7.43629 5.21559C7.43533 5.25758 7.43469 5.29956 7.43469 5.34187C7.43469 8.23653 9.75055 10.5524 12.6452 10.5524C15.5399 10.5524 17.8557 8.23653 17.8557 5.34187C17.8557 5.29956 17.8551 5.25758 17.8541 5.21559H17.8532Z"
        fill="#F0FF32"
      />
      <path d="M19.5916 8.81567H5.69678V10.5524H19.5916V8.81567Z" fill="#91DCF9" />
      <path
        d="M14.4365 7.60522H12.9514C12.0018 7.60522 11.2294 6.83252 11.2294 5.88323V3.89972C11.2294 2.95011 12.0018 2.17773 12.9514 2.17773H14.4365V3.16452H12.9514C12.5459 3.16452 12.2162 3.49431 12.2162 3.89972V5.88291C12.2162 6.28833 12.5459 6.61812 12.9514 6.61812H14.4365V7.60522Z"
        fill="#094754"
      />
      <path d="M13.4501 4.39819H10.4897V5.38498H13.4501V4.39819Z" fill="#094754" />
    </svg>
  </SvgIcon>
);

export default OrdersIcon;
