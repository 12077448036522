import { Box, Button } from '@mui/material';
import EmbedFile from '../../EmbedFile';

import useModal from '../../../../hooks/useModal';
type ConfirmUploadFileModalProps = {
  file: File;
  onAccept: (file: File) => void;
};
const ConfirmUploadFileModal = (props: ConfirmUploadFileModalProps) => {
  const { onAccept, file } = props;
  const { closeModal } = useModal();
  return (
    <Box width={'100%'} height={'100%'} minHeight={'100%'} overflow={'hidden'}>
      <EmbedFile file={file} />
      <Box
        p={1}
        position={'absolute'}
        bottom={0}
        left={0}
        right={0}
        sx={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}
      >
        <Button
          size="medium"
          color={'primary'}
          variant={'outlined'}
          onClick={() => {
            closeModal();
          }}
        >
          Annulla
        </Button>
        <Button
          size="medium"
          color={'primary'}
          variant={'contained'}
          onClick={() => {
            onAccept(file);
            closeModal();
          }}
        >
          Conferma
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmUploadFileModal;
