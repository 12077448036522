import { createSlice } from '@reduxjs/toolkit';

// types
import { SnackbarActionProps, SnackbarProps } from 'types/snackbar';

const initialState: SnackbarProps = {
  open: false,
  message: '',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  variant: 'info',
  transition: 'SlideLeft',
  persist: undefined,
  snackKey: '',
  count: 0
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action: SnackbarActionProps) {
      const { message, anchorOrigin, variant, transition, persist } = action.payload;

      state.open = true;
      state.variant = variant;
      state.message = message;
      state.transition = transition || initialState.transition;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.persist = persist != null ? persist : initialState.persist;
      state.snackKey = `snackbar-${state.count + 1}`;
      state.count += 1;
    },

    closeSnackbar(state) {
      state.open = false;
      state.count -= 1;
      // state.snackKey = action.payload;
    }
  }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
