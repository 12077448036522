import { Stack, Typography } from '@mui/material';
import { StarBorder } from '@mui/icons-material';

const AlertFreeLicense = () => {
  return (
    <Stack
      sx={(theme) => ({
        width: '100%',
        // minHeight: 200,
        paddingY: '50px',
        paddingX: '10px',
        borderTop: `4px solid ${theme.palette.success.main}`,
        backgroundColor: theme.palette.success.light,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        alignItems: 'center',
        justifyContent: 'center'
      })}
    >
      <Stack flexDirection={'row'} gap={2}>
        <StarBorder color={'success'} fontSize={'large'} />
        <Typography variant={'h5'} fontWeight={500} maxWidth={600}>
          Per ringraziarti della fiducia dimostrata, solo per te e i nostri primi clienti, buyplus ti{' '}
          <span style={{ fontWeight: 700 }}>sconta e regala</span> la fee di licenza su questo ordine.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AlertFreeLicense;
