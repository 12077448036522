import { OrderDTO, OrderPurchaseConfiguration, OrderRentConfiguration } from '../../../../../types/dto/orders.dto';
import MainCard from '../../MainCard';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { downloadFile } from '../../../../../utils/downloadFile';
import { dispatch } from '../../../../../store';
import { openSnackbar } from '../../../../../store/reducers/snackbar';
import useAxiosIstance from '../../../../../hooks/useAxiosIstance';
import LoadingButton from '../../../LoadingButton';
import BillingReview from './section/BillingReview';
import OrderInfoReview from './section/OrderInfoReview';
import CreditAndPaymentReview from './section/CreditAndPaymentReview';
import TotalReview from './section/TotalReview';

type OrderReviewProps = {
  order: Omit<OrderDTO, 'orderConfigurations' | 'activeConfiguration'> & {
    userConfiguration: OrderDTO['activeConfiguration'] | OrderPurchaseConfiguration | OrderRentConfiguration;
  };
};

const OrderReview = (props: OrderReviewProps) => {
  const { order } = props;
  const { axiosInstance } = useAxiosIstance();
  const [downloadLoading, setDownloadLoading] = useState(false);

  return (
    <MainCard
      title={<Typography variant={'h6'}>RIEPILOGO</Typography>}
      borderColor={'#C8D600'}
      padding={'20px 40px'}
      action={
        <LoadingButton
          loading={downloadLoading}
          variant={'outlined'}
          size={'small'}
          onClick={() => {
            setDownloadLoading(true);
            downloadFile({
              fileName: `riepilogo-${order.code}.pdf`,
              url: `/orders/${order.code}/summary/${order.userConfiguration.id}`,
              axiosInstance
            }).then(() => {
              setDownloadLoading(false);
              dispatch(
                openSnackbar({
                  variant: 'success',
                  message: 'Riepilogo scaricato con successo'
                })
              );
            });
          }}
        >
          Scarica riepilogo
        </LoadingButton>
      }
    >
      <Grid container rowSpacing={2} columnSpacing={2}>
        <BillingReview billingInfo={order.billingInfo} />

        <Grid item xs={12} mt={4}>
          <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h5'}>{order.title}</Typography>
            <Typography variant={'h5'} fontWeight={400} color={'text.disabled'}>
              {order.type === 'purchase' ? 'Acquisto' : 'Noleggio'}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} mb={1}>
          <Divider orientation={'horizontal'} variant={'fullWidth'} />
        </Grid>

        {/*Ordine Acquisto*/}
        <OrderInfoReview
          orderInfo={{
            type: order.type,
            orderCategory: order.orderCategory,
            createdAt: order.createdAt,
            shippingAddress: order.shippingAddress,
            deliveryDateHistory: order.deliveryDateHistory,
            userConfiguration: order.userConfiguration
          }}
        />

        {/*Credito e pagamenti*/}
        <CreditAndPaymentReview creditAndPaymentReview={order} />

        <Grid item xs={12} my={1}>
          <Divider />
        </Grid>

        {/*Preventivi e note - Totale*/}
        <TotalReview
          order={{
            shippingNotes: order.shippingNotes,
            userConfiguration: order.userConfiguration
          }}
        />
      </Grid>
    </MainCard>
  );
};

export default OrderReview;
