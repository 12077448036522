import { Box, Divider, Fade, FormHelperText, Grid, InputLabel, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import OrderSummaryCard from '../../components/custom/Cards/OrderSummary/OrderSummaryCard';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import AddressBox from '../../components/custom/Cards/ShippingBoxes/AddressBox';
import NewAddressBox from '../../components/custom/Cards/ShippingBoxes/NewAddressBox';
import EditAddressForm from '../settings/AddressForms/EditAddressForm';
import useModal from '../../hooks/useModal';
import { ShippingAddress } from '../../types/dto/orders.dto';
import { useEndpoint } from '../../hooks/useEndpoint';
import NewAddressForm from '../settings/AddressForms/NewAddressForm';
import { ErrorMessage, FormikHelpers, FormikProvider, getIn, useFormik } from 'formik';
import { NewOrderFormikStep4 } from '../../types/formik/new-order/new-order-step-4';
import * as Yup from 'yup';
import { NewOrderStepProps } from '../../types/formik/new-order/new-order-step-props';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import BillingBox from '../../components/custom/Cards/BillingBoxes/BillingBox';
import NewBillingForm from '../settings/BillingForms/NewBillingForm';
import { BillingInfoDto } from '../../types/dto/billing-info.dto';
import EditBillingForm from '../settings/BillingForms/EditBillingForm';
// import DefaultAddressForm from '../settings/AddressForms/DefaultAddressForm';
// import DeleteAddressForm from '../settings/AddressForms/DeleteAddressForm';
// import DeleteBillingForm from '../settings/BillingForms/DeleteBillingForm';
// import DefaultBillingForm from '../settings/BillingForms/DefaultBillingForm';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import { useSelector } from '../../store';
import { SingleDataDTO } from '../../types/server/single-data';
import DocumentTableRow from '../settings/Documents/DocumentTableRow';
import { useOutletContext } from 'react-router';

// {
//   orderId,
//     handleNextStep,
//     orderSummary,
//     setLoadingButton,
//     refetchOrderSummary,
//     setFormikErrors
// }: NewOrderStepProps
const NewOrderStep4 = () => {
  const {
    initialRenders,
    setNavigateLoading,
    navigateLoading,
    orderSummary,
    refetchOrderSummary,
    orderId,
    setLoadingButton,
    handleNextStep,
    setFormikErrors,
    setInitialRenders
  } = useOutletContext<NewOrderStepProps>();
  const theme = useTheme();
  const { openModal } = useModal();
  const { trackAction } = useNavigationTracker();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector((state) => state.auth);

  const updateOrderStep4 = useEndpoint<
    Omit<NewOrderFormikStep4, 'shippingAddressLength' | 'billingAddressLength' | 'documentsToUploads'>,
    'patch'
  >({
    method: 'patch',
    endpoint: `/orders/${orderId}`,
    mutationKey: `/orders/${orderId}/shippingAddresses`
  });

  const getDefaultDeliveryDays = useEndpoint<SingleDataDTO<number>, 'get'>({
    method: 'get',
    endpoint: '/info/defaultDeliveryDays',
    queryKey: 'get-info',
    options: {
      onSuccess: (data) => {
        formStep4.setValues({
          ...formStep4.values,
          deliveryDate: dayjs().add(data.data.data, 'days').toISOString()
        });
      }
    }
  });

  const shippingAddressList = useEndpoint<SingleDataDTO<ShippingAddress[]>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${user?.building.id}/shipping-addresses`,
    queryKey: 'get-shipping-addresses',
    options: {
      onSuccess: (data) => {
        formStep4.setValues({
          ...formStep4.values,
          shippingAddressLength: data.data.data.length
        });
      }
    }
  });

  const billingInfosList = useEndpoint<SingleDataDTO<BillingInfoDto[]>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${user?.building.id}/billing-infos`,
    queryKey: 'get-billing-infos',
    options: {
      onSuccess: (data) => {
        formStep4.setValues({
          ...formStep4.values,
          billingAddressLength: data.data.data.length
        });
      }
    }
  });

  //SHIPPING ADDRESS MODALS
  // const openModalDefaultAddress = (
  //   address: ShippingAddress,
  //   setValues: FormikHelpers<NewOrderFormikStep4>['setValues'],
  //   values: NewOrderFormikStep4
  // ) => {
  //   openModal({
  //     title: 'Imposta indirizzo di default',
  //     content: (
  //       <DefaultAddressForm
  //         address={address}
  //         refetchAddress={shippingAddressList.refetch}
  //         onSuccessDefaultAddress={() => {
  //           if (address.id !== values.shippingAddressId) {
  //             updateOrderStep4.mutate({
  //               shippingAddressId: address.id
  //             });
  //             setValues({
  //               ...values,
  //               shippingAddressId: address.id
  //             });
  //           }
  //         }}
  //       />
  //     )
  //   });
  // };

  const openModalEditAddress = (address: ShippingAddress, isDefault: boolean, buildingId: number) => {
    openModal({
      title: `Modifica indirizzo ${address.fraction != null ? address.fraction.name.it + ' - ' : ''}${address.address} - ${
        address.city.name.it
      }`,
      titleEllipse: true,
      content: (
        <EditAddressForm address={address} isDefault={isDefault} buildingId={buildingId} refetchAddress={shippingAddressList.refetch} />
      )
    });
  };

  // const openModalDeleteAddress = (
  //   address: ShippingAddress,
  //   setValues: FormikHelpers<NewOrderFormikStep4>['setValues'],
  //   values: NewOrderFormikStep4
  // ) => {
  //   openModal({
  //     title: `Conferma eliminazione indirizzo`,
  //     content: (
  //       <DeleteAddressForm
  //         address={address}
  //         refetchAddress={shippingAddressList.refetch}
  //         onSuccessDeleteAddress={() => {
  //           if (address.id === values.shippingAddressId) {
  //             setValues({
  //               ...values,
  //               shippingAddressId: null
  //             });
  //           }
  //         }}
  //       />
  //     )
  //   });
  // };

  const openModalNewAddress = (
    buildingId: number,
    setValues: FormikHelpers<NewOrderFormikStep4>['setValues'],
    values: NewOrderFormikStep4
  ) => {
    openModal({
      title: 'Aggiungi un nuovo indirizzo',
      content: (
        <NewAddressForm
          buildingId={buildingId}
          refetchAddress={shippingAddressList.refetch}
          onSuccessAddAddress={(addressId) => {
            console.log('imposto ordine di default');
            updateOrderStep4.mutate({
              shippingAddressId: addressId
            });
            setValues({
              ...values,
              shippingAddressId: addressId
              // shippingAddressLength: values.shippingAddressLength + 1
            });
          }}
        />
      )
    });
  };

  //BILLINGS INFO MODALS
  const openModalNewBilling = (
    buildingId: number,
    setValues: FormikHelpers<NewOrderFormikStep4>['setValues'],
    values: NewOrderFormikStep4
  ) => {
    openModal({
      title: 'Aggiungi dati di fatturazione',
      content: (
        <NewBillingForm
          buildingId={buildingId}
          refetchBillings={billingInfosList.refetch}
          onSuccessAddBillings={(billing) => {
            console.log('imposto ordine di default', billing);
            setValues({
              ...values,
              billingInfoId: billing.id
              // billingAddressLength: values.billingAddressLength + 1
            });
            updateOrderStep4.mutate({
              billingInfoId: billing.id
            });
          }}
        />
      )
    });
  };

  const openModalEditBillings = (billing: BillingInfoDto, isDefault: boolean) => {
    console.log(billing);
    openModal({
      title: `Modifica dati fatturazione`,
      titleEllipse: true,
      content: <EditBillingForm billing={billing} refetchBillings={billingInfosList.refetch} isDefault={isDefault} />
    });
  };

  // const openModalDeleteBilling = (
  //   billing: BillingInfoDto,
  //   setValues: FormikHelpers<NewOrderFormikStep4>['setValues'],
  //   values: NewOrderFormikStep4,
  //   setErrors: FormikHelpers<NewOrderFormikStep4>['setErrors']
  // ) => {
  //   openModal({
  //     title: `Conferma eliminazione dati di fatturazione`,
  //     content: (
  //       <DeleteBillingForm
  //         billing={billing}
  //         refetchBillings={refetchOrderSummary}
  //         onSuccessDeleteBillings={() => {
  //           if (billing.id === values.billingInfoId) {
  //             setValues({
  //               ...values,
  //               billingInfoId: null
  //             });
  //           }
  //           if (values.billingAddressLength === 1) {
  //             setErrors({
  //               billingAddressLength: 'Aggiungi un indirizzo di fatturazione'
  //             });
  //           }
  //         }}
  //       />
  //     ),
  //     maxHeight: '220px'
  //   });
  // };

  // const openModalDefaultBillings = (
  //   billing: BillingInfoDto,
  //   setValues: FormikHelpers<NewOrderFormikStep4>['setValues'],
  //   values: NewOrderFormikStep4
  // ) => {
  //   openModal({
  //     title: 'Imposta dati di fatturazione come predefinito',
  //     content: (
  //       <DefaultBillingForm
  //         billing={billing}
  //         refetchBillings={refetchOrderSummary}
  //         onSuccessDefault={() => {
  //           if (billing.id !== values.billingInfoId) {
  //             updateOrderStep4.mutate({
  //               billingInfoId: billing.id
  //             });
  //             setValues({
  //               ...values,
  //               billingInfoId: billing.id
  //             });
  //           }
  //         }}
  //       />
  //     ),
  //     maxHeight: '230px'
  //   });
  // };

  const formStep4 = useFormik<NewOrderFormikStep4>({
    enableReinitialize: false,
    initialValues: {
      deliveryDate: orderSummary?.deliveryDateHistory[0] ?? dayjs().add(1, 'month').toISOString(),
      shippingNotes: orderSummary?.shippingNotes ?? undefined,
      shippingAddressId: orderSummary!.shippingAddress?.id ?? null,
      shippingAddressLength: shippingAddressList.data?.data.data.length ?? 0,
      billingInfoId: orderSummary!.billingInfo?.id ?? null,
      billingAddressLength: billingInfosList.data?.data.data.length ?? 0,
      documentsToUploads:
        orderSummary?.documents.filter((d) => d.signatureType !== 'check' && d.userUpload == null && !d.optional).length ?? 0
    },
    validationSchema: Yup.object().shape({
      deliveryDate: Yup.string().required('Inserisci una data'),
      shippingAddressId: Yup.number().required('Seleziona o aggiungi un indirizzo di spedizione'),
      billingInfoId: Yup.number().required('Seleziona o aggiungi dati di fatturazione'),
      // shippingAddressLength: Yup.number().min(1, 'Aggiungi un indirizzo di spedizione'),
      // billingAddressLength: Yup.number().min(1, 'Aggiungi dati di fatturazione'),
      documentsToUploads: Yup.number().max(0, 'Carica tutti i documenti richiesti')
    }),
    onSubmit: (values) => {
      setLoadingButton(true);
      updateOrderStep4.mutate(
        {
          deliveryDate: values.deliveryDate,
          shippingNotes: values.shippingNotes,
          shippingAddressId: values.shippingAddressId,
          billingInfoId: values.billingInfoId,
          lastStep: 5
        },
        {
          onSuccess: async () => {
            await refetchOrderSummary();
            setLoadingButton(false);
            handleNextStep(5);
          }
        }
      );
      trackAction({
        type: ClientTypeEnum.Form,
        action: ClientActionEnum.Submit,
        name: `E' andato allo step 5 dell'ordine: ${orderId}`
      });
    }
  });

  useEffect(() => {
    if (Object.keys(formStep4.touched).length === 0) return;
    setFormikErrors(formStep4.errors);
  }, [formStep4.errors, formStep4.touched]);

  useEffect(() => {
    if (orderSummary != null)
      formStep4.setValues({
        ...formStep4.values,
        documentsToUploads: orderSummary.documents.filter((d) => d.signatureType !== 'check' && d.userUpload == null && !d.optional).length
      });
  }, [orderSummary]);

  useEffect(() => {
    // const paths = location.pathname.split('/');
    // const urlStep = paths[paths.length - 1]?.split('-')[1];

    if (orderSummary.lastStep > 4 && !initialRenders.step4 && initialRenders.step3) {
      console.log('navigo allo step 5');
      handleNextStep(5);
      // navigate(`/orders/new-order/${orderSummary.code}/${textToUrl(orderSummary.title)}/step-5`);
      setNavigateLoading(true);
      setInitialRenders({
        ...initialRenders,
        step4: true
      });
    } else if (!initialRenders.step4 && initialRenders.step3) {
      setNavigateLoading(false);
      setInitialRenders({
        ...initialRenders,
        step4: true
      });
    }
  }, [initialRenders]);

  if (shippingAddressList.isLoading || billingInfosList.isLoading || getDefaultDeliveryDays.isLoading || navigateLoading) return <></>;

  return orderSummary != null && orderId != null ? (
    <Fade in>
      <Box>
        <Typography variant="h5">Carica i documenti</Typography>
        <Typography mt={1} mb={2} variant={'body2'}>
          Carica e/o aggiorna i tuoi documenti per sbloccare le soluzioni di credito selezionate
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <FormikProvider value={formStep4}>
          <form noValidate onSubmit={formStep4.handleSubmit} id={'new-order-step-4'}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Box>
                  {orderSummary.documents
                    .filter((d) => d.signatureType !== 'check')
                    .map((doc) => (
                      <DocumentTableRow
                        doc={doc}
                        partner={'order'}
                        reference={orderId}
                        refetchDocuments={refetchOrderSummary}
                        key={doc.id != null ? doc.id : doc.autoRequestId}
                      />
                    ))}
                  {formStep4.errors.documentsToUploads && formStep4.touched.documentsToUploads && (
                    <FormHelperText error>{formStep4.errors.documentsToUploads}</FormHelperText>
                  )}
                </Box>
                <Divider sx={{ my: 3 }} />

                <InputLabel variant={'standard'}>Quando vorresti ricevere il prodotto / servizio?</InputLabel>
                {!isMobile ? (
                  <DatePicker
                    disablePast
                    minDate={dayjs().add(getDefaultDeliveryDays.data?.data.data ?? 30, 'days')}
                    formatDensity={'dense'}
                    value={dayjs(formStep4.values.deliveryDate)}
                    onChange={(value) => {
                      if (value == null) return;
                      formStep4.setValues({
                        ...formStep4.values,
                        deliveryDate: value.toISOString()
                      });
                      trackAction({
                        type: ClientTypeEnum.Input,
                        action: ClientActionEnum.Typed,
                        name: `Ha selezionato la data di spedizione: ${value.format('DD/MM/YYYY')}`
                      });
                    }}
                    slotProps={{
                      textField: {
                        id: 'shipping_date_picker',
                        error: Boolean(formStep4.errors.deliveryDate && formStep4.touched.deliveryDate),
                        size: 'small',
                        onBlur: (e) => {
                          formStep4.handleBlur(e);
                        }
                      }
                    }}
                  />
                ) : (
                  <MobileDatePicker
                    disablePast
                    formatDensity={'dense'}
                    minDate={dayjs().add(getDefaultDeliveryDays.data?.data.data ?? 30, 'days')}
                    value={dayjs(formStep4.values.deliveryDate)}
                    onChange={(value) => {
                      if (value == null) return;
                      formStep4.setValues({
                        ...formStep4.values,
                        deliveryDate: value.toISOString()
                      });
                      trackAction({
                        type: ClientTypeEnum.Input,
                        action: ClientActionEnum.Typed,
                        name: `Ha selezionato la data di spedizione: ${value.format('DD/MM/YYYY')}`
                      });
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(formStep4.errors.deliveryDate && formStep4.touched.deliveryDate),
                        size: 'small',
                        onBlur: (e) => {
                          formStep4.handleBlur(e);
                        }
                      }
                    }}
                  />
                )}
                <ErrorMessage name={'deliveryDate'} render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>} />

                <Stack flexDirection={'row'} alignItems={'flex-start'} gap={'20px'} flexWrap={'wrap'} mt={3}>
                  <Box>
                    <InputLabel variant={'standard'}>Indirizzo di spedizione</InputLabel>
                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      gap={'20px'}
                      sx={{ paddingRight: { xs: 0, md: 5 }, justifyContent: 'flex-start' }}
                    >
                      {shippingAddressList.data?.data.data.map((address) => {
                        return (
                          <AddressBox
                            key={address.id}
                            isSelected={formStep4.values.shippingAddressId === address.id}
                            isDefault={address.$.isDefault}
                            address={address}
                            // onClickDefaultAddress={() => {
                            //   openModalDefaultAddress(address, formStep4.setValues, formStep4.values);
                            //   trackAction({
                            //     type: ClientTypeEnum.Button,
                            //     action: ClientActionEnum.Clicked,
                            //     name: `Ha aperto il modal per impostare l'indirizzo di spedizione come default`
                            //   });
                            // }}
                            // onClickDeleteAddress={() => {
                            //   openModalDeleteAddress(address, formStep4.setValues, formStep4.values);
                            //   trackAction({
                            //     type: ClientTypeEnum.Button,
                            //     action: ClientActionEnum.Clicked,
                            //     name: `Ha aperto il modal per l'eliminazione dell'indirizzo di spedizione`
                            //   });
                            // }}
                            onClickEditAddress={(address) => {
                              openModalEditAddress(address, address.$.isDefault, orderSummary!.building.id);

                              trackAction({
                                type: ClientTypeEnum.Button,
                                action: ClientActionEnum.Clicked,
                                name: `Ha aperto il modal per la modifica dell'indirizzo di spedizione`
                              });
                            }}
                            onClickBox={() => {
                              formStep4.setValues({
                                ...formStep4.values,
                                shippingAddressId: address.id
                              });
                              trackAction({
                                type: ClientTypeEnum.Button,
                                action: ClientActionEnum.Clicked,
                                name: `Ha selezionato l'indirizzo di spedizione`
                              });
                            }}
                          />
                        );
                      })}
                      {shippingAddressList.data?.data.data.length === 0 && (
                        <NewAddressBox
                          title={'Nuovo indirizzo di spedizione'}
                          type={'address'}
                          onClick={() => {
                            openModalNewAddress(orderSummary!.building.id, formStep4.setValues, formStep4.values);
                            trackAction({
                              type: ClientTypeEnum.Button,
                              action: ClientActionEnum.Clicked,
                              name: `Ha aperto il modal per l'aggiunta di un nuovo indirizzo di spedizione`
                            });
                          }}
                        />
                      )}
                    </Box>
                    {!getIn(formStep4.errors, 'shippingAddressLength') && (
                      <ErrorMessage
                        name={'shippingAddressId'}
                        render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                      />
                    )}
                    <ErrorMessage
                      name={'shippingAddressLength'}
                      render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                    />
                  </Box>

                  <Box>
                    <InputLabel variant={'standard'}>Dati di fatturazione</InputLabel>
                    <Box
                      display={'flex'}
                      flexWrap={'wrap'}
                      gap={'20px'}
                      sx={{ paddingRight: { xs: 0, md: 5 }, justifyContent: 'flex-start' }}
                    >
                      {billingInfosList.data?.data.data.map((billing) => {
                        return (
                          <BillingBox
                            key={billing.id}
                            data-cy={'billing_address_box'}
                            isSelected={formStep4.values.billingInfoId === billing.id}
                            // isDefault={billing.id === orderSummary.building.defaultBillingInfoId}
                            billing={billing}
                            onClickEdit={(billing) => {
                              openModalEditBillings(billing, billing.$.isDefault);

                              trackAction({
                                type: ClientTypeEnum.Button,
                                action: ClientActionEnum.Clicked,
                                name: `Ha aperto il modal per la modifica dei dati di fatturazione`
                              });
                            }}
                            // onClickDefault={() => {
                            //   openModalDefaultBillings(billing, formStep4.setValues, formStep4.values);
                            //   trackAction({
                            //     type: ClientTypeEnum.Button,
                            //     action: ClientActionEnum.Clicked,
                            //     name: `Ha aperto il modal per impostare i dati di fatturazione come default`
                            //   });
                            // }}
                            // onClickDelete={() => {
                            //   openModalDeleteBilling(billing, formStep4.setValues, formStep4.values, formStep4.setErrors);
                            //   trackAction({
                            //     type: ClientTypeEnum.Button,
                            //     action: ClientActionEnum.Clicked,
                            //     name: `Ha aperto il modal per l'eliminazione dei dati di fatturazione`
                            //   });
                            // }}
                            onClickBox={() => {
                              formStep4.setValues({
                                ...formStep4.values,
                                billingInfoId: billing.id
                              });
                              // trackAction({
                              //   type: ClientTypeEnum.Button,
                              //   action: ClientActionEnum.Clicked,
                              //   name: `Ha selezionato i dati di fatturazione`
                              // });
                            }}
                          />
                        );
                      })}

                      {billingInfosList.data?.data.data.length === 0 && (
                        <NewAddressBox
                          title={'Aggiungi dati di fatturazione'}
                          type={'billing'}
                          onClick={() => {
                            openModalNewBilling(orderSummary!.building.id, formStep4.setValues, formStep4.values);
                            trackAction({
                              type: ClientTypeEnum.Button,
                              action: ClientActionEnum.Clicked,
                              name: `Ha aperto il modal per l'aggiunta dei dati di fatturazione`
                            });
                          }}
                        />
                      )}
                    </Box>
                    {!getIn(formStep4.errors, 'billingAddressLength') && (
                      <ErrorMessage
                        name={'billingInfoId'}
                        render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                      />
                    )}
                    <ErrorMessage
                      name={'billingAddressLength'}
                      render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                    />
                  </Box>
                </Stack>

                <Box mt={4}>
                  <InputLabel variant={'standard'}>Note</InputLabel>
                  <TextField
                    fullWidth
                    placeholder={'Campo note'}
                    sx={{ maxWidth: 620 }}
                    name={'shippingNotes'}
                    onChange={formStep4.handleChange}
                    onBlur={(e) => {
                      formStep4.handleBlur(e);
                      trackAction({
                        type: ClientTypeEnum.Input,
                        action: ClientActionEnum.Typed,
                        name: `Ha inserito una nota all'ordine: ${e.target.value}`
                      });
                    }}
                    value={formStep4.values.shippingNotes ?? ''}
                    minRows={6}
                    multiline
                    maxRows={20}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Box display={'flex'} width={'100%'} justifyContent={{ xs: 'center', lg: 'flex-start' }}>
                  <OrderSummaryCard order={orderSummary!} refetchOrderSummary={refetchOrderSummary} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </Box>
    </Fade>
  ) : (
    <></>
  );

  // return orderSummary != null && orderId != null ? (
  //   <Fade in>
  //     <Box>
  //       <Typography gutterBottom variant="h5">
  //         Completa le informazioni su luogo e data desiderata
  //       </Typography>
  //
  //       <Divider sx={{ mb: 3 }} />
  //       <Formik<NewOrderFormikStep4 & { shippingAddressLength: number, billingsAddressLength: number }>
  //         initialValues={{
  //           deliveryDate: orderSummary?.deliveryDate ?? '',
  //           shippingNotes: orderSummary?.shippingNotes ?? '',
  //           shippingAddressId: orderSummary.shippingAddressId ?? null,
  //           shippingAddressLength: orderSummary.building.shippingAddresses.length
  //         }}
  //         validationSchema={Yup.object().shape({
  //           deliveryDate: Yup.string().required('Inserisci una data'),
  //           shippingAddressId: Yup.number().required('Seleziona un indirizzo di spedizione'),
  //           shippingAddressLength: Yup.number().min(1, 'Aggiungi un indirizzo di spedizione')
  //         })}
  //         onSubmit={(values, { setErrors }) => {
  //           setLoadingButton(true);
  //           updateOrderStep4.mutate(
  //             {
  //               deliveryDate: values.deliveryDate,
  //               shippingNotes: values.shippingNotes,
  //               shippingAddressId: values.shippingAddressId,
  //               lastStep: 4
  //             },
  //             {
  //               onSuccess: () => {
  //                 setLoadingButton(false);
  //                 handleNextStep(4);
  //               }
  //             }
  //           );
  //         }}
  //       >
  //         {({ values, handleChange, handleSubmit, errors, touched, setValues, handleBlur }) => (
  //           <form noValidate onSubmit={handleSubmit} id={'new-order-step-3'}>
  //             <Grid container spacing={3}>
  //               <Grid item xs={12} md={6} lg={8}>
  //                 <InputLabel variant={'standard'}>Quando vorresti ricevere il prodotto / servizio?</InputLabel>
  //                 {!isMobile ? (
  //                   <DatePicker
  //                     formatDensity={'dense'}
  //                     value={dayjs(values.deliveryDate)}
  //                     onChange={(value) => {
  //                       if (value == null) return;
  //                       setValues({
  //                         ...values,
  //                         deliveryDate: value.toISOString()
  //                       });
  //                     }}
  //                     slotProps={{
  //                       textField: {
  //                         error: Boolean(errors.deliveryDate && touched.deliveryDate),
  //                         size: 'small'
  //                       }
  //                     }}
  //                   />
  //                 ) : (
  //                   <MobileDatePicker
  //                     formatDensity={'dense'}
  //                     value={dayjs(values.deliveryDate)}
  //                     onChange={(value) => {
  //                       if (value == null) return;
  //                       setValues({
  //                         ...values,
  //                         deliveryDate: value.toISOString()
  //                       });
  //                     }}
  //                     slotProps={{
  //                       textField: {
  //                         error: Boolean(errors.deliveryDate && touched.deliveryDate),
  //                         size: 'small'
  //                       }
  //                     }}
  //                   />
  //                 )}
  //                 <ErrorMessage name={'deliveryDate'} render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>} />
  //
  //                 <Box mt={4}>
  //                   <InputLabel variant={'standard'}>Indirizzo di spedizione</InputLabel>
  //                   <Box
  //                     display={'flex'}
  //                     flexWrap={'wrap'}
  //                     gap={'20px'}
  //                     sx={{ paddingRight: { xs: 0, md: 5 }, justifyContent: 'flex-start' }}
  //                   >
  //                     {orderSummary?.building.shippingAddresses.map((address) => {
  //                       return (
  //                         <AddressBox
  //                           key={address.id}
  //                           isSelected={values.shippingAddressId === address.id}
  //                           isDefault={address.id === orderSummary.building.defaultShippingAddressId}
  //                           address={address}
  //                           onClickDefaultAddress={() => {
  //                             openModalDefaultAddress(address, setValues, values);
  //                           }}
  //                           onClickDeleteAddress={() => {
  //                             openModalDeleteAddress(address, setValues, values);
  //                           }}
  //                           onClickEditAddress={(address) =>
  //                             openModalEditAddress(
  //                               address,
  //                               address.id === orderSummary?.building.defaultShippingAddressId,
  //                               orderSummary!.building.id
  //                             )
  //                           }
  //                           onClickBox={() => {
  //                             setValues({
  //                               ...values,
  //                               shippingAddressId: address.id
  //                             });
  //                           }}
  //                         />
  //                       );
  //                     })}
  //                     <NewAddressBox
  //                       onClick={() => {
  //                         openModalNewAddress(orderSummary!.building.id, setValues, values);
  //                       }}
  //                     />
  //                   </Box>
  //                   {!getIn(errors, 'shippingAddressLength') && (
  //                     <ErrorMessage
  //                       name={'shippingAddressId'}
  //                       render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
  //                     />
  //                   )}
  //                   <ErrorMessage
  //                     name={'shippingAddressLength'}
  //                     render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
  //                   />
  //                 </Box>
  //
  //                 <Box mt={4}>
  //                   <InputLabel variant={'standard'}>Note</InputLabel>
  //                   <TextField
  //                     fullWidth
  //                     placeholder={'Campo note'}
  //                     sx={{ maxWidth: 620 }}
  //                     name={'shippingNotes'}
  //                     onChange={handleChange}
  //                     onBlur={handleBlur}
  //                     value={values.shippingNotes}
  //                     minRows={6}
  //                     multiline
  //                     maxRows={20}
  //                   />
  //                 </Box>
  //               </Grid>
  //               <Grid item xs={12} md={5} lg={4}>
  //                 <Box display={'flex'} width={'100%'} justifyContent={{ xs: 'center', lg: 'flex-start' }}>
  //                   <OrderSummaryCard order={orderSummary!} updateLoading={false} refetchOrderSummary={refetchOrderSummary} />
  //                 </Box>
  //               </Grid>
  //             </Grid>
  //           </form>
  //         )}
  //       </Formik>
  //     </Box>
  //   </Fade>
  // ) : (
  //   <></>
  // );
};

export default NewOrderStep4;
