import {
  MutationKey,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { DataToSendProps } from '../types/server/data-to-send-props';
import useAxiosInstance from './useAxiosIstance';
import { ErrorDTO } from '../types/server/error.dto';

type EndpointMethod = MutationMethod | QueryMethod;
type MutationMethod = 'post' | 'put' | 'patch' | 'delete';
type QueryMethod = 'get';

type UseEndpointProps<T, M extends EndpointMethod, R> = {
  endpoint: string;
  method: M;
  queryParams?: Partial<DataToSendProps>;
  headers?: Record<string, string>;
} & (M extends QueryMethod ? UseQueryProps<T> : M extends MutationMethod ? UseMutationProps<T, R> : never);

type UseQueryProps<T> = {
  queryKey: QueryKey;
  options?: Omit<
    UseQueryOptions<AxiosResponse<T>, AxiosError<ErrorDTO>>,
    'queryKey' | 'queryFn' | 'keepPreviousData' | 'retry' | 'refetchOnWindowFocus'
  >;
};

type UseMutationProps<T, R> = {
  mutationKey: MutationKey;
  options?: Omit<UseMutationOptions<AxiosResponse<R>, AxiosError<ErrorDTO>, T>, 'mutationFn' | 'mutationKey'>;
};

function isQueryProps(props: any): props is UseQueryProps<any> {
  return (props as UseQueryProps<any>).queryKey !== undefined;
}

function isMutationProps(props: any): props is UseMutationProps<any, any> {
  return (props as UseMutationProps<any, any>).mutationKey !== undefined;
}

function hasFileToSend(dataToSend: any): boolean {
  let fileToSend = false;
  if (typeof dataToSend === 'object') {
    for (let key in dataToSend) {
      if (dataToSend[key] instanceof window.File) {
        // @ts-ignore
        fileToSend = true;
        break;
      } else if (Array.isArray(dataToSend[key])) {
        // @ts-ignore
        const array = [...dataToSend[key]];

        if (array.length > 0 && array[0] instanceof window.File) {
          fileToSend = true;
        }
      } else if (typeof dataToSend[key] === 'object') {
        fileToSend = hasFileToSend(dataToSend[key]);
      }
    }
  }

  return fileToSend;
}

// export interface Type<T = any> extends Function {
//   new (...args: any[]): T;
// }

export const useEndpoint = <T, M extends EndpointMethod, R = any>(
  props: UseEndpointProps<T, M, R>
): M extends QueryMethod
  ? UseQueryResult<AxiosResponse<T>, AxiosError<ErrorDTO>>
  : UseMutationResult<AxiosResponse<R>, AxiosError<ErrorDTO>, T> => {
  const { axiosInstance } = useAxiosInstance();
  // const { rememberMe } = useSelector((state) => state.helpersAuth);
  const { method, endpoint } = props;

  if (isQueryProps(props)) {
    const { queryKey, options, queryParams, headers } = props;
    const queryFn = async () => {
      return await axiosInstance<T>({
        method,
        url: endpoint,
        params: queryParams,
        headers: {
          // Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`,
          ...headers
        }
      });
    };

    const result = useQuery<AxiosResponse<T>, AxiosError<ErrorDTO>>([queryKey, queryParams], queryFn, {
      keepPreviousData: true,
      retry: false,
      refetchOnMount: props.options?.refetchOnMount ?? false,
      refetchOnWindowFocus: false,
      ...options
    });

    return result as M extends QueryMethod
      ? UseQueryResult<AxiosResponse<T>, AxiosError<ErrorDTO>>
      : UseMutationResult<AxiosResponse<R>, AxiosError<ErrorDTO>, T>;
  } else if (isMutationProps(props)) {
    const { mutationKey, options, queryParams, headers } = props;
    const mutationFn = async (dataToSend: T) => {
      // //Controllo se ci sono dei file da inviare, controllare se il dataToSend è un oggetto
      // //Se è un oggetto e all'interno dell'oggetto è presente un valore che è un File allora lo setto a true
      const fileToSend = hasFileToSend(dataToSend);
      console.log(fileToSend);

      return (await axiosInstance<R>({
        method,
        url: endpoint,
        data: dataToSend,
        params: queryParams,
        headers: {
          // Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`,
          'Content-Type': fileToSend ? 'multipart/form-data' : 'application/json',
          ...headers
        }
      })) as AxiosResponse<R>;
    };

    const result = useMutation<AxiosResponse<R>, AxiosError<ErrorDTO>, T>(mutationKey, mutationFn, options);
    return result as M extends QueryMethod
      ? UseQueryResult<AxiosResponse<T>, AxiosError<ErrorDTO>>
      : UseMutationResult<AxiosResponse<R>, AxiosError<ErrorDTO>, T>;
  } else {
    throw new Error('Unexpected useEndpoint props type');
  }
};
