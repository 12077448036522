import { SyntheticEvent, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import {
  Box,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LoadingButton from '../../components/custom/LoadingButton';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import ContentCommonLayout from '../../layout/CommonLayout/ContentCommonLayout';
import IllustrationCommonLayout from '../../layout/CommonLayout/IllustrationCommonLayout';
import IllustrationSignUp from 'assets/images/illustrations/auth/sign_up.svg';
import FeedbackCommonLayout from '../../layout/CommonLayout/FeedbackCommonLayout';
import IllustrationVerifyEmail from '../../assets/images/illustrations/auth/conferma_mail.svg';
import PasswordSecurity from '../../components/custom/PasswordSecurity';
import { FastRegisterForm } from '../../types/formik/register-form';
import { useOpenFileModal } from '../../components/custom/EmbedFile';
import { useEndpoint } from '../../hooks/useEndpoint';
import { SingleDataDTO } from '../../types/server/single-data';
import { DocumentDto } from '../../types/dto/document.dto';
import { RegistrationEnum } from '../../types/auth';
import Typography from '@mui/material/Typography';

const FastRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrong, setPasswordStrong] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { fastRegister, getCodeCheckEmail } = useAuth();
  const [successRegister, setSuccessRegister] = useState(false);

  const openFileModal = useOpenFileModal();
  const platformContracts = useEndpoint<SingleDataDTO<DocumentDto[]>, 'get'>({
    method: 'get',
    endpoint: '/documents/public',
    queryKey: 'get-platform-contracts-fast-register',
    headers: {
      'uefvc-lhkih': 'OwO9XTbxbzn52z1zU00A'
    }
  });

  const navigate = useNavigate();
  const [emailNewVerification, setEmailNewVerification] = useState<string>();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  if (platformContracts.isLoading) {
    return <></>;
  }

  return !successRegister ? (
    <>
      <ContentCommonLayout>
        <Fade in>
          <Box maxWidth={{ xs: 600, xxl: 800 }} marginX={'auto'}>
            <Box>
              <Typography variant="h4" color="primary">
                <FormattedMessage id="title-register" />
              </Typography>
              <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
                Compila i campi e in pochi step potrai fare il tuo primo acquisto
              </Typography>
            </Box>
            <Formik<FastRegisterForm & { submit: null }>
              initialValues={{
                structureName: '',
                vat: '',
                email: '',
                password: '',
                confirmPassword: '',
                documents:
                  platformContracts.data?.data.data.map((c) => ({
                    ...c,
                    accepted: false
                  })) ?? [],
                submit: null
              }}
              validationSchema={Yup.object().shape({
                // structureName: Yup.string().max(255).required('structureName-error-required'),
                email: Yup.string().email('email-error-invalid').max(255).required('email-error-required'),
                password: Yup.string().max(255).required('password-error-required'),
                confirmPassword: Yup.string()
                  .required('password-confirm-required-error')
                  .oneOf([Yup.ref('password')], 'password-confirm-not-match-error'),
                acceptedPrivacyTerms: Yup.bool()
                  // .required('error-accepted-privacy-term-required')
                  .oneOf([true], 'error-accepted-privacy-term-required'),
                vat: Yup.string()
                  .matches(/^\d{11}$/, 'error-vat-number-length')
                  .required('error-vat-number-required'),
                documents: Yup.array().of(
                  Yup.object()
                    .shape({
                      accepted: Yup.boolean(),
                      optional: Yup.boolean()
                    })
                    .test('accepted-optional', 'Accetta la condizione', function (value) {
                      // const { accepted, optional } = this.parent;

                      if (!value.accepted && !value.optional) {
                        return false;
                      }

                      return true;
                    })
                )
              })}
              onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
                if (!passwordStrong) return;
                setLoading(true);
                setEmailNewVerification(values.email);
                fastRegister({
                  email: values.email,
                  password: values.password,
                  vat: values.vat,
                  confirmPassword: values.confirmPassword,
                  name: values.structureName !== '' ? values.structureName : undefined,
                  origin: RegistrationEnum.RegistrationFast,
                  documents: values.documents.map((d) => ({
                    autoRequestId: d.autoRequestId!,
                    accepted: d.accepted ?? false
                  }))
                })
                  .then(() => {
                    getCodeCheckEmail(values.email).then(() => {
                      setLoading(false);
                      setSubmitting(false);
                      setSuccessRegister(true);
                    });
                  })
                  .catch((err) => {
                    if (!axios.isAxiosError(err)) return;
                    setLoading(false);
                    let errorMessage: string;
                    if (err.response?.data.statusCode === 500) {
                      errorMessage = "C'è stato un errore nel server, riprovare più tardi.";
                      setErrors({ submit: errorMessage });
                    } else if (err.response?.data.statusCode === 400) {
                      const errors: { email?: string; vat?: string } = {};

                      err.response.data.message.forEach((message: string) => {
                        if (message === 'email non disponibile') {
                          errors.email = 'error-email-alredy-exists';
                        } else if (message === "user.l'email deve essere valida") {
                          errors.email = 'email-error-invalid';
                        } else if (message === 'partita iva non disponibile') {
                          errors.vat = 'error-vat-alredy-exists';
                        } else {
                          console.error(message);
                        }
                      });
                      setErrors(errors);
                    }

                    setStatus({ success: false });
                    setSubmitting(false);
                    setLoading(false);
                  });
              }}
            >
              {({ errors, handleBlur, handleChange, setValues, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3} paddingY={5}>
                    {/* Structure Name */}
                    <Grid item xs={12}>
                      <TextField
                        label={'Nome Struttura'}
                        variant="outlined"
                        id="structure-name-register"
                        type="text"
                        name="structureName"
                        value={values.structureName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(touched.structureName && errors.structureName)}
                      />
                      {touched.structureName && errors.structureName && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.structureName} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Input Partita IVA */}
                    <Grid item xs={12}>
                      <PatternFormat
                        format="###########"
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        label={<FormattedMessage id="vatNumber-label" />}
                        variant="outlined"
                        id="vat-number-register"
                        type="text"
                        name="vat"
                        value={values.vat}
                        onChange={(e) => setFieldValue('vat', e.target.value.replace(/\s/g, ''))}
                        fullWidth
                        error={Boolean(touched.vat && errors.vat)}
                        customInput={TextField}
                      />
                      <FormHelperText>Non potrà essere cambiata in seguito</FormHelperText>

                      {touched.vat && errors.vat && (
                        <FormHelperText error id="standard-weight-helper-text-vat-register">
                          <FormattedMessage id={errors.vat} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12}>
                      <TextField
                        label={<FormattedMessage id="email-label" />}
                        variant="outlined"
                        id="email-register"
                        type="email"
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        InputProps={{
                          autoComplete: 'email'
                        }}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.email} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* Password */}
                    <Grid item xs={12}>
                      {/* Input Password */}
                      <Stack>
                        <TextField
                          variant="outlined"
                          label={<FormattedMessage id="password-label" />}
                          fullWidth
                          error={Boolean(touched.password && errors.password) || (!passwordStrong && touched.password)}
                          id="password-reset"
                          type={showPassword ? 'text' : 'password'}
                          value={values.password}
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            autoComplete: 'new-password'
                          }}
                        />
                        {touched.password && errors.password && (
                          <FormHelperText error>
                            <FormattedMessage id={errors.password} />
                          </FormHelperText>
                        )}
                        {touched.password && !errors.password && !passwordStrong && (
                          <FormHelperText error>La password non è abbastanza sicura</FormHelperText>
                        )}
                      </Stack>

                      <Box mt={2}>
                        <PasswordSecurity
                          password={values.password}
                          setIsSafe={(v) => {
                            setPasswordStrong(v);
                          }}
                        />
                      </Box>
                    </Grid>

                    {/* Password Confirm */}
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        label={<FormattedMessage id="password-confirm-label" />}
                        fullWidth
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        id="confirm-password-reset"
                        type={showPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                          autoComplete: 'new-password'
                        }}
                      />
                      {touched.confirmPassword && errors.confirmPassword && (
                        <FormHelperText error>
                          <FormattedMessage id={errors.confirmPassword} />
                        </FormHelperText>
                      )}
                    </Grid>

                    {/* referral code*/}
                    {/*<Grid item xs={12}>*/}
                    {/*  <TextField*/}
                    {/*    label={'Referral Code'}*/}
                    {/*    variant="outlined"*/}
                    {/*    id="referral-code-register"*/}
                    {/*    type="text"*/}
                    {/*    name="referralCode"*/}
                    {/*    value={values.referralCode}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    fullWidth*/}
                    {/*    error={Boolean(touched.referralCode && errors.referralCode)}*/}
                    {/*  />*/}
                    {/*  <FormHelperText>Se hai un codice referral inseriscilo qui</FormHelperText>*/}
                    {/*  {touched.referralCode && errors.referralCode && (*/}
                    {/*    <FormHelperText error id="standard-weight-helper-text-referralCode-register">*/}
                    {/*      <FormattedMessage id={errors.referralCode} />*/}
                    {/*    </FormHelperText>*/}
                    {/*  )}*/}
                    {/*</Grid>*/}

                    {values.documents.map((d, i) => {
                      return (
                        <Grid item xs={12} key={i}>
                          <Box>
                            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                              <Checkbox
                                sx={{ padding: 0, marginRight: 1 }}
                                value={d.accepted}
                                // onBlur={handleBlur}
                                onChange={(event, checked) => {
                                  setValues({
                                    ...values,
                                    documents: values.documents.map((doc) => {
                                      return {
                                        ...doc,
                                        accepted: doc.autoRequestId === d.autoRequestId ? checked : doc.accepted
                                      };
                                    })
                                  });
                                }}
                                checked={d.accepted}
                                size="medium"
                              />
                              <Typography variant="body2">
                                Accetta <span style={{ fontWeight: 500 }}>"{d.title}"</span>{' '}
                                <span
                                  style={{ fontWeight: 500, cursor: 'pointer', textDecoration: 'underline' }}
                                  onClick={() =>
                                    openFileModal({
                                      title: d.title,
                                      fileCode: d.providedUpload!.code
                                    })
                                  }
                                >
                                  visualizza
                                </span>
                                {!d.optional && '*'}
                              </Typography>
                            </FormControl>
                            <ErrorMessage
                              name={`documents[${i}]`}
                              render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                            />
                          </Box>
                        </Grid>
                      );
                    })}

                    {/* Button Submit */}
                    {errors.submit && (
                      <Grid item xs={12}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <LoadingButton
                        loading={loading}
                        children={<FormattedMessage id="register-button" />}
                        // disabled={Object.keys(errors).length !== 0 || !passwordStrong || isSubmitting}
                        size={'large'}
                        variant="contained"
                        type="submit"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                      <Typography variant="body2" mt={2}>
                        Se sei già registrato effettua il
                        <Link component={RouterLink} to={'/login'} fontWeight={700} ml={0.8}>
                          login
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Fade>
      </ContentCommonLayout>
      <IllustrationCommonLayout>
        <Fade in>
          <img alt={'illustration fast register'} src={IllustrationSignUp} />
        </Fade>
        <Typography variant="h6" fontWeight={400} maxWidth={550} mt={10} textAlign={'center'}>
          Registrati, <span style={{ fontWeight: 600 }}>conferma via mail</span>, continua la compilazione dei dati e inizia ad usare
          buyplus
        </Typography>
      </IllustrationCommonLayout>
    </>
  ) : (
    <FeedbackCommonLayout>
      <img src={IllustrationVerifyEmail} alt={'illustrazione verifica email'} />
      <Typography color={'primary'} variant="h4" fontWeight={500} mt={7} textAlign={'center'} maxWidth={650}>
        Ti abbiamo inviato un <span style={{ fontWeight: 700 }}>link</span> sulla tua <span style={{ fontWeight: 700 }}>mail </span>
        per proseguire con la registrazione
      </Typography>
      <Typography variant={'body1'} mt={2}>
        Non hai ricevuto il link di conferma?{' '}
        <Link
          variant={'body1'}
          fontWeight={600}
          onClick={() => navigate('/new-email-verification', { state: { email: emailNewVerification } })}
        >
          Richiedine uno nuovo
        </Link>
      </Typography>
    </FeedbackCommonLayout>
  );
};

export default FastRegister;
