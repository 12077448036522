import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';

// types
import { openDrawer } from 'store/reducers/menu';
import Breadcrumb from 'components/custom/Breadcrumb';
import NotificationDrawer from './NotificationDrawer';
import { OutletWithError } from '../../components/custom/FallbackErrorBoundary';
import SimpleBar from 'simplebar';
import { useSelector } from '../../store';
import HolderBar from '../../components/custom/HolderBar';
import { useEndpoint } from '../../hooks/useEndpoint';
import { SingleDataDTO } from '../../types/server/single-data';
import { AppInfoDTO } from '../../types/dto/app-info.dto';
import { setAppInfo } from '../../store/reducers/appInfo';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { formattingPath } from '../../hooks/useGaPageView';
import RibbonEnvironment from './Header/RibbonEnvironment';

// import useScrollTrigger from '@mui/material/useScrollTrigger';

// ==============================|| MAIN LAYOUT ||============================== //
export let simpleBar: SimpleBar;

const MainLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  const { holder } = useSelector((state) => state.auth);
  const holderBarRef = useRef<HTMLDivElement | null>(null);
  const { trackPageView } = useNavigationTracker();
  useEndpoint<SingleDataDTO<AppInfoDTO>, 'get'>({
    method: 'get',
    endpoint: '/info/private',
    queryKey: 'get-info-app-private',
    options: {
      cacheTime: 0,
      onSuccess: (data) => {
        dispatch(
          setAppInfo({
            registeredEmail: data.data.data.registeredEmail,
            ordersEmail: data.data.data.ordersEmail
          })
        );
      }
    }
  });

  // drawer toggler
  const [open, setOpen] = useState<boolean>(drawerOpen);
  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const handleDrawerNotificationToggle = useCallback(() => {
    setOpenNotificationDrawer(!openNotificationDrawer);
  }, [openNotificationDrawer]);

  const simplebarRef = useRef<HTMLElement | null>(null);
  // const isScrolling = useScrollTrigger({
  //   target: simplebarRef.current
  // });

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (!location.pathname.includes('new-order')) {
      trackPageView({
        name: formattingPath(location.pathname),
        path: location.pathname
      });
    }

    setOpenNotificationDrawer(false);
    if (isMobile) {
      // setIsScrolling(true);
      if (open) {
        handleDrawerToggle();
      }
    }

    if (simplebarRef.current == null) return;

    if (!location.pathname.includes('faqs')) {
      simplebarRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    simplebarRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location, simplebarRef.current]);

  useEffect(() => {
    simpleBar = new SimpleBar(document.getElementById('main')!, {
      scrollbarMinSize: 150,
      scrollbarMaxSize: 250
    });
    simplebarRef.current = simpleBar.getScrollElement() as HTMLElement;

    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('root')?.classList.remove('body-bootstrap');
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);

  return (
    <Box width={'100%'} height={'100dvh'} display={'flex'} flexDirection={'column'}>
      {holder !== undefined && holder.$.hasAdminPermissions && (
        <div ref={holderBarRef}>
          <HolderBar />
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          height: holder !== undefined && holder.$.hasAdminPermissions ? `calc(100% - ${holderBarRef.current?.clientHeight}px)` : '100%'
        }}
      >
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <RibbonEnvironment />}
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <NotificationDrawer open={openNotificationDrawer} handleDrawerToggle={handleDrawerNotificationToggle} />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor: '#FDFCFC' }}>
          <Header
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            // isScrolling={!isScrolling}
            isScrolling={true}
            handleDrawerNotificationToggle={handleDrawerNotificationToggle}
          />
          <Box
            id={'main'}
            component={'main'}
            sx={{
              width: '100%',
              height: '100%',
              paddingTop: '20px',
              flexGrow: 1,
              overflowY: 'auto',
              margin: 0,
              overscrollBehavior: 'none',
              '&:focus-visible': { outline: 'none' }
            }}
          >
            <Box width={'100%'} height={'100%'} paddingX={isMobile ? 1.5 : 3} display={'flex'} flexDirection={'column'}>
              {!isMobile && !location.pathname.includes('new-order') && <Breadcrumb />}
              <Box flexGrow={1} minHeight={!isMobile ? 'calc(100% - 35px)' : '100%'} maxWidth="lg" paddingTop={isMobile ? 0 : '20px'}>
                <Suspense fallback={<></>}>
                  <OutletWithError />
                </Suspense>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
