import {
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme
} from '@mui/material';
import { PlafondState, WalletDTO } from 'types/dto/plafond.dto';
import ActiveChip from '../Chips/ActiveChip';
import useDrawer from '../../../hooks/useDrawer';
import BigNumber from 'bignumber.js';
import InProgressChip from '../Chips/InProgressChip';
import NotActiveChip from '../Chips/NotActiveChip';
import NotActivetedChip from '../Chips/NotActivetedChip';

import { Link, useNavigate } from 'react-router-dom';
// import useModal from '../../../hooks/useModal';
// import BuyplusTrialForm from '../../../sections/wallet/AdditionalDataForms/BuyplusTrialForm';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import WarningIcon from '@mui/icons-material/Warning';
import SecondaryCard from './SecondaryCard';

// import NotActiveIllustration from 'assets/images/illustrations/illustration_plafond_not_active.svg';
// import NotActivetedIllustration from 'assets/images/illustrations/illustration_plafond_not_activated.svg';
import { Error } from '@mui/icons-material';
import useModal from '../../../hooks/useModal';
import MoreCreditModal from '../Modal/MoreCreditModal';
import SlowCreditSolutionModal from '../Modal/SlowCreditSolutionModal';
import { BuildingDTO } from '../../../types/dto/building.dto';
import { openModalUploadPmsCode } from '../Modal/ModalUploadPmsCode';
import formatNumber from '../../../utils/formatNumber';
import { useState } from 'react';
import { TooltipInfo } from './OrderSummary/OrderSummaryCard';
import { ContactFormPresetEnum } from '../../../utils/contactFormPreset';

type PlafondCardProps = {
  plafond: WalletDTO;
  // buildingId: number;
  report: boolean;
  registrationCompleted: boolean;
  registrationMethod: BuildingDTO['registrationMethod'];
  registrationConfirmed: boolean;
  // boxStyle?: 'primary' | 'secondary';
  testMode?: boolean;
};

export const WarningTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.warning.main,
      color: '#ffff'
    },
    [`& .${tooltipClasses.arrow}`]: { color: theme.palette.warning.main }
  };
});

export const ErrorTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.error.main,
      color: '#ffff'
    },
    [`& .${tooltipClasses.arrow}`]: { color: theme.palette.error.main }
  };
});

const PlafondCard = ({ plafond, report, registrationCompleted, testMode, registrationConfirmed, registrationMethod }: PlafondCardProps) => {
  const theme = useTheme();
  const { openDrawer } = useDrawer();
  // const { openModal } = useModal();
  const navigate = useNavigate();
  const { trackAction } = useNavigationTracker();
  const { openModal } = useModal();
  const modalUploadPmsCode = openModalUploadPmsCode();
  const [logoLoading, setLogoLoading] = useState<boolean>(plafond.imageUploadCode != null);

  const plafondState =
    plafond.wallet != null
      ? plafond.wallet.state.key
      : !registrationCompleted && registrationConfirmed && registrationMethod !== 'pms'
      ? PlafondState.Pending
      : 'not-active';

  // const openAdditionalDataBuyplusTrial = () => {
  //   openModal({
  //     title: 'Dati aggiuntivi richiesti',
  //     content: <BuyplusTrialForm buildingId={buildingId} />,
  //     maxHeight: '320px'
  //   });
  // };

  // if (boxStyle == null || boxStyle === 'primary') {
  return (
    <SecondaryCard
      borderColor={plafond.color}
      title={
        <Box display={'flex'} alignItems={'center'} gap={'5px'}>
          <Typography variant="h6">{plafond.title}</Typography>
          {plafond.wallet &&
            plafond.wallet.documents.length > 0 &&
            plafond.wallet.documents.some(
              (ed) => (ed.userUpload == null && ed.signatureType !== 'check') || (ed.signatureType === 'check' && ed.signedAt == null)
            ) && (
              <WarningTooltip
                title={
                  <div>
                    <Typography variant={'body1'}>Documenti richiesti mancanti: </Typography>
                    <ul style={{ margin: 0, paddingLeft: '15px' }}>
                      {plafond.wallet.documents
                        .filter(
                          (ed) =>
                            (ed.userUpload == null && ed.signatureType !== 'check') || (ed.signatureType === 'check' && ed.signedAt == null)
                        )
                        .map((ed, i) => (
                          <Typography component={'li'} variant={'body2'} key={i}>
                            {ed.title}
                          </Typography>
                        ))}
                    </ul>
                  </div>
                }
                arrow
              >
                <WarningIcon
                  fontSize={'small'}
                  color={'primary'}
                  sx={{
                    '&:hover': {
                      transition: '.3s all',
                      color: theme.palette.warning.main
                    }
                  }}
                  onClick={() => {
                    navigate('/account/documents');
                    trackAction({
                      action: ClientActionEnum.Clicked,
                      type: ClientTypeEnum.Link,
                      name: `Inserisci i documenti mancanti dal warning del plafond "${plafond.title}"`
                    });
                  }}
                />
              </WarningTooltip>
            )}

          {testMode && plafond.wallet?.failureReason != null && (
            <ErrorTooltip
              title={
                <div>
                  <Typography variant={'body1'} color={'white'}>
                    {plafond.wallet.failureReason}
                  </Typography>
                </div>
              }
              arrow
            >
              <Error
                fontSize={'small'}
                sx={{
                  color: theme.palette.error.main
                }}
              />
            </ErrorTooltip>
          )}
        </Box>
      }
      chip={
        plafondState === 'not-active' ? (
          <NotActiveChip size={'small'} />
        ) : plafondState === 'pending' ? (
          <InProgressChip size={'small'} />
        ) : plafondState === 'approved' ? (
          <ActiveChip size="small" data-cy={'active_pladond_chip'} />
        ) : plafondState === 'refused' ? (
          <NotActivetedChip size={'small'} />
        ) : (
          <></>
        )
      }
    >
      <Grid container>
        <Grid item xs={12} minHeight={140}>
          <Stack height={'100%'} flexDirection={'column'} justifyContent={'space-between'}>
            <Box color={theme.palette.grey[500]} mb={2}>
              <div dangerouslySetInnerHTML={{ __html: plafond.excerpt }} />
              {/*{plafond.excerpt}*/}
              <span
                style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  openDrawer({ title: plafond.title, content: <div dangerouslySetInnerHTML={{ __html: plafond.description }} /> });
                  trackAction({
                    action: ClientActionEnum.Clicked,
                    type: ClientTypeEnum.Button,
                    name: `Ha aperto i dettagli del plafond  "${plafond.title}"`
                  });
                }}
              >
                {' '}
                Scopri di più
              </span>
            </Box>

            {plafond.hideNameIn != null && !plafond.hideNameIn.includes('wallet') ? (
              <>
                {plafond.imageUploadCode != null ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      img: {
                        width: 100,
                        objectFit: 'contain'
                      }
                    }}
                  >
                    <Typography color={theme.palette.primary.main}>Servizio di</Typography>
                    {logoLoading && <Skeleton variant={'rectangular'} sx={{ width: 100, height: 30 }} />}
                    <img
                      alt={'logo servizio'}
                      className={!logoLoading ? 'ms_fade-in' : undefined}
                      style={{ opacity: 0 }}
                      src={`${process.env.REACT_APP_SERVER_URL}api/${process.env.REACT_APP_API_VERSION}/uploads/${plafond.imageUploadCode}`}
                      onLoad={() => setLogoLoading(false)}
                    />
                  </Box>
                ) : (
                  <Typography color={theme.palette.primary.main}>
                    Servizio di <span style={{ fontWeight: 600 }}>{plafond.company}</span>
                  </Typography>
                )}
              </>
            ) : (
              <Typography color={theme.palette.primary.main}>Servizio erogato da partner</Typography>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'5px'} minHeight={170}>
          {plafondState === 'approved' && plafond.wallet != null && (
            <>
              {/*<Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>*/}
              {/*  <Box>*/}
              {/*    <Typography variant="body2" color={theme.palette.grey[400]}>*/}
              {/*      Data di attivazione*/}
              {/*    </Typography>*/}
              {/*    <Typography variant="body1" fontWeight={600} color={'grey'}>*/}
              {/*      {dayjs(plafond.wallet.state.at).format('DD MMMM YYYY')}*/}
              {/*    </Typography>*/}
              {/*  </Box>*/}
              {/*  <Box>*/}
              {/*    <Typography variant="body2" color={theme.palette.grey[400]}>*/}
              {/*      Durata*/}
              {/*    </Typography>*/}
              {/*    <Typography variant="body1" fontWeight={600} color={'grey'}>*/}
              {/*      {plafond.timeValidity} Mesi*/}
              {/*    </Typography>*/}
              {/*  </Box>*/}
              {/*</Stack>*/}

              <Divider sx={{ my: 2 }} />

              {/*<Box*/}
              {/*  sx={{*/}
              {/*    display: 'flex',*/}
              {/*    alignItems: 'flex-start',*/}
              {/*    justifyContent: 'space-between',*/}
              {/*    mb: 2*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div>*/}
              {/*    <Typography variant="body2" color={theme.palette.grey[400]}>*/}
              {/*      Utilizzato*/}
              {/*    </Typography>*/}
              {/*    <Typography variant="body1" fontWeight={500}>*/}
              {/*      {formatNumber(BigNumber(plafond.wallet.usedAmount).toNumber())} €*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <Typography variant="body2" color={theme.palette.grey[400]}>*/}
              {/*      Restituito*/}
              {/*    </Typography>*/}
              {/*    <Typography variant="body1" fontWeight={500}>*/}
              {/*      {formatNumber(BigNumber(plafond.wallet.returnAmount).toNumber())} €*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <Typography variant="body2" color={theme.palette.grey[400]}>*/}
              {/*      Bloccato*/}
              {/*    </Typography>*/}
              {/*    <Typography variant="body1" fontWeight={500}>*/}
              {/*      {formatNumber(BigNumber(plafond.wallet.lockedAmount).toNumber())} €*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*</Box>*/}

              <Box maxWidth={210}>
                {/*<TooltipInfo arrow placement={'left'} title={'Il plafond bloccato per pratiche in bozza o in lavorazione'}>*/}
                {/*  <Box*/}
                {/*    sx={{*/}
                {/*      display: 'flex',*/}
                {/*      alignItems: 'center',*/}
                {/*      cursor: 'default'*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <Typography variant="body2" color={theme.palette.grey[400]} minWidth={80}>*/}
                {/*      Bloccato*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body1" fontWeight={600} textAlign={'end'} flexGrow={1}>*/}
                {/*      {formatNumber(BigNumber(plafond.wallet.lockedAmount).toNumber())} €*/}
                {/*    </Typography>*/}
                {/*  </Box>*/}
                {/*</TooltipInfo>*/}

                <TooltipInfo arrow placement={'left'} title={'Il plafond utilizzato per l’acquisto di beni o servizi '}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'default'
                    }}
                  >
                    <Typography variant="body2" color={theme.palette.grey[400]} minWidth={80}>
                      Utilizzato
                    </Typography>
                    <Typography variant="body1" fontWeight={600} textAlign={'end'} flexGrow={1}>
                      {formatNumber(BigNumber(plafond.wallet.usedAmount).toNumber())} €
                    </Typography>
                  </Box>
                </TooltipInfo>

                <TooltipInfo arrow placement={'left'} title={'Il plafond residuo e ancora da utilizzare'}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'default'
                    }}
                  >
                    <Typography variant="body2" color={theme.palette.grey[400]} minWidth={80}>
                      Residuo
                    </Typography>
                    <Typography variant="body1" fontWeight={600} textAlign={'end'} flexGrow={1}>
                      {formatNumber(BigNumber(plafond.wallet.availableAmount).toNumber())} €
                    </Typography>
                  </Box>
                </TooltipInfo>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                    cursor: 'default'
                  }}
                >
                  <Typography variant="body2" color={theme.palette.grey[400]} minWidth={80}>
                    Totale
                  </Typography>
                  <Typography variant="body1" fontWeight={600} textAlign={'end'} flexGrow={1}>
                    {formatNumber(BigNumber(plafond.wallet.amount).toNumber())} €
                  </Typography>
                </Box>
              </Box>
              {/*{plafond.maxReturnTime != null && plafond.wallet.maxAmount != null && (*/}
              {/*  <Typography variant={'body2'} color={'grey'}>*/}
              {/*    *Puoi avere fino a <span style={{ fontWeight: 600 }}>{formatNumber(plafond.wallet.maxAmount)} €</span> in{' '}*/}
              {/*    <span style={{ fontWeight: 600 }}>{plafond.maxReturnTime} mesi</span>*/}
              {/*  </Typography>*/}
              {/*)}*/}
            </>
          )}

          <Box flexGrow={1} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
            {plafondState === 'refused' ? (
              <Button
                variant={'contained'}
                size={'small'}
                component={Link}
                to={'/contacts'}
                state={{
                  preset: ContactFormPresetEnum.PlafondNotActivating
                }}
                onClick={() =>
                  trackAction({
                    action: ClientActionEnum.Clicked,
                    type: ClientTypeEnum.Link,
                    name: `Contattaci dalla card del plafond ${plafond.title}`
                  })
                }
              >
                contattaci
              </Button>
            ) : plafondState === 'not-active' && !registrationCompleted ? (
              <>
                {plafond.slug === 'ivh' && !registrationConfirmed ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    // sx={{ color: theme.palette.secondary.main }}
                    onClick={() => {
                      navigate('/building-register');
                      trackAction({
                        type: ClientTypeEnum.Link,
                        action: ClientActionEnum.Clicked,
                        name: `Continua registrazione dalla card del plafond ${plafond.title}`
                      });
                    }}
                  >
                    continua registrazione
                  </Button>
                ) : plafond.slug === 'ivh' && registrationConfirmed && registrationMethod === 'pms' ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    // sx={{ color: theme.palette.secondary.main }}
                    onClick={() => {
                      modalUploadPmsCode();
                      trackAction({
                        type: ClientTypeEnum.Link,
                        action: ClientActionEnum.Clicked,
                        name: `Ha aperto il modal per inserire il codice del pms`
                      });
                    }}
                  >
                    Inserisci codice
                  </Button>
                ) : (
                  <></>
                )}
              </>
            ) : plafondState === 'not-active' && registrationCompleted && plafond.slug === 'buyplus-trial' ? (
              <Button
                size={'small'}
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  trackAction({
                    type: ClientTypeEnum.Button,
                    action: ClientActionEnum.Clicked,
                    name: `Attiva plafond ${plafond.title}`
                  });
                  if (plafond.slug === 'buyplus-trial') {
                    // openAdditionalDataBuyplusTrial();
                  }
                }}
              >
                attiva
              </Button>
            ) : plafondState === 'approved' ? (
              <>
                {plafond.slug === 'ivh' && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      trackAction({
                        type: ClientTypeEnum.Button,
                        action: ClientActionEnum.Clicked,
                        name: 'Ha aperto il modal per avere più credito di paga in camere'
                      });

                      openModal({
                        content: <MoreCreditModal />
                      });
                    }}
                  >
                    ottieni più credito
                  </Button>
                )}
                {!plafond.isQuick && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      trackAction({
                        type: ClientTypeEnum.Button,
                        action: ClientActionEnum.Clicked,
                        name: `Ha aperto il modal per utilizzare la soluzione di credito lenta: "${plafond.title}"`
                      });

                      openModal({
                        content: <SlowCreditSolutionModal />
                      });
                    }}
                  >
                    utilizza questa soluzione
                  </Button>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
    </SecondaryCard>
  );
  // }

  //da non considerare
  // return (
  //   <MainCard
  //     title={
  //       <Box display={'flex'} alignItems={'center'} gap={'5px'}>
  //         <Typography variant="h6">{plafond.title}</Typography>
  //         {plafond.wallet &&
  //           plafond.wallet.documents.length > 0 &&
  //           plafond.wallet.documents.some(
  //             (ed) => (ed.userUpload == null && ed.signatureType !== 'check') || (ed.signatureType === 'check' && ed.signedAt == null)
  //           ) && (
  //             <WarningTooltip
  //               title={
  //                 <div>
  //                   <Typography variant={'body2'}>Documenti richiesti mancanti: </Typography>
  //                   <ul style={{ margin: 0, paddingLeft: '15px' }}>
  //                     {plafond.wallet.documents.map((ed, i) =>
  //                       (ed.userUpload == null && ed.signatureType !== 'check') || (ed.signatureType === 'check' && ed.signedAt == null) ? (
  //                         <li key={i}>{ed.title}</li>
  //                       ) : (
  //                         false
  //                       )
  //                     )}
  //                   </ul>
  //                 </div>
  //               }
  //               arrow
  //             >
  //               <WarningIcon
  //                 fontSize={'small'}
  //                 sx={{
  //                   '&:hover': {
  //                     transition: '.3s all',
  //                     color: theme.palette.warning.main
  //                   }
  //                 }}
  //                 onClick={() => {
  //                   navigate('/account/documents');
  //                   trackAction({
  //                     action: ClientActionEnum.Clicked,
  //                     type: ClientTypeEnum.Link,
  //                     name: `Inserisci i documenti mancanti dal warning del plafond "${plafond.title}"`
  //                   });
  //                 }}
  //               />
  //             </WarningTooltip>
  //           )}
  //       </Box>
  //     }
  //     bottomLabel={plafond.isQuick ? <QuickSolutionChip /> : undefined}
  //     action={
  //       plafondState === 'refused' ? (
  //         <Button
  //           variant={'contained'}
  //           size={'small'}
  //           component={Link}
  //           to={'/contacts'}
  //           onClick={() =>
  //             trackAction({
  //               action: ClientActionEnum.Clicked,
  //               type: ClientTypeEnum.Link,
  //               name: `Contattaci dalla card del plafond ${plafond.title}`
  //             })
  //           }
  //         >
  //           contattaci
  //         </Button>
  //       ) : plafondState === 'not-active' && !registrationCompleted ? (
  //         <>
  //           {plafond.slug === 'ivh' && (
  //             <Button
  //               size="small"
  //               variant="contained"
  //               color="primary"
  //               sx={{ color: theme.palette.secondary.main }}
  //               onClick={() => {
  //                 navigate('/building-register');
  //                 trackAction({
  //                   type: ClientTypeEnum.Link,
  //                   action: ClientActionEnum.Clicked,
  //                   name: `Continua registrazione dalla card del plafond ${plafond.title}`
  //                 });
  //               }}
  //             >
  //               continua registrazione
  //             </Button>
  //           )}
  //         </>
  //       ) : plafondState === 'not-active' && registrationCompleted && plafond.slug === 'buyplus-trial' ? (
  //         <Button
  //           size={'small'}
  //           variant={'contained'}
  //           color={'primary'}
  //           onClick={() => {
  //             trackAction({
  //               type: ClientTypeEnum.Button,
  //               action: ClientActionEnum.Clicked,
  //               name: `Attiva plafond ${plafond.title}`
  //             });
  //             if (plafond.slug === 'buyplus-trial') {
  //               // openAdditionalDataBuyplusTrial();
  //             }
  //           }}
  //         >
  //           attiva
  //         </Button>
  //       ) : (
  //         <></>
  //       )
  //     }
  //     chip={
  //       plafondState === 'not-active' ? (
  //         <NotActiveChip size={'small'} />
  //       ) : plafondState === 'pending' ? (
  //         <InProgressChip size={'small'} />
  //       ) : plafondState === 'approved' ? (
  //         <ActiveChip size="small" data-cy={'active_pladond_chip'} />
  //       ) : plafondState === 'refused' ? (
  //         <NotActivetedChip size={'small'} />
  //       ) : (
  //         <></>
  //       )
  //     }
  //   >
  //     <Grid container spacing={3} pt={1} minHeight={200}>
  //       <Grid item xs={12}>
  //         <Typography color={theme.palette.grey[500]} mb={2} minHeight={50}>
  //           {plafond.excerpt}
  //           <span
  //             style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }}
  //             onClick={() => {
  //               openDrawer({ title: plafond.title, content: <div dangerouslySetInnerHTML={{ __html: plafond.description }} /> });
  //               trackAction({
  //                 action: ClientActionEnum.Clicked,
  //                 type: ClientTypeEnum.Button,
  //                 name: `Ha aperto i dettagli del plafond  "${plafond.title}"`
  //               });
  //             }}
  //           >
  //             Scopri di più
  //           </span>
  //         </Typography>
  //         <Typography color={theme.palette.grey[500]}>
  //           Partner: <span style={{ fontWeight: 600 }}>{plafond.company}</span>
  //         </Typography>
  //       </Grid>
  //
  //       {plafondState === 'approved' && plafond.wallet != null ? (
  //         <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'5px'}>
  //           <Box>
  //             <Typography variant="body2" color={theme.palette.grey[400]}>
  //               Data di attivazione
  //             </Typography>
  //             <Typography variant="body1" fontWeight={600} color={'grey'}>
  //               {dayjs(plafond.wallet.state.at).format('DD MMMM YYYY')}
  //             </Typography>
  //
  //             <Divider sx={{ my: 1 }} />
  //           </Box>
  //
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               alignItems: 'flex-start',
  //               justifyContent: 'space-between',
  //               mb: 2
  //             }}
  //           >
  //             <div>
  //               <Typography variant="body2" color={theme.palette.grey[400]}>
  //                 Utilizzato
  //               </Typography>
  //               <Typography variant="body1" fontWeight={600}>
  //                 {BigNumber(plafond.wallet.usedAmount).toNumber().toLocaleString('it', {
  //                   maximumFractionDigits: 2,
  //                   minimumFractionDigits: 2
  //                 })}{' '}
  //                 €
  //               </Typography>
  //             </div>
  //             <div>
  //               <Typography variant="body2" color={theme.palette.grey[400]}>
  //                 Restituito
  //               </Typography>
  //               <Typography variant="body1" fontWeight={600}>
  //                 {BigNumber(plafond.wallet.returnAmount).toNumber().toLocaleString('it', {
  //                   maximumFractionDigits: 2,
  //                   minimumFractionDigits: 2
  //                 })}{' '}
  //                 €
  //               </Typography>
  //             </div>
  //             <div>
  //               <Typography variant="body2" color={theme.palette.grey[400]}>
  //                 Bloccato
  //               </Typography>
  //               <Typography variant="body1" fontWeight={600}>
  //                 {BigNumber(plafond.wallet.lockedAmount).toNumber().toLocaleString('it', {
  //                   maximumFractionDigits: 2,
  //                   minimumFractionDigits: 2
  //                 })}{' '}
  //                 €
  //               </Typography>
  //             </div>
  //           </Box>
  //
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               alignItems: 'center'
  //             }}
  //           >
  //             <Typography variant="body2" color={theme.palette.grey[400]} minWidth={80}>
  //               Disponibile
  //             </Typography>
  //             <Typography variant="body1" fontWeight={600}>
  //               {BigNumber(plafond.wallet.availableAmount).toNumber().toLocaleString('it', {
  //                 maximumFractionDigits: 2,
  //                 minimumFractionDigits: 2
  //               })}{' '}
  //               €
  //             </Typography>
  //           </Box>
  //           <Box
  //             sx={{
  //               display: 'flex',
  //               alignItems: 'center'
  //             }}
  //           >
  //             <Typography variant="body2" color={theme.palette.grey[400]} minWidth={80}>
  //               Totale
  //             </Typography>
  //             <Typography variant="body1" fontWeight={600}>
  //               {BigNumber(plafond.wallet.amount).toNumber().toLocaleString('it', {
  //                 maximumFractionDigits: 2,
  //                 minimumFractionDigits: 2
  //               })}{' '}
  //               €
  //             </Typography>
  //           </Box>
  //         </Grid>
  //       ) : plafondState === 'not-active' ? (
  //         <Grid item xs={12} display={'flex'} alignItems={'center'}>
  //           <img src={NotActiveIllustration} alt={'not active illustration'} />
  //         </Grid>
  //       ) : (
  //         <></>
  //       )}
  //     </Grid>
  //   </MainCard>
  // );
};

export default PlafondCard;
