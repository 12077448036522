import { Drawer, styled } from '@mui/material';

const MobileDrawerStyled = styled(Drawer)(({ theme }) => ({
  width: '100%',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: 400,
    boxSizing: 'border-box',
    backgroundImage: 'none',
    boxShadow: 'inherit'
  }
}));

export default MobileDrawerStyled;
