import { LangText } from '../formik/manual-integration-step';

export type NotificationDTO = {
  id: number;
  user: {
    id: number;
    firstname: string;
    lastname: string;
  };
  resource: ResourceEnum;
  operation: 'create' | 'update' | 'delete';

  subject?: string | number; //ci dice cosa è stato modificato es orderCode o buildingId
  subjectName?: string; //nome di riferimento del subject

  customMessage?: string; //messaggio scritto manualmente, soltanto se un utente del backoffice lo ha creato manualmente(Avviso backoffice)
  description?: LangText; //Descrizione di ciò che avvenuto soprattuto nelle patch

  severity: ServerityEnum;
  readAt: string | null;
  at: string;

  old: any;
  new: any;
  tag?: NotifyTag;
};

export enum ResourceEnum {
  Building = 'building',
  Order = 'order',
  DocumentAutoRequest = 'documentAutoRequest',
  // WalletPartner = 'walletPartner',
  User = 'user'
}

enum ServerityEnum {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export enum NotifyTag {
  Wallet = 'wallet', //Dentro Building
  PmsExports = 'pms-exports', //Dentro Building
  Contact = 'contact', //dentro User
  Document = 'document', //Dentro ordine o building fa riferimento ai documenti,
  BillingInfo = 'billing-infos'
}
