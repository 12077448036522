import { alpha, Box, Fade, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from 'assets/images/logo/logo-small.svg';

const UpdatePaymentLoader = () => {
  const theme = useTheme();
  return (
    <Fade in>
      <Box
        sx={{
          width: '100%',
          // maxWidth: 800,
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          // left: '50%',
          // transform: 'translateX(-50%)',
          backgroundColor: alpha(theme.palette.primary.main, 0.6),
          zIndex: 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '6px'
        }}
      >
        <motion.div animate={{ rotate: 360 }} transition={{ duration: 0.8, repeat: Infinity }}>
          <Logo width={'100px'} />
        </motion.div>
      </Box>
    </Fade>
  );
};

export default UpdatePaymentLoader;
