import { Fade, Grid, Skeleton } from '@mui/material';

const WalletSkeletons = () => {
  return (
    <Fade in>
      <Grid container spacing={3}>
        {/* Main Column */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            {/* Wallet */}
            <Grid item xs={12}>
              <Skeleton width={'100%'} height="150px" variant={'rounded'} />
            </Grid>

            {/* I tuoi prodotti Prodotti finanziari */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {/* Title prodotti finanziari */}
                <Grid item xs={12}>
                  <Skeleton variant={'text'} width={'300px'} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Skeleton width={'100%'} sx={{ maxWidth: 450, marginX: 'auto' }} height={'170px'} variant={'rounded'} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Skeleton width={'100%'} sx={{ maxWidth: 450, marginX: 'auto' }} height={'170px'} variant={'rounded'} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Skeleton width={'100%'} sx={{ maxWidth: 450, marginX: 'auto' }} height={'170px'} variant={'rounded'} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Skeleton width={'100%'} sx={{ maxWidth: 450, marginX: 'auto' }} height={'170px'} variant={'rounded'} />
                </Grid>
              </Grid>
            </Grid>

            {/* Promo card */}
            <Grid item xs={6}>
              <Skeleton sx={{ width: '100%', maxWidth: 450, height: '180px' }} variant={'rectangular'} />
            </Grid>
          </Grid>
        </Grid>

        {/* Left Column */}
        <Grid item xs={12} md={4}>
          <Skeleton width={'100%'} variant={'rounded'} height={'250px'} />
          <Skeleton width={'100%'} height={'100px'} variant={'rounded'} sx={{ mt: 3 }} />
        </Grid>
      </Grid>
    </Fade>
  );
};

export default WalletSkeletons;
