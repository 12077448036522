import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const LockClosedIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9602_112413)">
        <path
          d="M18.3371 9.50879V12.9025H15.8087V9.50879H8.96358V12.9025H6.43519V9.50879H4V22.0003H18.0064C19.5337 22.0003 20.7717 20.7622 20.7717 19.2349V9.50879H18.3368H18.3371Z"
          fill="#094754"
        />
        <path
          d="M8.96332 6.95109C8.96332 5.06372 10.4987 3.52838 12.386 3.52838C14.2734 3.52838 15.8087 5.06372 15.8087 6.95109V9.50851H18.3371V6.95109C18.3371 5.36155 17.7182 3.86714 16.5941 2.74302C15.47 1.61889 13.9756 1 12.386 1C10.7965 1 9.30208 1.61889 8.17795 2.74302C7.05383 3.86714 6.43494 5.36155 6.43494 6.95109V9.50851H8.96332V6.95109Z"
          fill="#91DCF9"
        />
        <path d="M8.96332 12.9022V10.3738V9.50879H6.43494V12.9022H8.96332Z" fill="#49EBAA" />
        <path d="M15.8085 10.3738V12.9022H18.3371V9.50879H15.8085V10.3738Z" fill="#49EBAA" />
        <path
          d="M12.4427 17.0568C13.2839 17.0568 13.9659 16.3749 13.9659 15.5337C13.9659 14.6924 13.2839 14.0105 12.4427 14.0105C11.6015 14.0105 10.9196 14.6924 10.9196 15.5337C10.9196 16.3749 11.6015 17.0568 12.4427 17.0568Z"
          fill="#F0FF32"
        />
        <path d="M13.269 16.2444H11.6167V19.5001H13.269V16.2444Z" fill="#F0FF32" />
      </g>
      <defs>
        <clipPath id="clip0_9602_112413">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default LockClosedIcon;
