import { BillingInfoDto } from '../../../../types/dto/billing-info.dto';
import { AddressBoxStyle } from '../ShippingBoxes/NewAddressBox';
import { Box, Fade, Grid, Link, Tooltip, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { AddressCheckedLabel } from '../ShippingBoxes/AddressBox';
import { useSelector } from '../../../../store';

type BillingBoxProps = {
  isDefault?: boolean;
  isSelected: boolean;
  billing: BillingInfoDto;
  onClickEdit: (billing: BillingInfoDto) => void;
  onClickDelete?: (billing: BillingInfoDto) => void;
  onClickDefault?: (billing: BillingInfoDto) => void;
  onClickBox?: () => void;
};
const BillingBox = (props: BillingBoxProps) => {
  const { isDefault, isSelected, billing, onClickDefault, onClickDelete, onClickEdit, onClickBox } = props;
  const { user } = useSelector((state) => state.auth);

  return (
    <Tooltip title={user?.isDemo ? "L'account demo non può modificare i dati di fatturazione" : undefined} arrow>
      <AddressBoxStyle isdefault={isSelected && !user?.isDemo ? 'true' : 'false'} data-cy={'billing_address_box'}>
        {isSelected && (
          <Fade in>
            <AddressCheckedLabel isdemo={user?.isDemo ? 'true' : undefined}>
              <Check fontSize="small" />
            </AddressCheckedLabel>
          </Fade>
        )}
        <Box flexGrow={1} mb={3} width={'100%'} onClick={onClickBox} sx={{ cursor: onClickBox != null ? 'pointer' : 'normal' }}>
          <Typography variant="body2" gutterBottom>
            Ragione sociale: <span style={{ fontWeight: '500' }}>{billing.name}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Cod. Fiscale: <span style={{ fontWeight: '500' }}>{billing.taxCode}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            P. Iva: <span style={{ fontWeight: '500' }}>{billing.vat}</span>
          </Typography>
          {billing.iban != null && (
            <Typography variant="body2" gutterBottom>
              IBAN: <span style={{ fontWeight: '500' }}>{billing.iban}</span>
            </Typography>
          )}
          {billing.pec != null && (
            <Typography variant="body2" gutterBottom>
              PEC: <span style={{ fontWeight: '500' }}>{billing.pec}</span>
            </Typography>
          )}

          {billing.recipientCode != null && (
            <Typography variant="body2" gutterBottom>
              Codice destinatario: <span style={{ fontWeight: '500' }}>{billing.recipientCode}</span>
            </Typography>
          )}

          <Typography variant="body2" gutterBottom>
            Indirizzo: <span style={{ fontWeight: '500' }}>{billing.address}</span>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <span style={{ fontWeight: '500' }}>
              {billing.city.name.it} - {billing.cap} - {billing.country.name.it}
            </span>
          </Typography>
        </Box>
        <Grid container flexGrow={0}>
          <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
            <Link
              variant="body1"
              color={'inherit'}
              fontSize={'.8rem'}
              sx={{ cursor: 'pointer' }}
              gutterBottom
              onClick={() => {
                if (!user?.isDemo) onClickEdit(billing);
              }}
            >
              Modifica l'indirizzo
            </Link>
            {onClickDelete != null && (
              <Link
                variant="body1"
                color="inherit"
                fontSize={'.8rem'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!user?.isDemo) onClickDelete(billing);
                }}
              >
                Elimina l'indirizzo
              </Link>
            )}
          </Grid>
          <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} textAlign={'end'}>
            {isDefault ? (
              <Typography variant="body2" fontWeight={600} color={'inherit'}>
                Predefinito
              </Typography>
            ) : (
              <>
                {onClickDefault != null && (
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color={'inherit'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!user?.isDemo) onClickDefault(billing);
                    }}
                  >
                    Imposta come predefinito
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </AddressBoxStyle>
    </Tooltip>
  );
};

export default BillingBox;
