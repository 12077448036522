import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Slide } from '@mui/material';

function HideOnScroll({ children }: { children: JSX.Element }) {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  return (
    <Slide in={!trigger} appear={false} direction="down">
      {children}
    </Slide>
  );
}

export default HideOnScroll;
