// ==============================|| OVERRIDES - TABS ||============================== //

import { Components, Theme } from '@mui/material';

export default function Tabs(theme: Theme) {
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return {
    MuiTabs: {
      defaultProps: {
        scrollButtons: 'auto',
        variant: 'scrollable'
      },
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: '100%',
          backgroundColor: '#F2F2F2',
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
            top: 0,
            height: '4px'
          }
        }
      }
    } as Components['MuiTabs']
  };
}
