import { PasswordValidatorType } from '../types/password';

function isNumber(value: string): boolean {
  return new RegExp('^(?=.*[0-9]).+$').test(value);
}

function isLowercaseChar(value: string): boolean {
  return new RegExp('^(?=.*[a-z]).+$').test(value);
}

function isUppercaseChar(value: string): boolean {
  return new RegExp('^(?=.*[A-Z]).+$').test(value);
}

function isSpecialChar(value: string): boolean {
  return new RegExp('^(?=.*[-+_!@#$%^&*.,?]).+$').test(value);
}

function minLength(value: string): boolean {
  return value.length > 7;
}

/**
 * Funziona che in base alla password ci ritorna quali requisiti minimi mancano per una password sicura
 * @param value
 */
export function passwordValidation(value: string): PasswordValidatorType {
  return {
    number: isNumber(value),
    lowercaseChar: isLowercaseChar(value),
    uppercaseChar: isUppercaseChar(value),
    minLength: minLength(value),
    specialChar: isSpecialChar(value)
  };
}

export function getPasswordValidationLabel(label: string): string {
  switch (label) {
    case 'minLength':
      return 'Minimo 8 caratteri';

    case 'lowercaseChar':
      return '1 lettera minuscola';

    case 'uppercaseChar':
      return '1 lettera maiuscola';

    case 'number':
      return '1 numero';

    case 'specialChar':
      return '1 simbolo';

    default:
      return '';
  }
}
