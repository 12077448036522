import { Button, ListItem, ListItemButton, ListItemIcon, alpha, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'store';
import { activeItem } from 'store/reducers/menu';
import { NavItemType } from 'types/menu';

const NavButton = ({ item }: { item: NavItemType; level: number }) => {
  const theme = useTheme();
  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const Icon = item.icon!;
  const DesktopIcon = Icon[0]!;
  const itemIcon = Icon[0] ? <DesktopIcon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  const handleNewOrder = () => {
    navigate(item.url!);
    dispatch(activeItem({ openItem: [item.id] }));
  };

  return drawerOpen || matchDownSM ? (
    <ListItem
      id={`nav-button-${item.id}`}
      sx={{
        paddingTop: 3.5,
        '&:hover': {
          bgcolor: 'transparent'
        }
      }}
    >
      <Button
        variant="contained"
        size="medium"
        fullWidth
        onClick={handleNewOrder}
        color={matchDownSM ? 'secondary' : 'primary'}
        disabled={openItem.findIndex((id) => id === item.id) > -1}
      >
        {item.title}
      </Button>
    </ListItem>
  ) : (
    <ListItemButton
      id={`nav-button-${item.id}`}
      onClick={handleNewOrder}
      disabled={openItem.findIndex((id) => id === item.id) > -1}
      sx={{
        pl: 1.5,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
          bgcolor: alpha(theme.palette.primary.main, 0.8)
        }
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 28,
          borderRadius: 1.5,
          width: 36,
          height: 36,
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.palette.common.white,
          svg: {
            fontSize: '1.5rem!important'
          }
        }}
      >
        {itemIcon}
      </ListItemIcon>
    </ListItemButton>
  );
};

export default NavButton;
