// project import
import Navigation from './Navigation';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SimpleBar from 'simplebar-react';

// ==============================|| DRAWER CONTENT ||============================== //
const SimpleBarStyled = styled(SimpleBar)(() => ({
  '& .simplebar-content': {
    height: '100%'
  }
}));
const DrawerContent = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <SimpleBarStyled
      style={{
        ...(matchDownSM && { paddingTop: 40 }),
        height: !matchDownSM ? 'calc(100% - 98px)' : 'calc(100% - 100px)',
        borderRight: `1px solid ${theme.palette.divider}`
      }}
    >
      <Navigation />
    </SimpleBarStyled>
  );
};

export default DrawerContent;
