// material-ui
import { useMediaQuery, useTheme } from '@mui/material';

// assets
import LogoBuyPlus from '../../assets/images/logo/logo-full.svg';
import HideOnScroll from 'utils/hideOnScroll';
import Box from '@mui/material/Box';
import RibbonEnvironment from '../MainLayout/Header/RibbonEnvironment';

// ==============================|| COMPONENTS - APP BAR ||============================== //
export const commonHeaderHeightDesktop: number = 100;
export const commonHeaderHeightMobile: number = 60;
const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return !isMobile ? (
    <Box>
      <Box
        sx={{
          width: '100%',
          height: commonHeaderHeightDesktop,
          backgroundColor: theme.palette.primary.main,
          borderTop: `4px solid ${theme.palette.secondary.main}`,
          display: 'flex',
          alignItems: 'center',
          paddingX: 3,
          position: 'relative'
        }}
      >
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <RibbonEnvironment />}
        <img src={LogoBuyPlus} alt="logo buyplus" />
      </Box>
    </Box>
  ) : (
    <HideOnScroll>
      <Box
        sx={{
          width: '100%',
          height: commonHeaderHeightMobile,
          backgroundColor: theme.palette.primary.main,
          borderTop: `4px solid ${theme.palette.secondary.main}`,
          position: 'static',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          paddingX: 1.5,
          paddingTop: 1.5,
          img: {
            width: 140
          }
        }}
      >
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <RibbonEnvironment />}

        <img src={LogoBuyPlus} alt="logo buyplus" />
      </Box>
    </HideOnScroll>
  );
};

export default Header;
