import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import useNavigationTracker from '../../../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../../../types/dataToSend/clientLog';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { trackAction } = useNavigationTracker();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;

  // let itemTarget: LinkTarget = '_self';
  // if (item.target) {
  //   itemTarget = '_blank';
  // }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => (
      <Link
        {...props}
        ref={ref}
        to={item.url!}
        // target={itemTarget}
        onClick={() => {
          trackAction({
            type: ClientTypeEnum.DrawerLink,
            action: ClientActionEnum.Clicked,
            name: `Ha navigato nella pagina: ${item.url}`
          });
        }}
      />
    ))
  };
  if (item?.external) {
    listItemProps = {
      component: 'a',
      href: item.url
      // target: itemTarget
    };
  }

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const Icon = item.icon;
  const DesktopIcon = Icon ? Icon[0] : undefined;
  const MobileIcon = Icon ? Icon[1] : undefined;
  const itemIcon =
    DesktopIcon !== undefined && !matchDownSM ? (
      <DesktopIcon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
    ) : MobileIcon !== undefined && matchDownSM ? (
      <MobileIcon />
    ) : (
      false
    );

  const { pathname } = useLocation();
  const [isSelected, setIsSelected] = useState(false);

  // active menu item on page load
  useEffect(() => {
    if (pathname === item.url && !openItem.includes(item.id!)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
  }, [pathname]);

  useEffect(() => {
    setIsSelected(openItem.findIndex((id) => id === item.id) > -1);
  }, [openItem]);

  return (
    <ListItemButton
      {...listItemProps}
      id={`nav-item-${item.id}`}
      // disabled={item.disabled || (openItem[0] === 'new-order' && item.url === '/orders/new-order')}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen && !matchDownSM ? 2.8 : 1.5,
        py: !drawerOpen && !matchDownSM ? 1.25 : 1,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: !matchDownSM ? alpha(theme.palette.secondary.light, 0.35) : 'transparent'
          },
          '&.Mui-selected': {
            bgcolor: !matchDownSM ? theme.palette.secondary.light : alpha(theme.palette.secondary.light, 0.2),
            '&:hover': {
              bgcolor: !matchDownSM ? theme.palette.secondary.light : alpha(theme.palette.secondary.light, 0.2)
            }
          },
          '&.Mui-disabled': {
            opacity: 1
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            // bgcolor: alpha(theme.palette.secondary.light, 0.35)
            bgcolor: !matchDownSM ? alpha(theme.palette.secondary.light, 0.35) : 'transparent'
          },
          '&.Mui-selected': {
            bgcolor: !matchDownSM ? theme.palette.secondary.light : alpha(theme.palette.secondary.light, 0.2),
            '&:hover': {
              bgcolor: !matchDownSM ? theme.palette.secondary.light : alpha(theme.palette.secondary.light, 0.2)
            }
          },
          '&.Mui-disabled': {
            opacity: 1
          }
        })
      }}
    >
      <Box display={'flex'} flexGrow={1} alignItems={'center'} sx={{ pl: itemIcon ? 0 : drawerOpen && !matchDownSM ? '28px' : 0 }}>
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              ...(!drawerOpen &&
                !matchDownSM && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
                  }
                }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                  }
                })
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {/*Se menu aperto mostro il nome*/}
        {(drawerOpen || (!drawerOpen && level !== 1) || matchDownSM) && (
          <ListItemText
            primary={
              <Typography
                variant="body1"
                sx={{
                  color: !matchDownSM ? theme.palette.primary.main : theme.palette.common.white,
                  ml: !itemIcon && matchDownSM ? 6 : 3
                }}
              >
                {item.title}
              </Typography>
            }
          />
        )}
      </Box>
      {/*Se menu aperto e ho dei children mostro il chip*/}
      {/* {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )} */}
    </ListItemButton>
  );
};

export default NavItem;
