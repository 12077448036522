import { GuardProps } from '../../types/auth';
import { useEffect } from 'react';
import { dispatch, useSelector } from '../../store';
import useAuth from '../../hooks/useAuth';
import { setInizialized } from '../../store/reducers/auth';
const RouteGuard = ({ children }: GuardProps) => {
  const { authTokenLogin } = useAuth();
  const { isLoggedIn, isInitialized } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      !isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      console.log('faccio il me');
      authTokenLogin();
    } else {
      dispatch(setInizialized());
    }
  }, [isLoggedIn]);

  return isInitialized ? children : <></>;
};

export default RouteGuard;
