import { ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Slide, styled, useMediaQuery, useTheme } from '@mui/material';

import { JSX, useEffect, useState } from 'react';

type MainCardProps = {
  title: JSX.Element;
  chip?: JSX.Element;
  children: JSX.Element;
  action?: JSX.Element;
  bottomLabel?: JSX.Element;
  borderColor?: string;
  padding?: string;
  close?: boolean;
  dataTour?: string;
};
const MainCardBox = styled(Box)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  borderTop: `4px solid ${theme.palette.secondary.main}`
}));

const HeaderMainCard = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // padding: '15px 24px',
  backgroundColor: '#3A6C76',
  position: 'relative',
  zIndex: 10
}));

const MainCard = ({ title, chip, children, action, padding, close, bottomLabel, borderColor, dataTour }: MainCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!close) return;
    if (!isMobile) {
      setOpen(true);
    } else setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <MainCardBox data-tour={dataTour}>
      <HeaderMainCard sx={{ padding: close && isMobile ? '0px 24px' : '15px 24px' }}>
        <Box color={theme.palette.common.white}>{title}</Box>
        <Box>{chip}</Box>
        {close !== null && close === true && isMobile && (
          <IconButton size="small" color="secondary" onClick={() => setOpen(!open)}>
            <ExpandMore />
          </IconButton>
        )}
      </HeaderMainCard>
      {/* Content */}
      <Slide in={open} unmountOnExit>
        <Box
          padding={padding ?? '10px 24px'}
          sx={{
            borderRadius: '0px 0px 10px 10px',
            borderLeft: `1px solid ${borderColor ?? theme.palette.divider}`,
            borderRight: `1px solid ${borderColor ?? theme.palette.divider}`,
            borderBottom: `1px solid ${borderColor ?? theme.palette.divider}`,
            backgroundColor: '#ffff'
          }}
        >
          {children}

          {(bottomLabel != null || action != null) && (
            <Box mt={3} textAlign={'end'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              {bottomLabel != null ? bottomLabel : <Box />}
              {action != null ? action : <Box minHeight={10} />}
            </Box>
          )}
        </Box>
      </Slide>
    </MainCardBox>
  );
};

export default MainCard;
