// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { PaletteColorOptions } from '@mui/material/styles';
import { ThemeMode } from '../../types/config';

// ==============================|| PRESET THEME - DEFAULT ||============================== //
const Default = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  const { grey } = colors;
  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  let primaryColors = ['#83A3AA', '#094754', '#04232A'];
  let secondaryColors = ['#F8FF99', '#F0FF32', '#C8D600', '#094754'];
  let errorColors = ['#F9969B', '#FF4351', '#D80025'];
  let warningColors = ['#FFE5DB', '#FF966F', '#EB694E', '#094754'];
  let infoColors = ['#E3F9F8', '#7DEBF5', '#63D3CB', '#094754'];
  let successColors = ['#D2FAEA', '#49EBAA', '#16C399', '#094754'];

  // if (mode === 'dark') {
  //   primaryColors = ['#1c2134', '#1f294d', '#243462', '#273e83', '#2c4db0', '#305bdd', '#567fe9', '#80a4f4', '#a9c5f8', '#d2e2fb'];
  //   errorColors = ['#341d1b', '#b03725', '#dd3f27', '#e9664d', '#fbd6c9'];
  //   warningColors = ['#342a1a', '#83631a', '#dda116', '#e9ba3a', '#fbefb5'];
  //   infoColors = ['#202734', '#416fb0', '#4c88dd', '#74a8e9', '#ecf4fb'];
  //   successColors = ['#1f2e1c', '#449626', '#4fba28', '#74cf4d', '#e3fbd2'];
  // }

  return {
    primary: {
      light: primaryColors[0],
      main: primaryColors[1],
      dark: primaryColors[2],
      // lighter: primaryColors[3],
      contrastText
    },
    secondary: {
      light: secondaryColors[0],
      main: secondaryColors[1],
      dark: secondaryColors[2],
      contrastText: secondaryColors[3]
    },
    error: {
      light: errorColors[0],
      main: errorColors[1],
      dark: errorColors[2],
      contrastText
    },
    warning: {
      light: warningColors[0],
      main: warningColors[1],
      dark: warningColors[2],
      contrastText: warningColors[3]
    },
    info: {
      light: infoColors[0],
      main: infoColors[1],
      dark: infoColors[2],
      contrastText: infoColors[3]
    },
    success: {
      light: successColors[0],
      main: successColors[1],
      dark: successColors[2],
      contrastText: successColors[3]
    },
    grey: greyColors
  };
};

export default Default;
