import { StateDto } from './state.dto';
import { DocumentDto } from './document.dto';

export type WalletDTO = {
  slug: string;
  excerpt: string;
  description: string;
  title: string;
  color: string;
  isQuick: boolean;
  priority: number;
  company: string;
  timeValidity: number;
  maxReturnTime?: number;
  hideNameIn: string[] | null;
  imageUploadCode: string | null;
  wallet: Plafond | null;
};

export type Plafond = {
  id: number;
  buildingId: number;
  expireAt: string | null;
  failureReason?: string;
  state: StateDto<PlafondState>;
  usedAmount: string;
  maxAmount: number | null; //Importo massimo considerando tutti i plafond futuri
  availableAmount: string;
  returnAmount: number;
  returnPercentage: number;
  amount: string;
  // extraDocuments: Omit<ExtraDocumentDto, 'walletPartners'>[];
  documents: DocumentDto[];
} & (PosCashPlafond | IVHPlafond);

export enum PlafondState {
  Pending = 'pending', //in elaborazione
  Approved = 'approved', // attivo
  Refused = 'refused' //non attivabile
}

export type PosCashPlafond = {
  walletPartnerSlug: 'posCash';
  customData: {};
};

export type IVHPlafond = {
  walletPartnerSlug: 'ivh';
  customData: {
    id: number;
    annualRate: string;
    markupPercentage: string;
  };
};
