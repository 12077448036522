import { alpha, Box, Button, Grid, styled, Typography, useTheme } from '@mui/material';
import MainCard from './MainCard';
import ActiveChip from '../Chips/ActiveChip';
import { WalletDTO } from 'types/dto/plafond.dto';
import { reduce } from 'lodash';
import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import NotActiveChip from '../Chips/NotActiveChip';
import InProgressChip from '../Chips/InProgressChip';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import { getWalletState } from '../../../utils/getWalletState';
import WalletBar from './WalletBar/WalletBar';
import WalletBarLabel from './WalletBar/WalletBarLabel';
import { useNavigate } from 'react-router-dom';
import { BuildingDTO } from '../../../types/dto/building.dto';
import { openModalUploadPmsCode } from '../Modal/ModalUploadPmsCode';
import formatNumber from '../../../utils/formatNumber';

type WalletProps = {
  title: string;
  wallet: WalletDTO[];
  report: boolean;
  testMode?: boolean;
  registrationComplete: boolean;
  registrationMethod: BuildingDTO['registrationMethod'];
  registrationConfirmed: boolean;
};

export const PlafondLabel = styled(Box)(() => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  // backgroundColor: theme.palette.common.white,
  // border: `2px solid ${theme.palette.secondary.main}`,
  // borderWidth: '2px',
  // borderStyle: 'solid',
  marginRight: 4
}));

const WalletCard = ({ title, wallet, report, registrationComplete, testMode, registrationConfirmed, registrationMethod }: WalletProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { trackAction } = useNavigationTracker();
  const modalUploadPmsCode = openModalUploadPmsCode();
  /**
   * Amount totale
   */
  const avaibleAmount = useMemo(
    () =>
      reduce(
        wallet,
        (result, value) => {
          if (value.wallet != null) {
            if (value.wallet.state.key === 'approved') return BigNumber(value.wallet.amount).plus(result).toNumber();
            return result;
          } else return result;
        },
        0
      ),
    [wallet]
  );

  /**
   * Amount utilizzato
   */
  const usedAmount = useMemo(
    () =>
      reduce(
        wallet,
        (result, value) => {
          if (value.wallet != null) {
            if (value.wallet.state.key === 'approved') return BigNumber(result).plus(BigNumber(value.wallet.usedAmount)).toNumber();
            return result;
          } else return result;
        },
        0
      ),
    [wallet]
  );

  // /**
  //  * Amount bloccato
  //  */
  // const lockedAmount = useMemo(
  //   () =>
  //     reduce(
  //       wallet,
  //       (result, value) => {
  //         if (value.wallet != null) {
  //           if (value.wallet.state.key === 'approved') return BigNumber(result).plus(BigNumber(value.wallet.lockedAmount)).toNumber();
  //           return result;
  //         } else return result;
  //       },
  //       0
  //     ),
  //   [wallet]
  // );

  const walletState = useMemo(
    () => getWalletState(wallet, report, registrationComplete, registrationConfirmed, registrationMethod),
    [wallet]
  );

  return (
    <MainCard
      title={
        <Box>
          <Typography variant="h5">{walletState === 'not-active' ? 'Attiva il tuo wallet' : title}</Typography>
          <Typography variant={'body1'} sx={{ color: alpha(theme.palette.common.white, 0.8) }}>
            Il tuo plafond di soluzioni di pagamento disponibile
          </Typography>
        </Box>
      }
      chip={
        walletState === 'approved' ? (
          <ActiveChip size="medium" data-cy={'wallet_active_chip'} />
        ) : walletState === 'pending' ? (
          <InProgressChip size={'medium'} />
        ) : (
          <NotActiveChip size={'medium'} />
        )
      }
      padding="24px 24px 10px"
      dataTour={'wallet-box'}
    >
      <Grid container spacing={2}>
        {/* Utilizzato */}
        {(walletState === 'approved' || walletState === 'pending') && (
          <Grid
            item
            xs={7}
            textAlign={'start'}
            display={'flex'}
            gap={{ xs: '5px', md: '30px' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box>
              <Typography variant="body2" color={theme.palette.grey[500]}>
                Utilizzato
              </Typography>
              <Typography variant="h6">{formatNumber(+usedAmount, { fractionDigits: 0 })} €</Typography>
            </Box>

            <Box>
              <Typography variant="body2" color={theme.palette.grey[500]}>
                Residuo
              </Typography>
              <Typography variant="h6">
                {formatNumber(BigNumber(avaibleAmount).minus(BigNumber(usedAmount)).toNumber(), { fractionDigits: 0 })} €
              </Typography>
            </Box>
          </Grid>
        )}

        {/* Disponibile */}
        {(walletState === 'approved' || walletState === 'pending') && (
          <Grid item xs={5} textAlign={'end'}>
            <Typography variant="body2" color={theme.palette.grey[500]}>
              Totale
            </Typography>
            <Typography variant="h6">{formatNumber(avaibleAmount, { fractionDigits: 0 })} €</Typography>
          </Grid>
        )}

        {/* Slider Wallet */}
        <Grid item xs={12}>
          <WalletBar wallets={wallet} avaibleAmount={avaibleAmount} walletState={walletState} />
        </Grid>

        {/* Messaggio quando la registrazione è terminata e non ce nessun plafond attivo */}
        {walletState === 'pending' && report && !registrationComplete && (
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant="h6" color={'primary'} maxWidth={500} marginX={'auto'} mb={2}>
              Resta in attesa che un collaboratore inserisca i tuoi report e attivi i plafond
            </Typography>
          </Grid>
        )}

        {/* Label della barra */}
        {walletState === 'approved' && usedAmount > 0 ? (
          <WalletBarLabel wallet={wallet} />
        ) : walletState === 'not-active' ? (
          <>
            {!testMode && !registrationComplete && (registrationMethod !== 'pms' || !registrationConfirmed) ? (
              <Grid item xs={12} mt={2} textAlign={'center'}>
                <Typography variant="h6" color={'primary'} maxWidth={500} marginX={'auto'} mb={2}>
                  Integra il PMS o inserisci i tuoi dati per calcolare il tuo wallet e attivare i tuoi servizi finanziari
                </Typography>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={{ color: theme.palette.secondary.main }}
                  onClick={() => {
                    navigate('/building-register');
                    // modalUploadPmsCode();
                    trackAction({
                      type: ClientTypeEnum.Button,
                      action: ClientActionEnum.Clicked,
                      name: 'Continua la registrazione'
                    });
                  }}
                >
                  continua
                </Button>
              </Grid>
            ) : !testMode && registrationConfirmed && !registrationComplete && registrationMethod === 'pms' ? (
              <Grid item xs={12} mt={2} textAlign={'center'}>
                <Typography variant="h6" color={'primary'} maxWidth={500} marginX={'auto'} mb={2}>
                  Inserisci il codice fornito dal tuo PMS per poter terminare la registrazione, oppure cambia metodo.
                </Typography>
                <Button
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={{ color: theme.palette.secondary.main }}
                  onClick={() => {
                    modalUploadPmsCode();
                    trackAction({
                      type: ClientTypeEnum.Button,
                      action: ClientActionEnum.Clicked,
                      name: 'Ha aperto il modal per inserisce il codice del pms'
                    });
                  }}
                >
                  Inserisci codice
                </Button>
              </Grid>
            ) : testMode ? (
              <Grid item xs={12} mt={2} textAlign={'center'}>
                <Typography variant="h6" color={'primary'} maxWidth={500} marginX={'auto'} mb={2}>
                  Compila il form soprastante per poter generare i wallet
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </MainCard>
  );
};

export default WalletCard;
