import { Box, Skeleton } from '@mui/material';

const NewOrderSkeleton = () => {
  return (
    <>
      <Box maxWidth={800} marginX={'auto'} mb={2}>
        <Skeleton variant={'rounded'} width={'100%'} height={'150px'} />
      </Box>
      <Box maxWidth={800} marginX={'auto'} mb={2}>
        <Skeleton variant={'rounded'} width={'100%'} height={'150px'} />
      </Box>
      <Box maxWidth={800} marginX={'auto'}>
        <Skeleton variant={'rounded'} width={'100%'} height={'150px'} />
      </Box>
    </>
  );
};

export default NewOrderSkeleton;
