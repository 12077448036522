// material-ui
import { useTheme } from '@mui/material/styles';

// project import
// import DrawerHeaderStyled from './DrawerHeaderStyled';
import CloseIcon from '@mui/icons-material/Close';
import Logo from 'components/logo';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import Profile from 'layout/MainLayout/Header/HeaderContent/Profile';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const DrawerHeader = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return !matchDownSM ? (
    <Box
      sx={{
        width: '100%',
        height: 98,
        backgroundColor: theme.palette.primary.main,
        borderTop: `4px solid ${theme.palette.secondary.main}`,
        display: 'flex',
        alignItems: 'center',
        paddingX: open ? 2.8 : 1.5,
        img: {
          width: !open ? 35 : 190,
          objectFit: 'contain',
          objectPosition: 'center'
        }
      }}
    >
      <Logo isIcon={!open} />
    </Box>
  ) : (
    <Box
      sx={{
        width: '93%',
        height: 100,
        marginX: 'auto',
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.secondary.main}`
      }}
    >
      <Box flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Profile isDrawerMobile={true} />
      </Box>
      <Box flexGrow={0} display={'flex'} alignItems={'center'}>
        <IconButton size="medium" onClick={handleDrawerToggle} color="info">
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DrawerHeader;
