//@ts-nocheck

import { BuildingSale } from '../../../types/dataToSend/building';
import { useEndpoint } from '../../../hooks/useEndpoint';
import { useFormik } from 'formik';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import React from 'react';
import { monthLabel } from '../../../utils/monthLabel';
import LoadingButton from '../LoadingButton';
import useModal from '../../../hooks/useModal';

type EditBuildingSalesModalProps = {
  refetchBuildingSales: () => Promise<any>;
  buildingSale: BuildingSale;
};

const trimestral = ['first', 'second', 'third', 'fourth'] as const;
const monthly = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
] as const;

const EditBuildingSalesModal = (props: EditBuildingSalesModalProps) => {
  const { closeModal } = useModal();
  const addBuildingSales = useEndpoint<any, 'patch'>({
    method: 'patch',
    endpoint: `building-sales/${props.buildingSale.id}`,
    queryParams: {
      mode: 'test'
    },
    mutationKey: 'add-building-sales-data',
    options: {
      onSettled: async () => {
        await props.refetchBuildingSales();
        closeModal();
      }
    }
  });
  const buildingSalesForm = useFormik({
    initialValues: {
      isIndirect: props.buildingSale.isIndirect,
      indirectChannelName: props.buildingSale.indirectChannelName,
      interval: props.buildingSale.interval,
      periods: props.buildingSale.periods,
      year: props.buildingSale.year
    },
    onSubmit: (values) => {
      addBuildingSales.mutate({ ...values });
    }
  });
  return (
    <form noValidate onSubmit={buildingSalesForm.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container width={'100%'} p={0} spacing={3}>
            {/* fonte dati */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id={`isIndirect`}>Fonte *</InputLabel>
                <Select
                  labelId={`isIndirect`}
                  id={`isIndirect`}
                  name={`isIndirect`}
                  value={buildingSalesForm.values.isIndirect ? 'channel' : 'direct'}
                  onChange={(e) => buildingSalesForm.setValues({ ...buildingSalesForm.values, isIndirect: e.target.value !== 'direct' })}
                  label="Fonte *"
                  MenuProps={{ sx: { zIndex: 2000 } }}
                >
                  <MenuItem value={'direct'}>Diretta</MenuItem>
                  <MenuItem value={'channel'}>Canale indiretto</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id={`channel-name`}
                label="Nome canale *"
                type="text"
                disabled={!buildingSalesForm.values.isIndirect}
                value={buildingSalesForm.values.indirectChannelName}
                onChange={buildingSalesForm.handleChange}
                name={`indirectChannelName`}
                autoComplete="off"
                autoFocus
              />
            </Grid>

            {/* tipo dati fatturazione */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id={`interval`}>Fatturato</InputLabel>
                <Select
                  labelId={`interval`}
                  id={`interval`}
                  name={`interval`}
                  value={buildingSalesForm.values.interval}
                  onChange={buildingSalesForm.handleChange}
                  label="Fatturato"
                >
                  <MenuItem value={'annual'}>Annuale</MenuItem>
                  <MenuItem value={'quarterly'}>Trimestrale</MenuItem>
                  <MenuItem value={'monthly'}>Mensile</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {buildingSalesForm.values.interval === 'annual' ? (
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <NumericFormat
                      customInput={TextField}
                      onBlur={buildingSalesForm.handleBlur}
                      id="amount"
                      label={'Venduto'}
                      variant="outlined"
                      value={buildingSalesForm.values.periods?.[0]?.amount ?? ''}
                      name={`periods[${0}].amount`}
                      onChange={(e) =>
                        buildingSalesForm.setFieldValue(
                          `periods[${0}].amount`,
                          e.target.value !== '' ? parseInt(e.target.value.replace('€', '').replace(/,/g, '')) : null
                        )
                      }
                      fullWidth
                      error={Boolean(buildingSalesForm.touched.periods && buildingSalesForm.errors.periods)}
                      valueIsNumericString={true}
                      decimalScale={0}
                      thousandSeparator
                      suffix="€ "
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumericFormat
                      customInput={TextField}
                      onBlur={buildingSalesForm.handleBlur}
                      id="occupancy"
                      label={'Percentuale di occupazione'}
                      variant="outlined"
                      value={buildingSalesForm.values?.periods?.[0]?.occupancy ?? ''}
                      name={`periods[${0}].occupancy`}
                      onChange={(e) =>
                        buildingSalesForm.setFieldValue(
                          `periods[${0}].occupancy`,
                          e.target.value !== '' ? parseInt(e.target.value.replace('%', '').replace(/,/g, '')) : null
                        )
                      }
                      fullWidth
                      error={Boolean(buildingSalesForm.touched.periods && buildingSalesForm.errors.periods)}
                      valueIsNumericString={true}
                      decimalScale={0}
                      thousandSeparator
                      suffix="%"
                    />
                  </Grid>
                </Grid>
              ) : buildingSalesForm.values.interval === 'quarterly' ? (
                <Grid container spacing={3}>
                  {trimestral.map((trimester, j) => (
                    <React.Fragment key={j}>
                      <Grid item xs={6}>
                        <NumericFormat
                          customInput={TextField}
                          onBlur={buildingSalesForm.handleBlur}
                          label={
                            trimester === 'first'
                              ? 'Gennaio - Marzo Venduto'
                              : trimester === 'second'
                              ? 'Aprile - Giugno Venduto'
                              : trimester === 'third'
                              ? 'Luglio - Settembre Venduto'
                              : 'Ottobre - Dicembre Venduto'
                          }
                          variant="outlined"
                          id={`amount-${trimester}-${buildingSalesForm.values.indirectChannelName}`}
                          name={`periods[${j}].amount`}
                          value={buildingSalesForm.values?.periods?.[j]?.amount ?? ''}
                          onChange={(e) =>
                            buildingSalesForm.setFieldValue(
                              `periods[${j}].amount`,
                              e.target.value !== '' ? parseInt(e.target.value.replace('€', '').replace(/,/g, '')) : null
                            )
                          }
                          fullWidth
                          error={Boolean(buildingSalesForm.touched?.periods && buildingSalesForm.errors.periods)}
                          valueIsNumericString={true}
                          decimalScale={0}
                          thousandSeparator
                          suffix="€ "
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumericFormat
                          customInput={TextField}
                          onBlur={buildingSalesForm.handleBlur}
                          label={
                            trimester === 'first'
                              ? 'Gennaio - Marzo Occupazione'
                              : trimester === 'second'
                              ? 'Aprile - Giugno Occupazione'
                              : trimester === 'third'
                              ? 'Luglio - Settembre Occupazione'
                              : 'Ottobre - Dicembre Occupazione'
                          }
                          variant="outlined"
                          id={`occupancy-${trimester}-${buildingSalesForm.values.indirectChannelName}`}
                          name={`periods[${j}].occupancy`}
                          value={buildingSalesForm.values?.periods?.[j]?.occupancy ?? ''}
                          onChange={(e) =>
                            buildingSalesForm.setFieldValue(
                              `periods[${j}].occupancy`,
                              e.target.value !== '' ? parseInt(e.target.value.replace('%', '').replace(/,/g, '')) : null
                            )
                          }
                          fullWidth
                          error={Boolean(buildingSalesForm.touched?.periods && buildingSalesForm.errors.periods)}
                          valueIsNumericString={true}
                          decimalScale={0}
                          thousandSeparator
                          suffix="%"
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              ) : buildingSalesForm.values.interval === 'monthly' ? (
                <Grid container spacing={3}>
                  {monthly.map((month, k) => (
                    <Grid
                      item
                      xs={12}
                      key={`monthly-${month}-${buildingSalesForm.values.indirectChannelName}-${k}`}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 3
                      }}
                    >
                      <Grid item xs={6}>
                        <NumericFormat
                          customInput={TextField}
                          onBlur={buildingSalesForm.handleBlur}
                          id={`"amount-${month}`}
                          label={`Venduto ${monthLabel(month)}`}
                          variant="outlined"
                          value={buildingSalesForm.values?.periods?.[k]?.amount ?? ''}
                          name={`periods[${k}].amount`}
                          onChange={(e) =>
                            buildingSalesForm.setFieldValue(
                              `periods[${k}].amount`,
                              e.target.value !== '' ? parseInt(e.target.value.replace('€', '').replace(/,/g, '')) : null
                            )
                          }
                          fullWidth
                          error={Boolean(buildingSalesForm.touched.periods && buildingSalesForm.errors.periods)}
                          valueIsNumericString={true}
                          decimalScale={0}
                          thousandSeparator
                          suffix="€ "
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumericFormat
                          customInput={TextField}
                          onBlur={buildingSalesForm.handleBlur}
                          id={`"occupancy-${month}`}
                          label={`Occupazione ${monthLabel(month)}`}
                          variant="outlined"
                          value={buildingSalesForm.values?.periods?.[k]?.occupancy ?? ''}
                          name={`periods[${k}].occupancy`}
                          onChange={(e) =>
                            buildingSalesForm.setFieldValue(
                              `periods[${k}].occupancy`,
                              e.target.value !== '' ? parseInt(e.target.value.replace('%', '').replace(/,/g, '')) : null
                            )
                          }
                          fullWidth
                          error={Boolean(buildingSalesForm.touched.periods && buildingSalesForm.errors.periods)}
                          valueIsNumericString={true}
                          decimalScale={0}
                          thousandSeparator
                          suffix="%"
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} textAlign={'end'} px={3}>
          <LoadingButton
            // disabled={Object.keys(buildingSalesForm.errors).length !== 0}
            loading={buildingSalesForm.isSubmitting}
            type={'submit'}
            variant={'contained'}
            size={'medium'}
          >
            Salva
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditBuildingSalesModal;
