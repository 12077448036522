import { createContext } from 'react';
import AsyncLock from 'async-lock';

import { AuthContextType, AxiosAuthResponseType } from 'types/auth';
import axios from 'axios';
// import { queryClient } from 'App';
import { dispatch, useSelector } from 'store';
// import { useError } from 'hooks/useError';
import { setLogin, setLogout, setPawn } from 'store/reducers/auth';
import { useError } from '../hooks/useError';
import { setRememberMe } from '../store/reducers/helpersAuth';
import { openSnackbar } from '../store/reducers/snackbar';
import { useErrorHandler } from 'react-error-boundary';
import { setNotificationCount } from '../store/reducers/websocket';
import dayjs from 'dayjs';
import { queryClient } from '../App';
// import dayjs from 'dayjs';

// ISTANZA DI AXIOS PER L'ENDOPOINT AUTH
const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + 'api/' + process.env.REACT_APP_API_VERSION + '/auth'
});

const lock = new AsyncLock();
const REFRESH_LOCK = 'REFRESH_LOCK';
const LOGOUT_LOCK = 'LOGOUT_LOCK';
// ==============================|| AUTHENTICATION CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { rememberMe } = useSelector((state) => state.helpersAuth);
  const { handleError } = useError();
  const handleErrorBoundary = useErrorHandler();

  const authEmailPasswordSingIn: AuthContextType['authEmailPasswordSingIn'] = (email, password) =>
    axiosAuth<AxiosAuthResponseType>({
      method: 'post',
      url: '/welcome',
      data: { param1: email, param2: password },
      headers: {
        pawn: localStorage['pawnId']
      }
    }).then((res) => {
      const user = res.data.user;
      const holder = res.data.holder;

      dispatch(
        setLogin({
          user: user
            ? {
                id: user.id,
                email: user.email,
                phone: user.phone,
                firstname: user.firstname,
                lastname: user.lastname,
                tag: user.tag,
                authResources: user.authResources,
                imageUploadCode: user.imageUploadCode,
                lastEdit: user.lastEdit,
                super: user.super,
                emailVerifiedAt: user.emailVerifiedAt,
                building: user.buildings[0],
                $: {
                  registrationCompleted: user.$.registrationCompleted,
                  hasAdminPermissions: user.$.hasAdminPermissions
                },
                deleteRequestAt: user.deleteRequestAt,
                isDemo: user.isDemo,
                _count: user._count
              }
            : null,
          holder:
            holder !== undefined
              ? {
                  id: holder.id,
                  email: holder.email,
                  phone: holder.phone,
                  firstname: holder.firstname,
                  lastname: holder.lastname,
                  tag: holder.tag,
                  authResources: holder.authResources,
                  imageUploadCode: holder.imageUploadCode,
                  lastEdit: holder.lastEdit,
                  super: holder.super,
                  emailVerifiedAt: holder.emailVerifiedAt,
                  buildings: holder.buildings,
                  $: {
                    registrationCompleted: holder.$.registrationCompleted,
                    hasAdminPermissions: holder.$.hasAdminPermissions
                  },
                  deleteRequestAt: holder.deleteRequestAt,
                  isDemo: holder.isDemo,
                  holderBuilding: holder.buildings.find((b) => !b.users?.length)
                }
              : undefined
        })
      );

      if (user != null) dispatch(setNotificationCount(user._count.userNotificationNotices));
      localStorage['accessExpireAt'] = res.data.accessExpireAt;

      if (rememberMe) {
        localStorage['accessToken'] = res.data.accessToken;
        localStorage['refreshToken'] = res.data.refreshToken;
      } else {
        sessionStorage['accessToken'] = res.data.accessToken;
        sessionStorage['refreshToken'] = res.data.refreshToken;
      }

      // navigate('/dashboard', { replace: true });
    });

  const authTokenLogin: AuthContextType['authTokenLogin'] = async () => {
    try {
      const res = await axiosAuth<Omit<AxiosAuthResponseType, 'accessToken' | 'refreshToken' | 'accessExpireAt'>>({
        method: 'get',
        url: '/me',
        headers: {
          Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`,
          pawn: localStorage['pawnId']
        }
      });

      const user = res.data.user;
      const holder = res.data.holder;

      dispatch(
        setLogin({
          user: user
            ? {
                id: user.id,
                email: user.email,
                phone: user.phone,
                firstname: user.firstname,
                lastname: user.lastname,
                tag: user.tag,
                authResources: user.authResources,
                imageUploadCode: user.imageUploadCode,
                lastEdit: user.lastEdit,
                super: user.super,
                emailVerifiedAt: user.emailVerifiedAt,
                building: user.buildings[0],
                $: {
                  registrationCompleted: user.$.registrationCompleted,
                  hasAdminPermissions: user.$.hasAdminPermissions
                },
                deleteRequestAt: user.deleteRequestAt,
                isDemo: user.isDemo,
                _count: user._count
              }
            : null,
          holder:
            holder !== undefined
              ? {
                  id: holder.id,
                  email: holder.email,
                  phone: holder.phone,
                  firstname: holder.firstname,
                  lastname: holder.lastname,
                  tag: holder.tag,
                  authResources: holder.authResources,
                  imageUploadCode: holder.imageUploadCode,
                  lastEdit: holder.lastEdit,
                  super: holder.super,
                  emailVerifiedAt: holder.emailVerifiedAt,
                  buildings: holder.buildings,

                  $: {
                    registrationCompleted: holder.$.registrationCompleted,
                    hasAdminPermissions: holder.$.hasAdminPermissions
                  },
                  deleteRequestAt: holder.deleteRequestAt,
                  isDemo: holder.isDemo,
                  holderBuilding: holder.buildings.find((b) => !b.users?.length)
                }
              : undefined
        })
      );

      if (user != null) dispatch(setNotificationCount(user._count.userNotificationNotices));

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) return;

      if (error.response?.data?.message === 'invalid_token') {
        console.log('refresho il token');
        localStorage.removeItem('accessExpireAt');
        await refreshToken();
      } else if (error.response?.data?.message === 'invalid_pawn') {
        console.log('non controlli piu la struttura');

        dispatch(setPawn(undefined));
        dispatch(
          openSnackbar({
            variant: 'error',
            message: 'Non controlli più questa struttura',
            transition: 'Fade'
          })
        );
        await authTokenLogin();
      } else {
        console.log('error boundary');
        handleErrorBoundary(new Error());
        await logout();
      }
    }
  };

  const refreshToken = async () => {
    return lock.acquire(REFRESH_LOCK, async () => {
      if (localStorage['accessExpireAt'] && dayjs().isBefore(localStorage['accessExpireAt'])) return;
      await axiosAuth<AxiosAuthResponseType>({
        method: 'post',
        url: '/refresh',
        headers: {
          Authorization: `Bearer ${rememberMe ? localStorage['refreshToken'] : sessionStorage['refreshToken']}`,
          pawn: localStorage['pawnId']
        }
      })
        .then((res) => {
          const user = res.data.user;
          const holder = res.data.holder;

          dispatch(
            setLogin({
              user: user
                ? {
                    id: user.id,
                    email: user.email,
                    phone: user.phone,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    tag: user.tag,
                    authResources: user.authResources,
                    imageUploadCode: user.imageUploadCode,
                    lastEdit: user.lastEdit,
                    super: user.super,
                    emailVerifiedAt: user.emailVerifiedAt,
                    building: user.buildings[0],
                    $: {
                      registrationCompleted: user.$.registrationCompleted,
                      hasAdminPermissions: user.$.hasAdminPermissions
                    },
                    deleteRequestAt: user.deleteRequestAt,
                    isDemo: user.isDemo,
                    _count: user._count
                  }
                : null,
              holder:
                holder !== undefined
                  ? {
                      id: holder.id,
                      email: holder.email,
                      phone: holder.phone,
                      firstname: holder.firstname,
                      lastname: holder.lastname,
                      tag: holder.tag,
                      authResources: holder.authResources,
                      imageUploadCode: holder.imageUploadCode,
                      lastEdit: holder.lastEdit,
                      super: holder.super,
                      emailVerifiedAt: holder.emailVerifiedAt,
                      buildings: holder.buildings,
                      $: {
                        registrationCompleted: holder.$.registrationCompleted,
                        hasAdminPermissions: holder.$.hasAdminPermissions
                      },
                      deleteRequestAt: holder.deleteRequestAt,
                      isDemo: holder.isDemo,
                      holderBuilding: holder.buildings.find((b) => !b.users?.length)
                    }
                  : undefined
            })
          );

          if (user != null) dispatch(setNotificationCount(user._count.userNotificationNotices));

          localStorage['accessExpireAt'] = res.data.accessExpireAt;

          if (rememberMe) {
            localStorage['accessToken'] = res.data.accessToken;
            localStorage['refreshToken'] = res.data.refreshToken;
          } else {
            sessionStorage['accessToken'] = res.data.accessToken;
            sessionStorage['refreshToken'] = res.data.refreshToken;
          }
        })
        .catch(async () => {
          await logout();
        });

      return;
    });
  };

  const logout: AuthContextType['logout'] = async () => {
    return lock.acquire(LOGOUT_LOCK, async () => {
      // if (
      //   !localStorage['accessToken'] &&
      //   !localStorage['refreshToken'] &&
      //   !sessionStorage['accessToken'] &&
      //   !sessionStorage['refreshToken']
      // )
      //   return;

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('refreshToken');
      localStorage.removeItem('accessExpireAt');
      localStorage.removeItem('pawnId');
      sessionStorage.removeItem('canRent');

      queryClient.clear();
      dispatch(setLogout());
      // await axiosAuth<{ result: string }>({
      //   method: 'post',
      //   url: '/logout',
      //   headers: { Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}` }
      // }).finally(() => {
      //   localStorage.removeItem('accessToken');
      //   localStorage.removeItem('refreshToken');
      //   sessionStorage.removeItem('accessToken');
      //   sessionStorage.removeItem('refreshToken');
      //   localStorage.removeItem('accessExpireAt');
      //   localStorage.removeItem('pawnId');
      //   localStorage.removeItem('canRent');
      //
      //   queryClient.clear();
      //   dispatch(setLogout());
      // });
    });
  };

  const forgotPassword = (email: string) =>
    axiosAuth({
      method: 'post',
      url: '/password-reset',
      data: {
        email
      }
    });

  const checkPasswordResetCode = (code: string) =>
    axiosAuth({
      method: 'get',
      url: `/password-reset/${code}`
    });

  const resetPassword = (password: string, newPassword: string, code: string) =>
    axiosAuth({
      method: 'put',
      url: `/password-reset/${code}`,
      data: {
        password,
        passwordConfirm: newPassword
      }
    });

  const register: AuthContextType['register'] = ({
    firstname,
    lastname,
    tag,
    email,
    password,
    confirmPassword,
    phone,
    name,
    businessName,
    vat,
    documents,
    origin
  }) =>
    axiosAuth({
      method: 'post',
      url: '/entries',
      data: {
        user: {
          firstname,
          lastname,
          tag,
          email,
          password,
          confirmPassword,
          phone,
          origin
        },
        building: {
          name,
          businessName,
          vat,
          documents
        }
      }
    });

  const fastRegister: AuthContextType['fastRegister'] = ({
    name,
    email,
    password,
    confirmPassword,
    vat,
    documents,
    referralCode,
    origin
  }) =>
    axiosAuth({
      method: 'post',
      url: '/entries',
      data: {
        user: {
          email,
          password,
          confirmPassword,
          referralCode,
          origin
        },
        building: {
          name,
          vat,
          documents
        }
      }
    });

  const buildingRegister: AuthContextType['buildingRegister'] = (building, lastEdit, referralCode) =>
    axiosAuth({
      method: 'patch',
      url: '/entries',
      headers: {
        Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`,
        pawn: localStorage['pawnId']
      },
      data: {
        user:
          referralCode != null
            ? {
                referralCode,
                lastEdit
              }
            : undefined,
        building: {
          ...building,
          lastEdit
        }
      }
    }).catch(async (e) => {
      if (e.response.data.message === 'Unauthorized') {
        await logout();
        handleError(e);
      } else if (e.response.data.message === 'invalid_token') {
        localStorage.removeItem('accessExpireAt');
        await refreshToken();
      }
      return Promise.reject(e);
    });

  const completeFastRegister: AuthContextType['completeFastRegister'] = (data) =>
    axiosAuth({
      method: 'patch',
      url: '/entries',
      headers: {
        Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`,
        pawn: localStorage['pawnId']
      },
      data: {
        user: {
          firstname: data.firstname,
          lastname: data.lastname,
          tag: data.tag,
          phone: data.phone,
          lastEdit: data.userlastEdit
        },
        building: {
          name: data.name,
          businessName: data.businessName,
          lastEdit: data.buildingLastEdit
        }
      }
    });

  const checkEmail = (code: string) =>
    axiosAuth<AxiosAuthResponseType>({
      method: 'put',
      url: `/email-verification/${code}`
    })
      .then((res) => {
        console.log('email verificata con successo setto i token', res.data);
        const user = res.data.user;
        // if (user != null) {
        //   dispatch(setNotificationCount(user._count.userNotificationNotices));
        // }

        console.log('dispaccio il remember me');

        dispatch(setRememberMe(false));
        console.log('setto i token');
        localStorage.setItem('accessExpireAt', res.data.accessExpireAt);
        sessionStorage.setItem('accessToken', res.data.accessToken);
        sessionStorage.setItem('refreshToken', res.data.refreshToken);
        dispatch(
          setLogin({
            user: user
              ? {
                  id: user.id,
                  email: user.email,
                  phone: user.phone,
                  firstname: user.firstname,
                  lastname: user.lastname,
                  tag: user.tag,
                  authResources: user.authResources,
                  imageUploadCode: user.imageUploadCode,
                  lastEdit: user.lastEdit,
                  super: user.super,
                  emailVerifiedAt: user.emailVerifiedAt,
                  building: user.buildings[0],
                  $: {
                    registrationCompleted: user.$.registrationCompleted,
                    hasAdminPermissions: user.$.hasAdminPermissions
                  },
                  deleteRequestAt: user.deleteRequestAt,
                  isDemo: user.isDemo,
                  _count: user._count
                }
              : null,
            holder: undefined
          })
        );
      })
      .catch(async (error) => {
        if (error.response?.data?.message === 'invalid_token') {
          localStorage.removeItem('accessExpireAt');
          await refreshToken();
        } else {
          await logout();
        }
      });

  const getCodeCheckEmail = (email: string) =>
    axiosAuth({
      method: 'post',
      url: '/email-verification',
      data: {
        email
      }
    });

  const editPassword = (oldPassword: string, newPassword: string, confirmNewPassword: string) =>
    axiosAuth({
      method: 'patch',
      url: '/password',
      headers: { Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}` },
      data: {
        oldPassword,
        newPassword,
        confirmNewPassword
      }
    });

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        authEmailPasswordSingIn,
        authTokenLogin,
        logout,
        forgotPassword,
        checkPasswordResetCode,
        resetPassword,
        register,
        fastRegister,
        completeFastRegister,
        buildingRegister,
        checkEmail,
        getCodeCheckEmail,
        editPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
