import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const OrdersNegativeIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9602_112410)">
        <path d="M23.2437 3.51221H1V19.9025H23.2437V3.51221Z" fill="#F0FF32" />
        <path d="M15.0487 18.1462H9.19495V24H15.0487V18.1462Z" fill="#91DCF9" />
        <path d="M19.1463 21.6584H5.09753V24H19.1463V21.6584Z" fill="#91DCF9" />
        <path d="M15.0487 18.1462H9.19495V19.9022H15.0487V18.1462Z" fill="#1ED367" />
        <path
          d="M17.3886 5.14057C17.3883 5.12372 17.3873 5.10687 17.387 5.09002C17.386 5.06506 17.3854 5.04044 17.3841 5.01581C17.3831 4.9941 17.3818 4.97206 17.3805 4.95035C17.3795 4.93156 17.3782 4.91277 17.377 4.89397C17.3753 4.86902 17.3731 4.84407 17.3711 4.81912C17.3698 4.80454 17.3685 4.78995 17.3672 4.77537C17.3646 4.74783 17.362 4.72029 17.3591 4.69307C17.3578 4.68237 17.3569 4.67168 17.3556 4.66131C17.352 4.63118 17.3484 4.60104 17.3445 4.57123C17.3439 4.56637 17.3433 4.56183 17.3426 4.55697C17.3345 4.49637 17.3251 4.4361 17.3151 4.37616C17.3134 4.36579 17.3118 4.35509 17.3099 4.34472C17.3076 4.33241 17.3053 4.3201 17.3031 4.30778C17.2969 4.27408 17.2904 4.24071 17.284 4.20733C17.284 4.20701 17.284 4.20636 17.284 4.20604C17.2353 3.96463 17.1722 3.73326 17.0986 3.51227H17.0911C16.3666 1.46596 14.4156 0 12.122 0C9.82845 0 7.87708 1.46596 7.15383 3.51227H7.14637C7.07282 3.73326 7.00963 3.9643 6.96102 4.20604C6.96102 4.20636 6.96102 4.20701 6.96102 4.20733C6.95422 4.24071 6.94806 4.27408 6.9419 4.30778C6.93963 4.3201 6.93737 4.33241 6.9351 4.34472C6.93315 4.35509 6.93153 4.36579 6.92991 4.37616C6.91954 4.4361 6.91047 4.49637 6.90237 4.55697C6.90172 4.56183 6.90107 4.56637 6.90043 4.57123C6.89654 4.60104 6.89297 4.63118 6.88941 4.66131C6.88811 4.672 6.88682 4.68237 6.88584 4.69307C6.88293 4.72061 6.88001 4.74783 6.87774 4.77537C6.87645 4.78995 6.87515 4.80454 6.87386 4.81912C6.87191 4.84407 6.86964 4.86902 6.86802 4.89397C6.86673 4.91277 6.86543 4.93156 6.86446 4.95035C6.86316 4.97206 6.86187 4.99378 6.86089 5.01581C6.8596 5.04044 6.85895 5.06539 6.85798 5.09002C6.85733 5.10687 6.85668 5.12372 6.85636 5.14057C6.85538 5.18301 6.85474 5.22546 6.85474 5.26824C6.85474 8.19496 9.19625 10.5365 12.123 10.5365C15.0497 10.5365 17.3912 8.19496 17.3912 5.26824C17.3912 5.22546 17.3906 5.18301 17.3896 5.14057H17.3886Z"
          fill="#1ED367"
        />
        <path d="M19.1463 8.78052H5.09753V10.5365H19.1463V8.78052Z" fill="#91DCF9" />
        <path
          d="M13.9341 7.5567H12.4325C11.4723 7.5567 10.6914 6.77544 10.6914 5.81564V3.81016C10.6914 2.85003 11.4723 2.06909 12.4325 2.06909H13.9341V3.06681H12.4325C12.0226 3.06681 11.6891 3.40025 11.6891 3.81016V5.81531C11.6891 6.22522 12.0226 6.55866 12.4325 6.55866H13.9341V7.5567Z"
          fill="#094754"
        />
        <path d="M12.9366 4.31396H9.94348V5.31168H12.9366V4.31396Z" fill="#094754" />
      </g>
      <defs>
        <clipPath id="clip0_9602_112410">
          <rect width="22.2437" height="24" fill="white" transform="translate(1)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default OrdersNegativeIcon;
