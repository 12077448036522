import { useLayoutEffect } from 'react';
import { Box, Fade, Grid, Typography } from '@mui/material';
import Illustration from 'assets/images/illustrations/illustration_building_choice.svg';
import { dispatch, useSelector } from '../../store';
import PawnAutoComplete from '../../components/custom/PawnAutoComplete';
import { setPawn } from '../../store/reducers/auth';
import AppBar from '@mui/material/AppBar';
import HolderBar from '../../components/custom/HolderBar';
const HolderChoice = () => {
  const { holder, pawn } = useSelector((state) => state.auth);
  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('root')?.classList.remove('body-bootstrap');
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);

  if (holder === undefined) {
    return <></>;
  }

  return (
    <Fade in>
      <Box>
        <AppBar
          elevation={0}
          position={'absolute'}
          sx={(theme) => ({
            background: 'none'
          })}
        >
          <HolderBar />
        </AppBar>
        <Grid container width={'95%'} marginX={'auto'} maxWidth={1000} height={'100dvh'}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: { xs: 'center', md: 'flex-start' },
              justifyContent: 'center',
              flexDirection: 'column',
              height: 'calc(100% - 50px)'
            }}
          >
            <Box>
              <Typography variant={'h4'}>Scegli un utente</Typography>
              <Typography variant={'body1'} mb={3}>
                Seleziona un utente per cui visualizzare la piattaforma
              </Typography>
              <PawnAutoComplete
                holder={holder}
                pawn={pawn ?? null}
                onChange={(event, value) => {
                  dispatch(setPawn(value ?? undefined));
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100% - 50px)',
              img: {
                width: '100%',
                maxWidth: 370
              }
            }}
          >
            <img src={Illustration} alt={'illustration'} />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default HolderChoice;
