import React from 'react';
import { SnackbarContent, CustomContentProps, useSnackbar, SnackbarKey } from 'notistack';
import { dispatch } from '../../store';
import { closeSnackbar } from '../../store/reducers/snackbar';
import { Box, IconButton, Typography } from '@mui/material';
import InfoIcon from '../custom/BuyPlusIcons/InfoIcon';
import CloseIcon from '@mui/icons-material/Close';

const NotificationSnackbar = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { closeSnackbar: closeNotistack } = useSnackbar();
  const handleClose = (key?: SnackbarKey) => {
    closeNotistack(key);
    dispatch(closeSnackbar());
  };
  return (
    <SnackbarContent ref={ref}>
      <Box
        sx={(theme) => ({
          width: '100%',
          backgroundColor: '#EDF8F9',
          borderTop: `5px solid #7DEBF5`,
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)'
        })}
        onClick={() => {
          handleClose(props.id);
        }}
      >
        <InfoIcon fontSize={'medium'} />
        <Typography variant={'body1'} fontWeight={400} maxWidth={375}>
          {props.message}
        </Typography>

        <IconButton sx={{ width: 30, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CloseIcon fontSize={'small'} />
        </IconButton>
      </Box>
    </SnackbarContent>
  );
});

NotificationSnackbar.displayName = 'NotificationSnackbar';

export default NotificationSnackbar;
