import { Components, Theme, alpha } from '@mui/material';

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5)
        },
        '&:active': {
          borderRadius: 50
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.04)
          }
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.04)
          }
        },
        colorSuccess: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.success.main, 0.04)
          }
        },
        colorError: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.04)
          }
        },
        colorWarning: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.warning.main, 0.04)
          }
        },
        colorInfo: {
          color: theme.palette.common.white,
          bgcolor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: alpha(theme.palette.info.main, 0.04)
          }
        }
      }
    } as Components['MuiIconButton']
  };
}
