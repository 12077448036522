import { createSlice } from '@reduxjs/toolkit';

interface AppInfoProps {
  //Boolean per dirci se salvare i token nel localStorage o nel SessionStorage
  registeredEmail: string;
  ordersEmail: string;
}

const initialState: AppInfoProps = {
  registeredEmail: '',
  ordersEmail: ''
};

const appInfo = createSlice({
  name: 'appInfo',
  initialState,
  reducers: {
    setAppInfo: (state, action: { payload: AppInfoProps }) => {
      state.registeredEmail = action.payload.registeredEmail;
      state.ordersEmail = action.payload.ordersEmail;
    }
  }
});

export default appInfo.reducer;

export const { setAppInfo } = appInfo.actions;
