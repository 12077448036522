import { WalletDTO } from '../types/dto/plafond.dto';

/**
 * Funzione che mi dice se ci sono documenti extra da caricare
 * @param wallets
 */
export default function getExtraDocumentsToUpload(wallets: WalletDTO[]): boolean {
  return wallets.some((p) => {
    if (p.wallet != null && p.wallet.documents?.length > 0) {
      if (
        p.wallet?.documents.some(
          (ed) => (ed.userUpload == null && ed.signatureType !== 'check') || (ed.signatureType === 'check' && ed.signedAt == null)
        )
      ) {
        return true;
      }
    }
    return false;
  });
}
