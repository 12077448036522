// types
import { createSlice } from '@reduxjs/toolkit';
import { PayloadWebSocket, WebSocketHelperType } from 'types/websocketHelper';

// initial state
const initialState: WebSocketHelperType = {
  notificationCount: 0,
  refetchNotifications: {
    refetchDrawer: false,
    refetchPage: false
  },
  refetchWallets: false,
  refetchOrders: {
    refetch: false,
    subjects: null,
    userId: null
  },
  refetchAppState: false,
  refetchBuilding: false
};

// ==============================|| SLICE - MENU ||============================== //

const websocket = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setRefetchNotifications(state, action: PayloadWebSocket<WebSocketHelperType['refetchNotifications']>) {
      state.refetchNotifications = action.payload;
    },
    setNotificationCount(state, action: PayloadWebSocket<number>) {
      state.notificationCount = action.payload;
    },
    setRefetchWallets(state, action: PayloadWebSocket<boolean>) {
      state.refetchWallets = action.payload;
    },
    setRefetchOrders(state, action: PayloadWebSocket<WebSocketHelperType['refetchOrders']>) {
      state.refetchOrders = action.payload;
    },
    setRefetchAppState(state, action: PayloadWebSocket<boolean>) {
      state.refetchAppState = action.payload;
    },
    setRefetchBuilding(state, action: PayloadWebSocket<boolean>) {
      state.refetchBuilding = action.payload;
    }
  }
});

export default websocket.reducer;

export const {
  setRefetchNotifications,
  setNotificationCount,
  setRefetchWallets,
  setRefetchOrders,
  setRefetchAppState,
  setRefetchBuilding
} = websocket.actions;
