// third-party
import AccountIcon from 'components/custom/BuyPlusIcons/AccountIcon';
import PeopleIcon from 'components/custom/BuyPlusIcons/PeopleIcon';
import { FormattedMessage } from 'react-intl';
// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const account: NavItemType = {
  id: 'account-group',
  title: '',
  type: 'group',
  children: [
    {
      id: 'account',
      title: <FormattedMessage id="account" />,
      type: 'collapse',
      icon: [AccountIcon, PeopleIcon],
      children: [
        {
          id: 'settings',
          title: <FormattedMessage id="settings" />,
          type: 'item',
          url: '/account/settings'
        },
        {
          id: 'documents',
          title: <FormattedMessage id="documents" />,
          type: 'item',
          url: '/account/documents'
        }
      ]
    }
  ]
};

export default account;
