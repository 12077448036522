import { Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { OrderDTO, OrderPurchaseConfiguration, OrderRentConfiguration } from '../../../../../../types/dto/orders.dto';
import PreventivoIcon from '../../../../BuyPlusIcons/PreventivoIcon';
import { useOpenFileModal } from '../../../../EmbedFile';
import Grid from '@mui/material/Grid';

type OrderInfoReviewProps = {
  orderInfo: Pick<OrderDTO, 'orderCategory' | 'deliveryDateHistory' | 'shippingAddress' | 'createdAt' | 'type'> & {
    userConfiguration: OrderDTO['activeConfiguration'] | OrderPurchaseConfiguration | OrderRentConfiguration;
  };
};

const OrderInfoReview = ({ orderInfo }: OrderInfoReviewProps) => {
  // const configuration = orderInfo.editableConfiguration != null ? orderInfo.editableConfiguration : orderInfo.orderConfigurations[0];

  const openFileModal = useOpenFileModal();
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        position: 'relative',
        '&::after': {
          content: '""',
          display: { xs: 'none', md: 'block' },
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          height: '90%',
          right: 0,
          bottom: 0,
          width: '1px',
          backgroundColor: 'divider'
        }
      }}
    >
      <Typography variant={'h6'} fontWeight={600} mb={1}>
        {/*Requisiti {orderInfo.type === 'purchase' ? 'acquisto' : 'noleggio'}*/}
        Requisiti d'ordine
      </Typography>

      <Grid container maxWidth={500} mt={3} rowSpacing={1}>
        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Data della proposta
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'} fontWeight={600}>
            {dayjs(orderInfo.createdAt).format('DD/MM/YYYY')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Tipologia di ordine
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'} fontWeight={600}>
            {orderInfo.type === 'purchase' ? 'ACQUISTO' : 'NOLEGGIO'}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Categoria
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'} fontWeight={600}>
            {orderInfo.orderCategory.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Data di consegna
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'} fontWeight={600}>
            {dayjs(orderInfo.deliveryDateHistory[orderInfo.deliveryDateHistory.length - 1]).format('DD/MM/YYYY')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Indirizzo di consegna
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'} fontWeight={600}>
            {`${orderInfo.shippingAddress?.address}, ${orderInfo.shippingAddress?.city.name.it}(${orderInfo.shippingAddress?.region.name.it})`}
          </Typography>
        </Grid>

        <Grid item xs={12} mt={3}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant={'body1'} fontWeight={500} color={'grey.500'}>
                Budget
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant={'body1'} fontWeight={500}>
                {BigNumber(orderInfo.userConfiguration.amount).toNumber().toLocaleString()}€
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider orientation={'horizontal'} sx={{ my: 2, maxWidth: 500 }} />

      <Typography variant={'h6'} fontWeight={400} color={'text.disabled'} mb={3}>
        Preventivi
      </Typography>

      <Grid container maxWidth={500} mt={1} rowSpacing={3}>
        {!orderInfo.userConfiguration.areQuotesManual &&
          orderInfo.userConfiguration.orderQuotes != null &&
          orderInfo.userConfiguration.orderQuotes.length > 0 &&
          orderInfo.userConfiguration.orderQuotes.map((orderQuote) => (
            <Grid item xs={12} key={orderQuote.id}>
              <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    openFileModal({
                      title: orderQuote.upload.metadata.filename,
                      fileCode: orderQuote.upload.code
                    })
                  }
                >
                  <PreventivoIcon sx={{ fontSize: '35px' }} />
                </div>

                <Typography variant={'body1'} fontWeight={400}>
                  {orderQuote.upload.metadata.filename}
                </Typography>
              </Stack>
            </Grid>
          ))}

        {orderInfo.userConfiguration.areQuotesManual &&
          orderInfo.userConfiguration.requestedProducts?.length &&
          orderInfo.userConfiguration.requestedProducts.map((manualProduct, i) => (
            <Grid container key={i}>
              {i > 0 && (
                <Grid item xs={12} my={1}>
                  <Divider />
                </Grid>
              )}
              <Grid item xs={12} mb={1}>
                <Typography variant={'body1'} color={'text.disabled'} fontWeight={600}>
                  Preventivo {i + 1}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant={'body2'} color={'text.disabled'}>
                  Titolo del prodotto
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body1'}>{manualProduct.name}</Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography variant={'body2'} color={'text.disabled'}>
                  Quantità
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body1'}>{manualProduct.quantity}</Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography variant={'body2'} color={'text.disabled'}>
                  Azienda fornitrice
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'body1'}>{manualProduct.company ?? 'Non definita'}</Typography>
              </Grid>

              {manualProduct.extraInfo !== '' && (
                <Grid item xs={12}>
                  <Typography variant={'body2'} color={'text.disabled'} gutterBottom>
                    Descrizione
                  </Typography>
                  <Typography variant={'body2'}>{manualProduct.extraInfo}</Typography>
                </Grid>
              )}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default OrderInfoReview;
