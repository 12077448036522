// project import
import Routes from 'routes';
import Locales from 'components/Locales';
// import Snackbar from 'components/Snackbar';
import React, { useMemo } from 'react';
import { AuthProvider } from 'contexts/AuthContext';
import { useEffect } from 'react';
import { setFavicon } from 'utils/setFavicon';
import { QueryClient, QueryClientProvider } from 'react-query';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { AxiosInstanceProvider } from 'contexts/AxiosIstanceContext';
import useConfig from 'hooks/useConfig';
import { ModalProvider } from 'contexts/ModalContext';
import { useDispatch } from 'store';
import { activeItem } from 'store/reducers/menu';
import DrawerProvider from './contexts/DrawerContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import UnderMaintenance from './components/UnderMaintenance';
import RouteGuard from './utils/route-guard/RouteGuard';
import { withErrorBoundary } from 'react-error-boundary';
import { FallbackComponent } from './components/custom/FallbackErrorBoundary';
import JivoChatProvider from './contexts/JivoChatContext';
import NotistackProvider from './components/notification/NotistackProvider';
import useGaPageView from './hooks/useGaPageView';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
export const queryClient = new QueryClient();

const App = () => {
  const pathname = useMemo(() => window.location.pathname, []);
  const dispatch = useDispatch();
  const { i18n } = useConfig();
  useGaPageView();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      document.title = 'Buyplus App - Sviluppo';
    } else if (process.env.REACT_APP_ENVIRONMENT === 'test') {
      document.title = 'Buyplus App - Test';
    } else if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      document.title = 'Buyplus App - Locale';
    }

    dayjs.locale(i18n);

    //Set menu active
    const locations = pathname.split('/');
    let activeLocation = locations[locations.length - 1];
    if (activeLocation !== 'dashboard') {
      if (locations[locations.length - 2] === 'new-order') {
        dispatch(activeItem({ openItem: ['new-order'] }));
      } else {
        dispatch(activeItem({ openItem: [activeLocation] }));
      }
    }

    //Set favicon
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setFavicon(mediaQuery.matches ? 'dark' : 'light');
    const handleChange = (e: MediaQueryListEvent) => {
      setFavicon(e.matches ? 'dark' : 'light');
    };
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  console.log(process.env.REACT_APP_ENVIRONMENT);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
      <Locales>
        <NotistackProvider maxSnack={5}>
          <AuthProvider>
            <JivoChatProvider>
              <AxiosInstanceProvider>
                <QueryClientProvider client={queryClient}>
                  <DrawerProvider>
                    <ModalProvider>
                      <UnderMaintenance />
                      <RouteGuard>
                        <Routes />
                      </RouteGuard>
                      {/*<Snackbar />*/}
                    </ModalProvider>
                  </DrawerProvider>
                </QueryClientProvider>
              </AxiosInstanceProvider>
            </JivoChatProvider>
          </AuthProvider>
        </NotistackProvider>
      </Locales>
    </LocalizationProvider>
  );
};

export default withErrorBoundary(React.memo(App), {
  FallbackComponent: (props) => <FallbackComponent {...props} />
});

// export default React.memo(App);
