import { SortingProps } from 'types/server/sorting-props';

export const formatSorting = (sorting: SortingProps[]) => {
  if (!sorting?.length) return undefined;
  return JSON.stringify(
    sorting.map((el: any) => ({
      field: el.id,
      sort: el.desc ? 'desc' : 'asc'
    }))
  );
};
