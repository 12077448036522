import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { HolderType, PawnType } from '../../types/auth';
type PawnAutoCompleteProps = {
  holder: HolderType;
  pawn: PawnType | null;
};
const PawnAutoComplete = ({
  pawn,
  holder,
  onChange,
  ...other
}: PawnAutoCompleteProps &
  Omit<
    AutocompleteProps<PawnType, false, boolean, false>,
    'getOptionLabel' | 'isOptionEqualToValue' | 'options' | 'value' | 'renderInput' | 'disableClearable' | 'noOptionsText'
  >) => {
  return (
    <Autocomplete<PawnType, false, boolean, false>
      {...other}
      value={pawn}
      disableClearable={true}
      options={holder.buildings
        .filter((b) => !!b.users.length)
        .map((b) => ({
          id: b.users[0].id,
          firstname: b.users[0].firstname,
          lastname: b.users[0].lastname,
          email: b.users[0].email,
          building: {
            name: b.name,
            businessName: b.businessName
          }
        }))}
      renderInput={(params) => (
        <TextField {...params} size={'small'} fullWidth sx={{ width: '100%', maxWidth: 400 }} label={"Scegli l'utente"} />
      )}
      noOptionsText={'Non sei assegnato a nessuna struttura'}
      getOptionLabel={(option) =>
        `${
          option.firstname == null && option.lastname == null
            ? option.email
            : `${option.firstname} ${option.lastname} - ${option.building.name}`
        }`
      }
      isOptionEqualToValue={(option, value) => option.email === value.email}
      onChange={(event, value, reason, details) => {
        if (onChange) {
          onChange(event, value, reason, details);
          window.location.reload();
        }
      }}
    />
  );
};

export default PawnAutoComplete;
