import { Box, Button, Divider, Fade, FormHelperText, Grid, IconButton, InputLabel, Link, TextField, Typography } from '@mui/material';
// import OrderSummaryCard from '../../components/custom/Cards/OrderSummary/OrderSummaryCard';
import BoxUploadFile from '../../components/custom/BoxUploadFile';
import { useEffect, useState } from 'react';
import ListUploadedFile from '../../components/custom/ListUploadedFile';
import { ErrorMessage, FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEndpoint } from '../../hooks/useEndpoint';
import { NewOrderFormikStep3, NewOrderFormikStep3Manual } from '../../types/formik/new-order/new-order-step-3';
import { OrderManualProductDto } from '../../types/dto/orders.dto';
import { NumericFormat } from 'react-number-format';
import ClearIcon from '@mui/icons-material/Clear';
import NewOrderStep3Skeletons from '../../components/custom/Skeletons/NewOrderSkeletons/NewOrderStep3Skeletons';
import { NewOrderStepProps } from '../../types/formik/new-order/new-order-step-props';
import { dispatch } from '../../store';
import { openSnackbar } from '../../store/reducers/snackbar';
import AddIcon from '@mui/icons-material/Add';
import ListFakeLoaderFile from '../../components/custom/ListFakeLoaderFile';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import { useTour } from '@reactour/tour';
import { useOutletContext } from 'react-router';

// {
//   orderId,
//     handleNextStep,
//     orderSummary,
//     setLoadingButton,
//     refetchOrderSummary,
//     setFormikErrors
// }: NewOrderStepProps
const NewOrderStep2 = () => {
  const {
    orderSummary,
    setNavigateLoading,
    refetchOrderSummary,
    orderId,
    setLoadingButton,
    handleNextStep,
    setFormikErrors,
    initialRenders,
    navigateLoading,
    setInitialRenders
  } = useOutletContext<NewOrderStepProps>();
  const { trackAction } = useNavigationTracker();
  const tour = useTour();
  /**
   * Boolean per switchare tra l'invio dei file o il form manuale
   */
  const [isForm, setIsForm] = useState(orderSummary?.activeConfiguration.areQuotesManual);

  /**
   * Endpoint per inviare i file e eliminare i file
   */
  const updateQuotesFile = useEndpoint<{ documents: File[] }, 'post'>({
    method: 'post',
    endpoint: `orders/${orderId}/quotes`,
    mutationKey: 'send-quotes',
    options: {
      onSuccess: () => {
        refetchOrderSummary();
        dispatch(
          openSnackbar({
            variant: 'success',
            message: 'Preventivo aggiunto con successo!'
          })
        );
      }
    }
  });

  /**
   * Endpoint per inviare i preventivi manualmente
   */
  const updateOrderQuotes = useEndpoint<Partial<NewOrderFormikStep3Manual> & Partial<NewOrderFormikStep3>, 'patch'>({
    method: 'patch',
    endpoint: `/orders/${orderId}`,
    mutationKey: `/orders/${orderId}/manualQuotes`
  });

  const formFileStep3 = useFormik<NewOrderFormikStep3>({
    initialValues: {
      quotesExtraInfo: orderSummary?.activeConfiguration.quotesExtraInfo ?? undefined,
      documents: []
    },
    validationSchema: Yup.object().shape({
      quotesExtraInfo: Yup.string().notRequired(),
      documents: Yup.array().test('documents', 'Inserisci un preventivo', function (value) {
        if (
          orderSummary?.activeConfiguration.orderQuotes != null &&
          orderSummary.activeConfiguration.orderQuotes.length === 0 &&
          value?.length === 0
        ) {
          return false;
        } else return true;
      })
    }),
    onSubmit: (values) => {
      setLoadingButton(true);
      trackAction({
        name: `E' andato allo step 3 dell'ordine: ${orderId}`,
        type: ClientTypeEnum.Form,
        action: ClientActionEnum.Submit
      });
      updateOrderQuotes.mutate(
        {
          quotesExtraInfo: values.quotesExtraInfo,
          lastStep: 3
        },
        {
          onSuccess: async () => {
            await refetchOrderSummary();
            handleNextStep(3);
            setLoadingButton(false);
          }
        }
      );
    }
  });

  const formManualStep3 = useFormik<NewOrderFormikStep3Manual>({
    initialValues: {
      requestedProducts: orderSummary?.activeConfiguration.requestedProducts ?? [
        {
          extraInfo: '',
          name: '',
          quantity: undefined,
          company: undefined
        }
      ]
    },
    validationSchema: Yup.object().shape({
      requestedProducts: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Inserisci un nome'),
          quantity: Yup.number().min(0, 'La quantità deve essere maggiore di 0').required('Inserisci una quantità'),
          extraInfo: Yup.string().notRequired(),
          company: Yup.string().notRequired()
        })
      )
    }),
    onSubmit: (values) => {
      setLoadingButton(true);
      trackAction({
        type: ClientTypeEnum.Form,
        action: ClientActionEnum.Submit,
        name: `E' andato allo step 3 dell'ordine: ${orderId}`
      });
      updateOrderQuotes.mutate(
        {
          requestedProducts: values.requestedProducts,
          lastStep: 3
        },
        {
          onSuccess: async () => {
            await refetchOrderSummary();
            handleNextStep(3);
            setLoadingButton(false);
          }
        }
      );
    }
  });

  useEffect(() => {
    if (!isForm) {
      setFormikErrors(formFileStep3.errors);
    } else {
      setFormikErrors(formManualStep3.errors);
    }
  }, [formFileStep3.errors, formManualStep3.errors, isForm]);

  useEffect(() => {
    if (tour.isOpen && tour.setSteps) {
      tour.setSteps([
        {
          selector: '#new-order-step-1',
          content: 'In questa sezione potrai inserire tutti i preventivi a tua disposizione o se non ne hai puoi richiederli a noi',
          position: 'left'
        },
        {
          selector: '[data-tour="order-summary"]',
          content: "Questo è un sommario che ti accompagnerà per tutta la procedura dell'ordine mostrandoti il riepilogo"
        },
        {
          selector: '[data-tour="change_quotes_to_manual"]',
          content: 'Da qui puoi passare alla modalità di richiesta dei preventivi tramite un pratico form',
          actionAfter: () => {
            setIsForm(true);
          }
        },
        {
          selector: '#new-order-step-1',
          content:
            'Da questo pratico form potrai inserire tutti i prodotti di cui hai bisogno inserendo per ognuno un titolo, una quantità e una descrizione'
        },
        {
          selector: '[data-tour="change_quotes_to_upload"]',
          content: 'Se hai invece a disposizione dei preventivi puoi ritornare nella modalità precedente',
          actionAfter: () => {
            setIsForm(false);
          }
        },
        {
          selector: 'main',
          mutationObservables: ['main'],
          content:
            'Carica i tuoi preventivi oppure compila la richiesta di preventivo e potrai proseguire con la configurazione del credito',
          position: [0, 10],
          stepInteraction: true
        }
      ]);
      tour.setCurrentStep(0);
    }
  }, []);

  useEffect(() => {
    // const paths = location.pathname.split('/');
    // const urlStep = paths[paths.length - 1]?.split('-')[1];
    if (orderSummary?.lastStep > 2 && !initialRenders.step2 && initialRenders.step1) {
      console.log('navigo allo step 3');
      // navigate(`/orders/new-order/${orderSummary.code}/${textToUrl(orderSummary.title)}/step-3`);
      setNavigateLoading(true);
      handleNextStep(3);
      setInitialRenders({
        ...initialRenders,
        step2: true
      });
    } else if (!initialRenders.step2 && initialRenders.step1) {
      setNavigateLoading(false);
      setInitialRenders({
        ...initialRenders,
        step2: true
      });
    }
  }, [initialRenders]);

  if (navigateLoading) {
    return <></>;
  }

  return orderSummary != null && orderId != null ? (
    <Fade in>
      <Box>
        <Typography gutterBottom variant="h5">
          Carica i tuoi preventivi
        </Typography>
        <Typography gutterBottom variant="body2" maxWidth={750}>
          Inserisci i preventivi, le foto e i dettagli del prodotto/servizio che vuoi acquistare.
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          {!isForm && (
            <Grid item xs={12} lg={8}>
              <FormikProvider value={formFileStep3}>
                <form noValidate onSubmit={formFileStep3.handleSubmit} id="new-order-step-2">
                  <Box sx={{ width: '100%', maxWidth: '800px', marginX: 'auto' }}>
                    <BoxUploadFile<File[]>
                      name={'preventivi-new-orders'}
                      maxFileSize={40}
                      multiple={true}
                      acceptedFiles={['image/svg+xml', 'image/png', 'image/jpeg', 'application/pdf', 'application/msword']}
                      acceptedFilesLabel={'PDF, word o un immagine'}
                      files={formFileStep3.values.documents}
                      setFileInput={(file) => {
                        formFileStep3.setValues({
                          ...formFileStep3.values,
                          documents: file
                        });
                        updateQuotesFile.mutate(
                          {
                            documents: file
                          },
                          {
                            onSuccess: async () => {
                              await refetchOrderSummary();
                              await formFileStep3.setValues({
                                ...formFileStep3.values,
                                documents: []
                              });
                              formFileStep3.setErrors({});
                            }
                          }
                        );
                        trackAction({
                          type: ClientTypeEnum.File,
                          name: `Ha caricato un preventivo`,
                          action: ClientActionEnum.Added
                        });
                      }}
                    />

                    <ErrorMessage name={'documents'} render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>} />
                  </Box>

                  <Box maxWidth={800} marginX={'auto'} mt={2}>
                    <ListUploadedFile
                      files={orderSummary?.activeConfiguration.orderQuotes ?? []}
                      orderId={orderId!}
                      refetch={refetchOrderSummary}
                    />
                    <ListFakeLoaderFile files={formFileStep3.values.documents ?? []} />

                    <Box mt={5}>
                      <InputLabel variant="standard">Inserisci ulteriori informazioni sui preventivi a tua disposizione</InputLabel>
                      <TextField
                        fullWidth
                        minRows={5}
                        maxRows={10}
                        multiline
                        placeholder={'Note sui preventivi'}
                        value={formFileStep3.values.quotesExtraInfo ?? ''}
                        name={'quotesExtraInfo'}
                        onBlur={() => {
                          trackAction({
                            type: ClientTypeEnum.Input,
                            action: ClientActionEnum.Typed,
                            name: `Ha aggiunto ulteriori informazioni sui preventivi: ${formFileStep3.values.quotesExtraInfo}`
                          });
                        }}
                        onChange={formFileStep3.handleChange}
                      />
                    </Box>

                    <Divider sx={{ mt: 2, mb: 1 }} />
                    <Typography variant={'body1'} data-tour={'change_quotes_to_manual'}>
                      Non hai un preventivo?
                      <Link
                        fontWeight={600}
                        sx={{ cursor: 'pointer', ml: 0.5 }}
                        data-cy={'change_quotes_to_manual'}
                        onClick={() => {
                          setIsForm(true);
                          updateOrderQuotes.mutate({
                            areQuotesManual: true
                          });
                          trackAction({
                            action: ClientActionEnum.Clicked,
                            type: ClientTypeEnum.Button,
                            name: "E' passato alla modalita aggiunta preventivi: manuale"
                          });
                        }}
                      >
                        Compila il form
                      </Link>
                    </Typography>
                  </Box>
                </form>
              </FormikProvider>
            </Grid>
          )}

          {/*/!*Preventivi Manuali*!/*/}
          {isForm && (
            <Grid item xs={12} lg={8}>
              <FormikProvider value={formManualStep3}>
                <form noValidate onSubmit={formManualStep3.handleSubmit} id="new-order-step-2">
                  <FieldArray
                    name={'requestedProducts'}
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {formManualStep3.values.requestedProducts &&
                            formManualStep3.values.requestedProducts.length &&
                            formManualStep3.values.requestedProducts.map((quote, i) => (
                              <Box key={i} display={'flex'} flexWrap={'nowrap'}>
                                {i >= 1 && (
                                  <Box flexGrow={0}>
                                    <IconButton
                                      size={'small'}
                                      color={'error'}
                                      onClick={() => {
                                        trackAction({
                                          action: ClientActionEnum.Clicked,
                                          type: ClientTypeEnum.Button,
                                          name: `Ha rimosso il prodotto ${
                                            !!quote.name ? quote.name : 'non ancora compilato'
                                          } nei preventivi manuali`
                                        });
                                        arrayHelpers.remove(i);
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                )}
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <Box mb={1}>
                                      <InputLabel variant="standard">Quale prodotto/servizio vorresti acquistare?*</InputLabel>
                                      <TextField
                                        name={`requestedProducts.${i}.name`}
                                        data-cy={'product_name'}
                                        onChange={formManualStep3.handleChange}
                                        onBlur={(e) => {
                                          formManualStep3.handleBlur(e);
                                          trackAction({
                                            type: ClientTypeEnum.Input,
                                            action: ClientActionEnum.Typed,
                                            name: `Ha inserito il nome del prodotto da acquistare: ${quote.name}`
                                          });
                                        }}
                                        value={quote.name}
                                        fullWidth
                                        size={'small'}
                                        autoComplete={'off'}
                                        placeholder={'Prodotto/Servizio'}
                                        error={
                                          getIn(formManualStep3.errors, `requestedProducts[${i}].name`) &&
                                          getIn(formManualStep3.touched, `requestedProducts[${i}].name`)
                                        }
                                      />
                                      <ErrorMessage
                                        name={`requestedProducts[${i}].name`}
                                        render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <InputLabel variant="standard">Di quanti prodotti hai bisogno?*</InputLabel>
                                    <NumericFormat
                                      allowNegative={false}
                                      customInput={TextField}
                                      name={`requestedProducts.${i}.quantity`}
                                      data-cy={'product_quantity'}
                                      onChange={formManualStep3.handleChange}
                                      onBlur={(e) => {
                                        formManualStep3.handleBlur(e);
                                        trackAction({
                                          type: ClientTypeEnum.Input,
                                          action: ClientActionEnum.Typed,
                                          name: `Ha inserito la quantità del prodotto da acquistare: ${quote.quantity}`
                                        });
                                      }}
                                      value={quote.quantity ?? ''}
                                      fullWidth
                                      size={'small'}
                                      autoComplete={'off'}
                                      placeholder={'Quantità'}
                                      error={
                                        getIn(formManualStep3.errors, `requestedProducts[${i}].quantity`) &&
                                        getIn(formManualStep3.touched, `requestedProducts[${i}].quantity`)
                                      }
                                    />
                                    <ErrorMessage
                                      name={`requestedProducts[${i}].quantity`}
                                      render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <InputLabel variant="standard">Inserisci il fornitore da cui vorresti il prodotto</InputLabel>
                                    <TextField
                                      name={`requestedProducts.${i}.company`}
                                      data-cy={'product_name'}
                                      onChange={formManualStep3.handleChange}
                                      onBlur={(e) => {
                                        formManualStep3.handleBlur(e);
                                        trackAction({
                                          type: ClientTypeEnum.Input,
                                          action: ClientActionEnum.Typed,
                                          name: `Ha inserito il nome del fornitore: ${quote.company}`
                                        });
                                      }}
                                      value={quote.company}
                                      fullWidth
                                      size={'small'}
                                      autoComplete={'off'}
                                      placeholder={'Preferenza fornitore'}
                                      error={
                                        getIn(formManualStep3.errors, `requestedProducts[${i}].company`) &&
                                        getIn(formManualStep3.touched, `requestedProducts[${i}].company`)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <InputLabel variant="standard">Inserisci ulteriori informazioni</InputLabel>
                                    <TextField
                                      multiline
                                      minRows={5}
                                      maxRows={10}
                                      fullWidth
                                      value={quote.extraInfo}
                                      name={`requestedProducts.${i}.extraInfo`}
                                      data-cy={'product_info'}
                                      onBlur={(e) => {
                                        formManualStep3.handleBlur(e);
                                        trackAction({
                                          type: ClientTypeEnum.Input,
                                          action: ClientActionEnum.Typed,
                                          name: `Ha inserito ulteriori informazioni del prodotto da acquistare: ${quote.extraInfo}`
                                        });
                                      }}
                                      onChange={formManualStep3.handleChange}
                                      autoComplete={'off'}
                                      error={
                                        getIn(formManualStep3.errors, `requestedProducts[${i}].extraInfo`) &&
                                        getIn(formManualStep3.touched, `requestedProducts[${i}].extraInfo`)
                                      }
                                    />
                                    <ErrorMessage
                                      name={`requestedProducts[${i}].extraInfo`}
                                      render={(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    {formManualStep3.values.requestedProducts.length > 0 && <Divider sx={{ my: 3 }} />}
                                  </Grid>
                                </Grid>
                              </Box>
                            ))}

                          <Button
                            startIcon={<AddIcon sx={{ fontSize: '1.5rem!important' }} />}
                            sx={{ my: 3 }}
                            size={'medium'}
                            variant={'contained'}
                            data-cy={'add_product'}
                            onClick={() => {
                              arrayHelpers.push<OrderManualProductDto>({
                                name: '',
                                extraInfo: '',
                                quantity: undefined
                              });
                              trackAction({
                                type: ClientTypeEnum.Button,
                                action: ClientActionEnum.Clicked,
                                name: 'Ha aggiunto un prodotto nei preventivi manuali'
                              });
                            }}
                          >
                            Aggiungi prodotto
                          </Button>
                        </>
                      );
                    }}
                  />
                </form>
              </FormikProvider>
              <Typography variant={'body1'} data-tour={'change_quotes_to_upload'}>
                Hai i preventivi?
                <Link
                  fontWeight={600}
                  sx={{ cursor: 'pointer', ml: 0.5 }}
                  onClick={() => {
                    setIsForm(false);
                    updateOrderQuotes.mutate({
                      areQuotesManual: false
                    });
                    trackAction({
                      type: ClientTypeEnum.Button,
                      action: ClientActionEnum.Clicked,
                      name: "E' passato alla modalita aggiunta preventivi: file"
                    });
                  }}
                >
                  Caricali
                </Link>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} lg={4}>
            {/*<Box display={'flex'} width={'100%'} justifyContent={{ xs: 'center', lg: 'flex-start' }}>*/}
            {/*  <OrderSummaryCard order={orderSummary} refetchOrderSummary={refetchOrderSummary} />*/}
            {/*</Box>*/}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  ) : (
    <NewOrderStep3Skeletons />
  );
};

export default NewOrderStep2;
