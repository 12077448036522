import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

export const formattingPath = (path: string) => {
  if (path.includes('dashboard')) {
    return 'Dashboard';
  } else if (path.includes('wallet')) {
    return 'Wallet';
  } else if (path.includes('new-order')) {
    return 'Nuovo ordine';
  } else if (path.includes('orders') && path.split('/').filter((p) => p !== '').length > 1) {
    return 'Dettaglio ordine';
  } else if (path.includes('orders')) {
    return 'Lista ordini';
  } else if (path.includes('account/settings/billing')) {
    return 'Profilo - Dati di fatturazione';
  } else if (path.includes('account/settings/address')) {
    return 'Profilo - Indirizzi di spedizione';
  } else if (path.includes('account/settings')) {
    return 'Dettaglio Profilo';
  } else if (path.includes('account/documents')) {
    return 'Documenti Profilo';
  } else if (path.includes('support/help-center')) {
    return 'Centro assistenza';
  } else if (path.includes('support/faqs')) {
    return 'Faqs';
  } else if (path.includes('contacts')) {
    return 'Contattaci';
  } else if (path.includes('notifications')) {
    return 'Notifiche';
  } else if (path.includes('login') || path.split('/').filter((p) => p !== '').length === 0) {
    return 'Login';
  } else if (path.split('/').filter((p) => p !== '').length === 1 && path.includes('fast-register')) {
    return 'Registrazione Fast';
  } else if (path.includes('forgot-password')) {
    return 'Recupera Password';
  } else if (path.includes('reset-password')) {
    return 'Reset Password';
  } else if (path.includes('verify-email')) {
    return 'Verifica email';
  } else if (path.includes('new-email-verification')) {
    return 'Richiedi nuova verifica email';
  } else if (path.split('/').filter((p) => p !== '').length === 1 && path.includes('complete-fast-register')) {
    return 'Completa registrazione fast';
  } else if (path.split('/').filter((p) => p !== '').length === 1 && path.includes('register')) {
    return 'Registrazione';
  } else if (path.split('/').filter((p) => p !== '').length === 1 && path.includes('building-register')) {
    return 'Scegli integrazione dati';
  } else if (path.includes('building-register') && path.includes('pms-integration') && !path.includes('step')) {
    return 'Integrazione PMS - Step 1';
  } else if (path.includes('building-register') && path.includes('pms-integration') && path.includes('step-2')) {
    return 'Integrazione PMS - Step 2';
  } else if (path.includes('building-register') && path.includes('pms-integration') && path.includes('step-3')) {
    return 'Integrazione PMS - Step 3';
  } else if (path.includes('building-register') && path.includes('pms-integration') && path.includes('step-4')) {
    return 'Integrazione PMS - Step 4';
  } else if (path.includes('building-register') && path.includes('pms-integration') && path.includes('step-5')) {
    return 'Integrazione PMS - Step 5';
  } else if (path.includes('building-register') && path.includes('manual-integration') && !path.includes('step')) {
    return 'Integrazione Manuale - Step 1';
  } else if (path.includes('building-register') && path.includes('manual-integration') && path.includes('step-2')) {
    return 'Integrazione Manuale - Step 2';
  } else if (path.includes('building-register') && path.includes('manual-integration') && path.includes('step-3')) {
    return 'Integrazione Manuale - Step 3';
  } else if (path.includes('building-register') && path.includes('manual-integration') && path.includes('step-4')) {
    return 'Integrazione Manuale - Step 4';
  } else if (path.includes('building-register') && path.includes('report-integration') && !path.includes('step')) {
    return 'Integrazione Report - Step 1';
  } else if (path.includes('building-register') && path.includes('report-integration') && path.includes('step-2')) {
    return 'Integrazione Report - Step 2';
  } else if (path.includes('building-register') && path.includes('report-integration') && path.includes('step-3')) {
    return 'Integrazione Report - Step 3';
  } else if (path.includes('building-register') && path.includes('report-integration') && path.includes('step-4')) {
    return 'Integrazione Report - Step 4';
  } else {
    return '';
  }
};

export default function useGaPageView() {
  const { pathname } = useLocation();

  useEffect(() => {
    // const title = pathname
    //   .split('/')
    //   .filter((p) => p !== '')
    //   .map((p) => formattingPath(p))
    //   .join(' > ');
    const title = formattingPath(pathname);
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title
    });
  }, [pathname]);
}
