// material-ui
import { alpha, Box, Divider, Fade, Link, Typography, useMediaQuery, useTheme } from '@mui/material';

// types

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import dayjs from 'dayjs';
import packageJson from '../../../../../../package.json';
import { useSelector } from 'store';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        pt: 1,
        '& > ul:first-of-type': { mt: 0 },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingX: { xs: 4, sm: 0 }
      }}
    >
      <Box>{navGroups}</Box>
      {drawerOpen && (
        <Fade in timeout={1500}>
          <Box p={2} sx={{ whiteSpace: 'normal', width: '100%' }}>
            <Box sx={{ color: alpha('#041C22', 0.38) }}>
              <Typography component="p" variant="caption" color={!isDownSm ? 'inherit' : 'secondary'}>
                Versione:{' '}
                <Typography color={!isDownSm ? 'inherit' : 'white'} component="span" variant="caption" fontWeight={600}>
                  {packageJson.version}
                </Typography>
                <br />
                Aggiornamento:{' '}
                <Typography color={!isDownSm ? 'inherit' : 'white'} component="span" variant="caption" fontWeight={600}>
                  {dayjs(process.env.REACT_APP_LAST_UPDATE).format('D MMMM YYYY | HH:mm')}
                </Typography>
                <br />
              </Typography>
            </Box>
            <Divider sx={{ my: '25px', backgroundColor: !isDownSm ? alpha('#094754', 0.3) : '#F0FF32' }} />
            <Box>
              <Link underline={'hover'} color={!isDownSm ? 'primary' : 'secondary'}>
                About us
              </Link>
              <Typography color={!isDownSm ? 'primary' : 'white'} variant={'body1'} fontSize={'.8rem'} mt={'30px'}>
                © 2023 IVH Group S.p.A <br />
                Via Visconti di Modrone, 7 <br />
                20122 Milano (MI) <br />
                P.IVA e C.F. 10713140969
              </Typography>
            </Box>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default Navigation;
