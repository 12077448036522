import { Box, Button } from '@mui/material';
import useModal from '../../../../hooks/useModal';

type ConfirmUnfairTermsProps = {
  // onAccept: (unfairAccepted: boolean[]) => void; //Rimosso ma potrà ritornare utile
  onAccept: () => void; //Rimosso ma potrà ritornare utile
  onReject?: () => void;
  isOptional?: boolean;
  unfairTerms: string[];
  // providedFileCode: string;
};

const ConfirmUnfairTerms = (props: ConfirmUnfairTermsProps) => {
  const { closeModal } = useModal();
  const { onAccept, isOptional, unfairTerms, onReject } = props;
  // const [step, setStep] = useState<number>(0);z

  // const unfairForm = useFormik<{
  //   unfairTerms: {
  //     term: string;
  //     accepted: boolean;
  //   }[];
  // }>({
  //   initialValues: {
  //     unfairTerms:
  //       props.unfairTerms != null
  //         ? props.unfairTerms.map((t) => ({
  //             term: t,
  //             accepted: false
  //           }))
  //         : []
  //   },
  //   validationSchema: Yup.object().shape({
  //     unfairTerms: Yup.array().of(
  //       Yup.object().shape({
  //         accepted: Yup.boolean().isTrue('Accetta la clausola')
  //       })
  //     )
  //   }),
  //   onSubmit: (values) => {
  //     onAccept(values.unfairTerms.length > 0 ? values.unfairTerms.map((t) => t.accepted) : undefined);
  //     closeModal();
  //   }
  // });
  //
  // const unfairErrors =
  //   unfairForm.errors.unfairTerms != null
  //     ? (unfairForm.errors?.unfairTerms as {
  //         accepted: string;
  //       }[])
  //     : undefined;

  return (
    <Box width={'100%'} height={'100%'} minHeight={'100%'} overflow={'hidden'} position={'relative'}>
      {/*<Slide in={step === 0} direction={'right'} appear={false}>*/}
      {/*  <Box width={'100%'} height={'100%'}>*/}
      {/*    <EmbedFile fileCode={providedFileCode} />*/}
      {/*  </Box>*/}
      {/*</Slide>*/}
      {/*<Slide in={step === 1} direction={'left'}>*/}
      <Box
        sx={{
          width: '100%',
          borderRadius: 4,
          backgroundColor: '#F6F5F5',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 2
        }}
      >
        {/*{unfairForm.values.unfairTerms.map((t, i) => (*/}
        {/*  <>*/}
        {/*    <FormControlLabel*/}
        {/*      key={i}*/}
        {/*      sx={{*/}
        {/*        margin: 0,*/}
        {/*        paddingBottom: 2,*/}
        {/*        alignItems: 'center'*/}
        {/*      }}*/}
        {/*      control={*/}
        {/*        <Checkbox*/}
        {/*          key={i}*/}
        {/*          size={'small'}*/}
        {/*          value={t.accepted}*/}
        {/*          checked={t.accepted}*/}
        {/*          onChange={unfairForm.handleChange}*/}
        {/*          onBlur={unfairForm.handleBlur}*/}
        {/*          name={`unfairTerms[${i}].accepted`}*/}
        {/*        />*/}
        {/*      }*/}
        {/*      label={*/}
        {/*        <Box>*/}
        {/*          <div dangerouslySetInnerHTML={{ __html: t.term }} />*/}
        {/*          {unfairErrors != null && unfairErrors[i] != null && unfairForm.touched?.unfairTerms?.[i]?.accepted && (*/}
        {/*            <FormHelperText error>{unfairErrors[i].accepted}</FormHelperText>*/}
        {/*          )}*/}
        {/*        </Box>*/}
        {/*      }*/}
        {/*      labelPlacement="end"*/}
        {/*    />*/}
        {/*  </>*/}
        {/*))}*/}
        {unfairTerms.map((t, i) => {
          return <div dangerouslySetInnerHTML={{ __html: t }} key={i} />;
        })}
      </Box>
      {/*</Slide>*/}
      <Box p={1} sx={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
        <Button
          size="medium"
          color={isOptional ? 'error' : 'primary'}
          variant={'outlined'}
          onClick={() => {
            // if (step === 1) {
            //   setStep(0);
            // } else {
            //   if (isOptional) {
            //     onReject();
            //   }
            //   closeModal();
            // }

            if (isOptional) {
              if (onReject) onReject();
              closeModal();
              return;
            }

            closeModal();
          }}
        >
          {/*{props.unfairTerms == null || step === 0 ? (isOptional ? 'Rifiuta' : 'Annulla') : 'Indietro'}*/}
          {isOptional ? 'Rifiuta' : 'Annulla'}
          {/*{step === 0 && props.unfairTerms == null && isOptional ? 'Rifiuta' : step === 0 ? 'Annulla' : isOptional ? 'Rifiuta' : 'Indietro'}*/}
        </Button>
        <Button
          size="medium"
          color={'primary'}
          variant={'contained'}
          onClick={() => {
            // if (props.unfairTerms != null && step === 0) {
            //   setStep(1);
            // } else {
            //   // unfairForm.handleSubmit();
            //   onAccept();
            //   // closeModal();
            // }
            // onAccept(unfairTerms.map((t) => true));
            onAccept();
            closeModal();
          }}
        >
          {/*{props.unfairTerms != null && step === 0 ? 'Avanti' : 'Conferma'}*/}
          Conferma
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmUnfairTerms;
