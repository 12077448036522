import { Box, Divider, FormHelperText, Grid, ListSubheader, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import { NewOrderFormikStep1 } from 'types/formik/new-order/new-order-step-1';
import { FormattedMessage } from 'react-intl';
import AsyncSelect from '../../components/custom/AsyncSelect';
import { OrderCategoriesDto } from '../../types/dto/order-categories.dto';
import { NewOrderStep1ToSend } from '../../types/dataToSend/new-order/newOrderStep1ToSend';
import { useSelector } from '../../store';
import { useEndpoint } from '../../hooks/useEndpoint';

import AmountAutoComplete from '../../components/custom/AmountAutoComplete';
import BigNumber from 'bignumber.js';
import { SingleDataDTO } from '../../types/server/single-data';
import { NewOrderStepProps } from '../../types/formik/new-order/new-order-step-props';
import useAuth from '../../hooks/useAuth';
import { useOutletContext } from 'react-router';
import { JSX, useEffect } from 'react';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import * as _ from 'lodash';
import groupByToArray from '../../utils/groupByToArray';
// import { textToUrl } from '../../utils/urlFormatting';
import { useTour } from '@reactour/tour';
import { textToUrl } from '../../utils/urlFormatting';
import { useNavigate } from 'react-router-dom';

const renderSelectOrderCatogoriesGroup = (categories: OrderCategoriesDto[]): JSX.Element[] => {
  const products = groupByToArray(
    _.groupBy(
      categories.filter((p) => p.isProduct),
      (i) => i.categoryGroup.id
    )
  )
    .map((pc, i) => {
      const productOfCategories = pc.map((p) => (
        <MenuItem key={p.id} value={JSON.stringify({ id: p.id, name: p.name })}>
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} flexWrap={'wrap'}>
            {p.name}
            {p.serviceProviderName != null && (
              <Typography variant={'body2'}>
                servizio di <span style={{ fontWeight: 700 }}>{p.serviceProviderName}</span>
              </Typography>
            )}
          </Stack>
        </MenuItem>
      ));

      return [
        <ListSubheader
          key={`product-category-${i}`}
          sx={(theme) => ({
            color: theme.palette.primary.main,
            fontWeight: 700,
            fontSize: '1rem'
            // borderBottom: `1px solid ${theme.palette.primary.main}`
          })}
        >
          {pc[0].categoryGroup.group}
        </ListSubheader>,
        ...productOfCategories
      ];
    })
    .flat();

  const services = groupByToArray(
    _.groupBy(
      categories.filter((p) => !p.isProduct),
      (i) => i.categoryGroup.id
    )
  )
    .map((pc, i) => {
      const productOfCategories = pc.map((p) => (
        <MenuItem key={p.id} value={JSON.stringify({ id: p.id, name: p.name })}>
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} flexWrap={'wrap'}>
            {p.name}
            {p.serviceProviderName != null && (
              <Typography variant={'body2'}>
                servizio di <span style={{ fontWeight: 700 }}>{p.serviceProviderName}</span>
              </Typography>
            )}
          </Stack>
        </MenuItem>
      ));

      return [
        <ListSubheader
          key={`services-category-${i}`}
          sx={(theme) => ({
            color: theme.palette.primary.main,
            fontWeight: 700,
            fontSize: '1rem'
            // borderBottom: `1px solid ${theme.palette.primary.main}`
          })}
        >
          {pc[0].categoryGroup.group}
        </ListSubheader>,
        ...productOfCategories
      ];
    })
    .flat();

  return [
    <ListSubheader
      key={'header-product'}
      sx={(theme) => ({
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: '1.2rem'
      })}
    >
      Prodotti
      <Divider sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} />
    </ListSubheader>,
    ...products,
    <ListSubheader
      key={'header-services'}
      sx={(theme) => ({
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: '1.2rem'
      })}
    >
      Servizi
      <Divider sx={(theme) => ({ backgroundColor: theme.palette.primary.main })} />
    </ListSubheader>,
    ...services
  ];
};

// {
//   handleNextStep,
//     orderSummary,
//     setFormikErrors,
//     orderId,
//     setOrderId,
//     setLoadingButton,
//     refetchOrderSummary,
//     testMode
// }: Omit<NewOrderStepProps, 'orderSummary'> & { orderSummary?: OrderDTO }
const NewOrderStep1 = () => {
  const { orderId, testMode, navigateLoading, refetchOrderSummary, setOrderId, setStep, setLoadingButton, orderSummary, setFormikErrors } =
    useOutletContext<NewOrderStepProps>();
  const { user, holder } = useSelector((state) => state.auth);
  const { trackAction } = useNavigationTracker();
  const tour = useTour();
  const navigate = useNavigate();
  const { authTokenLogin } = useAuth();

  const newOrder = useEndpoint<NewOrderStep1ToSend, 'patch' | 'post', SingleDataDTO<{ code: string; title: string }>>({
    method: orderId !== undefined ? 'patch' : 'post',
    endpoint: orderId !== undefined ? `/orders/${orderId}` : '/orders',
    mutationKey: orderId !== undefined ? `patch-order-${orderId}-step-1` : 'new-order-step-1',
    queryParams: {
      mode: testMode ? 'test' : undefined
    },
    options: {
      onSuccess: async (data) => {
        if (user?.building.pendingOrdersCount === 0) {
          await authTokenLogin();
        }
        if (orderId) {
          await refetchOrderSummary();
        }
        const code = data.data.data.code;
        const title = data.data.data.title;
        if (setOrderId) {
          setOrderId(code);
        }
        // await refetchOrderSummary();
        // handleNextStep(2);
        history.replaceState(null, document.title, `/orders/new-order/${code}/${textToUrl(title)}`);
        setStep(2);
        navigate(`/orders/new-order/${code}/${textToUrl(title)}/step-${2}`, { state: 2 });
        // const newUrl = window.location.protocol + '//' + window.location.host + `/orders/new-order/${code}`;
        // window.history.pushState({ path: newUrl }, '', newUrl);
        // navigate(!testMode ? `/orders/new-order/${code}/${textToUrl(title)}` : `/test/orders/new-order/${code}/${textToUrl(title)}`, {
        //   replace: true
        // });

        const canRent = JSON.parse(sessionStorage['canRent'] != null ? sessionStorage['canRent'] : '{}');
        delete canRent[orderId!];
        console.log(canRent);
        sessionStorage.setItem('canRent', JSON.stringify(canRent));
        setLoadingButton(false);
      },
      onError: (err) => {
        setLoadingButton(false);
        console.log(err);
      }
    }
  });

  const formStep1 = useFormik<NewOrderFormikStep1>({
    enableReinitialize: true,
    initialValues: {
      orderCategory:
        orderSummary?.orderCategory != null
          ? {
              id: orderSummary.orderCategory.id,
              name: orderSummary.orderCategory.name
            }
          : null,
      amount: orderSummary?.activeConfiguration.amount ?? null,
      title: orderSummary?.title ?? '',
      lastStep: 2
    },
    validationSchema: Yup.object().shape({
      orderCategory: Yup.object().required('error-product-required'),
      amount: Yup.number().required('error-amout-required').min(1000, 'error-amount-min'),
      title: Yup.string().required('error-name-required')
    }),
    onSubmit: (values) => {
      setLoadingButton(true);
      trackAction({
        action: ClientActionEnum.Submit,
        type: ClientTypeEnum.Form,
        name: orderId != null ? `E' andato allo step 2 dell'ordine: ${orderId}` : "Ha creato l'ordine"
      });
      newOrder.mutate({
        amount: BigNumber(values.amount ?? 0).toNumber(),
        title: values.title ?? undefined,
        categoryId: values.orderCategory?.id ?? 0,
        buildingId: !testMode ? user?.building.id ?? 0 : holder?.holderBuilding?.id ?? 0,
        lastStep: values.lastStep
      });
    }
  });

  useEffect(() => {
    setFormikErrors(formStep1.errors);
  }, [formStep1.errors]);

  useEffect(() => {
    console.log(tour);
    if (tour.isOpen && tour.setSteps) {
      console.log('setto gli steps');
      tour.setSteps([
        {
          selector: '[data-tour="order-category"]',
          content: 'Da qui selezioni la categoria del prodotto di cui hai bisogno',
          highlightedSelectors: ['#menu-orderCategory .MuiBackdrop-root'],
          mutationObservables: ['#menu-orderCategory'],
          position: 'right'
        },
        {
          selector: '[data-tour="order-amount"]',
          content: "Qui inserisci l'importo di cui hai bisogno",
          position: 'right'
        },
        {
          selector: '[data-tour="order-title"]',
          content: "Da qui puoi inserire un titolo che in seguito ti aiuterà ad identificare l'ordine",
          position: 'right'
        },
        {
          selector: '[data-tour="order-next-step"]',
          content: 'Questo è il bottone che ti permette di andare avanti nei seguenti step',
          position: 'top'
        },
        {
          selector: 'main',
          mutationObservables: ['main'],
          content: 'Procedi compilando tutti i campi e andando al secondo step',
          position: (postionsProps) => {
            console.log(postionsProps);

            return [0, 10];
          },
          stepInteraction: true
        }
      ]);
      tour.setCurrentStep(0);
    }
  }, [tour.setSteps]);

  if (navigateLoading) {
    return <></>;
  }

  return (
    <Box>
      <Typography gutterBottom variant="h5">
        La tua richiesta
      </Typography>
      <Divider />
      <form onSubmit={formStep1.handleSubmit} noValidate id="new-order-step-1">
        <Grid container spacing={3} mt={2} maxWidth={700}>
          {/* Product */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Di quale prodotto/servizio hai bisogno?
            </Typography>

            <AsyncSelect<OrderCategoriesDto>
              size={'small'}
              data-tour={'order-category'}
              onBlur={formStep1.handleBlur}
              value={formStep1.values.orderCategory ? JSON.stringify(formStep1.values.orderCategory) : ''}
              onChange={(e) => {
                const category = JSON.parse(e.target.value as string);
                formStep1.setValues({
                  ...formStep1.values,
                  orderCategory: category
                });
                trackAction({
                  action: ClientActionEnum.Selected,
                  type: ClientTypeEnum.Select,
                  name: `Selezionata categoria prodotto ${category.name}`
                });
              }}
              defaultList={(options) => renderSelectOrderCatogoriesGroup(options)}
              name="orderCategory"
              variant="outlined"
              fullWidth
              renderMenuItem={(value) => ({
                value: value.name,
                label: value.name
              })}
              error={Boolean(formStep1.errors.orderCategory && formStep1.touched.orderCategory)}
              sx={{
                '& .MuiSelect-select .notranslate::after': 'Seleziona un opzione'
                  ? {
                      content: `"Seleziona un opzione"`,
                      opacity: 0.42
                    }
                  : {}
              }}
              endpoint={'/order-categories'}
            />
            <FormHelperText>La categoria di prodotto o servizio determina le soluzioni di pagamento attivabili</FormHelperText>

            {formStep1.errors.orderCategory && formStep1.touched.orderCategory && (
              <FormHelperText error>
                <FormattedMessage id={formStep1.errors.orderCategory} />
              </FormHelperText>
            )}
          </Grid>

          {/* Amount */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Di quale importo hai bisogno? Indicaci il tuo budget
            </Typography>

            <AmountAutoComplete
              data-tour={'order-amount'}
              error={getIn(formStep1.errors, 'amount') && getIn(formStep1.touched, 'amount')}
              onBlur={(e) => {
                formStep1.handleBlur(e);
                trackAction({
                  action: ClientActionEnum.Typed,
                  type: ClientTypeEnum.Input,
                  name: `Ha inserito l'importo ${parseInt(formStep1.values.amount ?? '0').toLocaleString()} €`
                });
              }}
              name={'amount'}
              size={'small'}
              value={formStep1.values.amount != null ? BigNumber(formStep1.values.amount) : ''}
              onChange={(event, value) => {
                const amount = typeof value === 'string' ? (value === '' ? null : value) : value.toNumber().toString();
                formStep1.setValues({
                  ...formStep1.values,
                  amount
                });
              }}
            />

            <FormHelperText>L’importo inserito si riferisce al prodotto o servizio che vuoi acquistare</FormHelperText>

            {formStep1.errors.amount && formStep1.touched.amount && (
              <FormHelperText error>
                <FormattedMessage id={formStep1.errors.amount} />
              </FormHelperText>
            )}
          </Grid>

          {/* Name */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Inserisci il nome della pratica
            </Typography>
            <TextField
              data-tour={'order-title'}
              size="small"
              fullWidth
              name="title"
              onChange={(e) => {
                formStep1.handleChange(e);
              }}
              onBlur={(e) => {
                formStep1.handleBlur(e);

                trackAction({
                  action: ClientActionEnum.Typed,
                  type: ClientTypeEnum.Input,
                  name: `Ha aggiunto nome dell'ordine: ${formStep1.values.title}`
                });
              }}
              value={formStep1.values.title}
              variant="outlined"
              placeholder="Inserisci il nome"
              error={Boolean(formStep1.errors.title && formStep1.touched.title)}
            />
            <FormHelperText>Personalizza il nome della pratica per identificare facilmente l’ordine</FormHelperText>
            {formStep1.errors.title && formStep1.touched.title && (
              <FormHelperText error>
                <FormattedMessage id={formStep1.errors.title} />
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NewOrderStep1;
