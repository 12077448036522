import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import { useError } from '../../hooks/useError';
import { useEffect, useRef } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import IllustrationError from 'assets/images/illustrations/illustration_error.svg';
import Rotellina1 from 'assets/images/illustrations/rotellina_1.svg';
import Rotellina2 from 'assets/images/illustrations/rotellina_2.svg';

export const FallbackComponent = (fallbackProps: FallbackProps) => {
  const location = useLocation();
  const { sendErrorClient } = useError();
  const { resetErrorBoundary, error } = fallbackProps;
  const pathnameRef = useRef(location.pathname);
  let message: string = "C'è stato un errore nel caricamento della risorsa, si è pregati di ricaricare la pagina.";

  // const handleBackHome = () => {
  //   window.location.reload();
  // };

  useEffect(() => {
    if (error) sendErrorClient(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname !== pathnameRef.current) {
      resetErrorBoundary();
    }
  }, [location.pathname]);

  console.log(fallbackProps.error?.cause);

  return (
    // <Box p={3}>
    //   {/* {error.message} */}
    //   <Typography variant="h5">
    //     {fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync' ? fallbackProps.error.message : message}
    //   </Typography>
    //   <Box display={'flex'} mt={3}>
    //     <Button onClick={() => resetErrorBoundary()} variant="contained">
    //       Riprova
    //     </Button>
    //     <Button onClick={handleBackHome} variant="text">
    //       Ricarica
    //     </Button>
    //   </Box>
    // </Box>
    <Box width={'100%'} position={'relative'}>
      <Container maxWidth={'lg'}>
        <Grid
          container
          width={'100%'}
          minHeight={fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync' ? '100dvh' : '70dvh'}
        >
          <Grid item xs={12} md={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box textAlign={'center'}>
              <Typography variant={'h6'} mb={3}>
                {fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync'
                  ? fallbackProps.error.message
                  : message}
              </Typography>
              <Button onClick={() => resetErrorBoundary()} variant="contained">
                Riprova
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              img: {
                width: '100%',
                maxWidth: 300
              }
            }}
          >
            <img src={IllustrationError} alt={'error illustration'} />
          </Grid>
        </Grid>
      </Container>
      {(fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync') && (
        <>
          <Box
            sx={{
              img: {
                width: '100%',
                maxWidth: { xs: 150, md: 300 },
                zIndex: -1,
                position: 'absolute',
                top: 0,
                left: 0,
                objectFit: 'contain',
                transition: 'all 1s'
              }
            }}
          >
            <img src={Rotellina1} alt={'rotellina errore'} />
          </Box>

          <Box
            sx={{
              img: {
                width: '100%',
                maxWidth: { xs: 150, md: 300 },
                zIndex: -1,
                position: 'absolute',
                bottom: 0,
                right: 0,
                objectFit: 'contain',
                transition: 'all 1s'
              }
            }}
          >
            <img src={Rotellina2} alt={'rotellina errore'} />
          </Box>
        </>
      )}
    </Box>
  );
};

export const OutletWithError = withErrorBoundary(Outlet, {
  FallbackComponent
});
