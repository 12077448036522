// material-ui
import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main
        }
      }
    } as Components['MuiChip']
  };
}
