/**
 * Password validator for login pages
 */
import { NumbColorFunc, StringBoolFunc, StringNumFunc } from 'types/password';

// has number
const hasNumber: StringBoolFunc = (number) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed: StringBoolFunc = (number) => new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial: StringBoolFunc = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

// set color based on password strength
/**
 * Funzione che ci ritorna il messaggio e il colore del label da mostrare in base alla sicurezza della password
 * @param count sicurezza della password
 */
export const strengthColor: NumbColorFunc = (count) => {
  if (count < 2) return { label: 'poor', color: 'error.main' };
  if (count < 3) return { label: 'weak', color: 'warning.main' };
  if (count < 4) return { label: 'normal', color: 'warning.dark' };
  if (count < 5) return { label: 'good', color: 'success.main' };
  if (count < 6) return { label: 'strong', color: 'success.dark' };
  return { label: 'poor', color: 'error.main' };
};

/**
 * Funziona che ci ritorna un valore della sicurezza della password
 * @param number la password da valutare
 */
export const strengthIndicator: StringNumFunc = (number) => {
  let strengths = 0;

  if (number.length > 7) strengths += 1;
  if (hasNumber(number)) strengths += 1;
  if (hasSpecial(number)) strengths += 1;
  if (hasMixed(number)) strengths += 1;
  if (number.length > 12 && hasNumber(number) && hasSpecial(number) && hasMixed(number)) strengths += 1;

  return strengths;
};
