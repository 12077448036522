// third-party
import { FormattedMessage } from 'react-intl';

// type
import { NavItemType } from 'types/menu';

import DashboardIcon from '../components/custom/BuyPlusIcons/DashboardIcon';
import DashboardNegativeIcon from '../components/custom/BuyPlusIcons/DashboardNegativeIcon';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const dashboard: NavItemType = {
  id: 'dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: [DashboardIcon, DashboardNegativeIcon]
    }
  ]
};

export default dashboard;
