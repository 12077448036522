import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const WalletIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.087 11.7886C13.087 10.116 14.4429 8.76033 16.1152 8.76033H22.0219V5.96252C22.0219 4.32632 20.6956 3 19.0594 3H0V20.0191H22.0219V14.8172H16.1152C14.4426 14.8172 13.087 13.4613 13.087 11.7889V11.7886Z"
        fill="#094754"
      />
      <path d="M24.0001 8.76001H22.022V14.8169H24.0001V8.76001Z" fill="#91DCF9" />
      <path
        d="M16.1156 8.76006C14.4429 8.76006 13.0873 10.116 13.0873 11.7884C13.0873 13.461 14.4432 14.8166 16.1156 14.8166H22.0223V8.75977H16.1156V8.76006Z"
        fill="#49EBAA"
      />
      <path
        d="M16.9566 12.9764C17.6125 12.9764 18.1443 12.4447 18.1443 11.7888C18.1443 11.1328 17.6125 10.6011 16.9566 10.6011C16.3007 10.6011 15.7689 11.1328 15.7689 11.7888C15.7689 12.4447 16.3007 12.9764 16.9566 12.9764Z"
        fill="#F0FF32"
      />
      <path
        d="M16.9566 13.4205C17.8578 13.4205 18.5883 12.6899 18.5883 11.7887C18.5883 10.8875 17.8578 10.157 16.9566 10.157C16.0554 10.157 15.3248 10.8875 15.3248 11.7887C15.3248 12.6899 16.0554 13.4205 16.9566 13.4205Z"
        fill="#F0FF32"
      />
    </svg>
  </SvgIcon>
);

export default WalletIcon;
