import { ClientActionEnum, ClientLog, ClientTypeEnum } from 'types/dataToSend/clientLog';
import useAxiosInstance from './useAxiosIstance';
import { useSelector } from 'store';
import ReactGA from 'react-ga4';

const useNavigationTracker = () => {
  const { axiosInstance } = useAxiosInstance();

  const { rememberMe } = useSelector((state) => state.helpersAuth);
  const trackAction = (action: Pick<ClientLog, 'action' | 'name' | 'type'>) => {
    if (action.type === ClientTypeEnum.Button || action.type === ClientTypeEnum.Form || action.type === ClientTypeEnum.Link) {
      ReactGA.event({
        category: action.type,
        action: action.action,
        label: action.name
      });
    }
    console.log('track action', {
      name: action.name,
      type: action.type,
      action: action.action,
      path: window.location.pathname
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

    axiosInstance({
      url: '/client-logs',
      method: 'post',
      headers: {
        Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`
      },
      data: {
        name: action.name,
        type: action.type,
        action: action.action,
        path: window.location.pathname
      }
    }).catch();
  };

  const trackPageView = (action: Pick<ClientLog, 'path' | 'name'>) => {
    axiosInstance({
      url: '/client-logs',
      method: 'post',
      headers: {
        Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`
      },
      data: {
        name: action.name,
        type: ClientTypeEnum.Page,
        action: ClientActionEnum.View,
        path: action.path
      }
    }).catch();
  };

  return { trackAction, trackPageView };
};

export default useNavigationTracker;
