//material-ui
import { styled } from '@mui/material/styles';

// third-party
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import NotistackSnackbar from './NotistackSnackbar';
import NotificationSnackbar from './NotificationSnackbar';

// project import
// import { SnackbarUtilsConfigurator } from 'utils/ToastNotistack';

// assets
// import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';

// custom styles
const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.dark
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.dark
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.dark
  }
}));

// ===========================|| SNACKBAR - NOTISTACK ||=========================== //
type NotistackProviderProps = {
  children: JSX.Element;
} & SnackbarProviderProps;

const NotistackProvider = ({ children, ...other }: NotistackProviderProps) => {
  return (
    <StyledSnackbarProvider
      {...other}
      Components={{
        notification: NotificationSnackbar
      }}
      // iconVariant={
      //   snackbar.iconVariant === 'useemojis'
      //     ? {
      //         success: <CheckCircleOutlined style={iconSX} />,
      //         error: <CloseCircleOutlined style={iconSX} />,
      //         warning: <WarningOutlined style={iconSX} />,
      //         info: <InfoCircleOutlined style={iconSX} />
      //       }
      //     : undefined
      // }
    >
      <NotistackSnackbar />
      {children}
    </StyledSnackbarProvider>
  );
};

export default NotistackProvider;
