import { Box, Typography, styled } from '@mui/material';
import ChannelManagerIcon from '../BuyPlusIcons/ChannelManagerIcon';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type PromoCardProps = {
  customIcon?: JSX.Element;
  title: string;
  description: string;
  action?: JSX.Element;
  bgColor: string;
  borderColor: string;
  sx?: SxProps<Theme>;
  onClick?: () => void;
};

const PromoBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  padding: '21px',
  // backgroundColor: '#F0FF32',
  // borderTop: '4px solid #ABB800',
  borderRadius: '0px 0px 10px 10px'
}));
const PromoCard = ({ title, description, action, bgColor, borderColor, customIcon, sx, onClick }: PromoCardProps) => {
  return (
    <PromoBox sx={{ backgroundColor: bgColor, borderTop: `4px solid ${borderColor}`, ...sx }} onClick={onClick}>
      {customIcon != null ? customIcon : <ChannelManagerIcon sx={{ fontSize: 50 }} />}
      <Typography variant="h5" mt={1} mb={1}>
        {title}
      </Typography>
      <Typography variant="body2" mb={2}>
        {description}
      </Typography>
      {action != null && action}
    </PromoBox>
  );
};

export default PromoCard;
