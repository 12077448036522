import { ContactForm, ContactTag } from '../types/formik/contact-form';

export enum ContactFormPresetEnum {
  Dashboard = 'dashboard',
  Wallet = 'wallet',
  PlafondNotActivating = 'plafond-not-activating',
  SlowSolutions = 'slow-solutions',
  MoreCredit = 'more-credit',
  Profile = 'account',
  Document = 'documents'
}

export const contactsTagsLabel: Record<string, string> = {
  general: 'Generale',
  'edit-user': 'Modifica dati profilo',
  'use-slow-partner': 'Utilizzo soluzioni lente',
  'more-credit': 'Più credito con dilazione in camere',
  'wallet-refused': 'Soluzione di credito non attivabile',
  credit: 'Wallet',
  dashboard: 'Dashboard',
  document: 'Documenti'
};

export function getContactFormPreset(preset: ContactFormPresetEnum): Pick<ContactForm, 'message'> & { tag?: ContactTag } {
  switch (preset) {
    case ContactFormPresetEnum.Dashboard:
      return {
        message: 'Messaggio pre impostato per la dashboard',
        tag: ContactTag.Dashboard
      };

    case ContactFormPresetEnum.Profile:
      return {
        message: 'Messaggio pre impostato per modificare i dati dell utente',
        tag: ContactTag.EditUser
      };

    case ContactFormPresetEnum.Document:
      return {
        message: 'Messaggio pre impostato per i documenti',
        tag: ContactTag.Document
      };

    case ContactFormPresetEnum.SlowSolutions:
      return {
        message: 'Messaggio pre impostato per utilizzare una soluzione lenta',
        tag: ContactTag.UseSlowPartner
      };

    case ContactFormPresetEnum.MoreCredit:
      return {
        message: 'Messaggio pre impostato per avere più credito',
        tag: ContactTag.MoreCredit
      };

    case ContactFormPresetEnum.PlafondNotActivating:
      return {
        message: 'Messaggio pre impostato per utilizzare una soluzione non attivabile',
        tag: ContactTag.WalletRefused
      };

    case ContactFormPresetEnum.Wallet:
      return {
        message: 'Messaggio pre impostato per il wallet',
        tag: ContactTag.Credit
      };
    default:
      return {
        message: '',
        tag: undefined
      };
  }
}
