// types
import { createSlice } from '@reduxjs/toolkit';
import { BreadcrumbHelperType, PayloadBreadcrumbStatus } from '../../types/breadcrumb-helper';

// initial state
const initialState: BreadcrumbHelperType = {
  statusChip: undefined
};

// ==============================|| SLICE - MENU ||============================== //

const breadcrumbHelper = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setStatusChip(state, action: PayloadBreadcrumbStatus) {
      state.statusChip = action.payload;
    }
  }
});

export default breadcrumbHelper.reducer;

export const { setStatusChip } = breadcrumbHelper.actions;
