import { alpha, LinearProgress, Typography, useTheme } from '@mui/material';
const FileLoader = ({ size }: { size: number }) => {
  const theme = useTheme();
  // const [progress, setProgress] = useState(0);
  // const [loadingInterval, setLoadingInterval] = useState<NodeJS.Timeout | null>(null);
  //
  // const simulateLoading = () => {
  //   if (loadingInterval) {
  //     clearInterval(loadingInterval);
  //   }
  //   setProgress(0);
  //
  //   // memorizza l'intervallo dell'animazione di caricamento in uno stato locale
  //   setLoadingInterval(
  //     setInterval(() => {
  //       setProgress((prevProgress) => {
  //         if (prevProgress === 100) {
  //           clearInterval(loadingInterval!); // cancella l'intervallo dell'animazione di caricamento
  //           return 100;
  //         }
  //         return prevProgress + 5;
  //       });
  //     }, 50)
  //   );
  // };

  // useEffect(() => {
  //   simulateLoading();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <Typography fontSize={'.8rem'} fontWeight={400} sx={{ color: alpha(theme.palette.text.secondary, 0.6) }} gutterBottom>
        {(size / 1024).toFixed(2)}Kb • In corso
      </Typography>
      <LinearProgress variant="indeterminate" color="primary" sx={{ width: 150, height: 8, borderRadius: 8, mt: 1 }} />
    </>
  );
};

export default FileLoader;
