export type ClientLog = {
  name: string;
  type: ClientTypeEnum;
  action: ClientActionEnum;
  path: string;
};

export enum ClientTypeEnum {
  Slider = 'slider',
  Switch = 'switch',
  Input = 'input',
  Button = 'button',
  Form = 'form',
  Select = 'select',
  Link = 'link',
  File = 'file',
  Checkbox = 'checkbox',
  RadioButton = 'radio',
  Accordion = 'accordion',
  DrawerLink = 'drawer_link',
  Page = 'page'
}

export enum ClientActionEnum {
  Changed = 'changed',
  Typed = 'typed',
  Clicked = 'clicked',
  Submit = 'submit',
  Selected = 'selected',
  Added = 'added',
  Removed = 'removed',
  Checked = 'checked',
  View = 'view'
}
