import { Components } from '@mui/material';

export default function Accordion() {
  return {
    MuiAccordion: {
      defaultProps: {
        elevation: 0
      }
    } as Components['MuiAccordion']
  };
}
