import axios, { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';
import * as process from 'process';
import dayjs from 'dayjs';
import { SnackbarProps } from '../types/snackbar';

const axiosErrors = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + 'api/' + process.env.REACT_APP_API_VERSION
});
axiosErrors.defaults.headers.post['Content-Type'] = 'application/json';
export type ServerErrorType = {
  logId: number;
  message: string | string[];
  statusCode: number;
  timestamp: string;
};
/*
 * HOOK PER GESTIRE TUTTI GLI ERRORI DI OGNI CHIAMATA AXIOS
 */
export const useError = () => {
  const dispatch = useDispatch();

  const handleError = (error: AxiosError<ServerErrorType>) => {
    if (!axios.isAxiosError(error) || error.response?.status === 406 || error.response?.status === 422) return;
    let variant: SnackbarProps['variant'] = 'error';
    let message: string | string[] = "C'è stato un errore nel caricamento della risorsa.";

    switch (error.response?.data.statusCode) {
      case 404:
        message = 'Risorsa non trovata.';
        variant = 'error';
        break;
      case 400:
        message =
          !Array.isArray(error.response?.data?.message) && error.response.data.message !== 'Bad Request'
            ? error.response.data.message
            : Array.isArray(error.response?.data?.message)
            ? error.response.data.message
            : 'Dati inviati non corretti.';
        variant = 'warning';
        break;
      case 403:
        message = 'Non sei autorizzato.';
        variant = 'error';
        break;
      case 500:
        message = "C'è stato un errore del server, i tecnici sono già al lavoro per risolvere il problema.";
        variant = 'error';
        break;
      case 502:
        message = "C'è stato un errore di un sistema collegato, i tecnici sono già al lavoro per risolvere il problema.";
        variant = 'error';
        break;

      default:
        break;
    }

    console.log('dispatchio lo snackbar di errore');
    dispatch(
      openSnackbar({
        message,
        variant
      })
    );
  };

  const sendErrorClient = (error: Error) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') return;
    axiosErrors({
      method: 'post',
      headers: {
        'uefvc-lhkih': 'OwO9XTbxbzn52z1zU00A'
      },
      url: '/errors',
      data: {
        message: error.message,
        stack: error.stack,
        agent: navigator.userAgent,
        at: dayjs().toDate(),
        localStorage: JSON.stringify(localStorage),
        sessionStorage: JSON.stringify(sessionStorage)
      }
    })
      .then(() => {
        console.log('Errore inviato: ', error);
      })
      .catch(() => {
        console.log('Errore non inviato: ', error);
      });
  };
  return { handleError, sendErrorClient };
};
