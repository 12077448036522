import { NewOrderStepProps } from '../../types/formik/new-order/new-order-step-props';
import { Box, Chip, Divider, Fade, FormControl, Grid, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import OrderSummaryCard from '../../components/custom/Cards/OrderSummary/OrderSummaryCard';
import { useFormik } from 'formik';
import { OrderDTO, WalletOrder, WalletRent } from '../../types/dto/orders.dto';
import CardPaymentPlafond from '../../components/custom/Cards/OrderSummary/CardPaymentPlafond';
import { OrderWalletDataToSend } from '../../types/formik/new-order/new-order-step-2';
import { ReactComponent as BuyplusLabel } from 'assets/images/icons/label.svg';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import useDrawer from '../../hooks/useDrawer';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import useDelayLoading from '../../hooks/useDelayLoading';
import { useEffect, useRef, useState } from 'react';
import { useEndpoint } from '../../hooks/useEndpoint';
import useModal from '../../hooks/useModal';
import NewOrderSkeleton from '../../components/custom/Skeletons/NewOrderSkeletons/NewOrderSkeleton';
import NewRentSkeleton from '../../components/custom/Skeletons/NewOrderSkeletons/NewRentSkeleton';
import CardRentalNewOrder from '../../components/custom/Cards/RentalSummary/CardRentalNewOrder';
import UpdatePaymentLoader from '../../components/custom/Skeletons/NewOrderSkeletons/UpdatePaymentLoader';
import { SingleDataDTO } from '../../types/server/single-data';
import { useOutletContext } from 'react-router';
import { useTour } from '@reactour/tour';
// props: NewOrderStepProps
const NewOrderStep3 = () => {
  const {
    initialRenders,
    navigateLoading,
    setNavigateLoading,
    orderSummary,
    refetchOrderSummary,
    orderId,
    testMode,
    setLoadingButton,
    handleNextStep,
    setInitialRenders
  } = useOutletContext<NewOrderStepProps>();
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const { trackAction } = useNavigationTracker();
  const tour = useTour();
  const [updateDelayLoading, setUpdateDelayLoading] = useDelayLoading(false, 700);
  const [updateLoading, setUpdateLoading] = useState(false);
  //Loading per quando si passa da rent a purchase
  const [orderLoading, setOrderLoading] = useState(false);
  //Loading per quando si passa da purchase a rent
  const [rentLoading, setRentLoading] = useState(false);
  //Imposto id dello slider che sto muovendo
  const [movingSliderId, setMovingSliderId] = useState<number | undefined>();

  const orderChangesRef = useRef<
    Pick<OrderDTO['activeConfiguration'], 'wallets' | 'depositAmount' | 'minDepositAmount' | 'maxDepositAmount'>
  >({
    minDepositAmount: orderSummary.activeConfiguration.minDepositAmount,
    maxDepositAmount: orderSummary.activeConfiguration.maxDepositAmount,
    depositAmount: orderSummary.activeConfiguration.depositAmount,
    wallets: orderSummary.activeConfiguration.wallets
  });

  const updateOrder = useEndpoint<OrderWalletDataToSend, 'patch'>({
    method: 'patch',
    endpoint: `/orders/${orderId}`,
    mutationKey: `/orders/${orderId}/wallets`,
    queryParams: {
      mode: testMode ? 'test' : undefined
    },
    options: {
      onError: async (error) => {
        console.log('error', error);
        await refetchOrderSummary();
        if (error.response?.data.statusCode === 406) {
          openModal({
            title: "Non è possibile proseguire con l'operazione",
            content: (
              <Box>
                {(error.response.data.message as string[]).map((m, i) => (
                  <Typography variant={'body1'} key={i}>
                    {m}
                  </Typography>
                ))}
              </Box>
            )
          });
        }
      }
    }
  });

  const orderFormStep3 = useFormik<
    Pick<OrderDTO['activeConfiguration'], 'wallets' | 'depositAmount' | 'minDepositAmount' | 'maxDepositAmount'> & {
      lockDeposit: boolean | undefined;
      lockWalletId: number | undefined;
    } & Pick<OrderDTO, 'type'>
  >({
    initialValues: {
      type: orderSummary.type,
      minDepositAmount: orderSummary.activeConfiguration.minDepositAmount,
      maxDepositAmount: orderSummary.activeConfiguration.maxDepositAmount,
      depositAmount: orderSummary.activeConfiguration.depositAmount,
      wallets: orderSummary.activeConfiguration.wallets,
      lockDeposit: undefined,
      lockWalletId: undefined
    },
    onSubmit: () => {
      setLoadingButton(true);
      trackAction({
        action: ClientActionEnum.Submit,
        type: ClientTypeEnum.Form,
        name: `E' andato allo step 4 dell'ordine: ${orderId}`
      });

      updateOrder.mutate(
        {
          lastStep: 4
        },
        {
          onSuccess: async () => {
            await refetchOrderSummary();
            setLoadingButton(false);
            handleNextStep(4);
          }
        }
      );
    }
  });

  const canRent = useEndpoint<SingleDataDTO<true>, 'get'>({
    method: 'get',
    endpoint: `/orders/${orderId}/can-rent`,
    queryKey: `can-rent-${orderId}`,
    options: {
      cacheTime: 0,
      enabled:
        sessionStorage['canRent'] == null ||
        JSON.parse(sessionStorage['canRent'] != null ? sessionStorage['canRent'] : '{}')[orderId!] == null,
      onSuccess: (data) => {
        const canRent = JSON.parse(sessionStorage['canRent'] != null ? sessionStorage['canRent'] : '{}');
        canRent[orderId!] = data.data.data ? 'true' : 'false';
        console.log(canRent);
        sessionStorage.setItem('canRent', JSON.stringify(canRent));
      }
    }
  });

  const updateServerSlider = (values: OrderWalletDataToSend) => {
    if (movingSliderId != null) setMovingSliderId(undefined);
    setUpdateDelayLoading(true);
    setUpdateLoading(true);
    updateOrder.mutate(
      {
        ...values,
        lockWalletId: values.wallet?.walletId === values.lockWalletId ? undefined : values.lockWalletId,
        lockDeposit: values.depositAmount != null ? undefined : values.lockDeposit ? true : undefined
      },
      {
        onSuccess: async () => {
          await refetchOrderSummary();
        },
        onSettled: () => {
          setUpdateDelayLoading(false);
          setUpdateLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (orderFormStep3.values.type !== orderSummary.type)
      orderChangesRef.current = {
        wallets: orderSummary.activeConfiguration.wallets,
        maxDepositAmount: orderSummary.activeConfiguration.maxDepositAmount,
        minDepositAmount: orderSummary.activeConfiguration.minDepositAmount,
        depositAmount: orderSummary.activeConfiguration.depositAmount
      };
    orderFormStep3.setValues({
      ...orderFormStep3.values,
      type: orderSummary.type,
      minDepositAmount: orderSummary.activeConfiguration.minDepositAmount,
      maxDepositAmount: orderSummary.activeConfiguration.maxDepositAmount,
      depositAmount: orderSummary.activeConfiguration.depositAmount,
      wallets: orderSummary.activeConfiguration.wallets,
      lockDeposit:
        orderSummary.type === 'rent' || orderFormStep3.values.lockWalletId != null || orderFormStep3.values.lockDeposit === false
          ? false
          : (orderSummary.activeConfiguration.wallets as WalletOrder[]).filter((w) => w.orderWallet != null).length > 1,
      lockWalletId: orderSummary.type === 'rent' ? undefined : orderFormStep3.values.lockWalletId
    });
  }, [orderSummary]);

  useEffect(() => {
    if (tour.isOpen && tour.setSteps) {
      tour.setSteps([
        {
          selector: '[data-tour="content-new-order-step-3"]',
          content:
            "In questa sezione puoi decidere a quale plafond richiedere il credito per l'acquisto, fare un noleggio, e modificare i tempi di rimborso previsti",
          position: [1100, 10]
        },
        ...(JSON.parse(sessionStorage['canRent'] != null ? sessionStorage['canRent'] : '{}')[orderId!] === 'true'
          ? [
              {
                selector: '[data-tour="switch-rent"]',
                content: 'Questo pulsante ti farà passare alla modalità di noleggio'
              }
            ]
          : []),
        {
          selector: '[data-tour="card_payment_deposit"]',
          content: "In questo campo decidi l'ammontare dell'anticipo"
        },
        {
          selector: '[data-tour="card_payment_deposit"] [data-tour="plafond_slider"]',
          content:
            "Con questo slider puoi decidere l'importo che desideri, è da tenere conto che è presente un minimo e un massimo calcolato per la sua azienda"
        }
      ]);
      tour.setCurrentStep(0);
    }
  }, []);

  useEffect(() => {
    // const paths = location.pathname.split('/');
    // const urlStep = paths[paths.length - 1]?.split('-')[1];

    if (orderSummary.lastStep > 3 && !initialRenders.step3 && initialRenders.step2) {
      console.log('navigo allo step 4');
      handleNextStep(4);
      // navigate(`/orders/new-order/${orderSummary.code}/${textToUrl(orderSummary.title)}/step-4`);
      setNavigateLoading(true);
      setInitialRenders({
        ...initialRenders,
        step3: true
      });
    } else if (!initialRenders.step3 && initialRenders.step2) {
      setNavigateLoading(false);
      setInitialRenders({
        ...initialRenders,
        step3: true
      });
    }
  }, [initialRenders]);

  if (canRent.isLoading || navigateLoading) {
    return <></>;
  }

  return (
    <Fade in>
      <Box data-tour={'content-new-order-step-3'}>
        <Typography gutterBottom variant="h5">
          Configurazione del tuo pagamento
        </Typography>
        <Typography gutterBottom variant="body2" maxWidth={750}>
          Personalizza la tua proposta di modalità di pagamento, puoi scegliere se è un acquisto o un noleggio, attivare e disattivare le
          soluzioni di pagamento e combinarle come vuoi, rivedere gli importi e i tempi di restituzione.
        </Typography>

        {!orderSummary.orderCategory.fixedRentRate &&
          JSON.parse(sessionStorage['canRent'] != null ? sessionStorage['canRent'] : '{}')[orderId!] === 'true' && (
            <Box display={'flex'} alignItems={'center'} gap={'7px'} mb={2} mt={2} data-tour={'switch-rent'}>
              <Typography fontSize={'.8rem'} fontWeight={500}>
                Acquisto
              </Typography>
              <Switch
                checked={orderSummary.type === 'rent'}
                disabled={rentLoading || orderLoading}
                onChange={(e, checked) => {
                  trackAction({
                    type: ClientTypeEnum.Switch,
                    action: ClientActionEnum.Changed,
                    name: `Ha impostato la tipologia dell'ordine su: ${checked ? 'Noleggio' : 'Acquisto'}`
                  });
                  if (checked) {
                    setRentLoading(true);
                  } else {
                    setOrderLoading(true);
                  }

                  updateOrder.mutate(
                    {
                      type: checked ? 'rent' : 'purchase'
                    },
                    {
                      onSuccess: async () => {
                        await refetchOrderSummary();
                        if (checked) {
                          setRentLoading(false);
                        } else {
                          setOrderLoading(false);
                        }
                      },
                      onError: async (err) => {
                        await refetchOrderSummary();
                        if (checked) {
                          setRentLoading(false);
                        } else {
                          setOrderLoading(false);
                        }
                        if (err.response?.data.statusCode === 406) {
                          openModal({
                            title: 'Non puoi usare il noleggio',
                            content: (
                              <Box marginX={'auto'}>
                                <Typography variant={'body1'}>L'opzione di noleggio non è attivabile per questo ordine</Typography>
                              </Box>
                            )
                          });
                        }
                      }
                    }
                  );
                }}
                color={'primary'}
                size={'medium'}
              />
              <Typography fontSize={'.8rem'} fontWeight={500}>
                Noleggio
              </Typography>
            </Box>
          )}
        <Divider sx={{ mb: 3 }} />

        <Grid container position={'relative'}>
          {updateDelayLoading && <UpdatePaymentLoader />}

          <Grid item xs={12} lg={8}>
            <form onSubmit={orderFormStep3.handleSubmit} noValidate id="new-order-step-3">
              {/*Acquisto*/}
              {orderFormStep3.values.type === 'purchase' && !orderLoading && !rentLoading ? (
                <Fade in>
                  <Box>
                    <Box maxWidth={800} marginX={{ xs: 'auto', lg: 0 }} data-tour={'card_payment_deposit'}>
                      <CardPaymentPlafond
                        title={'Acconto'}
                        updateLoading={updateLoading || (movingSliderId !== -1 && movingSliderId != null)}
                        oldData={() => ({
                          min: +orderChangesRef.current.minDepositAmount,
                          max: +orderChangesRef.current.maxDepositAmount,
                          value: +orderChangesRef.current.depositAmount
                        })}
                        value={+orderFormStep3.values.depositAmount}
                        minValue={+orderFormStep3.values.minDepositAmount}
                        maxValue={+orderFormStep3.values.maxDepositAmount}
                        onChangeSlider={(event, value) => {
                          orderFormStep3.setFieldValue('depositAmount', value);
                          orderChangesRef.current = {
                            ...orderChangesRef.current,
                            wallets: orderFormStep3.values.wallets,
                            depositAmount: value as number
                          };
                          setMovingSliderId(-1);
                        }}
                        onChangeCommittedSlider={(event, value) => {
                          if (typeof value === 'number')
                            updateServerSlider({
                              depositAmount: value,
                              lockDeposit: orderFormStep3.values.lockDeposit,
                              lockWalletId: orderFormStep3.values.lockWalletId
                            });

                          orderChangesRef.current = {
                            ...orderChangesRef.current,
                            wallets: orderFormStep3.values.wallets,
                            depositAmount: value as number
                          };
                        }}
                        showLockedButton={(orderFormStep3.values.wallets as WalletOrder[]).filter((w) => w.orderWallet != null).length > 1}
                        locked={orderFormStep3.values.lockDeposit != null ? orderFormStep3.values.lockDeposit : false}
                        onChangeLocked={(event, checked) => {
                          orderFormStep3.setFieldValue('lockDeposit', checked);
                          if (checked) orderFormStep3.setFieldValue('lockWalletId', undefined);
                        }}
                        selectField={<></>}
                      />
                    </Box>

                    {(orderFormStep3.values.wallets as WalletOrder[]).map((wallet) => (
                      <Box maxWidth={800} marginX={{ xs: 'auto', lg: 0 }} marginY={4} key={wallet.id}>
                        <CardPaymentPlafond
                          title={wallet.walletPartner.title}
                          description={wallet.walletPartner.orderExcerpt}
                          updateLoading={updateLoading || (movingSliderId !== wallet.id && movingSliderId != null)}
                          oldData={() => {
                            const w = (orderChangesRef.current.wallets as WalletOrder[]).find((w) => w.id === wallet.id);

                            if (w == null || w.orderWallet == null) return undefined;
                            return {
                              min: +w.orderWallet.minAmount,
                              max: +w.orderWallet.maxAmount,
                              value: +w.orderWallet.amount
                            };
                          }}
                          disableSwitchActiveWallet={
                            (orderFormStep3.values.wallets as WalletOrder[]).filter((w) => w.orderWallet != null).length >= 2
                          }
                          partnerLogo={
                            !wallet.walletPartner.hideNameIn?.includes('orders') ? (
                              <Box my={'12px'}>
                                {wallet.walletPartner.slug === 'ivh' ? (
                                  <BuyplusLabel />
                                ) : (
                                  <Chip label={wallet.walletPartner.company} size={'medium'} color={'default'} />
                                )}
                              </Box>
                            ) : undefined
                          }
                          realAvaibleAmount={wallet.orderWallet?.realAvailableAmount}
                          minValue={wallet?.orderWallet?.minAmount != null ? +wallet.orderWallet.minAmount : undefined}
                          maxValue={wallet?.orderWallet?.maxAmount != null ? +wallet.orderWallet.maxAmount : undefined}
                          value={wallet?.orderWallet?.amount != null ? +wallet.orderWallet.amount : undefined}
                          showLockedButton={
                            (orderFormStep3.values.wallets as WalletOrder[]).filter((w) => w.orderWallet != null).length > 1
                          }
                          locked={orderFormStep3.values.lockWalletId === wallet.id}
                          onChangeLocked={(event, checked) => {
                            if (checked) {
                              orderFormStep3.setFieldValue('lockWalletId', wallet.id);
                              orderFormStep3.setFieldValue('lockDeposit', false);
                            } else {
                              orderFormStep3.setFieldValue('lockWalletId', undefined);
                            }
                          }}
                          active={wallet.orderWallet != null}
                          onChangeSwitch={(event, checked) => {
                            if (checked) {
                              orderFormStep3.setFieldValue('lockDeposit', true);
                              orderFormStep3.setFieldValue('lockWalletId', undefined);
                            } else {
                              orderFormStep3.setFieldValue('lockDeposit', false);
                              orderFormStep3.setFieldValue('lockWalletId', undefined);
                            }
                            updateServerSlider({
                              wallet: {
                                walletId: wallet.id,
                                enable: checked
                              },
                              // lockWalletId: orderFormStep3.values.lockWalletId,
                              lockDeposit: true
                            });
                          }}
                          onChangeSlider={(event, value) => {
                            if (typeof value !== 'number') return;
                            const wallets = (orderFormStep3.values.wallets as WalletOrder[]).map((w) => ({
                              ...w,
                              orderWallet:
                                w.id === wallet.id
                                  ? {
                                      ...w.orderWallet,
                                      amount: value
                                    }
                                  : w.orderWallet
                            }));
                            orderFormStep3.setFieldValue('wallets', wallets);
                            orderChangesRef.current = {
                              ...orderChangesRef.current,
                              wallets: wallets as any
                            };
                            setMovingSliderId(wallet.id);
                          }}
                          onChangeCommittedSlider={(event, value) => {
                            if (typeof value === 'number') {
                              updateServerSlider({
                                wallet: {
                                  walletId: wallet.id,
                                  amount: value
                                },
                                lockWalletId: orderFormStep3.values.lockWalletId,
                                lockDeposit: orderFormStep3.values.lockDeposit
                              });

                              orderChangesRef.current = {
                                depositAmount: orderFormStep3.values.depositAmount,
                                maxDepositAmount: orderFormStep3.values.maxDepositAmount,
                                minDepositAmount: orderFormStep3.values.minDepositAmount,
                                wallets: orderFormStep3.values.wallets
                              };
                            }
                          }}
                          selectField={
                            <>
                              {!!wallet.walletPartner.returnTimes?.length && (
                                <FormControl fullWidth sx={{ position: 'relative', zIndex: 0 }}>
                                  <InputLabel id={`return-time-${wallet.walletPartner.slug}`}>Durata</InputLabel>
                                  <Select
                                    size={'small'}
                                    name={`return-time-${wallet.walletPartner.slug}`}
                                    label={'Durata'}
                                    variant={'outlined'}
                                    labelId={`return-time-${wallet.walletPartner.slug}`}
                                    value={wallet.orderWallet?.returnTime ?? ''}
                                    onChange={(e) => {
                                      updateServerSlider({
                                        wallet: { walletId: wallet.id, returnTime: +e.target.value },
                                        lockWalletId: orderFormStep3.values.lockWalletId,
                                        lockDeposit: orderFormStep3.values.lockDeposit
                                      });
                                      orderChangesRef.current = {
                                        depositAmount: orderFormStep3.values.depositAmount,
                                        maxDepositAmount: orderFormStep3.values.maxDepositAmount,
                                        minDepositAmount: orderFormStep3.values.minDepositAmount,
                                        wallets: orderFormStep3.values.wallets
                                      };

                                      // trackAction({
                                      //   type: ClientTypeEnum.Select,
                                      //   action: ClientActionEnum.Selected,
                                      //   name: `Ha selezionato la durata del plafond "${wallet.walletPartner.title}" a ${e.target.value} Mesi`
                                      // });
                                    }}
                                  >
                                    {wallet.walletPartner.returnTimes.map((time) => {
                                      return (
                                        <MenuItem key={time} value={time}>
                                          {time} Mesi
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              )}
                            </>
                          }
                          handleOpenInfo={() => {
                            openDrawer({
                              title: wallet.walletPartner.title,
                              content: <div dangerouslySetInnerHTML={{ __html: wallet.walletPartner.description }} />
                            });
                            trackAction({
                              type: ClientTypeEnum.Button,
                              action: ClientActionEnum.Clicked,
                              name: `Ha aperto il drawer per le informazioni del plafond "${wallet.walletPartner.title}"`
                            });
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Fade>
              ) : orderLoading && !rentLoading ? (
                <Fade in>
                  <Box>
                    <NewOrderSkeleton />
                  </Box>
                </Fade>
              ) : (
                <></>
              )}

              {/*Noleggio*/}
              {orderFormStep3.values.type === 'rent' && !rentLoading && !orderLoading ? (
                <Fade in>
                  <Box>
                    {(orderFormStep3.values.wallets as WalletRent[]).map((wallet) => (
                      <CardRentalNewOrder
                        key={wallet.id}
                        updateLoading={updateLoading}
                        monthlyFee={wallet.orderWallet.entries.find((f) => f.key === 'monthlyFee')}
                        company={wallet.walletPartner.company}
                        title={wallet.walletPartner.title}
                        partnerLogo={
                          !wallet.walletPartner.hideNameIn?.includes('orders') ? (
                            <Chip size={'medium'} color={'default'} label={wallet.walletPartner.company} />
                          ) : (
                            <Chip size={'medium'} color={'default'} label={'Partner'} />
                          )
                        }
                        deposit={{
                          oldData: () => {
                            return {
                              min: +orderChangesRef.current.minDepositAmount,
                              max: +orderChangesRef.current.maxDepositAmount,
                              value: +orderChangesRef.current.depositAmount
                            };
                          },
                          step: 10,
                          min: +orderFormStep3.values.minDepositAmount,
                          max: +orderFormStep3.values.maxDepositAmount,
                          value: +orderFormStep3.values.depositAmount,
                          onChange: (event, value) => {
                            if (typeof value !== 'number') return;
                            orderFormStep3.setFieldValue('depositAmount', value);
                            orderChangesRef.current = {
                              ...orderChangesRef.current,
                              depositAmount: value as number
                            };
                          },
                          onChangeCommited: (event, value) => {
                            if (typeof value === 'number') {
                              updateServerSlider({
                                depositAmount: value
                              });

                              orderChangesRef.current = {
                                depositAmount: orderFormStep3.values.depositAmount,
                                maxDepositAmount: orderFormStep3.values.maxDepositAmount,
                                minDepositAmount: orderFormStep3.values.minDepositAmount,
                                wallets: orderFormStep3.values.wallets
                              };
                            }

                            // trackAction({
                            //   type: ClientTypeEnum.Slider,
                            //   action: ClientActionEnum.Changed,
                            //   name: `Ha cambiato lo slider del plafond "${wallet.walletPartner.title}" in ${
                            //     wallet.orderWallet.rentDepositAmount != null ? wallet.orderWallet.rentDepositAmount.toLocaleString() : 0
                            //   }€`
                            // });
                          }
                        }}
                        duration={{
                          oldData: () => {
                            const w = (orderChangesRef.current.wallets as WalletRent[]).find((w) => w.id === wallet.id);

                            if (w == null || w.orderWallet == null) return undefined;
                            return {
                              min: w.walletPartner.returnTimes![0],
                              max: w.walletPartner.returnTimes![w.walletPartner.returnTimes!.length - 1],
                              value: +w.orderWallet.returnTime
                            };
                          },
                          step: null,
                          marks: wallet.walletPartner.returnTimes!.map((time) => ({ value: time })),
                          min: wallet.walletPartner.returnTimes![0],
                          max: wallet.walletPartner.returnTimes![wallet.walletPartner.returnTimes!.length - 1],
                          value: wallet.orderWallet.returnTime,
                          onChange: (event, value) => {
                            if (typeof value === 'number') {
                              const wallets = (orderFormStep3.values.wallets as WalletRent[]).map((w) => ({
                                ...w,
                                orderWallet:
                                  w.id === wallet.id
                                    ? {
                                        ...w.orderWallet,
                                        returnTime: value
                                      }
                                    : w.orderWallet
                              }));
                              orderFormStep3.setFieldValue('wallets', wallets);

                              orderChangesRef.current = {
                                ...orderChangesRef.current,
                                wallets: wallets as any
                              };
                            }
                          },
                          onChangeCommited: (event, value) => {
                            if (typeof value === 'number') {
                              updateServerSlider({
                                wallet: { walletId: wallet.id, returnTime: value }
                              });
                              orderChangesRef.current = {
                                depositAmount: orderFormStep3.values.depositAmount,
                                maxDepositAmount: orderFormStep3.values.maxDepositAmount,
                                minDepositAmount: orderFormStep3.values.minDepositAmount,
                                wallets: orderFormStep3.values.wallets
                              };
                              trackAction({
                                type: ClientTypeEnum.Slider,
                                action: ClientActionEnum.Changed,
                                name: `Ha cambiato lo slider della durata del plafond "${wallet.walletPartner.title}" in ${wallet.orderWallet.returnTime} Mesi`
                              });
                            }
                          }
                        }}
                        taxSaving={{
                          ires: wallet.orderWallet.entries.find((f) => f.key === 'iresTaxSavings')?.value ?? 0,
                          irap: wallet.orderWallet.entries.find((f) => f.key === 'irapTaxSavings')?.value ?? 0
                        }}
                      />
                    ))}
                  </Box>
                </Fade>
              ) : rentLoading && !orderLoading ? (
                <Fade in>
                  <Box>
                    <NewRentSkeleton />
                  </Box>
                </Fade>
              ) : (
                <></>
              )}
            </form>
          </Grid>

          <Grid item xs={12} lg={4} display={'flex'} justifyContent={{ xs: 'center', lg: 'flex-end' }}>
            <Box>
              <OrderSummaryCard order={orderSummary} refetchOrderSummary={refetchOrderSummary} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default NewOrderStep3;
