import { LangText } from '../formik/manual-integration-step';

export type FaqDto = {
  id: number;
  question: LangText;
  anchorSlug: string;
  answer: LangText;
  categories: string[];
};

export enum FaqSection {
  DASHBOARD = 'dashboard',
  WALLET = 'wallet',
  ORDER = 'order',
  ACCOUNT = 'account',
  SETTINGS = 'settings',
  DOCUMENTS = 'documents',
  SUPPORT = 'support',
  CONTACT = 'contact'
}
