import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const SettingsIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="1114" height="956" viewBox="0 0 1114 956" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M741.37 502.41C751.09 473.8 757.42 443.77 760.01 412.82L698.23 382.9C698.3 372.89 697.94 362.8 697.04 352.66C696.79 349.16 696.32 345.72 695.92 342.23L754.04 305.5C748 274.99 738.39 245.85 725.61 218.61L656.96 223.59C650.26 211.7 642.78 200.27 634.65 189.45L666.6 128.68C646.42 105.73 623.5 85.2495 598.45 67.6895L541.53 106.27C529.87 99.3995 517.75 93.2195 505.19 87.8195L502.49 19.2296C473.89 9.50955 443.83 3.17953 412.93 0.519531L382.99 62.3495C372.99 62.2595 362.95 62.6495 352.77 63.5195C349.24 63.8295 345.79 64.2695 342.33 64.6795L305.66 6.49954C275.12 12.5995 245.97 22.2095 218.7 34.9695L223.7 103.61C211.82 110.33 200.38 117.78 189.59 125.94L128.78 93.9695C105.79 114.19 85.31 137.09 67.82 162.18L106.43 219.07C99.52 230.74 93.29 242.85 87.93 255.38L19.31 258.07C9.59998 286.68 3.29998 316.68 0.669983 347.66L62.48 377.58C62.42 387.6 62.74 397.68 63.61 407.82C63.92 411.32 64.36 414.75 64.83 418.24L6.64998 454.94C12.77 485.45 22.33 514.59 35.11 541.84L103.73 536.87C110.46 548.75 117.91 560.13 126.05 570.99L94.09 631.74C114.28 654.77 137.24 675.24 162.28 692.75L219.17 654.16C230.86 661.1 242.99 667.29 255.51 672.65L258.21 741.25C286.81 750.94 316.78 757.29 347.73 759.91L377.74 698.099C387.71 698.159 397.82 697.82 407.97 696.95C411.46 696.67 414.91 696.19 418.33 695.75L455.03 753.979C485.62 747.869 514.72 738.29 542 725.54L537.04 656.88C548.87 650.12 560.27 642.69 571.15 634.56L631.92 666.52C654.87 646.3 675.35 623.41 692.87 598.34L654.33 541.45C661.18 529.76 667.36 517.63 672.8 505.09L741.38 502.42L741.37 502.41ZM380.37 604.29C256.81 604.29 156.32 503.78 156.32 380.25C156.32 256.72 256.81 156.21 380.37 156.21C503.93 156.21 604.4 256.72 604.4 380.25C604.4 503.78 503.9 604.29 380.37 604.29Z"
        fill="#094754"
      />
      <path
        d="M172.32 380.25C172.32 494.95 265.62 588.28 380.36 588.28C495.1 588.28 588.37 494.95 588.37 380.25C588.37 265.55 495.07 172.2 380.36 172.2C265.65 172.2 172.32 265.54 172.32 380.25ZM536.91 366.58C544.43 453.1 480.46 529.32 394 536.82C307.54 544.39 231.29 480.34 223.74 393.9C216.26 307.43 280.23 231.19 366.69 223.68C453.19 216.13 529.44 280.14 536.92 366.57L536.91 366.58Z"
        fill="#91DCF9"
      />
      <path
        d="M647.64 663.84C645.59 762.14 723.89 843.8 822.18 845.85C920.48 847.92 1002.13 769.6 1004.18 671.32C1006.26 573.02 927.97 491.37 829.63 489.31C731.33 487.25 649.68 565.54 647.64 663.84ZM919.84 609.23C950.43 660.94 933.31 727.68 881.59 758.27C829.2 789.23 761.52 771.27 731.44 718.08C703.23 668.12 720.15 602.35 768.94 572.15C820.9 539.98 888.87 556.96 919.84 609.23Z"
        fill="#EEFF32"
      />
      <path
        d="M1112.45 695.84C1114.47 675.1 1114.29 654.2 1111.81 633.28L1063.3 621.68C1060.75 608.96 1057.15 596.28 1052.61 583.85L1088.16 548.77C1083.89 539.33 1079.06 530.03 1073.67 520.95C1068.33 511.9 1062.51 503.24 1056.28 494.93L1008.42 509.23C999.72 499.19 990.36 489.98 980.36 481.67L993.61 433.56C976.48 421.32 958.23 411.07 939.1 402.86L904.74 439.21C892.36 434.92 879.65 431.66 866.78 429.42L854.08 381.01C833.39 379.01 812.45 379.23 791.65 381.78L779.95 430.27C767.22 432.74 754.63 436.33 742.21 440.94L707.1 405.42C697.74 409.65 688.35 414.41 679.29 419.84C670.22 425.18 661.55 431.02 653.27 437.18L667.52 485.12C657.52 493.83 648.3 503.18 639.99 513.16L591.88 499.95C579.62 517.02 569.39 535.28 561.19 554.37L597.5 588.77C593.22 601.16 590.05 613.87 587.78 626.75L539.42 639.44C537.37 660.14 537.58 681.08 540.07 701.95L588.65 713.51C591.13 726.3 594.69 738.91 599.23 751.4L563.71 786.46C567.96 795.91 572.74 805.16 578.14 814.2C583.54 823.31 589.33 832.01 595.52 840.27L643.44 826.01C652.12 836.01 661.55 845.22 671.48 853.52L658.3 901.68C675.4 913.89 693.6 924.19 712.67 932.29L747.11 896.01C759.53 900.33 772.11 903.55 785.1 905.75L797.76 954.15C818.42 956.11 839.4 955.98 860.27 953.48L871.82 904.93C884.59 902.47 897.22 898.87 909.63 894.27L944.86 929.81C954.22 925.6 963.49 920.78 972.57 915.4C981.67 910.03 990.27 904.21 998.58 897.98L984.34 850.12C994.35 841.43 1003.56 832.02 1011.86 822.07L1060 835.26C1072.24 818.16 1082.53 799.91 1090.63 780.82L1054.32 746.38C1058.57 734.07 1061.87 721.39 1064.11 708.46L1112.47 695.82L1112.45 695.84ZM822.01 853.56C719.47 851.42 637.78 766.23 639.94 663.67C642.06 561.13 727.23 479.43 829.82 481.59C932.37 483.73 1014.04 568.92 1011.92 671.48C1009.76 774.03 924.6 855.73 822.01 853.56Z"
        fill="#91DCF9"
      />
      <path
        d="M156.31 380.25C156.31 503.79 256.8 604.29 380.36 604.29C503.92 604.29 604.39 503.78 604.39 380.25C604.39 256.72 503.89 156.21 380.36 156.21C256.83 156.21 156.31 256.72 156.31 380.25ZM588.37 380.25C588.37 494.95 495.07 588.28 380.36 588.28C265.65 588.28 172.32 494.95 172.32 380.25C172.32 265.55 265.62 172.2 380.36 172.2C495.1 172.2 588.37 265.54 588.37 380.25Z"
        fill="#EEFF32"
      />
      <path
        d="M639.94 663.67C637.78 766.23 719.47 851.42 822.01 853.56C924.6 855.72 1009.76 774.03 1011.92 671.48C1014.04 568.92 932.37 483.73 829.82 481.59C727.24 479.44 642.07 561.13 639.94 663.67ZM1004.19 671.32C1002.14 769.6 920.49 847.92 822.19 845.85C723.9 843.8 645.59 762.14 647.65 663.84C649.7 565.54 731.34 487.25 829.64 489.31C927.98 491.37 1006.27 573.02 1004.19 671.32Z"
        fill="#5CBFAB"
      />
      <path
        d="M631.87 667.82C634.63 665.38 637.36 662.91 640.05 660.39C641.61 625.82 652.61 593.77 670.49 566.68L654.27 542.75C661.12 531.06 667.3 518.93 672.74 506.39L740.25 503.76C740.67 503.54 741.1 503.32 741.52 503.11C748.7 481.85 754.02 459.8 757.31 437.18C752.23 438.7 747.17 440.39 742.14 442.26L707.03 406.74C697.67 410.97 688.28 415.73 679.22 421.16C670.15 426.51 661.48 432.34 653.2 438.5L667.45 486.44C657.45 495.15 648.23 504.5 639.92 514.48L591.81 501.27C579.55 518.34 569.32 536.6 561.12 555.69L597.43 590.09C593.15 602.48 589.98 615.19 587.71 628.07L539.35 640.76C538.8 646.31 538.43 651.89 538.2 657.47C549.59 650.9 560.58 643.72 571.08 635.87L631.85 667.82H631.87Z"
        fill="#1ED367"
      />
    </svg>
  </SvgIcon>
);

export default SettingsIcon;
