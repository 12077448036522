import { Box, Link, Typography, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const HeaderDrawer = styled(Box)(() => ({
  flexGrow: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 20,
  padding: '18px 15px 0px'
}));

const HeaderNotificationDrawer = () => {
  return (
    <HeaderDrawer>
      <Typography variant="h5" color={'primary'}>
        Notifiche
      </Typography>
      <Link component={RouterLink} to={'/notifications'}>
        Mostra tutto
      </Link>
    </HeaderDrawer>
  );
};

export default HeaderNotificationDrawer;
