import { Box, Button, Typography } from '@mui/material';
import useNavigationTracker from '../../../hooks/useNavigationTracker';
import { ClientActionEnum, ClientTypeEnum } from '../../../types/dataToSend/clientLog';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import { ContactFormPresetEnum } from '../../../utils/contactFormPreset';

const MoreCreditModal = () => {
  const { trackAction } = useNavigationTracker();
  const navigate = useNavigate();
  const { closeModal } = useModal();
  return (
    <Box width={'100%'} textAlign={'center'}>
      <Typography variant={'h4'} mb={4} mt={-3}>
        Vuoi più credito?
      </Typography>

      <Typography variant={'body1'} mb={4} maxWidth={500} marginX={'auto'}>
        Vuoi ottenere più credito sulla soluzione dilazione con camere? Puoi ottenerlo attivando uno dei servizi di revenue management dei
        nostri partner e/o prevedendo altre opzioni per la vendita delle camere come ad esempio le promozioni in flash sales e il "vuoto per
        pieno". Contattaci per sbloccare il credito.
      </Typography>

      <Button
        size={'medium'}
        color={'primary'}
        variant={'contained'}
        onClick={() => {
          trackAction({
            type: ClientTypeEnum.Link,
            action: ClientActionEnum.Clicked,
            name: 'Ha cliccato il bottone contattaci dal modal per avere più credito'
          });
          navigate('/contacts', {
            state: {
              preset: ContactFormPresetEnum.MoreCredit
            }
          });
          closeModal();
        }}
      >
        Contattaci
      </Button>
    </Box>
  );
};

export default MoreCreditModal;
