import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Collapse,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  IconButton,
  useMediaQuery
} from '@mui/material';

// project import
import NavItem from './NavItem';
import Transitions from 'components/Transitions';

// types
import { NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { activeItem } from 'store/reducers/menu';
import { BorderOuterOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

type VirtualElement = {
  getBoundingClientRect: () => ClientRect | DOMRect;
  contextElement?: Element;
};

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    borderBottom: `1px solid ${theme.palette.grey.A800}`
  }
}));

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
  menu: NavItemType;
  level: number;
}

const NavCollapse = ({ menu, level }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const menuState = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menuState;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null | undefined>(null);
  const isSelected =
    menu.children!.findIndex((c) => {
      return c.id != null && menuState.openItem.includes(c.id);
    }) > -1;
  const [anchorEl, setAnchorEl] = useState<VirtualElement | (() => VirtualElement) | null | undefined>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
    setAnchorEl(null);
    if (drawerOpen) {
      // setOpen(!open);
      setSelected(!selected ? menu.id : null);
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const childrens = menu.children ? menu.children : [];
    if (menu.url && pathname === menu.url) {
      dispatch(activeItem({ openItem: [menu.id] }));
    }
    childrens.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(menu.id);
        if (!drawerOpen) {
          dispatch(activeItem({ openItem: [item.id] }));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu]);

  const openMini = Boolean(anchorEl);

  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  const borderIcon = level === 1 ? <BorderOuterOutlined style={{ fontSize: '1rem' }} /> : false;
  const Icon = menu.icon;
  const DesktopIcon = Icon ? Icon[0] : undefined;
  const MobileIcon = Icon ? Icon[1] : undefined;
  const menuIcon =
    DesktopIcon !== undefined && !matchDownSM ? (
      <DesktopIcon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
    ) : MobileIcon !== undefined && matchDownSM ? (
      <MobileIcon />
    ) : (
      borderIcon
    );

  return (
    <>
      <ListItemButton
        disableRipple
        id={`nav-collapse-${menu.id}`}
        selected={!matchDownSM && !drawerOpen && isSelected}
        {...(matchDownSM && { disableRipple: true })}
        {...(!drawerOpen && !matchDownSM && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
        onClick={handleClick}
        sx={{
          height: '52px',
          pl: drawerOpen && !matchDownSM ? 2.8 : 1.5,
          // py: !drawerOpen && level === 1 && !matchDownSM ? 1.25 : 1,
          transition: 'all .3s',
          py: 1,
          position: 'relative',
          ...(drawerOpen && {
            '&:hover': {
              backgroundColor: !matchDownSM ? alpha(theme.palette.secondary.light, 0.35) : 'transparent'
            },
            '&.Mui-selected': {
              backgroundColor: !matchDownSM ? alpha(theme.palette.secondary.light, 0.65) : alpha(theme.palette.secondary.light, 0.2),
              '&:hover': {
                backgroundColor: !matchDownSM ? theme.palette.secondary.light : alpha(theme.palette.secondary.light, 0.2)
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              backgroundColor: !matchDownSM ? alpha(theme.palette.secondary.light, 0.35) : 'transparent'
            },
            '&.Mui-selected': {
              backgroundColor: !matchDownSM ? alpha(theme.palette.secondary.light, 0.65) : alpha(theme.palette.secondary.light, 0.2),
              '&:hover': {
                backgroundColor: !matchDownSM ? theme.palette.secondary.light : alpha(theme.palette.secondary.light, 0.2)
              }
            }
          })
        }}
      >
        <Box
          display={'flex'}
          flexGrow={1}
          alignItems={'center'}
          onClick={() => {
            if (menu.url) {
              navigate(menu.url);
            } else setOpen(!open);
          }}
        >
          {menuIcon && (
            <ListItemIcon
              sx={{
                minWidth: 28,
                ...(!drawerOpen &&
                  !matchDownSM && {
                    borderRadius: 1.5,
                    width: 36,
                    height: 36,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }),
                ...(!drawerOpen &&
                  selected === menu.id && {
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                    }
                  })
              }}
            >
              {menuIcon}
            </ListItemIcon>
          )}

          {(drawerOpen || (!drawerOpen && level !== 1) || matchDownSM) && (
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  sx={{
                    color: !matchDownSM ? theme.palette.primary.main : theme.palette.common.white,
                    ml: 3
                  }}
                >
                  {menu.title}
                </Typography>
              }
              secondary={
                menu.caption && (
                  <Typography variant="caption" color="secondary">
                    {menu.caption}
                  </Typography>
                )
              }
            />
          )}
        </Box>
        {(drawerOpen || (!drawerOpen && level !== 1)) &&
          (openMini || open ? (
            <IconButton onClick={() => setOpen(false)}>
              <KeyboardArrowUp
                fontSize="small"
                style={{
                  // fontSize: '0.8rem',
                  marginLeft: 1,
                  color: !matchDownSM ? theme.palette.primary.lighter : theme.palette.common.white
                }}
              />
            </IconButton>
          ) : (
            <IconButton onClick={() => setOpen(true)}>
              <KeyboardArrowDown
                fontSize="small"
                style={{
                  // fontSize: '0.625rem',
                  marginLeft: 1,
                  color: !matchDownSM ? theme.palette.primary.lighter : theme.palette.common.white
                }}
              />
            </IconButton>
          ))}

        {/*Poupop quando la sidebar ha solo l'icona*/}
        {!drawerOpen && !matchDownSM && (
          <PopperStyled
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            style={{
              zIndex: 2001
            }}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, 1]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{navCollapse}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>

      {(drawerOpen || matchDownSM) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{navCollapse}</List>
        </Collapse>
      )}
    </>
  );
};

export default NavCollapse;
