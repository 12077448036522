import { Box, Fade, Grid } from '@mui/material';

const FeedbackCommonLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        width: '100%',
        minHeight: { xs: `100dvh`, md: '100%' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: { xs: 10, md: 0 }
      }}
    >
      <Fade in>
        <Box
          sx={{
            padding: 3,
            maxWidth: 900,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            img: {
              width: '100%',
              maxWidth: 350
            }
          }}
        >
          {children}
        </Box>
      </Fade>
    </Grid>
  );
};

export default FeedbackCommonLayout;
