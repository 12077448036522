import axios, { AxiosError, AxiosInstance } from 'axios';
import useAuth from '../hooks/useAuth';
import { useError } from '../hooks/useError';
import React, { createContext, memo, useEffect } from 'react';
import { useSelector } from 'store';
import { ErrorDTO } from '../types/server/error.dto';
import { useErrorHandler } from 'react-error-boundary';

//Creo l'istanza di axios settando l'url base
const apiVersion: string = process.env.REACT_APP_API_VERSION;
const serverUrl: string = process.env.REACT_APP_SERVER_URL;
const axiosInstance = axios.create({
  baseURL: serverUrl + 'api/' + apiVersion,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

// let axiosErrorCount = 0;

const AxiosInstanceContext = createContext<{ axiosInstance: AxiosInstance } | null>(null);
export const AxiosInstanceProvider = memo(({ children }: { children: React.ReactElement }) => {
  const { logout, refreshToken } = useAuth();
  const { handleError } = useError();
  const { rememberMe } = useSelector((state) => state.helpersAuth);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const handleErrorBoundary = useErrorHandler();

  //Aggiungo l'interceptors per catturare tutte le risposte dell'istanza, in modo da controllare l'errore
  useEffect(() => {
    console.log('eseguo useEffect axiosIstance');
    if (localStorage['pawnId'] !== undefined) {
      axiosInstance.defaults.headers['pawn'] = localStorage['pawnId'];
    } else {
      delete axiosInstance.defaults.headers['pawn'];
    }

    if (localStorage['accessToken'] != null || sessionStorage['accessToken'] != null)
      axiosInstance.defaults.headers['Authorization'] =
        'Bearer ' + (rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']);
    if (!isLoggedIn) return;
    axiosInstance.interceptors.response.clear();

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<ErrorDTO>) => {
        if (!axios.isAxiosError(error)) return;
        const originalRequest = error.config!;
        /*
         * Se l'errore è invalid_token ritorno una chiamata post all'endpoint per il refresh del token passandogli come authorization (refresh token)
         * Alla risposta mi salvo i nuovi access e refresh token e ritorno la prima chiamata che era andata in errore
         */
        if (error.response?.data?.message === 'invalid_token' && error.response?.data?.statusCode === 403) {
          localStorage.removeItem('accessExpireAt');
          await refreshToken();
          axiosInstance.defaults.headers['Authorization'] =
            'Bearer ' + (rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']);
          originalRequest!.headers['Authorization'] =
            'Bearer ' + (rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']);
          return axios(originalRequest);
        } else if (error.response?.data?.message === 'Unauthorized' || error.response?.status === 502) {
          if (originalRequest.url === '/client-logs') return;
          handleError(error);
          await logout();
        } else if (error.code?.includes('ERR_NETWORK')) {
          if (originalRequest.url === '/client-logs') return;
          handleErrorBoundary(
            new Error("Ops c'è un problema di rete, riprova fra qualche minuto.", {
              cause: 'server_sync'
            })
          );
        } else {
          if (originalRequest.url === '/client-logs') return;
          handleError(error);
          return Promise.reject(error);
        }
      }
    );

    console.log('axios interceptor', axiosInstance.interceptors.response);
  }, [isLoggedIn]);

  return (
    <AxiosInstanceContext.Provider
      value={{
        axiosInstance
      }}
    >
      {children}
    </AxiosInstanceContext.Provider>
  );
});

export default AxiosInstanceContext;
