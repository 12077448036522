import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const PartnerIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="67" height="48" viewBox="0 0 67 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.4752 39C31.3068 39 37.6556 32.2843 37.6556 24C37.6556 15.7157 31.3068 9 23.4752 9C15.6435 9 9.29474 15.7157 9.29474 24C9.29474 32.2843 15.6435 39 23.4752 39Z"
        fill="#094754"
        stroke="#094754"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M33.7429 13.1215C31.4565 10.8954 28.4384 9.39509 25.0751 9C18.6724 17.1429 18.3911 28.4378 24.231 36.8582C24.4991 37.2455 24.7822 37.6258 25.076 38C28.4392 37.6049 31.4574 36.1046 33.7438 33.8785C33.3757 33.52 33.0253 33.1423 32.6971 32.7481C32.0449 31.9649 31.4733 31.1129 30.9955 30.2061C29.9382 28.1976 29.3409 25.9184 29.3409 23.5009C29.3409 19.4412 31.0256 15.7696 33.7438 13.1223L33.7429 13.1215Z"
        fill="#91DCF9"
      />
      <path
        d="M39.7256 7.02983C30.75 -2.34328 16.1991 -2.34328 7.2235 7.02983C-0.714165 15.3191 -1.62992 28.162 4.47202 37.5043C5.26983 38.7254 6.18558 39.8876 7.2235 40.9715C8.26141 42.0554 9.3743 43.0117 10.5436 43.8449C19.4897 50.2171 31.788 49.2607 39.7256 40.9715C48.7012 31.5984 48.7012 16.4029 39.7256 7.02983ZM23.4754 38.7642C21.135 38.7642 18.9278 38.1694 16.9842 37.119C16.1064 36.6439 15.2816 36.0765 14.5234 35.4272C13.7955 34.8052 13.1283 34.1084 12.5327 33.3483C11.9118 32.5565 11.3676 31.6952 10.9126 30.7785C9.90589 28.7479 9.33723 26.4438 9.33723 23.9998C9.33723 15.8452 15.6675 9.23543 23.4754 9.23543C31.2833 9.23543 37.6136 15.846 37.6136 23.9998C37.6136 32.1535 31.2833 38.7642 23.4754 38.7642Z"
        fill="#094754"
      />
      <path
        d="M60.0198 40.5725C52.8684 47.885 42.2098 49.4221 33.5824 45.1881C35.8814 44.0596 38.0377 42.5217 39.9439 40.5725C40.6555 39.8448 41.3111 39.0806 41.9113 38.2877C42.4717 38.3555 43.0413 38.3911 43.6195 38.3911C51.4995 38.3911 57.8882 31.8593 57.8882 23.8009C57.8882 15.7424 51.4995 9.21057 43.6195 9.21057C43.0413 9.21057 42.4708 9.24708 41.9105 9.3149C41.3111 8.522 40.6555 7.75867 39.9439 7.03098C38.0368 5.08178 35.8806 3.54381 33.5807 2.41533C42.2089 -1.81952 52.8684 -0.281553 60.0198 7.03098C69.0782 16.2927 69.0782 31.3099 60.0198 40.5725Z"
        fill="#91DCF9"
      />
      <path
        d="M37.6557 24.0004C37.6557 27.904 35.9741 31.4392 33.2548 34C30.5356 31.4392 28.854 27.904 28.854 24.0004C28.854 20.0968 30.5356 16.5616 33.2548 14C35.9741 16.5616 37.6557 20.096 37.6557 24.0004Z"
        fill="#F0FF32"
      />
      <path
        d="M33.7446 33.8785C35.8677 36.1046 38.6703 37.6049 41.7933 38C47.7386 29.8571 47.9999 18.5622 42.5771 10.1418C42.3282 9.7545 42.0653 9.3742 41.7925 9C38.6695 9.39509 35.8669 10.8954 33.7438 13.1215C34.0856 13.48 34.4109 13.8577 34.7158 14.2519C35.3213 15.0351 35.8521 15.8871 36.2958 16.7939C37.2776 18.8024 37.8322 21.0816 37.8322 23.4991C37.8322 27.5588 36.2678 31.2304 33.7438 33.8777L33.7446 33.8785Z"
        fill="#094754"
      />
    </svg>
  </SvgIcon>
);

export default PartnerIcon;
