import { Box, Grid, TextField, Typography } from '@mui/material';
import { useOutletContext } from 'react-router';
import { SettingsOutletContextType } from '../../../pages/account/SettingsPage';
import dayjs from 'dayjs';
import { NewValuesStep4Type } from '../../../types/formik/manual-integration-step';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Switch from '@mui/material/Switch';
import { NumericFormat } from 'react-number-format';
import Divider from '@mui/material/Divider';
import { Fragment } from 'react';
import Button from '@mui/material/Button';
import ModalNewBuildingSales from '../ModalNewBuildingSales';
import useModal from '../../../hooks/useModal';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
// import useModal from '../../../hooks/useModal';
// import Button from '@mui/material/Button';
// import ModalNewBuildingSales from '../ModalNewBuildingSales';

const BuildingInfoTab = () => {
  const { isLoading, building, refetchBuilding } = useOutletContext<SettingsOutletContextType>();
  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const { openModal } = useModal();

  const bs = building?.buildingSales[building?.buildingSales.length - 1];

  const insertionPeriod =
    bs != null
      ? {
          from: dayjs(bs.at).subtract(12, 'month'),
          to: dayjs(bs.at).subtract(1, 'month')
        }
      : null;

  const periods: NewValuesStep4Type['periods'] | null =
    bs != null && insertionPeriod != null
      ? Array.from({ length: 12 }, (_, i) => {
          const period = {
            month: insertionPeriod.to.subtract(i, 'month').get('month'),
            year: insertionPeriod.to.subtract(i, 'month').get('year')
          };
          const buildingSale = bs.buildingSalePeriods.find((sale) => sale.month === period.month && sale.year === period.year);

          if (buildingSale == null) {
            return {
              opening: false as const,
              month: period.month,
              year: period.year,
              occupancy: undefined,
              turnover: undefined,
              closingDays: undefined
            };
          }

          return {
            opening: true as const,
            month: buildingSale.month,
            year: buildingSale.year,
            closingDays: buildingSale.closingDays,
            turnover: +buildingSale.turnover,
            occupancy: buildingSale.occupancy
          };
        }).sort((a, b) => {
          // Confronto per l'anno
          if (a.year !== b.year) {
            return a.year - b.year;
          }

          // Se gli anni sono uguali, confronta per il mese
          return a.month - b.month;
        })
      : null;

  const buildingSaleExpireDays = bs != null ? dayjs(bs.at).add(1, 'year').diff(dayjs(), 'day') : null;

  if (isLoading || building == null) {
    return <></>;
  }

  return (
    <Box mb={4}>
      <Typography variant="h5">Dati della struttura</Typography>
      <Grid container mt={2} rowSpacing={2} columnSpacing={2} maxWidth={750}>
        <Grid item xs={12} md={6}>
          <TextField variant={'outlined'} label={'Categoria struttura'} disabled fullWidth value={building.buildingType?.name ?? ''} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField variant={'outlined'} label={'Stelle hotel'} value={building.stars ?? ''} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant={'outlined'}
            label={'Channel Manager'}
            value={building.channelManager != null ? building.channelManager.name ?? '' : building.channelManagerCustom ?? ''}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant={'outlined'}
            label={'PMS'}
            value={building.pms != null ? building.pms.name ?? '' : building.pmsCustom ?? ''}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField variant={'outlined'} label={'Numero di camere'} value={building.roomsNum ?? ''} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField variant={'outlined'} label={'Valutazione booking'} value={building.bookingRating ?? ''} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField variant={'outlined'} label={'Tipo di destinazione'} value={building.destinationType?.name ?? ''} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant={'outlined'}
            label={'Listino prezzi'}
            value={
              building.priceListType === 'fit_with_allotment'
                ? 'Fit con allortment'
                : building.priceListType === 'fit_without_allotment'
                ? 'Fit senza allortment'
                : building.priceListType === 'bar'
                ? 'Bar'
                : ''
            }
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField variant={'outlined'} label={'Numero di telefono della struttura'} value={building.phone ?? ''} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant={'outlined'}
            label={'Revenue managment'}
            value={
              building.internalRevenueManagement
                ? 'Gestione interna'
                : building.externalRevenueManagement != null
                ? building.externalRevenueManagement
                : building.registrationConfirmed
                ? 'No'
                : ''
            }
            disabled
            fullWidth
          />
        </Grid>
      </Grid>

      {building.buildingSales.length > 0 && bs != null && (
        // <>
        //   {building.buildingSales.map((bs) => {
        //     const insertionPeriod = {
        //       from: dayjs(bs.at).subtract(12, 'month'),
        //       to: dayjs().subtract(1, 'month')
        //     };
        //
        //     const periods: NewValuesStep4Type['periods'] = Array.from({ length: 12 }, (_, i) => {
        //       const period = {
        //         month: insertionPeriod.to.subtract(i, 'month').get('month'),
        //         year: insertionPeriod.to.subtract(i, 'month').get('year')
        //       };
        //       const buildingSale = bs.buildingSalePeriods.find((sale) => sale.month === period.month && sale.year === period.year);
        //
        //       if (buildingSale == null) {
        //         return {
        //           opening: false as const,
        //           month: period.month,
        //           year: period.year,
        //           occupancy: undefined,
        //           turnover: undefined,
        //           closingDays: undefined
        //         };
        //       }
        //
        //       return {
        //         opening: true as const,
        //         month: buildingSale.month,
        //         year: buildingSale.year,
        //         closingDays: buildingSale.closingDays,
        //         turnover: +buildingSale.turnover,
        //         occupancy: buildingSale.occupancy
        //       };
        //     }).sort((a, b) => {
        //       // Confronto per l'anno
        //       if (a.year !== b.year) {
        //         return a.year - b.year;
        //       }
        //
        //       // Se gli anni sono uguali, confronta per il mese
        //       return a.month - b.month;
        //     });
        //
        //     return (
        //       <Box key={bs.id}>
        //         <Typography variant="h5" mt={3}>
        //           Dati di vendita{'   '}
        //           <span style={{ fontWeight: 400 }}>
        //             ({insertionPeriod.from.format('MMMM/YYYY')} - {insertionPeriod.to.format('MMMM/YYYY')})
        //           </span>
        //         </Typography>
        //         <Grid container mt={2} columnSpacing={2} rowSpacing={1} maxWidth={750}>
        //           {!isDownMD && (
        //             <Grid item xs={12}>
        //               <Grid container alignItems={'center'} columnSpacing={1}>
        //                 <Grid item xs={6} md={2.5} />
        //
        //                 <Grid item xs={6} md={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        //                   <Typography variant={'body1'} fontWeight={500}>
        //                     Apertura
        //                   </Typography>
        //                 </Grid>
        //
        //                 <Grid item xs={4} md={2.5}>
        //                   <Typography variant={'body1'} fontWeight={500}>
        //                     Giorni chiusura
        //                   </Typography>
        //                 </Grid>
        //                 <Grid item xs={4} md={2.5}>
        //                   <Typography variant={'body1'} fontWeight={500}>
        //                     Fatturato
        //                   </Typography>
        //                 </Grid>
        //                 <Grid item xs={4} md={2.5}>
        //                   <Typography variant={'body1'} fontWeight={500}>
        //                     Occupancy
        //                   </Typography>
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //           )}
        //
        //           {periods.map((period, i) => (
        //             <Grid item xs={12} key={`building-sales-${bs.id}-period-${i}`}>
        //               <Grid container alignItems={'center'} columnSpacing={1} rowSpacing={0.5}>
        //                 <Grid item xs={4} md={2.5}>
        //                   <Typography variant={'body1'} color={'text.disabled'}>
        //                     {dayjs().set('year', period.year!).set('month', period.month!).format('MMMM YYYY')}
        //                   </Typography>
        //                 </Grid>
        //
        //                 <Grid item xs={6} md={2} display={'flex'} justifyContent={{ xs: 'flex-start', md: 'center' }} alignItems={'center'}>
        //                   {isDownMD && (
        //                     <Typography variant={'body1'} fontWeight={500}>
        //                       Apertura
        //                     </Typography>
        //                   )}
        //                   <Switch checked={period.opening} value={period.opening} disabled />
        //                 </Grid>
        //
        //                 <Grid item xs={4} md={2.5}>
        //                   {isDownMD && (
        //                     <Typography variant={'body1'} fontWeight={500}>
        //                       Giorni chiusura
        //                     </Typography>
        //                   )}
        //                   <NumericFormat
        //                     customInput={TextField}
        //                     size={'medium'}
        //                     fullWidth
        //                     disabled
        //                     value={period.closingDays}
        //                     valueIsNumericString={false}
        //                     decimalScale={0}
        //                     allowNegative={false}
        //                   />
        //                 </Grid>
        //
        //                 <Grid item xs={4} md={2.5}>
        //                   {isDownMD && (
        //                     <Typography variant={'body1'} fontWeight={500}>
        //                       Fatturato
        //                     </Typography>
        //                   )}
        //                   <NumericFormat
        //                     customInput={TextField}
        //                     disabled
        //                     size={'medium'}
        //                     variant="outlined"
        //                     value={period.turnover ?? ''}
        //                     allowNegative={false}
        //                     fullWidth
        //                     valueIsNumericString={false}
        //                     suffix={' €'}
        //                     decimalScale={2}
        //                     thousandSeparator={'.'}
        //                     decimalSeparator={','}
        //                     // error={Boolean(touched.annual?.directSales && errors.annual?.directSales)}
        //                   />
        //                 </Grid>
        //
        //                 <Grid item xs={4} md={2.5}>
        //                   {isDownMD && (
        //                     <Typography variant={'body1'} fontWeight={500}>
        //                       Occupancy
        //                     </Typography>
        //                   )}
        //                   <NumericFormat
        //                     customInput={TextField}
        //                     disabled
        //                     size={'medium'}
        //                     variant="outlined"
        //                     value={period.occupancy ?? ''}
        //                     fullWidth
        //                     valueIsNumericString={false}
        //                     allowNegative={false}
        //                     suffix={' %'}
        //                     decimalScale={2}
        //                     decimalSeparator={','}
        //                   />
        //                 </Grid>
        //               </Grid>
        //             </Grid>
        //           ))}
        //         </Grid>
        //         <Grid container columnSpacing={2} rowSpacing={3} maxWidth={750}>
        //           <Grid item xs={12} mt={3}>
        //             <Divider />
        //           </Grid>
        //
        //           <Grid item xs={6}>
        //             <NumericFormat
        //               customInput={TextField}
        //               size={'medium'}
        //               label={'Percentuale canale diretti'}
        //               disabled
        //               variant="outlined"
        //               value={bs.directChannelsPercentage ?? ''}
        //               fullWidth
        //               valueIsNumericString={false}
        //               allowNegative={false}
        //               suffix={' %'}
        //               decimalScale={2}
        //               decimalSeparator={','}
        //             />
        //           </Grid>
        //           <Grid item xs={6}>
        //             <NumericFormat
        //               customInput={TextField}
        //               size={'medium'}
        //               disabled
        //               label={'Percentuale OTA'}
        //               variant="outlined"
        //               value={bs.otaPercentage ?? ''}
        //               fullWidth
        //               valueIsNumericString={false}
        //               allowNegative={false}
        //               suffix={' %'}
        //               decimalScale={2}
        //               decimalSeparator={','}
        //             />
        //           </Grid>
        //
        //           {bs.buildingSaleChannels.map((c, i) => (
        //             <Fragment key={i}>
        //               <Grid item xs={6}>
        //                 <TextField disabled size={'medium'} label={'Nome canale'} fullWidth value={c.name ?? ''} />
        //               </Grid>
        //               <Grid item xs={6}>
        //                 <NumericFormat
        //                   customInput={TextField}
        //                   disabled
        //                   size={'medium'}
        //                   label={'Percentuale canale'}
        //                   variant="outlined"
        //                   name={`channels[${i}].percentage`}
        //                   value={c.percentage ?? ''}
        //                   fullWidth
        //                   valueIsNumericString={false}
        //                   allowNegative={false}
        //                   suffix={' %'}
        //                   decimalScale={2}
        //                   decimalSeparator={','}
        //                 />
        //               </Grid>
        //             </Fragment>
        //           ))}
        //         </Grid>
        //       </Box>
        //     );
        //   })}
        // </>
        <>
          <Box mt={5} maxWidth={750}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant="h5">
                Dati di vendita{'   '}
                <span style={{ fontWeight: 400 }}>
                  ({insertionPeriod?.from.format('MMMM/YYYY')} - {insertionPeriod?.to.format('MMMM/YYYY')})
                </span>
              </Typography>
              {buildingSaleExpireDays != null && (
                <>
                  {buildingSaleExpireDays >= 0 && buildingSaleExpireDays < 5 ? (
                    <Chip label={'In scadenza'} size={'small'} color={'warning'} />
                  ) : buildingSaleExpireDays < 0 ? (
                    <Chip label={'Scaduti'} size={'small'} color={'warning'} />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Stack>
            <Grid container mt={2} columnSpacing={2} rowSpacing={1} maxWidth={750}>
              {!isDownMD && (
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} columnSpacing={1}>
                    <Grid item xs={6} md={2.5} />

                    <Grid item xs={6} md={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <Typography variant={'body1'} fontWeight={500}>
                        Apertura
                      </Typography>
                    </Grid>

                    <Grid item xs={4} md={2.5}>
                      <Typography variant={'body1'} fontWeight={500}>
                        Giorni chiusura
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={2.5}>
                      <Typography variant={'body1'} fontWeight={500}>
                        Fatturato
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={2.5}>
                      <Typography variant={'body1'} fontWeight={500}>
                        Occupancy
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {periods?.map((period, i) => (
                <Grid item xs={12} key={`building-sales-${bs.id}-period-${i}`}>
                  <Grid container alignItems={'center'} columnSpacing={1} rowSpacing={0.5}>
                    <Grid item xs={4} md={2.5}>
                      <Typography variant={'body1'} color={'text.disabled'}>
                        {dayjs().set('year', period.year!).set('month', period.month!).format('MMMM YYYY')}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={2} display={'flex'} justifyContent={{ xs: 'flex-start', md: 'center' }} alignItems={'center'}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Apertura
                        </Typography>
                      )}
                      <Switch checked={period.opening} value={period.opening} disabled />
                    </Grid>

                    <Grid item xs={4} md={2.5}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Giorni chiusura
                        </Typography>
                      )}
                      <NumericFormat
                        customInput={TextField}
                        size={'medium'}
                        fullWidth
                        disabled
                        value={period.closingDays}
                        valueIsNumericString={false}
                        decimalScale={0}
                        allowNegative={false}
                      />
                    </Grid>

                    <Grid item xs={4} md={2.5}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Fatturato
                        </Typography>
                      )}
                      <NumericFormat
                        customInput={TextField}
                        disabled
                        size={'medium'}
                        variant="outlined"
                        value={period.turnover ?? ''}
                        allowNegative={false}
                        fullWidth
                        valueIsNumericString={false}
                        suffix={' €'}
                        decimalScale={2}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        // error={Boolean(touched.annual?.directSales && errors.annual?.directSales)}
                      />
                    </Grid>

                    <Grid item xs={4} md={2.5}>
                      {isDownMD && (
                        <Typography variant={'body1'} fontWeight={500}>
                          Occupancy
                        </Typography>
                      )}
                      <NumericFormat
                        customInput={TextField}
                        disabled
                        size={'medium'}
                        variant="outlined"
                        value={period.occupancy ?? ''}
                        fullWidth
                        valueIsNumericString={false}
                        allowNegative={false}
                        suffix={' %'}
                        decimalScale={2}
                        decimalSeparator={','}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container columnSpacing={2} rowSpacing={3} maxWidth={750}>
              <Grid item xs={12} mt={3}>
                <Divider />
              </Grid>

              <Grid item xs={6}>
                <NumericFormat
                  customInput={TextField}
                  size={'medium'}
                  label={'Percentuale canale diretti'}
                  disabled
                  variant="outlined"
                  value={bs.directChannelsPercentage ?? ''}
                  fullWidth
                  valueIsNumericString={false}
                  allowNegative={false}
                  suffix={' %'}
                  decimalScale={2}
                  decimalSeparator={','}
                />
              </Grid>
              <Grid item xs={6}>
                <NumericFormat
                  customInput={TextField}
                  size={'medium'}
                  disabled
                  label={'Percentuale OTA'}
                  variant="outlined"
                  value={bs.otaPercentage ?? ''}
                  fullWidth
                  valueIsNumericString={false}
                  allowNegative={false}
                  suffix={' %'}
                  decimalScale={2}
                  decimalSeparator={','}
                />
              </Grid>

              {bs.buildingSaleChannels.map((c, i) => (
                <Fragment key={i}>
                  <Grid item xs={6}>
                    <TextField disabled size={'medium'} label={'Nome canale'} fullWidth value={c.name ?? ''} />
                  </Grid>
                  <Grid item xs={6}>
                    <NumericFormat
                      customInput={TextField}
                      disabled
                      size={'medium'}
                      label={'Percentuale canale'}
                      variant="outlined"
                      name={`channels[${i}].percentage`}
                      value={c.percentage ?? ''}
                      fullWidth
                      valueIsNumericString={false}
                      allowNegative={false}
                      suffix={' %'}
                      decimalScale={2}
                      decimalSeparator={','}
                    />
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </Box>
        </>
      )}

      {bs != null && (
        <Box mt={4} textAlign={'end'} maxWidth={750}>
          <Button
            size={'medium'}
            disabled={buildingSaleExpireDays != null && buildingSaleExpireDays > 0}
            variant={'contained'}
            onClick={() =>
              openModal({
                title: 'Nuovi dati di vendita',
                content: <ModalNewBuildingSales refetchBuilding={refetchBuilding} />
              })
            }
          >
            Aggiungi nuovi dati di vendita
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BuildingInfoTab;
