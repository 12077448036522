import { Box } from '@mui/material';
import useModal from '../../hooks/useModal';
import { OpenModalProps } from '../../contexts/ModalContext';
import * as process from 'process';
export function useOpenFileModal() {
  const { openModal } = useModal();

  return (props: EmbedFileProps & Pick<OpenModalProps, 'title' | 'titleEllipse'>) => {
    const { fileCode, file, style, ...other } = props;

    openModal({
      ...other,
      content: <EmbedFile fileCode={fileCode} file={file} style={style} />,
      fullHeight: true,
      contentFullWidth: true
    });
  };
}

type EmbedFileProps = {
  fileCode?: string;
  file?: File;
  style?: React.CSSProperties;
};
const EmbedFile = ({ fileCode, file, style }: EmbedFileProps) => {
  // const theme = useTheme();
  // const isXL = useMediaQuery(theme.breakpoints.up('xl'));
  // const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  const src =
    fileCode != null
      ? `${process.env.REACT_APP_SERVER_URL}api/${process.env.REACT_APP_API_VERSION}/uploads/${fileCode}`
      : file != null
      ? URL.createObjectURL(file)
      : null;
  if (src == null) throw new Error('file and fileCode are nullable');

  return (
    <Box width={'100%'} height={'100%'} overflow={'hidden'}>
      <iframe
        src={src}
        title={'file'}
        style={{
          width: '100%',
          // minHeight: isXS ? '100%' : isXL ? '750px' : '450px',
          height: '100%',
          overflow: 'hidden',
          border: 'none',
          ...style
        }}
      ></iframe>
    </Box>
  );
};

export default EmbedFile;
