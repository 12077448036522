import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../store';
import { useMediaQuery, useTheme } from '@mui/material';

type JivoChatContextType = {
  jivoApi: typeof window.jivo_api;
};

export const JivoChatContext = createContext<JivoChatContextType | null>(null);
const JivoChatProvider = ({ children }: { children: JSX.Element }) => {
  const theme = useTheme();
  const [jivoApi, setJivoApi] = useState<typeof window.jivo_api>();
  const { pathname } = useLocation();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Crea lo script per JivoChat
    const jivoScript = document.createElement('script');
    jivoScript.src = '//code.jivosite.com/widget/UE3jA35NnQ';
    jivoScript.async = true;

    const handleLoadScript = function () {
      // Inizializza la costante jivo_api
      // console.log('jivo script loaded');

      // eslint-disable-next-line valid-typeof
      if (typeof window.jivo_api != null) {
        // console.log('setto jivo api', window.jivo_api);
        setJivoApi(window.jivo_api);
      }
    };

    // Aggiungi il gestore dell'evento 'load' per inizializzare JivoChat
    window.addEventListener('jivoLoadEvent', handleLoadScript);

    // Aggiungi lo script al documento
    document.head.appendChild(jivoScript);

    return () => {
      // Rimuovi lo script quando il componente viene smontato
      window.removeEventListener('jivoLoadEvent', handleLoadScript);
      document.head.removeChild(jivoScript);
    };
  }, []);

  useEffect(() => {
    const jivoIcon = document.getElementById('jivo_custom_widget');
    if (jivoIcon != null) {
      if (pathname.includes('new-order') || pathname.includes('notifications')) {
        if (isMobile) {
          jivoIcon.style.bottom = '10px';
          jivoIcon.style.left = '10px';
        } else {
          jivoIcon.style.bottom = '90px';
        }
        // jivoIcon.style.right = '50px';
      } else {
        // jivoIcon.style.right = '30px';
        jivoIcon.style.bottom = '30px';
      }
    }
    if (jivoApi) {
      const info = jivoApi.getContactInfo();
      if (isLoggedIn && user && info.client_name !== `${user.firstname} ${user.lastname}`) {
        console.log('setto contatto di jivo');
        jivoApi.setContactInfo({
          name: user.firstname != null && user.lastname != null ? `${user.firstname} ${user.lastname}` : '',
          email: user.email,
          phone: user.phone
        });

        jivoApi.setCustomData([
          {
            title: 'Nome Struttura',
            content: user.building.name ?? 'Ancora nessun nome'
          },
          {
            title: 'Ragione Sociale',
            content: user.building.businessName ?? 'Ancora nessun nome'
          },
          {
            title: 'Vai al profilo del cliente',
            content:
              user.building?.name != null
                ? `https://backoffice-dev.buyplus.biz/buildings/${user.building.id}/${user.building?.name?.replaceAll(' ', '-')}`
                : 'Nessun link disponibile'
          }
        ]);
      }
      // jivoApi.clearHistory();

      jivoApi.sendPageTitle(document.title, true, pathname);
    }
  }, [pathname, isLoggedIn, jivoApi]);

  return (
    <JivoChatContext.Provider
      value={{
        jivoApi
      }}
    >
      {children}
    </JivoChatContext.Provider>
  );
};

export default JivoChatProvider;
