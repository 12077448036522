import { useSelector } from 'react-redux';
import { KeyedObject, RootStateProps } from '../../types/root';
import { SnackbarKey, useSnackbar } from 'notistack';
import { closeSnackbar } from '../../store/reducers/snackbar';
import { dispatch } from '../../store';
import { Box, Fade, Grow, IconButton, Slide, SlideProps, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { SnackbarProps } from '../../types/snackbar';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

const CustomSnackbar = (props: SnackbarProps & { handleClose: (key: SnackbarKey) => void }) => {
  const { message, snackKey, persist, variant, handleClose } = props;

  return (
    <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
      <Box>
        {Array.isArray(message) ? (
          <>
            {message.map((item, index) => (
              <Typography key={index}>
                {message.length > 1 ? '• ' : ''}
                {item}
              </Typography>
            ))}
          </>
        ) : (
          <Typography variant="body2" px={0.5}>
            {message}
          </Typography>
        )}
      </Box>

      {(variant === 'warning' || variant === 'error' || persist) && (
        <Box>
          <IconButton
            color={'inherit'}
            onClick={() => handleClose(snackKey)}
            sx={{
              width: 30,
              height: 30
            }}
          >
            <Close fontSize={'small'} color={'inherit'} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const NotistackSnackbar = () => {
  const snackbar = useSelector((state: RootStateProps) => state.snackbar);
  const { snackKey, persist, anchorOrigin, open, transition, variant, message } = snackbar;
  const { enqueueSnackbar, closeSnackbar: closeNotistack } = useSnackbar();

  const handleClose = (key?: SnackbarKey) => {
    closeNotistack(key);
    dispatch(closeSnackbar());
  };

  useEffect(() => {
    if (open) {
      console.log('apro notifica di tipo: ', variant);
      enqueueSnackbar(variant !== 'notification' ? <CustomSnackbar {...snackbar} handleClose={handleClose} /> : message, {
        anchorOrigin,
        TransitionComponent: animation[transition],
        variant,
        onClose:
          persist != null
            ? persist
              ? variant === 'notification'
                ? (event, reason, key) => {
                    dispatch(closeSnackbar());
                  }
                : undefined
              : (event, reason, key) => {
                  dispatch(closeSnackbar());
                }
            : variant === 'success' || variant === 'info'
            ? (event, reason, key) => {
                dispatch(closeSnackbar());
              }
            : undefined,
        autoHideDuration: 3000,
        persist: persist != null ? persist : variant === 'warning' || variant === 'error' || variant === 'notification',
        key: snackKey,
        SnackbarProps: {
          style: {
            width: 'auto',
            minWidth: 250
          }
        }
      });
    }
  }, [open, snackKey]);

  return <></>;
};

export default NotistackSnackbar;
