import Chip from '@mui/material/Chip';
import { OrderStatus } from '../../../types/dto/orders.dto';

interface Props {
  isProduct: boolean;
  state: OrderStatus;
}

// export const orderStatusList: {
//   key: OrderStatus;
//   label: string;
//   color: string;
//   backgroundColor: string;
//   isProduct?: boolean;
//   isAlternativeOffer?: boolean;
// }[] = [
//   {
//     key: OrderStatus.Draft,
//     label: 'Bozza',
//     color: '#3E4142',
//     backgroundColor: '#EDF8F9'
//   },
//   {
//     key: OrderStatus.Filled,
//     color: '#094754',
//     backgroundColor: '#7DEBF5',
//     label: 'In verifica'
//   },
//   {
//     key: OrderStatus.Refused,
//     label: 'Richiesta rifiutata',
//     color: '#094754',
//     backgroundColor: '#F9969B'
//   },
//   {
//     key: OrderStatus.UserApproved,
//     label: 'Richiesta approvata',
//     color: '#094754',
//     backgroundColor: '#FFFDCB'
//   },
//   {
//     key: OrderStatus.AdminProcessing,
//     label: 'Elaborazione controproposta',
//     color: '#094754',
//     backgroundColor: '#FFFA91'
//   },
//   {
//     isAlternativeOffer: true,
//     key: OrderStatus.AdminProcessing,
//     label: 'Elaborazione proposta',
//     color: '#094754',
//     backgroundColor: '#FFFA91'
//   },
//   {
//     key: OrderStatus.AdminPublished,
//     label: 'Approva modulo',
//     color: '#094754',
//     backgroundColor: '#FFF630'
//   },
//   {
//     key: OrderStatus.AdminRefused,
//     label: 'Modulo rifiutato',
//     color: '#094754',
//     backgroundColor: '#FECDD2'
//   },
//   {
//     key: OrderStatus.Accepted,
//     label: 'Modulo accettato',
//     color: '#094754',
//     backgroundColor: '#D5FB6C'
//   },
//   {
//     key: OrderStatus.IssuedInvoice,
//     label: 'Paga acconto',
//     color: '#094754',
//     backgroundColor: '#FFC700'
//   },
//   {
//     key: OrderStatus.DepositPaid,
//     label: 'Collegamento al CM',
//     color: '#094754',
//     backgroundColor: '#E9A800'
//   },
//   {
//     key: OrderStatus.Setup,
//     label: 'Preparazione ordine',
//     color: '#094754',
//     backgroundColor: '#E7EAFF'
//   },
//   {
//     isProduct: true,
//     key: OrderStatus.InProgress,
//     label: 'Merce Spedita',
//     color: '#094754',
//     backgroundColor: '#BBC4FE'
//   },
//   {
//     isProduct: false,
//     key: OrderStatus.InProgress,
//     label: 'Servizio in corso',
//     color: '#094754',
//     backgroundColor: '#BBC4FE'
//   },
//   {
//     isProduct: true,
//     key: OrderStatus.Completed,
//     label: 'Merce consegnata',
//     color: '#094754',
//     backgroundColor: '#32D1AA'
//   },
//   {
//     isProduct: false,
//     key: OrderStatus.Completed,
//     label: 'Servizio terminato',
//     color: '#094754',
//     backgroundColor: '#32D1AA'
//   }
// ];

export const getOrderStatus = (
  props: Props
): {
  label: string;
  color: string;
  backgroundColor: string;
} | null => {
  const { state, isProduct } = props;
  switch (state) {
    case OrderStatus.Draft:
      return {
        label: 'Bozza',
        color: '#3E4142',
        backgroundColor: '#EDF8F9'
      };

    case OrderStatus.Filled:
      return {
        color: '#094754',
        backgroundColor: '#7DEBF5',
        label: 'In verifica'
      };

    case OrderStatus.Refused:
      return {
        label: 'Richiesta rifiutata',
        color: '#094754',
        backgroundColor: '#F9969B'
      };

    case OrderStatus.UserApproved:
      return {
        label: 'Elaborazione proposta',
        color: '#094754',
        backgroundColor: '#FFFDCB'
      };

    case OrderStatus.AdminProcessing:
      return {
        label: 'Elab. controproposta',
        color: '#094754',
        backgroundColor: '#FFFA91'
      };

    case OrderStatus.AdminPublished:
      return {
        label: 'Approva modulo',
        color: '#094754',
        backgroundColor: '#FFF630'
      };

    case OrderStatus.AdminRefused:
      return {
        label: 'Modulo rifiutato',
        color: '#094754',
        backgroundColor: '#FECDD2'
      };

    case OrderStatus.Accepted:
      return {
        label: 'Modulo accettato',
        color: '#094754',
        backgroundColor: '#D5FB6C'
      };

    case OrderStatus.IssuedInvoice:
      return {
        label: 'Paga acconto',
        color: '#094754',
        backgroundColor: '#FFC700'
      };

    case OrderStatus.DepositPaid:
      return {
        label: 'Collegamento al CM',
        color: '#094754',
        backgroundColor: '#E9A800'
      };

    case OrderStatus.Setup:
      return {
        label: 'Preparazione ordine',
        color: '#094754',
        backgroundColor: '#E7EAFF'
      };

    case OrderStatus.InProgress:
      if (isProduct) {
        return {
          label: 'Merce Spedita',
          color: '#094754',
          backgroundColor: '#BBC4FE'
        };
      } else {
        return {
          label: 'Servizio in corso',
          color: '#094754',
          backgroundColor: '#BBC4FE'
        };
      }

    case OrderStatus.Completed:
      if (isProduct) {
        return {
          label: 'Merce consegnata',
          color: '#094754',
          backgroundColor: '#32D1AA'
        };
      } else {
        return {
          label: 'Servizio terminato',
          color: '#094754',
          backgroundColor: '#32D1AA'
        };
      }

    default:
      return null;
  }
};

const OrderChip = (props: Props) => {
  // const { isAlternativeOffer, isProduct, state } = props;

  const chipConfig = getOrderStatus(props);

  if (chipConfig == null) return <></>;
  return (
    <Chip
      sx={{
        width: 165,
        paddingX: '6px',
        fontWeight: 400,
        color: chipConfig.color,
        backgroundColor: chipConfig.backgroundColor
      }}
      size={'small'}
      label={chipConfig.label}
    />
  );
};

export default OrderChip;
