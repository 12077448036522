import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuardProps } from 'types/auth';
import { useSelector } from 'store';

// ==============================|| GUEST GUARD ||============================== //
const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isLoggedIn && !pathname.includes('verify-email')) {
      navigate('/dashboard', { replace: true });
    }
  }, [isLoggedIn]);

  if (pathname.includes('verify-email')) {
    return children;
  }

  return !isLoggedIn ? children : <></>;
};

export default GuestGuard;
