import { ListItemText, ListItemTextProps, styled } from '@mui/material';
import { JSX } from 'react';

type SummaryListItemProps = Omit<ListItemTextProps, 'primary' | 'secondary'> & {
  primary: JSX.Element | string;
  secondary: JSX.Element | string;
};

const ListItemTextSummary = styled(ListItemText)(({ theme }) => ({
  width: '100%',
  padding: 0,
  display: 'flex',
  margin: 0,
  marginBottom: '1.5px',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const SummaryListItemText = (props: SummaryListItemProps) => {
  const { primary, secondary, primaryTypographyProps, secondaryTypographyProps, ...other } = props;
  return (
    <ListItemTextSummary
      primary={primary}
      secondary={secondary}
      primaryTypographyProps={{ variant: 'body2', color: 'text.disabled', ...primaryTypographyProps }}
      secondaryTypographyProps={{
        variant: 'body1',
        color: 'text.primary',
        sx: { whiteSpace: 'normal', ...secondaryTypographyProps?.sx }
      }}
      {...other}
    />
  );
};

export default SummaryListItemText;
