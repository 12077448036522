import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const DashboardIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 22.2955H24V16.092V8.86913V5.26147H1V22.2955Z" fill="#094754" />
      <path d="M4.2616 2V5.26164H24C24 3.46015 22.5395 2 20.7383 2H4.2616Z" fill="#91DCF9" />
      <path d="M4.26164 2H1V5.26164H4.26164V2Z" fill="#49EBAA" />
      <path d="M21.4327 11.6985H15.3597V20.2501H21.4327V11.6985Z" fill="#91DCF9" />
      <path d="M13.151 17.1892H3.81531V20.25H13.151V17.1892Z" fill="#91DCF9" />
      <path d="M21.4327 7.49121H4.2616V9.77486H21.4327V7.49121Z" fill="#91DCF9" />
      <path
        d="M5.69405 15.456C6.73166 15.456 7.5728 14.6148 7.5728 13.5772C7.5728 12.5396 6.73166 11.6985 5.69405 11.6985C4.65645 11.6985 3.81531 12.5396 3.81531 13.5772C3.81531 14.6148 4.65645 15.456 5.69405 15.456Z"
        fill="#F0FF32"
      />
      <path
        d="M11.2727 15.456C12.3103 15.456 13.1514 14.6148 13.1514 13.5772C13.1514 12.5396 12.3103 11.6985 11.2727 11.6985C10.2351 11.6985 9.39392 12.5396 9.39392 13.5772C9.39392 14.6148 10.2351 15.456 11.2727 15.456Z"
        fill="#F0FF32"
      />
    </svg>
  </SvgIcon>
);

export default DashboardIcon;
