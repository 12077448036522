// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import MobileDrawerStyled from './MobileDrawerStyled';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const MainDrawer = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  // responsive drawer container
  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box id={'navigation-drawer'} component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}>
      {!matchDownSM ? (
        //Drawer per Desktop
        <MiniDrawerStyled variant="permanent" open={open}>
          <DrawerHeader open={open} handleDrawerToggle={handleDrawerToggle} />
          <DrawerContent />
        </MiniDrawerStyled>
      ) : (
        // Drawer Per Mobile
        <MobileDrawerStyled
          anchor="right"
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' }
          }}
        >
          <DrawerHeader open={open} handleDrawerToggle={handleDrawerToggle} />
          <DrawerContent />
        </MobileDrawerStyled>
      )}
    </Box>
  );
};

export default MainDrawer;
