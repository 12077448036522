export default function formatNumber(
  num: number,
  options?: { fractionDigits: number } & Omit<Intl.NumberFormatOptions, 'minimumFractionDigits' | 'maximumFractionDigits'>
) {
  const { fractionDigits, ...other } = options ?? {};
  return num.toLocaleString('it', {
    minimumFractionDigits: fractionDigits ?? 0,
    maximumFractionDigits: fractionDigits ?? 0,
    ...other
  });
}
