import { Box, Drawer, styled } from '@mui/material';
import HeaderNotificationDrawer from './HeaderDrawer/HeaderNotificationDrawer';
import { memo, useEffect } from 'react';
import { NotificationDTO } from 'types/dto/notification.dto';
import { CountedData } from 'types/server/counted-data';
import ContentNotifcationDrawer from './ContentDrawer/ContentNotifcationDrawer';
import { useEndpoint } from '../../../hooks/useEndpoint';
import { dispatch, useSelector } from '../../../store';
import { setNotificationCount, setRefetchNotifications } from '../../../store/reducers/websocket';
import { formatSorting } from '../../../utils/filtersUtils/formatSorting';

type NotificationDrawerProps = {
  open: boolean;
  window?: () => Window;
  handleDrawerToggle?: () => void;
};

const NotificationDrawerStyle = styled(Drawer)(() => ({
  width: '100%',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    backgroundColor: '#FFFFFF',
    width: 360,
    boxSizing: 'border-box',
    backgroundImage: 'none',
    boxShadow: 'inherit'
  }
}));

const NotificationDrawer = ({ open, handleDrawerToggle, window }: NotificationDrawerProps) => {
  const container = window !== undefined ? () => window().document.body : undefined;
  const { refetchNotifications, notificationCount } = useSelector((state) => state.websocket);
  const { holder } = useSelector((state) => state.auth);
  // const { sendNotification } = useNotification();
  const getNotifications = useEndpoint<CountedData<NotificationDTO>, 'get'>({
    method: 'get',
    endpoint: '/notifications',
    queryKey: 'get-drawer-notifications',
    queryParams: {
      skip: 0,
      take: 15,
      orderBy: formatSorting([{ id: 'at', desc: true }])
    }
  });

  const readNotifications = useEndpoint<{ ids: number[] } | undefined, 'put'>({
    method: 'put',
    mutationKey: 'read-notifications',
    endpoint: '/notifications/readings',
    options: {
      onSuccess: (data, variables) => {
        getNotifications.refetch();
        dispatch(
          setRefetchNotifications({
            ...refetchNotifications,
            refetchPage: true
          })
        );
        dispatch(setNotificationCount(variables != null ? notificationCount - variables.ids.length : 0));
      }
    }
  });

  useEffect(() => {
    if (refetchNotifications.refetchDrawer) {
      getNotifications.refetch().then(() => {
        dispatch(
          setRefetchNotifications({
            ...refetchNotifications,
            refetchDrawer: false
          })
        );
      });
    }
  }, [refetchNotifications.refetchDrawer]);

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1100 }} aria-label="notification folders">
      <NotificationDrawerStyle
        data-tour={'notification-drawer'}
        container={container}
        anchor="right"
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
      >
        <Box width={'100%'} height={'100%'} display={'flex'} flexDirection={'column'}>
          <HeaderNotificationDrawer />
          <ContentNotifcationDrawer
            canRead={holder == null}
            notifications={getNotifications.data?.data.data}
            handleReadNotification={(id) => {
              readNotifications.mutate({
                ids: [id]
              });
            }}
          />
        </Box>
      </NotificationDrawerStyle>
    </Box>
  );
};

export default memo(NotificationDrawer);
