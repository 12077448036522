import { Box, Fade, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FileLoader from './FileLoader';
import UploadIcon from './BuyPlusIcons/UploadIcon';

const ListFakeLoaderFile = ({ files }: { files: File[] }) => {
  return (
    <Box>
      <List disablePadding>
        {files.length > 0 &&
          files.map((file, i) => (
            <Fade in key={i}>
              <ListItem divider sx={{ my: 2 }}>
                <ListItemIcon>
                  <UploadIcon sx={{ fontSize: '40px' }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }} fontWeight={600}>
                    {file.name}
                  </Typography>
                  <FileLoader size={file.size} />
                </ListItemText>
              </ListItem>
            </Fade>
          ))}
      </List>
    </Box>
  );
};

export default ListFakeLoaderFile;
