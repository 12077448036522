import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const InfoIcon = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9997 12.5C23.9997 18.8507 18.8507 24 12.4997 24C6.14871 24 1 18.8507 1 12.5C1 6.1493 6.149 1 12.5 1C18.851 1 24 6.149 24 12.5H23.9997Z"
        fill="#094754"
      />
      <path
        d="M12.4999 8.66117C13.3426 8.66117 14.0257 7.97806 14.0257 7.13539C14.0257 6.29273 13.3426 5.60962 12.4999 5.60962C11.6572 5.60962 10.9741 6.29273 10.9741 7.13539C10.9741 7.97806 11.6572 8.66117 12.4999 8.66117Z"
        fill="#F0FF32"
      />
      <path d="M13.5636 10.1152H9.8407V12.2424H13.5636V10.1152Z" fill="#7DEBF5" />
      <path d="M13.5633 10.1152H11.436V19.1565H13.5633V10.1152Z" fill="#7DEBF5" />
      <path d="M15.6909 17.0288H9.30896V19.156H15.6909V17.0288Z" fill="#7DEBF5" />
      <path d="M13.5633 10.1152H11.436V12.2424H13.5633V10.1152Z" fill="#7DEBF5" />
      <path d="M13.5633 17.0288H11.436V19.156H13.5633V17.0288Z" fill="#49EBAA" />
    </svg>
  </SvgIcon>
);

export default InfoIcon;
