export const getIsColorDark = (hexColor: string) => {
  // Rimuoviamo eventuali caratteri "#" dal codice esadecimale
  hexColor = hexColor.replace('#', '');

  // Convertiamo il codice esadecimale in valori RGB
  const red = parseInt(hexColor.substr(0, 2), 16);
  const green = parseInt(hexColor.substr(2, 2), 16);
  const blue = parseInt(hexColor.substr(4, 2), 16);

  // Calcoliamo la luminosità del colore (valore compreso tra 0 e 255)
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  // Ora possiamo decidere se il colore è chiaro o scuro
  // Qui, usiamo una soglia arbitraria (125) per considerare il colore come "chiaro"
  // Puoi regolare questa soglia in base alle tue preferenze
  return brightness < 150;
};
