import { Box, Grid, Skeleton, Stack } from '@mui/material';

const DashboardSkeleton = () => {
  return (
    <Box width={'100%'}>
      <Stack width={'100%'} maxWidth={500}>
        <Skeleton variant={'text'} height={35} />
        <Skeleton variant={'text'} />
      </Stack>
      <Grid container sx={{ width: '100%', mb: 5, mt: 1 }} spacing={3}>
        {/*Main Column*/}
        <Grid item xs={12} md={8}>
          <Grid container rowSpacing={3} columnSpacing={3}>
            <Grid item xs={12}>
              <Skeleton variant={'rounded'} width={'100%'} height={'300px'} />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant={'rounded'} width={'100%'} height={'500px'} />
            </Grid>

            {/* Promo card */}
            <Grid item xs={12} md={6} textAlign={'center'}>
              <Skeleton variant={'rounded'} width={'100%'} height={'250px'} />
            </Grid>
            <Grid item xs={12} md={6} textAlign={'center'}>
              <Skeleton variant={'rounded'} width={'100%'} height={'250px'} />
            </Grid>
          </Grid>
        </Grid>

        {/*SideColumn*/}
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Skeleton variant={'rounded'} width={'100%'} height={'300px'} />
            </Grid>

            <Grid item xs={12} mt={3}>
              <Skeleton variant={'rounded'} width={'100%'} height={'300px'} />
            </Grid>

            <Grid item xs={12} mt={3}>
              <Skeleton variant={'rounded'} width={'100%'} height={'300px'} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardSkeleton;
