import { CityDTO, CountryDTO, PostalCodeDTO, ProvinceDTO, RegionDTO } from '../formik/manual-integration-step';

export type BuildingDTO = {
  id: number;
  name: string | null;
  businessName: string | null;
  phone: string | null;
  vat: string;
  address: string | null;
  city: CityDTO | null;
  cap: PostalCodeDTO | null;
  province: ProvinceDTO | null;
  region: RegionDTO | null;
  country: CountryDTO | null;
  pmsExportsDoneAt: string | null;
  pendingOrdersCount: number;
  draftOrdersCount: number;
  registrationMethod: BuildingRegistrationMethodEnum | null; //Metodi della registrazione
  registrationConfirmed: boolean; //Ha completato tutti gli step della registrazione
  lastEdit: string;
};

export enum BuildingRegistrationMethodEnum {
  Pms = 'pms',
  Manual = 'manual',
  Report = 'report'
}
