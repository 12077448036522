// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import helpersAuth from './helpersAuth';
import websocket from './websocket';
import auth from './auth';
import breadcrumbHelper from './breadcrumbHelper';
import appInfo from './appInfo';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  helpersAuth,
  websocket,
  auth,
  breadcrumbHelper,
  appInfo
});

export default reducers;
