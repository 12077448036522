import { Autocomplete, AutocompleteProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ServerListSelector } from 'types/server-list-selector';
import { CountedData } from 'types/server/counted-data';
import { useEndpoint } from '../../hooks/useEndpoint';
import { debounce } from 'lodash';

type AsyncAutoCompleteProps<T, R = T> = ServerListSelector<T, R> &
  Omit<AutocompleteProps<R, boolean, boolean, boolean>, 'loading' | 'options' | 'onChange'> & {
    onChange?: (item: R | NonNullable<string | R> | (string | R)[] | null, serverOptions: T[]) => void;
    inputChangeFilterField?: string;
  };

export default function AsyncAutoComplete<T, R = T>(props: AsyncAutoCompleteProps<T, R>) {
  const { mapServerResponse, inputChangeFilterField, endpoint, query, onChange, flatMapServerResponse, ...otherProps } = props;
  const [options, setOptions] = useState<R[]>([]);
  const [loading, setLoading] = useState(!props.freeSolo);
  const [textValue, setTextValue] = useState<string>('');
  const autoCompleteValueRef = useRef<R | NonNullable<string | R> | (string | R)[] | null>();
  const fetch = useEndpoint<CountedData<T>, 'get'>({
    method: 'get',
    endpoint,
    queryKey: `get-${endpoint}`,
    queryParams:
      query && inputChangeFilterField == null
        ? query
        : inputChangeFilterField != null
        ? {
            filterBy:
              textValue.length > 0 ? JSON.stringify([{ field: inputChangeFilterField, value: { contains: textValue } }]) : undefined,
            ...(query != null ? query : {})
          }
        : undefined,
    options: {
      enabled: !props.disabled && !props.freeSolo
      // cacheTime: 0
    }
  });

  useEffect(() => {
    if (fetch.data?.data) {
      setLoading(false);
      if (mapServerResponse) {
        setOptions(fetch.data.data.data.map(mapServerResponse));
      } else if (flatMapServerResponse) {
        setOptions(fetch.data.data.data.flatMap(flatMapServerResponse));
      } else {
        setOptions(fetch.data.data.data as any as R[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch.data?.data]);

  useEffect(() => {
    if (!props.disabled && !props.freeSolo) {
      fetch.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled, props.freeSolo]);

  const handleInputChange = debounce((value: string) => {
    if (inputChangeFilterField != null) {
      setTextValue(value);
    }
  }, 200);

  // useEffect(() => {
  //   if (inputChangeFilterField && autoCompleteValueRef.current == null) {
  //     console.log(textValue, 'refetcho il textvalue');
  //     fetch.refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [textValue]);

  return (
    <Autocomplete<R, boolean, boolean, boolean>
      {...otherProps}
      slotProps={{
        popper: {
          sx: { display: props.freeSolo ? 'none' : undefined }
        }
      }}
      disablePortal={true}
      disableClearable={props.freeSolo || props.disableClearable}
      filterOptions={inputChangeFilterField != null ? (options) => options : undefined}
      onBlur={(event) => {
        if (autoCompleteValueRef.current == null && options.length === 0) {
          fetch.refetch();
        }
        if (props.onBlur) props.onBlur(event);
      }}
      // options={options?.length ? options : props.value ? [props.value as R] : []}
      options={loading || fetch.isFetching ? [] : options}
      loading={loading || fetch.isFetching}
      onChange={
        onChange
          ? (e, i) => {
              autoCompleteValueRef.current = i;
              onChange(i, fetch.data?.data.data ?? []);
            }
          : undefined
      }
      onInputChange={(e, value, reason) => {
        if (props.onInputChange) props.onInputChange(e, value, reason);
        if (inputChangeFilterField == null) return;
        // debouncedOnChange(value);
        setLoading(true);
        handleInputChange(value);
      }}
      // inputValue={autoCompleteValueRef.current != null ? undefined : textValue}
    />
  );
}
