import { OrderDTO } from '../../../../../../types/dto/orders.dto';
import { Grid, Typography } from '@mui/material';

type BillingReviewProps = {
  billingInfo: OrderDTO['billingInfo'];
};

const BillingReview = ({ billingInfo }: BillingReviewProps) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      // sx={{
      //   position: 'relative',
      //   '&::after': {
      //     content: '""',
      //     display: { xs: 'none', md: 'block' },
      //     position: 'absolute',
      //     top: '50%',
      //     transform: 'translateY(-50%)',
      //     height: '90%',
      //     right: 0,
      //     bottom: 0,
      //     width: '1px',
      //     backgroundColor: 'divider'
      //   }
      // }}
    >
      <Typography variant={'h6'} fontWeight={400} color={'text.disabled'}>
        Dati Fatturazione
      </Typography>

      <Grid container maxWidth={500} mt={1} rowSpacing={1}>
        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Ragione sociale
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'}>{billingInfo?.name}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Indirizzo struttura
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'}>{`${billingInfo?.address}(${billingInfo?.city.name.it})`}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Cod. fiscale
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'}>{billingInfo?.taxCode}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant={'body2'} color={'grey.500'}>
            Partita Iva
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'body2'}>{billingInfo?.vat}</Typography>
        </Grid>

        {billingInfo?.iban && (
          <>
            <Grid item xs={6}>
              <Typography variant={'body2'} color={'grey.500'}>
                Iban
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{billingInfo?.iban}</Typography>
            </Grid>
          </>
        )}

        {billingInfo?.pec && (
          <>
            <Grid item xs={6}>
              <Typography variant={'body2'} color={'grey.500'}>
                Pec
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{billingInfo?.pec}</Typography>
            </Grid>
          </>
        )}

        {billingInfo?.recipientCode && (
          <>
            <Grid item xs={6}>
              <Typography variant={'body2'} color={'grey.500'}>
                Cod. destinatario
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'body2'}>{billingInfo?.recipientCode}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default BillingReview;
