import { dispatch } from '../store';
import { openSnackbar } from '../store/reducers/snackbar';

export default function useNotification() {
  const notificationSound = new Audio('/sounds/notification_sound.mp3');

  const openLocalNotification = (message: string) => {
    dispatch(
      openSnackbar({
        message,
        variant: 'notification'
      })
    );
    notificationSound.play().catch((reason) => {
      console.log(reason);
    });
  };

  const openBrowserNotification = (message: string) => {
    new Notification(message, {
      icon: `/icons/icon-512x512.png`,
      badge: `/icons/icon-512x512.png`,
      image: '/icons/icon-512x512.png',
      body: 'Messaggio della notifica',
      silent: false,
      vibrate: [200, 100, 200]
    });
  };

  const sendNotification = async (message: string) => {
    openLocalNotification(message);

    if (!document.hasFocus() && 'Notification' in window && Notification.permission !== 'denied') {
      if (Notification.permission === 'granted') {
        openBrowserNotification(message);
      } else if (Notification.permission === 'default') {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
          openBrowserNotification(message);
        } else {
          openLocalNotification(message);
        }
      }
    }
  };

  return {
    sendNotification
  };
}
