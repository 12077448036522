import { MenuItem, Select, SelectProps } from '@mui/material';
import { JSX, useEffect, useState } from 'react';
import { ServerListSelector } from 'types/server-list-selector';
import { CountedData } from 'types/server/counted-data';
import { useEndpoint } from '../../hooks/useEndpoint';

interface AsyncSelectProps<T, R = T> extends SelectProps, ServerListSelector<T, R> {
  renderMenuItem: (value: R) => { value: number | string | T; label: string };
  setIsCustom?: (value: boolean) => void;
  renderCustomValueItem?: () => R;
  defaultList?: (options: R[]) => JSX.Element | JSX.Element[];
}

export default function AsyncSelect<T, R = T>(props: AsyncSelectProps<T, R>) {
  const { endpoint, setIsCustom, renderMenuItem, query, value, defaultList, mapServerResponse, renderCustomValueItem, ...other } = props;
  const [options, setOptions] = useState<R[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // const fetch = useServer<CountedData<T>>('get', endpoint, `get-${endpoint}`, query ?? {}, true);
  const fetch = useEndpoint<CountedData<T>, 'get'>({
    method: 'get',
    endpoint,
    queryKey: `get-async-select-${endpoint}`,
    queryParams: query ?? undefined
  });

  useEffect(() => {
    if (fetch.data?.data) {
      if (mapServerResponse) {
        setOptions(fetch.data.data.data.map(mapServerResponse));
      } else {
        setOptions(fetch.data.data.data as any as R[]);
      }
      setIsLoading(false);
    }
  }, [fetch.data?.data]);

  return (
    <Select {...other} value={!isLoading ? value : ''}>
      {isLoading && <MenuItem>Caricamento...</MenuItem>}
      {!isLoading &&
        defaultList == null &&
        options.map((item, i) => {
          const { label, value } = renderMenuItem(item);
          return (
            <MenuItem
              key={i}
              value={JSON.stringify(value)}
              onClick={() => {
                if (setIsCustom) {
                  setIsCustom(false);
                }
              }}
            >
              {label}
            </MenuItem>
          );
        })}

      {!isLoading && defaultList != null && defaultList(options)}
      {!isLoading && !!setIsCustom && (
        <MenuItem
          value={!!renderCustomValueItem ? JSON.stringify(renderCustomValueItem()) : JSON.stringify({ id: -1, name: 'other' })}
          onClick={() => {
            if (setIsCustom) {
              setIsCustom(true);
            }
          }}
        >
          Altro...
        </MenuItem>
      )}
    </Select>
  );
}
