import { Box, Button, Fade, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import DashboardChart from '../../sections/dashboard/DashboardChart';
import BoxLearning from '../../sections/dashboard/BoxLearning';
import BoxAccount from '../../sections/dashboard/BoxAccount';
import BoxActivity from '../../sections/dashboard/BoxActivity';
import BoxOrders from '../../sections/dashboard/BoxOrders';
import { useEndpoint } from '../../hooks/useEndpoint';
import { dispatch, useSelector } from '../../store';
import { GeneralWalletDto } from '../../types/dto/general-wallet.dto';
import { SingleDataDTO } from '../../types/server/single-data';
import { CountedData } from '../../types/server/counted-data';
import { OrderListDto } from '../../types/dto/order-list.dto';
import { formatSorting } from '../../utils/filtersUtils/formatSorting';
import { FaqDto, FaqSection } from '../../types/dto/faq.dto';
import getExtraDocumentsToUpload from '../../utils/getExtraDocumentsToUpload';
import PromoCard from '../../components/custom/Cta/PromoCard';
import UploadIcon from '../../components/custom/BuyPlusIcons/UploadIcon';
import { Link as RouterLink } from 'react-router-dom';
import { WalletDTO } from '../../types/dto/plafond.dto';
import { ClientActionEnum, ClientTypeEnum } from '../../types/dataToSend/clientLog';
import useNavigationTracker from '../../hooks/useNavigationTracker';
import PartnerIcon from '../../components/custom/BuyPlusIcons/PartnerIcon';
import AlertDeletedAccount from '../../sections/settings/AlertDeletedAccount';
import { useEffect } from 'react';
import { setRefetchOrders, setRefetchWallets } from '../../store/reducers/websocket';
import DashboardSkeleton from '../../components/custom/Skeletons/DashboardSkeleton';
import { useTour } from '@reactour/tour';
import { ContactFormPresetEnum } from '../../utils/contactFormPreset';

const NewDashboardPage = () => {
  const { user } = useSelector((state) => state.auth);
  const { trackAction } = useNavigationTracker();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const tour = useTour();
  const getWalletCredit = useEndpoint<SingleDataDTO<GeneralWalletDto>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${user?.building.id}/credit`,
    queryKey: 'get-building-credit'
  });

  const getWallets = useEndpoint<SingleDataDTO<WalletDTO[]>, 'get'>({
    method: 'get',
    endpoint: `/buildings/${user?.building.id}/wallets`,
    queryKey: 'get-wallets-dashboard',
    options: {
      cacheTime: 0,
      enabled: true
    }
  });

  const getFaqs = useEndpoint<CountedData<FaqDto>, 'get'>({
    method: 'get',
    endpoint: '/faqs',
    queryKey: `get-faqs-dashboard`,
    queryParams: {
      filterBy: JSON.stringify([{ field: 'sections', value: { equals: FaqSection.DASHBOARD } }])
    }
  });

  const { data: getLastOrders, refetch: refetchLastOrders } = useEndpoint<CountedData<OrderListDto>, 'get'>({
    method: 'get',
    queryParams: {
      skip: 0,
      take: 3,
      orderBy: formatSorting([
        {
          id: 'createdAt',
          desc: true
        }
      ])
    },
    queryKey: 'get-last-orders-dashboard',
    endpoint: `buildings/${user!.building.id}/orders`,
    options: {
      enabled: true,
      cacheTime: 0
    }
  });

  const getLastDraftOrders = useEndpoint<CountedData<OrderListDto>, 'get'>({
    method: 'get',
    queryParams: {
      skip: 0,
      take: 4,
      orderBy: formatSorting([
        {
          id: 'createdAt',
          desc: true
        }
      ])
      // filterBy: JSON.stringify([
      //   {
      //     field: ['state', 'key'],
      //     value: {
      //       equals: 'draft'
      //     }
      //   }
      // ])
    },
    queryKey: 'get-last-draft-orders',
    endpoint: `buildings/${user!.building.id}/orders`,
    options: {
      enabled: true,
      cacheTime: 0
    }
  });

  const registrationProgress = useEndpoint<
    SingleDataDTO<{
      user: {
        total: number;
        count: number;
      };
      building: {
        total: number;
        count: number;
      };
    }>,
    'get'
  >({
    method: 'get',
    endpoint: 'auth/registration-progress',
    queryKey: 'registration-progress-dashboard',
    options: {
      cacheTime: 0
    }
  });

  const documentsRequests = useEndpoint<SingleDataDTO<{ total: number; count: number }>, 'get'>({
    method: 'get',
    endpoint: `buildings/${user?.building.id}/documents-progress`,
    queryKey: 'get-documents-requests-count',
    options: {
      cacheTime: 0
    }
  });

  const { refetchWallets, refetchOrders } = useSelector((state) => state.websocket);
  useEffect(() => {
    if (refetchWallets) {
      (async () => {
        await getWallets.refetch();
        await getWalletCredit.refetch();
        dispatch(setRefetchWallets(false));
      })();
    }

    if (refetchOrders.refetch) {
      (async () => {
        await refetchLastOrders();
        await getLastDraftOrders.refetch();
        dispatch(
          setRefetchOrders({
            refetch: false,
            subjects: null,
            userId: null
          })
        );
      })();
    }
  }, [refetchWallets, refetchOrders]);

  useEffect(() => {
    if (tour.isOpen && tour.setSteps) {
      tour.setSteps([
        {
          selector: '#main',
          content: 'Questa è la tua dashboard, qui hai tutto ciò che ti interessa a portata di mano'
        },
        {
          selector: '[data-tour="dashboard-chart"]',
          content:
            'Qui hai a disposizione il grafico sul completamento dei tuoi dati, gli ordini effettuati e un riepilogo generale del tuo wallet'
        },
        {
          selector: '[data-tour="activity-box"]',
          content: "Qui hai una checklist delle azioni consigliate per ottimizzare l'utilizzo della piattaforma"
        },
        {
          selector: '[data-tour="box-learning"]',
          content: 'Queste sono le domande più frequenti e del materiale che potrebbe esserti utile'
        }
      ]);
      tour.setCurrentStep(0);
    }
  }, [tour.isOpen]);

  if (
    getWalletCredit.data?.data == null ||
    getWallets.data?.data == null ||
    getLastOrders?.data.data == null ||
    getFaqs.data?.data.data == null ||
    registrationProgress.data?.data?.data == null ||
    documentsRequests.data?.data.data == null ||
    getLastDraftOrders.data?.data.data == null ||
    user == null
  ) {
    return <DashboardSkeleton />;
  }

  return (
    <Fade in>
      <Box width={'100%'}>
        <Typography variant={'h4'} fontWeight={400} gutterBottom>
          Benvenuto nella tua dashboard, {user.firstname}
        </Typography>
        <Grid container sx={{ width: '100%', mb: 5, mt: 1 }} rowSpacing={3} columnSpacing={{ xs: 0, md: 3 }}>
          {user.deleteRequestAt != null && (
            <Grid item xs={12}>
              <AlertDeletedAccount />
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} data-tour={'dashboard-chart'}>
                <DashboardChart
                  registration={{
                    ...registrationProgress.data.data.data,
                    documents: documentsRequests.data.data.data
                  }}
                  orders={{
                    total: user.building.pendingOrdersCount + user.building.draftOrdersCount,
                    completed: user.building.pendingOrdersCount
                  }}
                  wallet={{
                    usedAmount: getWalletCredit.data.data.data.usedAmount,
                    availableAmount: getWalletCredit.data.data.data.availableAmount,
                    state: getWalletCredit.data.data.data.state
                  }}
                />
              </Grid>

              <Grid item xs={12} data-tour={'activity-box'}>
                <BoxActivity
                  isMini={isMobile}
                  registrationData={{
                    ...registrationProgress.data.data.data,
                    documents: documentsRequests.data.data.data,
                    registrationCompleted: user.$.registrationCompleted,
                    registrationMethod: user.building.registrationMethod,
                    registrationConfirmed: user.building.registrationConfirmed
                  }}
                  orderData={{
                    totalCount: +user.building.draftOrdersCount + +user.building.pendingOrdersCount,
                    pendingCount: +user.building.pendingOrdersCount,
                    lastOrder: getLastDraftOrders.data.data.data[0]
                  }}
                />
              </Grid>

              {getLastOrders.data.count > 0 && (
                <Grid item xs={12}>
                  <BoxOrders orders={getLastOrders.data.data} />
                </Grid>
              )}

              {!user.building.registrationConfirmed && (
                <Grid item xs={12} md={6}>
                  <BoxAccount />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <PromoCard
                  bgColor="#E3F9F8"
                  borderColor="#90E7E2"
                  title="Scopri i nostri partner"
                  description="Vuoi saperne di più o hai bisogno di supporto?"
                  customIcon={<PartnerIcon sx={{ fontSize: 50 }} />}
                  action={
                    <Button
                      variant="outlined"
                      color="primary"
                      component={Link}
                      target={'_blank'}
                      href={`${process.env.REACT_APP_LANDING_URL}/soluzioni-credito-hotel`}
                      onClick={() =>
                        trackAction({
                          action: ClientActionEnum.Clicked,
                          type: ClientTypeEnum.Link,
                          name: 'Scopri di più box partner'
                        })
                      }
                    >
                      Scopri di più
                    </Button>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12} data-tour={'box-learning'}>
                <BoxLearning faqs={getFaqs.data.data.data} />
              </Grid>

              {getExtraDocumentsToUpload(getWallets.data.data.data) && (
                <Grid item xs={12}>
                  <PromoCard
                    bgColor="#E3F9F8"
                    borderColor="#90E7E2"
                    title="Carica i documenti"
                    customIcon={<UploadIcon sx={{ fontSize: 50 }} />}
                    description="Inserisci i documenti necessari per completare la tua richiesta di ordine."
                    action={
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={'/account/documents'}
                        onClick={() => {
                          trackAction({
                            action: ClientActionEnum.Clicked,
                            type: ClientTypeEnum.Link,
                            name: `Inserisci i documenti mancanti dal box carica i documenti`
                          });
                        }}
                      >
                        Upload
                      </Button>
                    }
                  />
                </Grid>
              )}

              {/* Promo Contattaci */}
              <Grid item xs={12}>
                <PromoCard
                  bgColor="#F0FF32"
                  borderColor="#ABB800"
                  title="Siamo qui per te"
                  description="Vuoi saperne di più o hai bisogno di supporto?"
                  action={
                    <Button
                      variant="outlined"
                      color="primary"
                      component={RouterLink}
                      state={{
                        preset: ContactFormPresetEnum.Dashboard
                      }}
                      to={'/contacts'}
                      onClick={() =>
                        trackAction({
                          action: ClientActionEnum.Clicked,
                          type: ClientTypeEnum.Link,
                          name: 'Contattaci'
                        })
                      }
                    >
                      Contattaci
                    </Button>
                  }
                />
              </Grid>
            </Grid>

            {/*<MainCard*/}
            {/*  padding={'0'}*/}
            {/*  title={*/}
            {/*    <Typography variant={'body1'} fontWeight={600} textTransform={'uppercase'}>*/}
            {/*      Scopri come integrare il tuo pms*/}
            {/*    </Typography>*/}
            {/*  }*/}
            {/*>*/}
            {/*  <iframe*/}
            {/*    style={{*/}
            {/*      border: 'none'*/}
            {/*    }}*/}
            {/*    width="100%"*/}
            {/*    height="315"*/}
            {/*    src="https://www.youtube.com/embed/tgbNymZ7vqY"*/}
            {/*  ></iframe>*/}
            {/*</MainCard>*/}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default NewDashboardPage;
