import { Theme } from '@mui/material/styles';
import { alpha, Components } from '@mui/material';

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        // root: {
        //   fontSize: '1rem',
        //   fontWeight: 600,
        //   color: theme.palette.primary.main
        // }
        outlined: {
          color: alpha(theme.palette.primary.main, 0.5)
        },
        standard: {
          fontSize: '1rem',
          fontWeight: 600,
          marginBottom: '10px',
          color: theme.palette.primary.main
        }
      }
    } as Components['MuiInputLabel']
  };
}
