import { Box, Chip, styled, Tooltip, tooltipClasses, TooltipProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import Profile from './Profile';
import Notification from './Notification';
import { useSelector } from '../../../../store';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import NotificationsIcon from '@mui/icons-material/Notifications';

// ==============================|| HEADER - CONTENT ||============================== //
type HeaderProps = {
  handleDrawerNotificationToggle: () => void;
};

const AccountDemoTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.warning.main,
      cursor: 'info'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.warning.main
    }
  })
);
const HeaderContent = ({ handleDrawerNotificationToggle }: HeaderProps) => {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display={'flex'} alignItems={'center'}>
      {user?.isDemo && (
        <Box
          sx={{
            mr: 1
          }}
        >
          <AccountDemoTooltip
            arrow
            title={
              <Typography variant={'body2'} sx={{ cursor: 'info' }}>
                Questo è un account di prova. È possibile testare tutte le funzionalità della piattaforma senza procedere con i pagamenti
              </Typography>
            }
          >
            <Chip
              variant={'filled'}
              color={'warning'}
              size={isMobile ? 'small' : 'medium'}
              data-cy={'account_demo_label'}
              label={
                <Typography variant={'body1'} textTransform={'uppercase'} fontWeight={600}>
                  Demo account
                </Typography>
              }
              icon={<InfoOutlinedIcon />}
            />
          </AccountDemoTooltip>
        </Box>
      )}
      <Profile />
      <Notification handleDrawerNotificationToggle={handleDrawerNotificationToggle} />
    </Box>
  );
};

export default HeaderContent;
