import {
  Box,
  Button,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  styled,
  Fade,
  IconButton,
  Pagination
} from '@mui/material';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { PaginationProps } from 'types/server/pagination-props';
import { NotificationDTO } from 'types/dto/notification.dto';
import { CountedData } from 'types/server/counted-data';
import { formatFilters, toEquals, toGte, toLte, toNot } from 'utils/filtersUtils/formatFilters';
import getTimeSpent from 'utils/getTimeSpent';

import dayjs from 'dayjs';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useEndpoint } from '../../hooks/useEndpoint';
import NotificationSkeleton from '../../components/custom/Skeletons/NotificationSkeleton';
import { dispatch, useSelector } from '../../store';
import { setNotificationCount, setRefetchNotifications } from '../../store/reducers/websocket';
import { getNotificationAction, getNotificationTitle } from '../../utils/notificationUtils';
import { RadioButtonChecked } from '@mui/icons-material';
import { formatSorting } from '../../utils/filtersUtils/formatSorting';
import { useNavigate } from 'react-router-dom';

type ListItemNotifyStyleProps = ListItemProps & {
  read: string;
  // severity: AlertProps['severity'];
  cursor: CSSProperties['cursor'];
};
type ActiveTabType = 'all' | 'notRead' | 'read';

export const ListItemNotifyStyle = styled(ListItem)<ListItemNotifyStyleProps>(({ theme, read, cursor }) => ({
  width: '100%',
  backgroundColor: read === 'true' ? '#EDF8F9' : 'transparent',
  padding: '8px 16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: 2,
  justifyContent: 'space-between',
  cursor
}));

const NotificationsPage = () => {
  const navigate = useNavigate();
  const { refetchNotifications, notificationCount } = useSelector((state) => state.websocket);
  const { holder } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState<ActiveTabType>('all');

  const [pagination, setPagination] = useState<PaginationProps>({
    pageIndex: 0,
    pageSize: 10
  });
  const orderBy = useMemo(() => formatSorting([{ id: 'at', desc: true }]), []);
  const filterByOldNotifications = useMemo(
    () =>
      JSON.stringify(
        formatFilters([
          { id: 'at', value: dayjs().hour(0).minute(0).toISOString(), transformer: toLte },
          ...(activeTab !== 'all' ? [{ id: 'readAt', value: null, transformer: activeTab === 'notRead' ? toEquals : toNot }] : [])
        ])
      ),
    [activeTab]
  );

  const filterByTodayNotifications = useMemo(
    () =>
      JSON.stringify(
        formatFilters([
          ...(activeTab !== 'all' ? [{ id: 'readAt', value: null, transformer: activeTab === 'notRead' ? toEquals : toNot }] : []),
          { id: 'at', value: dayjs().hour(0).minute(0).toISOString(), transformer: toGte }
        ])
      ),
    [activeTab]
  );

  const getOldNotifications = useEndpoint<CountedData<NotificationDTO>, 'get'>({
    method: 'get',
    endpoint: '/notifications',
    queryKey: 'get-old-notifications',
    queryParams: {
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
      filterBy: filterByOldNotifications,
      orderBy
    },
    options: {
      enabled: true,
      cacheTime: 0
    }
  });
  const previouseNotifications = getOldNotifications.data?.data;

  const getTodayNotifications = useEndpoint<CountedData<NotificationDTO>, 'get'>({
    method: 'get',
    endpoint: '/notifications',
    queryKey: 'get-today-notifications',
    queryParams: {
      skip: pagination.pageIndex * pagination.pageSize,
      take: pagination.pageSize,
      filterBy: filterByTodayNotifications,
      orderBy
    },
    options: {
      enabled: true,
      cacheTime: 0
    }
  });
  const todayNotifications = getTodayNotifications.data?.data;

  const readNotifications = useEndpoint<{ ids: number[] } | undefined, 'put'>({
    method: 'put',
    mutationKey: 'read-notifications',
    endpoint: '/notifications/readings',
    options: {
      onSuccess: (data, variables, context) => {
        getOldNotifications.refetch();
        getTodayNotifications.refetch();
        dispatch(
          setRefetchNotifications({
            ...refetchNotifications,
            refetchDrawer: true
          })
        );
        dispatch(setNotificationCount(variables != null ? notificationCount - variables.ids.length : 0));
      }
    }
  });

  const handleChangeTab = useCallback((value: ActiveTabType) => {
    setActiveTab(value);
    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize
    });
  }, []);

  const handleReadNotify = useCallback((id: number) => {
    readNotifications.mutate({ ids: [id] });
  }, []);

  const handleReadAllNotifications = useCallback(() => {
    readNotifications.mutate(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTodayNotifications.refetch();
    getOldNotifications.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, activeTab]);

  useEffect(() => {
    if (refetchNotifications.refetchPage) {
      getTodayNotifications.refetch().then(() => {
        getOldNotifications.refetch().then(() => {
          dispatch(
            setRefetchNotifications({
              ...refetchNotifications,
              refetchPage: false
            })
          );
        });
      });
    }
  }, [refetchNotifications.refetchPage]);

  return (
    <Fade in>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {notificationCount > 0 && (
          <Fade in>
            <Box textAlign={'end'} sx={{ transform: 'translateY(-50px)', height: 0 }}>
              <Button
                variant="contained"
                size="small"
                onClick={handleReadAllNotifications}
                sx={{
                  borderRadius: 2,
                  fontSize: '.7rem'
                }}
              >
                Segna tutte come lette
              </Button>
            </Box>
          </Fade>
        )}

        <Box flexGrow={0}>
          <Tabs value={activeTab} onChange={(e, newValue) => handleChangeTab(newValue)}>
            <Tab label={'Tutte'.toUpperCase()} value={'all'} />
            <Tab label={'Non Lette'.toUpperCase()} value={'notRead'} />
            <Tab label={'Lette'.toUpperCase()} value={'read'} />
          </Tabs>
        </Box>
        <Box flexGrow={1} mb={7}>
          <List>
            {/* Oggi */}
            {todayNotifications && todayNotifications.data.length > 0 && !getTodayNotifications.isLoading ? (
              <>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h5">Oggi</Typography>
                  </ListItemText>
                </ListItem>
                {todayNotifications.data.map((notify, i) => {
                  const notifyAction =
                    notify.operation === 'delete' && notify.resource === 'order' ? undefined : getNotificationAction(notify);

                  return (
                    <Fade in key={i}>
                      <ListItemNotifyStyle
                        read={notify.readAt != null ? 'false' : 'true'}
                        key={i}
                        cursor={notifyAction != null ? 'pointer' : 'default'}
                        onClick={() => {
                          if (notify.readAt == null && holder == null) handleReadNotify(notify.id);
                          if (notifyAction == null) return;
                          navigate(notifyAction);
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '3px',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <Typography variant="body1" fontWeight={500}>
                            {getNotificationTitle(notify)}
                          </Typography>
                          {notify.description != null && <Typography variant={'body1'}>{notify.description.it} </Typography>}
                          <Typography variant={'body2'}>{getTimeSpent(notify.at)}</Typography>
                        </Box>
                        {notify.readAt == null ? (
                          <IconButton
                            sx={{ width: '30px', height: '30px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (holder != null) return;
                              handleReadNotify(notify.id);
                            }}
                          >
                            <RadioButtonUncheckedIcon color={'primary'} fontSize={'medium'} />
                          </IconButton>
                        ) : (
                          <RadioButtonChecked color={'primary'} fontSize={'medium'} />
                        )}
                      </ListItemNotifyStyle>
                    </Fade>
                  );
                })}
              </>
            ) : pagination.pageIndex === 0 && !getTodayNotifications.isLoading ? (
              <>
                <ListItem>
                  <ListItemText>
                    <Typography variant="h5">Oggi</Typography>
                  </ListItemText>
                </ListItem>
                <Fade in>
                  <ListItemNotifyStyle read="false" cursor={'default'}>
                    <Typography variant="h6" fontWeight={400}>
                      Nessuna Notifica Giornaliera
                    </Typography>
                  </ListItemNotifyStyle>
                </Fade>
              </>
            ) : getTodayNotifications.isLoading ? (
              <NotificationSkeleton item={3} />
            ) : (
              <></>
            )}

            {/* Precendenti */}
            <ListItem>
              <ListItemText>
                <Typography variant="h5">Precedenti</Typography>
              </ListItemText>
            </ListItem>
            {previouseNotifications && previouseNotifications.data.length > 0 && !getOldNotifications.isLoading ? (
              <>
                {previouseNotifications.data.map((notify, i) => {
                  const notifyAction =
                    notify.operation === 'delete' && notify.resource === 'order' ? undefined : getNotificationAction(notify);

                  return (
                    <Fade in key={i}>
                      <ListItemNotifyStyle
                        read={notify.readAt != null ? 'false' : 'true'}
                        key={i}
                        cursor={notifyAction != null ? 'pointer' : 'default'}
                        onClick={() => {
                          if (notifyAction == null) return;

                          navigate(notifyAction);
                          if (notify.readAt == null && holder == null) handleReadNotify(notify.id);
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '3px',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start'
                          }}
                        >
                          <Typography variant="body1" fontWeight={500}>
                            {getNotificationTitle(notify)}
                          </Typography>
                          {notify.description != null && <Typography variant={'body1'}>{notify.description.it}</Typography>}
                          <Typography variant={'body2'}>{getTimeSpent(notify.at)}</Typography>
                        </Box>
                        {notify.readAt == null ? (
                          <IconButton
                            sx={{ width: '30px', height: '30px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (holder != null) return;
                              handleReadNotify(notify.id);
                            }}
                          >
                            <RadioButtonUncheckedIcon color={'primary'} fontSize={'medium'} />
                          </IconButton>
                        ) : (
                          <RadioButtonChecked color={'primary'} fontSize={'medium'} />
                        )}
                      </ListItemNotifyStyle>
                    </Fade>
                  );
                })}
              </>
            ) : !getOldNotifications.isLoading ? (
              <Fade in>
                <ListItemNotifyStyle read="false" cursor={'default'}>
                  <Typography variant="h6" fontWeight={400}>
                    Nessuna Notifica Precedente
                  </Typography>
                </ListItemNotifyStyle>
              </Fade>
            ) : (
              <NotificationSkeleton item={6} />
            )}
          </List>
        </Box>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 0px 0px 1px #E0E0E0',
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: 0,
            paddingY: '15px',
            paddingX: '20px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {/*<TablePagination*/}
          {/*  component="div"*/}
          {/*  rowsPerPage={pagination.pageSize}*/}
          {/*  count={*/}
          {/*    todayNotifications != null && previouseNotifications != null*/}
          {/*      ? todayNotifications?.totalCount + previouseNotifications?.totalCount*/}
          {/*      : 0*/}
          {/*  }*/}
          {/*  page={pagination.pageIndex}*/}
          {/*  onPageChange={(e, page) => setPagination({ pageSize: pagination.pageSize, pageIndex: page })}*/}
          {/*  // onRowsPerPageChange={(e) => setPagination({ pageIndex: pagination.pageIndex, pageSize: parseInt(e.target.value) })}*/}
          {/*  // rowsPerPage={pagination.pageSize}*/}
          {/*  // sx={{*/}
          {/*  //   '.MuiTablePagination-selectLabel': { display: 'none' },*/}
          {/*  //   '.css-8i9pzi-MuiInputBase-root-MuiTablePagination-select': { display: 'none' }*/}
          {/*  // }}*/}
          {/*/>*/}

          <Pagination
            color={'standard'}
            count={
              todayNotifications != null && previouseNotifications != null
                ? todayNotifications?.totalCount + previouseNotifications?.totalCount
                : 0
            }
            page={pagination.pageIndex}
            size={'medium'}
            onChange={(e, page) => setPagination({ pageSize: pagination.pageSize, pageIndex: page })}
          />
        </Box>
      </Box>
    </Fade>
  );
};

export default NotificationsPage;
